import React from 'react';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

class TotalBel extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('reports.reportTotalBel');

    return (
      <BaseBrandReport
        title={title}
        excelUrl="reports/total-bel"
        mimeType="application/vnd.ms-excel"
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_TOTAL_BEL}
        excelAvaliable
        useMealCategories={true}
        reportConfiguration={{
          report: 'TotalBel',
          fields: [
            {
              size: 12,
              field: 'groupAddressStreet',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: true,
                    label: this.props.t(`common.yes`),
                  },
                  {
                    value: false,
                    label: this.props.t(`common.no`),
                  },
                ],
              },
            },
          ],
        }}
        useZoneCategories={true}
        archiveReportNames={['TOTAL_BEL']}
        dateType="single"
      />
    );
  }
}

export default withTranslation()(TotalBel);
