import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { fetchPermissions } from 'actions/Permissions';
import { fetchRole } from 'actions/Roles';
import { onDeselect, onSelect } from './permissionsConfig';
import { withToast } from 'material-ui-toast-redux';

import { post, put } from 'helpers/apiHelpers';

import FormLabel from '@material-ui/core/FormLabel';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInputNoGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { withTranslation } from 'react-i18next';

import { translateModules } from './RoleModules/translateModules';
import { translateActions } from './RoleModules/translateActions';
import { ROLE_BLOCKS } from './RoleModules/roleBlocks';
import RoleSections from './RoleModules/RoleSections';
import RoleSubSection from './RoleModules/RoleSubSection';

const lockedPermissions = [];
class RolesForm extends Component {
  state = {
    name: '',
    shortName: '',
    permissions: lockedPermissions,
    translatedActions: translateActions(this.props.t),
    translatedModules: translateModules(this.props.t),
  };

  isEdit = this.props.match.path.includes('edit');
  roleId = this.props.match.params.id;

  componentDidMount() {
    this.props.fetchPermissions();
    if (this.isEdit) {
      this.props.fetchRole(this.roleId).then(response =>
        this.setState({
          name: response.name,
          shortName: response.shortName,
          permissions: response.permissions,
        })
      );
    }
  }

  translate() {
    this.setState({
      translatedAction: translateActions(this.props.t),
      translatedModules: translateModules(this.props.t),
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.i18n.lang !== this.props.i18n.lang) {
      this.translate();
    }
  }

  showRoleToast = (action, toastInfoMessage, role, toastActionMessage) => {
    let arrayToCheck = [];
    let filteredArrayToCheck = [];

    if (action === 'select') {
      arrayToCheck = onSelect[role];
      filteredArrayToCheck = arrayToCheck.filter(
        i => !this.state.permissions.includes(i)
      );
    } else if (action === 'deselect') {
      arrayToCheck = onDeselect[role];
      filteredArrayToCheck = arrayToCheck.filter(i =>
        this.state.permissions.includes(i)
      );
    }

    if (filteredArrayToCheck.length > 0) {
      const messages = [
        toastInfoMessage,
        ...this.prepareRequiredRolesMessage(filteredArrayToCheck),
        toastActionMessage,
      ];
      return this.props.openToast({
        messages,
        type: 'info',
        autoHideDuration: 6000,
      });
    }
  };

  prepareRequiredRolesMessage = rolesToCheck => {
    return rolesToCheck.map(role => {
      const roleArrayStrings = role.split('_');
      const roleNameArray = roleArrayStrings.slice(2);

      const moduleKey = roleNameArray.join('_');
      const actionKey = roleArrayStrings[1];

      const translatedModule = this.state.translatedModules[moduleKey];
      const translatedAction = this.state.translatedActions[actionKey];

      return `${translatedModule} - ${translatedAction}`;
    });
  };

  handleInputChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheckboxChange = (ev, append, role) => {
    let newPermissions = [...this.state.permissions];
    if (!append) {
      const selectRequiredInfoMessage = this.props.t(
        'rolesForm.selectRoles',
        'Zaznaczenie tej roli wymaga też zaznaczenia ról:'
      );
      const selectRequiredActionMessage = this.props.t(
        'rolesForm.autoSelect',
        'Wymagane role zostały automatycznie zaznaczone.'
      );
      onSelect[role] &&
        this.showRoleToast(
          'select',
          selectRequiredInfoMessage,
          role,
          selectRequiredActionMessage
        );

      if (newPermissions.includes(role)) {
        const index = newPermissions.indexOf(role);
        newPermissions.splice(index, 1);
      } else newPermissions.push(role, ...(onSelect[role] || []));
    } else {
      const selectRequiredInfoMessage = this.props.t(
        'rolesForm.deselectRoles',
        'Odznaczenie tej roli wymaga też odznaczenia ról:'
      );
      const selectRequiredActionMessage = this.props.t(
        'rolesForm.autoDeselect',
        'Wymagane role zostały automatycznie odznaczone.'
      );
      onDeselect[role] &&
        this.showRoleToast(
          'deselect',
          selectRequiredInfoMessage,
          role,
          selectRequiredActionMessage
        );
      newPermissions = newPermissions.filter(
        perm => !(onDeselect[role] || []).includes(perm) && perm !== role
      );
    }

    this.setState({
      permissions: newPermissions,
    });
  };

  getRoleByType = (type, permissions) => {
    const perm = permissions.find(permission => permission.type === type);

    return perm ? perm.role : false;
  };

  validatePermissions = () => {
    return this.state.permissions.length > 0;
  };

  validateForm = () => {
    return this.state.name;
  };

  handleSubmit = () => {
    if (!this.validatePermissions()) {
      return alert(
        this.props.t(
          'rolesForm.onePermission',
          'Musisz nadać przynajmniej jedno uprawnienie'
        )
      );
    }
    if (!this.validateForm()) {
      return alert(
        this.props.t(
          'rolesForm.mandatoryFields',
          'Wypełnij wszystkie obowiązkowe pola'
        )
      );
    }
    const data = this.state;

    const action = this.isEdit
      ? put(`/roles/${this.roleId}`, data)
      : post('/roles', data);

    action.then(() => this.props.history.push('/admin/roles'));
  };

  render() {
    const { classes } = this.props;
    return (
      <form>
        <h2>
          {' '}
          {this.isEdit
            ? this.props.t('myCompany.roleEdit')
            : this.props.t('myCompany.roleCreation')}{' '}
        </h2>
        <AdminTable>
          <GridContainer>
            {/* Start - first section -> name and shortName */}
            <GridItem xs={6}>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('myCompany.roleName')} *
              </FormLabel>
              <FormTextInput
                classes={classes}
                value={this.state.name}
                name="name"
                handleChange={ev => this.handleInputChange(ev)}
              />
            </GridItem>
            <GridItem xs={6}>
              <FormLabel className={classes.labelHorizontal}>
                {this.props.t('myCompany.roleShort')}
              </FormLabel>
              <FormTextInput
                classes={classes}
                value={this.state.shortName}
                name="shortName"
                handleChange={ev => this.handleInputChange(ev)}
              />
            </GridItem>
          </GridContainer>
        </AdminTable>

        {ROLE_BLOCKS.map(role => {
          const sectionPermissions = role.permissionTitle.map(title =>
            this.props.permissions.find(permission => {
              return permission.name === title;
            })
          );

          let subSectionPermissions;
          if (role.subRoleSection) {
            subSectionPermissions = role?.subRoleSection?.options?.map(title =>
              this.props.permissions.find(permission => {
                return permission.name === title;
              })
            );
          }

          let subSeccondSectionPermissions;
          if (role.subRoleSectionSeccond) {
            subSeccondSectionPermissions =
              role?.subRoleSectionSeccond?.options.map(title =>
                this.props.permissions.find(permission => {
                  return permission.name === title;
                })
              );
          }
          return (
            <>
              {role.permissionTitle.length > 0 && (
                <AdminTable key={role.name}>
                  <RoleSections
                    section={role}
                    permissionGroup={sectionPermissions}
                    assignedPermissions={this.state.permissions}
                    handleCheckboxChange={this.handleCheckboxChange}
                  />
                </AdminTable>
              )}
              {role.subRoleSection && (
                <AdminTable
                  title={
                    role?.subRoleSection?.title &&
                    this.props.t(`${role?.subRoleSection?.title}`)
                  }
                >
                  <GridContainer>
                    {subSectionPermissions?.map((role, index) => {
                      return (
                        <RoleSubSection
                          role={role}
                          assignedPermissions={this.state.permissions}
                          key={`${role?.name}${index}`}
                          handleCheckboxChange={this.handleCheckboxChange}
                        />
                      );
                    })}
                  </GridContainer>
                </AdminTable>
              )}

              {role.subRoleSectionSeccond && (
                <AdminTable>
                  <GridContainer>
                    <GridItem xs={12}>
                      {this.props.t(`${role?.subRoleSectionSeccond?.title}`)}
                    </GridItem>
                    {subSeccondSectionPermissions?.map((role, index) => {
                      return (
                        <RoleSubSection
                          role={role}
                          assignedPermissions={this.state.permissions}
                          key={`${role?.name}${index}`}
                          handleCheckboxChange={this.handleCheckboxChange}
                        />
                      );
                    })}
                  </GridContainer>
                </AdminTable>
              )}
            </>
          );
        })}

        {/* last section-> save and cancel button */}
        <GridContainer>
          <GridItem xs={12}>
            <FormControlButtons
              classes={classes}
              discardText={this.props.t('common.shared.cancel')}
              submitText={this.props.t('common.shared.save')}
              cancelPath="/admin/roles"
              handleSubmit={this.handleSubmit}
            />
          </GridItem>
        </GridContainer>
      </form>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const mapStateToProps = state => ({
  permissions: state.Permissions.permissions,
  role: state.Roles.role,
});

const mapDispatchToProps = dispatch => ({
  fetchPermissions: () => dispatch(fetchPermissions()),
  fetchRole: id => dispatch(fetchRole(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withToast(withStyles(combinedStyles)(RolesForm))));
