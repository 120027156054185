import React from 'react';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';

const DefaultOrderMode = ({
  t,
  classes,
  modules,
  defaultPaymentOptions,
  setModules,
}) => {
  return (
    <>
      <GridItem sm={3}>
        {t(
          'paymentMethod.defaultOrderMode.description',
          'Domyślny tryb zamówienia'
        )}
      </GridItem>
      <GridItem sm={3}>
        <SelectInput
          noGrid
          classes={classes}
          mapBy="label"
          trackBy="value"
          name="defaultPaymentMode"
          value={modules.ConfigClientPanel.defaultPaymentMode}
          disabledMenuItems={defaultPaymentOptions.filter(({ value }) => {
            return (
              {
                2: !modules.ConfigClientPanel.isOneTimePayPaymentMode,
                1: !modules.ConfigClientPanel.isSubscriptionPaymentMode,
              }[value] ?? false
            );
          })}
          options={defaultPaymentOptions}
          handleChange={e =>
            setModules(prev => ({
              ...prev,
              ConfigClientPanel: {
                ...prev.ConfigClientPanel,
                defaultPaymentMode: e.target.value,
              },
            }))
          }
          size={12}
        />
      </GridItem>
    </>
  );
};

export default DefaultOrderMode;
