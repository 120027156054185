import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';

import AsyncSelect from 'react-select/lib/Async';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  chip: {
    margin: `20px 10px`,
  },
  chipFocused: {
    // backgroundColor: emphasize(
    //   theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
    //   0.08,
    // ),
  },
  noOptionsMessage: {
    padding: `10px 20px`,
  },
  singleValue: {
    fontSize: 14,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 14,
  },
  paper: {
    position: 'absolute',
    zIndex: 999,
    marginTop: '20px',
    left: 0,
    right: 0,
  },
  divider: {
    height: '20px',
  },
}));

function NoOptionsMessage(props) {
  const { t } = useTranslation();
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {t('components.formSelectDropDown.nameEnter', 'Wpisz nazwę')}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function Control(props) {
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: props.selectProps.classes.input,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

function Option(props) {
  return (
    <MenuItem
      buttonRef={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        height: 'auto',
        fontSize: '14px',
        fontWeight: props.isSelected ? 500 : 400,
        whiteSpace: 'normal',
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

function SingleValue(props) {
  return (
    <Typography
      className={props.selectProps.classes.singleValue}
      {...props.innerProps}
    >
      {props.children}
      {!props.isDisabled && !props.isMulti && props.hasValue && (
        <div
          style={{
            background: '#fff',
            position: 'absolute',
            cursor: 'pointer',
            right: '38px',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: '2',
            borderLeft: '1px solid',
            padding: '2px 10px',
            color: '#ababab',
          }}
          onClick={() => props.setValue(null)}
        >
          X
        </div>
      )}
    </Typography>
  );
}

function ValueContainer(props) {
  return (
    <div
      className={props.selectProps.classes.valueContainer}
      style={{ psoition: 'relative' }}
    >
      {props.children}
    </div>
  );
}

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={classNames(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  );
}

function Menu(props) {
  return (
    <Paper
      square
      className={props.selectProps.classes.paper}
      {...props.innerProps}
    >
      {props.children}
    </Paper>
  );
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

function FormSelectDropdown({
  handleClick,
  filter,
  clearAfterSelect,
  filterName,
  filterValue,
  includeWorkName,
  placeholder,
  includeId,
  value,
}) {
  const classes = useStyles();

  const [typingTimeout, setTypingTimeout] = useState(null);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [val, setVal] = useState(value ?? null);

  function handleChangeSingle(value) {
    setVal(value);
    handleClick(value);

    if (clearAfterSelect) {
      setVal(null);
      setInputValue('');
    }
  }

  useEffect(() => {
    setVal(value);
  }, [JSON.stringify(value)]);

  const selectStyles = {
    input: base => ({
      ...base,
      color: 'black',
      '& input': {
        font: 'inherit',
      },
    }),
  };

  const loadWithTimeout = (inputValue, callback) => {
    if (typingTimeout) clearTimeout(typingTimeout);

    setTypingTimeout(setTimeout(() => loadOptions(inputValue, callback), 400));
  };

  const loadOptions = (inputValue, callback) => {
    const filterNameFromProps = filterName || 'name';
    const filterValueFromProps = filterValue || 'name';

    return filter(inputValue)
      .then(async x => {
        const filteredOptions = x.map(el => {
          let label;
          let value;
          if (typeof filterNameFromProps === 'function') {
            label = filterNameFromProps(el);
          } else {
            label = includeId ? `(${el['id']}) ` : '';
            label += `${el[filterNameFromProps]}`;
            label += includeWorkName ? ` (${el['workName']})` : '';
          }
          if (typeof filterValueFromProps === 'function') {
            value = filterValueFromProps(el);
          } else {
            value = el[filterNameFromProps];
          }
          return {
            ...el,
            label,
            value: value,
          };
        });
        await setOptions(filteredOptions);
        return filteredOptions;
      })
      .then(c => callback(c));
  };

  return (
    <div className={classes.root}>
      <NoSsr>
        <AsyncSelect
          loadOptions={loadWithTimeout}
          defaultOptions={options}
          classes={classes}
          styles={selectStyles}
          components={components}
          onChange={handleChangeSingle}
          value={val}
          inputValue={inputValue}
          onInputChange={value => setInputValue(value)}
          placeholder={placeholder || 'Dodaj'}
        />
        <div className={classes.divider} />
      </NoSsr>
    </div>
  );
}

export default FormSelectDropdown;
