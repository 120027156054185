import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { get } from 'helpers/apiHelpers';

import DataGrid from 'components/DataGrid/DataGrid';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';

import LogView from 'components/History/LogView';
import FormTags from 'components/FormTags/FormTags';
import ReactSelect from 'components/ReactSelect/ReactSelect';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormSelectDropdown from 'components/FormSelect/FormSelectDropdown';
import IngredientsDetails from './IngredientsDetails';
import FormLabel from '@material-ui/core/FormLabel';

import productionHistoryColumnConfig from './productionHistoryColumnConfig';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import CardWrapper from 'components/Card/CardWrapper';

const mapEmployeeToLabel = employee =>
  `${employee.firstName} ${employee.lastName} (${employee.email})`;

const RegularRecipeForm = ({
  tags,
  type,
  match,
  filter,
  classes,
  recipeId,
  getImage,
  handleWpOM,
  removeImage,
  recipeState,
  handleSubmit,
  getAllergens,
  selectedTags,
  handleChange,
  workNameState,
  addIngredient,
  defaultWorker,
  selectEmployee,
  handleQuantity,
  handleUserValue,
  preparationTime,
  handleTagChange,
  userIngredients,
  includeWorkName,
  removeIngredient,
  kitchenImageStateUrl,
  handleThermalProcessing,
  handleWorkingOnMachining,
  departmentState,
  kitchenState,
  commontWotValue,
  isCommonWot,
  handleClickCommonWot,
  handleChangeCommonWotValue,
}) => {
  const { t } = useTranslation();
  const [kitchens, setKitchens] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    fetchKitchens();
    fetchDepartments();
    if (recipeId) {
      fetchEmployees();
    }
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await get(
        `/employees/list?recipeProductionHistoryId=${recipeId}`,
        { partial: false }
      );
      setEmployees(
        (response?.['hydra:member'] ?? []).map(
          ({ firstName, lastName, '@id': iri, image }) => ({
            '@id': iri,
            value: `${firstName} ${lastName}`,
            contentUrl: image?.contentUrl,
          })
        )
      );
    } catch (error) {}
  };

  const fetchKitchens = async () => {
    try {
      const response = await get('/kitchens');

      setKitchens(response?.['hydra:member'] ?? []);
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await get('/departments');

      setDepartments(response?.['hydra:member'] ?? []);
    } catch (error) {
      console.log({ error });
    }
  };

  const filterEmployee = e => {
    return new Promise(async res => {
      try {
        const employeesResponse = await get('/employees', {
          pageSize: 30,
          page: 1,
          sorted: [],
          _orX: [[{ id: e, firstName: e, lastName: e, email: e }]],
        });

        res(employeesResponse['hydra:member']);
      } catch (e) {
        console.error(e);
        res([]);
      }
    });
  };

  return (
    <>
      <CardWrapper title={t('form.basicInfo')}>
        <CardBody>
          <form>
            <GridContainer justify="center">
              <GridItem md={12}>
                <GridContainer>
                  <GridItem md={4}>
                    <FormTextInput
                      label={`${t('form.field.workingName')} *`}
                      classes={classes}
                      name="workNameState"
                      value={workNameState}
                      handleChange={handleChange}
                      inputSize={12}
                    />
                  </GridItem>
                  <GridItem md={4}>
                    <GridContainer>
                      <GridItem md={12} style={{ marginTop: '-3px' }}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t('form.field.tags.description')}
                        </FormLabel>
                        <ReactSelect
                          selectedValues={selectedTags}
                          options={tags}
                          handleChange={handleTagChange}
                        />
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem md={4}>
                    <FormSelectSingle
                      classes={classes}
                      label={t('form.field.type')}
                      options={[
                        {
                          label:
                            t('common.lack', 'Brak')[0].toUpperCase() +
                            t('common.lack', 'Brak').slice(1),
                          value: null,
                        },
                        {
                          label: t('form.field.type.WEIGHT'),
                          value: 'WEIGHT',
                        },
                        {
                          label: t('form.field.type.PIECE'),
                          value: 'PIECE',
                        },
                      ]}
                      value={type}
                      mapBy="label"
                      trackBy="value"
                      name="type"
                      handleChange={handleChange}
                      id="type"
                      allowDeselect={true}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem xs={12}>
                <GridContainer>
                  <GridItem md={4}>
                    <FormSelectSingle
                      classes={classes}
                      label={t('form.field.kitchen', '$$Kuchnia')}
                      options={kitchens}
                      value={kitchenState}
                      mapBy="value"
                      trackBy="@id"
                      name="kitchenState"
                      handleChange={handleChange}
                      id="kitchen"
                      allowDeselect={true}
                    />
                  </GridItem>
                  <GridItem md={4}>
                    <FormSelectSingle
                      classes={classes}
                      label={t('form.field.department', '$$Dział')}
                      options={departments}
                      value={departmentState}
                      mapBy="value"
                      trackBy="@id"
                      name="departmentState"
                      handleChange={handleChange}
                      id="department"
                      allowDeselect={true}
                    />
                  </GridItem>
                  <GridItem md={4}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.defaultWorker', '$$Domyślny pracownik')}
                    </FormLabel>
                    <FormSelectDropdown
                      handleClick={selectEmployee}
                      placeholder={t('form.field.search')}
                      filter={filterEmployee}
                      filterName={mapEmployeeToLabel}
                      filtervalue="@id"
                      includeWorkName={false}
                      value={
                        defaultWorker
                          ? {
                              value: defaultWorker['@id'],
                              label: mapEmployeeToLabel(defaultWorker),
                            }
                          : null
                      }
                      includeId
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </form>
        </CardBody>
      </CardWrapper>
      <CardWrapper title={t('form.field.ingredients')}>
        <CardBody>
          <GridContainer justify="center">
            <GridItem md={12}>
              <FormSelectDropdown
                handleClick={addIngredient}
                placeholder={t('form.field.search')}
                filter={filter}
                includeWorkName={includeWorkName}
                clearAfterSelect={true}
                includeId
              />
              <IngredientsDetails
                data={userIngredients}
                classes={classes}
                handleUserValue={handleUserValue}
                handleQuantity={handleQuantity}
                handleRemove={removeIngredient}
                handleWpOM={handleWpOM}
                handleThermalProcessing={handleThermalProcessing}
                handleWorkingOnMachining={handleWorkingOnMachining}
                commontWotValue={commontWotValue}
                isCommonWot={isCommonWot}
                handleClickCommonWot={handleClickCommonWot}
                handleChangeCommonWotValue={handleChangeCommonWotValue}
              />
            </GridItem>
            <GridItem md={6}>
              <FormTextInput
                label={t('form.field.recipe')}
                classes={classes}
                name="recipeState"
                value={recipeState}
                handleChange={handleChange}
                inputSize={12}
                multiline={true}
                rows={3}
                rowsMax={20}
                maxLength={5000}
              />
            </GridItem>
            <GridItem md={6}>
              <FormTags
                label={t('form.field.allergens')}
                classes={classes}
                tagProps={{
                  className:
                    'react-tagsinput-tag react-tagsinput-tag-small react-tagsinput-tag-disabled danger',
                }}
                state={getAllergens}
                disabled={true}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
      <CardWrapper title={t('form.additionalInfo')}>
        <CardBody>
          <GridContainer justify="center">
            <GridItem md={12}>
              <GridContainer>
                <GridItem md={6}>
                  <FormImageUpload
                    label={t('form.field.kitchenImage')}
                    classes={classes}
                    stateName="kitchenImageState"
                    getImage={getImage}
                    removeImage={removeImage}
                    previewUrl={kitchenImageStateUrl}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>

      <CardWrapper title={t('$*recipe.form.usedInDishes', '$$Użyty w daniach')}>
        <CardBody>
          <DataGrid
            actions={false}
            remove={false}
            hideArchive={true}
            paginationTop={true}
            paginationBottom={false}
            url={'/dishes'}
            columns={[
              {
                title: 'ID',
                accessor: 'id',
                name: 'id',
                filterable: true,
                sortable: true,
              },
              {
                title: 'form.field.name',
                accessor: 'nameForClient',
                name: 'nameForClient',
                filterable: true,
                sortable: true,
              },
            ]}
            manipulateQuery={(table, query) => {
              query['components.recipe.id'] = recipeId;
              return query;
            }}
          />
        </CardBody>
      </CardWrapper>
      {recipeId && (
        <CardWrapper
          title={t(
            '$*recipe.productionHistory.title',
            '$$Historia produkowania przepisu'
          )}
        >
          <CardBody>
            <DataGrid
              url={`/cook-recipe-tasks?recipe.id=${recipeId}`}
              minRows={15}
              columns={productionHistoryColumnConfig({
                t,
                kitchens,
                employees,
                departments,
              })}
              manipulateQuery={(table, query) => {
                query['properties'] = [
                  'id',
                  'taskList',
                  'assignedEmployee',
                  'amountToMake',
                  'amountOfDone',
                  'timeEntries',
                  'value',
                  'recipe',
                ];
                query['properties[taskList]'] = ['generatedAt'];
                query['properties[assignedEmployee]'] = [
                  'id',
                  'firstName',
                  'lastName'
                ];
                query['properties[timeEntries]'] = ['timeEntries', 'endAt'];
                query['properties[recipe]'] = [
                  'macrosAfterProcessing',
                  'productionHistory',
                  'kitchen',
                ];
                query['properties[recipe][macrosAfterProcessing]'] = ['weight'];
                query['properties[recipe][kitchen]'] = ['value'];
                query['properties[recipe][department]'] = ['value'];

                return query;
              }}
              paginationTop={true}
              paginationBottom={false}
              defaultHiddenColumns={['id']}
            />
          </CardBody>
        </CardWrapper>
      )}

      {!recipeId && (
        <FormControlStickyButton
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath="/admin/recipes"
          handleSubmit={handleSubmit}
        />
      )}
      {recipeId && (
        <LogView
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath="/admin/recipes"
          handleSubmit={handleSubmit}
          iri={`/recipes/${match.params.id}`}
        />
      )}
    </>
  );
};

export default RegularRecipeForm;
