import cx from 'classnames';
import { NavLink } from 'react-router-dom';

import Person from '@material-ui/icons/Person';
import ListItemText from '@material-ui/core/ListItemText';

const UserBar = ({ user, classes, bgColor, rtlActive, isMenuExpanded }) => {
  const uploadedContentBaseURL = `${process.env.REACT_APP_API_ENDPOINT}uploaded-media/`;

  const logoMini =
    classes.logoMini +
    ' ' +
    cx({
      [classes.logoMiniRTL]: rtlActive,
    });

  const logoClasses =
    classes.logo +
    ' ' +
    cx({
      [classes.whiteAfter]: bgColor === 'white',
    });

  const itemText =
    classes.itemText +
    ' ' +
    cx({
      [classes.itemTextMini]: !isMenuExpanded,
      [classes.itemTextMiniRTL]: rtlActive && !isMenuExpanded,
      [classes.itemTextRTL]: rtlActive,
    });

  return (
    <div className={logoClasses}>
      {user.image ? (
        <div style={{ marginLeft: '25px' }} className={logoMini}>
          <img
            style={{
              width: '30px',
              height: '30px',
              borderRadius: '50%',
              verticalAlign: 'middle',
            }}
            alt="userAvatar"
            src={uploadedContentBaseURL + user.imageUrl}
          />
        </div>
      ) : (
        <div style={{ marginLeft: '25px' }} className={logoMini}>
          <Person />
        </div>
      )}
      <NavLink
        to="/admin/my-profile"
        style={{ cursor: 'pointer', margin: '0 15px' }}
        className={classes.itemLink + ' ' + classes.userCollapseButton}
      >
        <ListItemText
          primary={
            <b data-cy="sidebar_personal-info">
              {user.firstName} {user.lastName}
            </b>
          }
          disableTypography={true}
          className={itemText + ' ' + classes.userItemText}
        />
      </NavLink>
    </div>
  );
};

export default UserBar;
