import Moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMemo, useState, useEffect } from 'react';

import { get, post, put } from 'helpers/apiHelpers';
import getPieceWeight from './utils/getPieceWeight';

const DEFAULT_PAGE_SIZE = 10;

const useWasteLogic = ({ openToast = () => {} } = {}) => {
  const { t } = useTranslation();

  const { userBrands, selectedBrand, multinational } = useSelector(
    ({
      Auth: { user: { brands } = {}, selectedBrand } = {},
      Brands: { brand: { multinational } = {} } = {},
    }) => ({
      multinational,
      selectedBrand,
      userBrands: brands,
    })
  );

  const defaultBrand = userBrands.find(brand => brand.id === selectedBrand);
  const selectInBrands = [
    {
      value: defaultBrand['@id'],
      label: defaultBrand.name,
    },
  ];

  const [date, setDate] = useState(new Date());
  const [pages, setPages] = useState(1);
  const [alert, setAlert] = useState(null);
  const [recipes, setRecipes] = useState([]);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [modalOpen, setModalOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [ingredients, setIngredients] = useState([]);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [overProductions, setOverProductions] = useState([]);
  const [currentTotalItems, setCurrentTotalItems] = useState(0);
  const [mealTypesFromConfig, setMealTypesFromConfig] = useState([]);
  const [
    isFetchingRecipesAndIngredients,
    setIsFetchingRecipesAndIngredients,
  ] = useState(false);

  useEffect(() => {
    if (selectInBrands.length) {
      setIsFetching(true);
      Promise.all([
        fetchRecipesAndIngredients(),
        fetchMealTypesFromConfig(),
        getTotalItems(pageSize),
        fetchOverProductionsOnFetchData({}),
      ]).then(() => {
        setIsFetching(false);
      });
    } else {
      setOverProductions([]);
    }
  }, [selectInBrands?.value, date]);

  const getTotalItems = async pageSize => {
    const query = {
      'date[]': date,
      'date[_operator]': 'eq',
      selectInBrands: selectInBrands.length
        ? selectInBrands.map(brand => brand.value).filter(val => val !== '*')
        : [],
    };

    let { 'hydra:totalItems': totalItems } = await get('/wastes', {
      ...query,
      partial: false,
      'properties[]': '_',
    });
    let pages = Math.floor(totalItems / (pageSize || DEFAULT_PAGE_SIZE));

    if (pages !== totalItems / pageSize) {
      pages++;
    }

    setCurrentTotalItems(totalItems);

    setPages(pages);
  };

  const fetchOverProductionsOnFetchData = ({
    requestData,
    table,
    rowToBeSaved,
  }) => {
    setIsTableLoading(true);

    const query = {
      itemsPerPage: pageSize,
      page: requestData ? requestData.page + 1 : 1,
      'order[id]': 'DESC',
      'date[]': date,
      'date[_operator]': 'eq',
      selectInBrands: selectInBrands.length
        ? selectInBrands.map(brand => brand.value).filter(val => val !== '*')
        : [],
    };

    get('/wastes', {
      ...query,
    }).then(res => {
      const newOverProductions = res['hydra:member'];

      const modified = newOverProductions.map(item => {
        return {
          ...item,
          temporaryId:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          lossWeight: (item.amount * getPieceWeight(item)).toFixed(2),
          hasChanged: false,
          lossUnit: 'g',
        };
      });

      const unSavedOverProductions = rowToBeSaved
        ? overProductions.filter(
            overProduction =>
              !overProduction['@id'] &&
              overProduction.temporaryId !== rowToBeSaved.temporaryId
          )
        : [];

      setIsTableLoading(false);
      setOverProductions([...unSavedOverProductions, ...modified]);
    });
  };

  const fetchMealTypesFromConfig = async () => {
    const response = await get('/meal-types', {
      pagination: false,
      selectInBrands: selectInBrands.length
        ? selectInBrands.map(brand => brand.value).filter(val => val !== '*')
        : [],
    });

    setMealTypesFromConfig(response['hydra:member']);

    return response;
  };

  const fetchRecipesAndIngredients = async () => {
    try {
      const recipes = await get('/recipes', {
        partial: false,
        pagination: false,
        hasUsageOnDate: new Moment(date).format('YYYY-MM-DD'),
        selectInBrands: selectInBrands.length
          ? selectInBrands.map(brand => brand.value).filter(val => val !== '*')
          : [],
      });
      const ingredients = await get('/ingredients', {
        partial: false,
        pagination: false,
        hasUsageOnDate: new Moment(date).format('YYYY-MM-DD'),
        selectInBrands: selectInBrands.length
          ? selectInBrands.map(brand => brand.value).filter(val => val !== '*')
          : [],
      });

      setRecipes(recipes?.['hydra:member']);
      setIngredients(ingredients?.['hydra:member']);
      setIsFetchingRecipesAndIngredients(false);
      return ingredients;
    } catch (e) {
      setIsFetchingRecipesAndIngredients(false);
    }
  };

  const changeRow = (row, value, name) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';
    const newOverProductions = [...overProductions];
    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );
    rowToChange[name] = value;
    rowToChange.hasChanged = true;
    setOverProductions(newOverProductions);
  };

  const changeSubBrand = (row, ev, name) => {
    const findBy = row.temporaryId ? row.temporaryId : row['@id'];
    const key = row.temporaryId ? 'temporaryId' : '@id';
    const newOverProductions = [...overProductions];

    const rowToChange = newOverProductions.find(
      overProduction => overProduction[key] === findBy
    );
    rowToChange.source = ev.target.value;
    rowToChange.hasChanged = true;
    setOverProductions(newOverProductions);
  };

  const handleChangeAmount = (row, ev) => {
    changeRow(row, ev.target.value, 'amount');
    changeRow(
      row,
      +(ev.target.value * getPieceWeight(row)).toFixed(2),
      'lossWeight'
    );
  };

  const validateRow = row => {
    if (row.amount < 0) {
      openToast({
        messages: [t('common.waste.wasteCannotBeNegative')],
        type: 'error',
        autoHideDuration: 3000,
      });

      return false;
    }

    return row.amount && row.source;
  };

  const saveRow = row => {
    if (validateRow(row)) {
      const data = { ...row };

      const action = data['@id'] ? put : post;
      const endpoint = data['@id'] ? data['@id'] : '/wastes';

      data.recipe
        ? (data.recipe = data.recipe['@id'])
        : (data.ingredient = data.ingredient['@id']);
      data.amount = parseFloat(data.amount);

      action(endpoint, data).then(
        () => {
          getTotalItems(pageSize);
          fetchOverProductionsOnFetchData({ rowToBeSaved: row });
          openToast({
            messages: [
              action === put
                ? t('common.waste.wasteChanged')
                : t('common.waste.wasteAdded'),
            ],
            type: 'success',
            autoHideDuration: 3000,
          });
        },
        error =>
          openToast({
            messages: [t('common.waste.notAddedWaste')],
            type: 'error',
            autoHideDuration: 3000,
          })
      );
    }
  };

  const overProductionsToSave = useMemo(
    () =>
      overProductions.filter(
        overProd => overProd.temporaryId && validateRow(overProd)
      ),
    [overProductions]
  );

  const saveAllRows = () => {
    overProductionsToSave.length && setIsFetching(true);
    setIsTableLoading(true);

    return Promise.all(
      overProductionsToSave.map(row => {
        const data = { ...row };

        const action = data['@id'] ? put : post;
        const endpoint = data['@id'] ? data['@id'] : '/wastes';

        data.recipe
          ? (data.recipe = data.recipe['@id'])
          : (data.ingredient = data.ingredient['@id']);
        data.amount = parseFloat(data.amount);

        return action(endpoint, data).then(
          () => {
            getTotalItems(pageSize);
            fetchOverProductionsOnFetchData({});
            openToast({
              messages: [t('common.waste.savedAllWaste')],
              type: 'success',
              autoHideDuration: 3000,
            });
            setIsFetching(false);
            setIsTableLoading(false);
          },
          error => {
            openToast({
              messages: [t('common.waste.notSavedWaste')],
              type: 'error',
              autoHideDuration: 3000,
            });
            setIsFetching(false);
            setIsTableLoading(false);
          }
        );
      })
    );
  };

  return {
    state: {
      date,
      pages,
      alert,
      recipes,
      pageSize,
      openToast,
      modalOpen,
      isFetching,
      ingredients,
      multinational,
      selectInBrands,
      isTableLoading,
      overProductions,
      defaultPageSize: DEFAULT_PAGE_SIZE,
      currentTotalItems,
      mealTypesFromConfig,
      overProductionsToSave,
      isFetchingRecipesAndIngredients,
    },
    setDate,
    saveRow,
    setAlert,
    changeRow,
    validateRow,
    saveAllRows,
    setPageSize,
    setModalOpen,
    getTotalItems,
    changeSubBrand,
    setOverProductions,
    handleChangeAmount,
    fetchOverProductionsOnFetchData,
  };
};

export default useWasteLogic;
