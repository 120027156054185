import React from 'react';
import MailNotificationClientToggleableCardContainer from './MailNotificationClientToggleableCard.container';

const NotificationsCardClientToggleable = ({
  adminMailing,
  clientMailing,
  mailing,
  mailingOptions,
  allowToChangedElements,
}) => {
  return (
    <MailNotificationClientToggleableCardContainer
      adminMailing={adminMailing}
      clientMailing={clientMailing}
      mailing={mailing}
      mailingOptions={mailingOptions}
      allowToChangedElements={allowToChangedElements}
    />
  );
};

export default NotificationsCardClientToggleable;
