import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import FormLabel from '@material-ui/core/FormLabel';

import { FontSelector } from 'components';
import allFontsLists from 'components/FontSelector/fontsList';

const FontsModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'FontsModule',
        })}
      </div>
    );
  }

  const getFontTypeByName = typeName => {
    const { font, library, libraryParams } = module[typeName];
    const libraryFonts = allFontsLists[library];
    const defaultFont = libraryFonts?.find(({ value }) => value === font);

    return {
      ...(defaultFont ? { defaultFont } : {}),
      ...(library ? { defaultFontLibrary: library } : {}),
      ...(libraryParams ? { defaultFontLibraryParams: libraryParams } : {}),
      ...(libraryFonts ? { defaultFontsList: libraryFonts } : {}),
    };
  };

  const setFontTypeByName = typeName => fontObject => {
    handleModuleUpdate({
      ...module,
      [typeName]: fontObject,
    });
  };

  return (
    <Fragment>
      <FormLabel className={classes.labelHorizontal}>
        {t('brandCfg.font.clientText')}
      </FormLabel>

      <FontSelector
        onUpdate={setFontTypeByName('clientText')}
        {...getFontTypeByName('clientText')}
      />
    </Fragment>
  );
};

export default FontsModule;
