const defaultState = custom => ({
  symbol: '',
  label: '',
  link: '',
  priority: 1,
  ...(!custom
    ? {
        allowedRoles: [],
        brands: [],
      }
    : {}),
});

export default defaultState;
