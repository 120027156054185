import { useState, useEffect } from 'react';

import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import AdminTable from 'layouts/AdminTable';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import gastro from 'helpers/gastro';
import { post, put } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

import defaultState from './defaultState';
import FormTextInputTranslatable from 'components/FormTextInput/FormTextInputTranslatable';
import { isTranslatableValuesObjectEmpty } from 'hooks/redux/Translatable/useTranslatableLanguages';

const Form = ({
  match,
  classes,
  history,
  valueUrl,
  valueKey,
  location,
  openToast,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ ...defaultState });

  const isEdit = location.pathname.includes('edit');
  const elementId = match.params.id;

  const displayModes = [
    {
      value: 0,
      label: t('form.field.displayMode.onlyName', 'Sama nazwa'),
    },
    { value: 1, label: t('form.field.displayMode.onlyIcon', 'Sama ikona') },
    {
      value: 2,
      label: t('form.field.displayMode.nameAndIcon', '$$Nazwa i ikona'),
    },
  ];

  useEffect(() => {
    if (isEdit) {
      fetchValue();
    }
  }, []);

  const fetchValue = () => {
    gastro
      .get(`${valueUrl}/${elementId}?translations=true`)
      .then(({ data }) => {
        setState({
          ...state,
          ...data,
          '@resources': {
            ...(!isEmpty(data?.icon)
              ? { [data?.icon?.['@id']]: data?.icon }
              : {}),
          },
          name: data?.value,
          icon: data?.icon?.['@id'],
          position: data?.position,
          iconVisible: data?.iconVisible,
        });
      });
  };

  const handleInputChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    const isNameEmpty = isTranslatableValuesObjectEmpty(state.name);
    if (isNameEmpty) {
      openToast({
        messages: [t('form.nameFieldCannotBeEmpty')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    if (state.iconVisible === 1 && !state.icon) {
      openToast({
        messages: [t('form.iconCannotBeEmptyIfDisplayIconModeIsOn')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    return !isNameEmpty && !(state.iconVisible === 1 && !state.icon);
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    const data = {
      [valueKey ? valueKey : 'value']: state.name,
      position: parseInt(state.position),
      icon: state?.icon,
      iconVisible: state?.iconVisible,
    };

    const action = isEdit
      ? put(`${valueUrl}/${elementId}`, data)
      : post(valueUrl, data);

    action.then(() => history.push(`/admin/${valueUrl}`));
  };

  const geteClientTagIcon = (fieldName, data) => {
    setState({
      ...state,
      '@resources': {
        ...(state?.['@resources'] ?? {}),
        [data?.['@id']]: { ...data },
      },
      [fieldName]: data?.['@id'] ?? null,
    });
  };

  const removeClientTagIcon = fieldName => {
    setState({
      ...state,
      [fieldName]: null,
    });
  };

  return (
    <AdminTable title={isEdit ? t('form.editValue') : t('form.addNewValue')}>
      <GridContainer>
        <GridItem md={4}>
          <FormTextInputTranslatable
            label={t('form.field.name') + '*'}
            classes={classes}
            name="name"
            value={state.name}
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem md={2}></GridItem>
        <GridItem md={4}>
          <FormTextInput
            label={t('form.field.order')}
            classes={classes}
            name="position"
            value={state.position}
            type="number"
            handleChange={handleInputChange}
          />
        </GridItem>
        <GridItem md={4}>
          <FormLabel className={classes.labelHorizontal}>
            {t('form.field.displayMode', '$$Sposób wyświetlenia')}
          </FormLabel>
          <FormControl fullWidth className={classes.selectFormControl}>
            <Select
              value={state?.iconVisible}
              onChange={event =>
                setState({ ...state, iconVisible: event.target.value })
              }
              name="iconVisible"
              classes={{ select: classes.select }}
              MenuProps={{ className: classes.selectMenu }}
            >
              {displayModes.map(({ value, label }, index) => (
                <MenuItem
                  key={index}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelectedMultiple,
                  }}
                  value={value}
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem md={2}></GridItem>
        <GridItem md={4}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormLabel className={classes.labelHorizontal}>
              {t('form.field.icon', '$$Ikona')}
            </FormLabel>
          </div>
          <FormImageUpload
            classes={classes}
            stateName="icon"
            buttonText={t('common.shared.select', 'Wybierz')}
            getImage={geteClientTagIcon}
            imgId="icon"
            removeImage={removeClientTagIcon}
            previewUrl={state?.['@resources']?.[state?.icon]?.contentUrl}
          />
        </GridItem>
        <FormControlButtons
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath={`/admin/${valueUrl}`}
          handleSubmit={handleSubmit}
        />
      </GridContainer>
    </AdminTable>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withToast(withStyles(combinedStyles)(Form));
