import { get, remove } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchUsers = ({ filtered = [] }, userType) => {
  return dispatch => {
    const filters = filtered.reduce((curr, next) => {
      curr[next.id] = next.value;
      return curr;
    }, {});

    let query = {
      type: userType,
      id: filters.id,
      firstName: filters.firstName,
      lastName: filters.lastName,
      phone: filters.phone,
      email: filters.email,
      partial: false,
    };

    if (!isNaN(filters.sex)) {
      query['sex[_operator]'] = 'eq';
      query['sex[]'] = filters.sex;
    }

    return get('/employees', query).then(response => {
      dispatch({
        type: types.FETCH_USERS,
        payload: {
          users: response['hydra:member'],
        },
      });
      return response['hydra:member'];
    });
  };
};

export const fetchUser = id => {
  return dispatch => {
    return get(`/employees/${id}`).then(response => {
      dispatch({
        type: types.FETCH_USER,
        payload: {
          ...response,
        },
      });
      return response;
    });
  };
};

export const clearUser = () => {
  return dispatch => {
    dispatch({
      type: types.CLEAR_USER,
      payload: {},
    });
  };
};

export const removeUser = id => {
  return dispatch =>
    remove(`/employees/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_USER,
        payload: {
          userId: id,
        },
      });
    });
};
