import { whiteColor } from 'assets/jss/material-dashboard-pro-react.jsx';

const customPickerStyle = {
  customPicker: {
    display: 'block',
    top: '40px !important',
    transform: 'none !important',
    position: 'absolute',
    width: '280px',
    padding: '4px',
    marginTop: '1px',
    zIndex: '99999 !important',
    background: whiteColor,
    borderRadius: '0.125rem',
    boxShadow: '0px 10px 50px 0px rgba(0, 0, 0, 0.2)',
    webkitBackgroundClip: 'padding - box',
    backgroundClip: 'padding-box',
    minWidth: '160px',
    '&:before': {
      display: 'inline-block',
      position: 'absolute',
      width: 0,
      height: 0,
      verticalAlign: 'middle',
      content: '""',
      top: '-5px',
      left: '10px',
      right: '0',
      color: whiteColor,
      borderBottom: '0.4em solid',
      borderRight: '0.4em solid transparent',
      borderLeft: '0.4em solid transparent',
    },
    '&:after': {
      borderBottom: '0.4em solid #ffffff',
      borderRight: '0.4em solid transparent',
      borderLeft: '0.4em solid transparent',
      content: '""',
      display: 'inline-block',
      position: 'absolute',
      top: '-5px',
      left: '10px',
    },
  },
};

export default customPickerStyle;
