import { get, remove } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchIngredient = (id, translations = false) => {
  return dispatch =>
    get(`/ingredients/${id}?translations=${translations}`).then(ingredient => {
      dispatch({
        type: types.FETCH_INGREDIENT,
        payload: {
          ingredient: ingredient,
        },
      });

      return ingredient;
    });
};

export const fetchIngredients = ({
  pageSize = 30,
  page = 1,
  sorted = [],
  filtered = [],
  logicalFilters = {},
}) => {
  const filters = filtered.reduce((curr, next) => {
    curr[next.id] = next.value;
    return curr;
  }, {});

  return dispatch =>
    get('/ingredients', {
      itemsPerPage: pageSize,
      page: page + 1,
      ...filters,
      ...logicalFilters,
      'categories.value': filters.categories,
      'allergens.value': filters.allergens,
      partial: false,
    }).then(ingredients => {
      dispatch({
        type: types.FETCH_INGREDIENTS,
        payload: {
          ingredients: ingredients['hydra:member'],
          pages: Math.ceil(ingredients['hydra:totalItems'] / pageSize),
        },
      });
      return ingredients['hydra:member'];
    });
};

export const removeIngredient = id => {
  return dispatch =>
    remove(`/ingredients/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_INGREDIENT,
        payload: {
          removedItemId: id,
        },
      });
    });
};

export const fetchIngredientCategories = () => {
  return dispatch =>
    get('/ingredient-categories', { pagination: false }).then(
      ingredientCategories => {
        dispatch({
          type: types.FETCH_INGREDIENT_CATEGORIES,
          payload: {
            ingredientCategories: ingredientCategories['hydra:member'],
          },
        });
      }
    );
};
