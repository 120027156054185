import { FormControlLabel, withStyles } from '@material-ui/core';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import Checkbox from '@material-ui/core/Checkbox';
import GridItem from 'components/Grid/GridItem';
import { combineStyles } from 'helpers/helpers';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import { translateModules } from './translateModules';
const RoleSubSection = ({
  t,
  role,
  assignedPermissions,
  handleCheckboxChange,
}) => {
  const titleTranslateModules = translateModules(t);

  if (role && role.permissions) {
    return role.permissions.map((permission, index) => {
      const labePrefix = () => {
        if (role.permissions && role.permissions.length >= 2) {
          if (permission.type === 'SHOW') {
            return titleTranslateModules[role?.name] + ' - Przeglądanie';
          }

          if (permission.type === 'EDIT') {
            return titleTranslateModules[role?.name] + ' - Edycja';
          }
          if (permission.type === 'REMOVE') {
            return titleTranslateModules[role?.name] + ' - Usuwanie';
          }

          if (permission.type === 'CREATE') {
            return titleTranslateModules[role?.name] + ' - Dodawanie';
          }
        } else {
          return titleTranslateModules[role?.name];
        }
      };

      return (
        <GridItem
          style={{
            height: '80px',
          }}
          xs={12}
          sm={6}
          md={6}
          lg={4}
          xl={3}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'start',
              border: '1px solid #e0e0e0',
              borderRadius: '5px',
              height: '100%',
              padding: '10px',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  tabIndex={-1}
                  name={permission.role}
                  checked={assignedPermissions.includes(permission.role)}
                  onClick={e => {
                    handleCheckboxChange(
                      e,
                      assignedPermissions.includes(permission.role),
                      permission.role
                    );
                  }}
                />
              }
              label={labePrefix()}
            />
          </div>
        </GridItem>
      );
    });
  } else {
    return null;
  }
};
const combinedStyles = combineStyles(extendedFormsStyle);
const mapStateToProps = state => ({
  permissions: state.Permissions.permissions,
});

const enhance = compose(
  withTranslation(),
  connect(mapStateToProps),
  withStyles(combinedStyles)
);
export default enhance(RoleSubSection);
