import {
  goldColor,
  infoColor,
  roseColor,
  whiteColor,
  dangerColor,
  primaryColor,
  warningColor,
  successColor,
  navyblueColor,
} from 'assets/jss/material-dashboard-pro-react.jsx';

export const getHexOfThemeColor = themeColorName => {
  const hexColors = {
    purple: primaryColor[0],
    caterings: goldColor[0],
    blue: infoColor[0],
    green: successColor[0],
    red: dangerColor[0],
    white: whiteColor,
    rose: roseColor[0],
    navyblue: navyblueColor,
    gold: goldColor[1],
    orange: warningColor[0],
  };

  return hexColors[themeColorName] ?? goldColor[0];
};
