import React, { Component } from 'react';
import { combineStyles } from 'helpers/helpers';
import { post, put } from 'helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { compose } from 'redux';
//actions
import { fetchZoneCategory } from 'actions/ZoneCategories';
//styles
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
//material-ui core
import FormLabel from '@material-ui/core/FormLabel';

// components
import AdminTable from 'layouts/AdminTable';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

class Form extends Component {
  state = {
    name: '',
  };

  editId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  async componentDidMount() {
    if (this.isEdit) {
      this.props.fetchZoneCategory(this.editId).then(response => {
        return this.setState({
          name: response.name,
        });
      });
    }
  }

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm = () => {
    return this.state.name;
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('zoneCategories.fillAllFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const data = {
      name: this.state.name,
    };

    const action = this.isEdit
      ? put(`/zone-categories/${this.editId}`, data)
      : post('/zone-categories', data);

    action.then(
      () =>
        this.props.history.push('/admin/zone-categories', {
          successfullyAddedZoneCategory: true,
        }),
      error => {
        return this.props.openToast({
          messages: [
            this.props.t('zoneCategories.smthWentWrong'),
            this.props.t('zoneCategories.checkForm'),
          ],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  render() {
    const { classes, t } = this.props;

    return (
      <AdminTable
        title={
          this.isEdit
            ? t('zoneCategories.editZoneCategory')
            : t('zoneCategories.addNewZoneCategory')
        }
      >
        <GridContainer>
          <GridItem sm={6}>
            <h4>{t('zoneCategories.basicInfo')}</h4>
            <GridContainer>
              <GridItem sm={6}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('zoneCategories.name')}*
                </FormLabel>
                <CustomInput
                  dataCy="zone-categories_input"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: 'name',
                    value: this.state.name,
                    onChange: ev => this.handleChange(ev),
                  }}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <FormControlButtons
          dataCy="zone-categories_buttons"
          classes={classes}
          discardText={t('zoneCategories.cancel')}
          submitText={t('zoneCategories.save')}
          cancelPath="/admin/zoneCategories"
          handleSubmit={this.handleSubmit}
        />
      </AdminTable>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
  fetchZoneCategory: id => dispatch(fetchZoneCategory(id)),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(Form);
