import { get, post } from 'helpers/apiHelpers';
import moment from 'moment';

import decode from 'jwt-decode';

import types from 'helpers/constants';
import { store } from 'store';
import i18n from 'i18next';
import { getI18n } from 'react-i18next';
import translationEN from 'locales/en.json';
import translationPL from 'locales/pl.json';

export const login = (username, password) => {
  return dispatch =>
    post('/login_check', { username, password }).then(
      async data => {
        const user = decode(data.token);
        dispatch({
          type: types.LOGIN,
          payload: {
            user: user,
            refreshToken: data.refreshToken,
            token: data.token,
            errorMessage: '',
          },
        });
        if (user.brands.length === 0) {
          console.error('User is not assigned to any brand');
          throw { noBrands: true };
        }
        await dispatch(
          handleBrandChange(null, { brand: { id: user.brands[0].id } })
        );
        await dispatch(getRoles());
        await dispatch(getMyLinks());
      },
      error => {
        throw { ...error };
      }
    );
};

export const addUserBrand = brand => dispatch => {
  const {
    Auth: {
      user: { brands },
    },
  } = store.getState();
  const withoutCurrentBrand = brands.filter(({ id }) => id !== brand.id);
  const isInBrands = brands.length !== withoutCurrentBrand.length;
  const newBrands = isInBrands
    ? [...withoutCurrentBrand, brand]
    : [...brands, brand];

  dispatch({
    type: types.ADD_USER_BRAND,
    payload: { brands: newBrands },
  });
};

export const logout = () => {
  return dispatch =>
    get('/logout').then(() => {
      dispatch({
        type: types.LOGOUT,
        payload: {},
      });
    });
};

export const getRoles = () => {
  return dispatch =>
    get('/my-roles').then(data => {
      const fetchedRoles = data['hydra:member'];
      dispatch({
        type: types.UPDATE_MY_ROLES,
        payload: {
          roles: fetchedRoles,
        },
      });
    });
};

export const getMyLinks = () => {
  return dispatch =>
    get('/my-links').then(data => {
      dispatch({
        type: types.UPDATE_MY_LINKS,
        payload: {
          links: data['hydra:member'],
        },
      });
    });
};

export const refreshLogin = () => {
  return dispatch =>
    post('/refresh_token', { refreshToken: store.getState().Auth.refreshToken })
      .then(async data => {
        await dispatch({
          type: types.REFRESH_LOGIN,
          payload: {
            user: decode(data.token),
            refreshToken: data.refreshToken,
            token: data.token,
          },
        });
        await dispatch(getRoles());
        await dispatch(getMyLinks());
      })
      .catch(err => {
        return (window.location.href = '/auth/login');
      });
};

export const refreshToken = () => {
  return dispatch =>
    post('/refresh_token', { refreshToken: store.getState().Auth.refreshToken })
      .then(async data => {
        await dispatch({
          type: types.REFRESH_LOGIN,
          payload: {
            user: decode(data.token),
            refreshToken: data.refreshToken,
            token: data.token,
          },
        });

        return data?.token;
      })
      .catch(err => {
        return (window.location.href = '/auth/login');
      });
};

export const handleBrandChange = (event, payload) => async dispatch => {
  await dispatch({
    type: types.CHANGE_BRAND,
    payload: {
      brand: payload.brand.id,
    },
  });

  if (payload.refresh) {
    window.location.reload();
  }
};

export const setTemporaryLock = isLock => async dispatch => {
  await dispatch({
    type: types.SET_TEMPORARY_LOCK,
    payload: {
      isLock,
      expiredAt: isLock ? moment().add(5, 'minutes') : moment(),
    },
  });
};

export const reloadTranslations = () => async dispatch => {
  const DEFAULT_BUNDLES = { en: translationEN, pl: translationPL };
  return new Promise(async res => {
    const lang = getI18n().language.substr(0, 2);
    const resource = i18n.getResource(lang, 'translation');

    if (typeof resource !== 'undefined') {
      i18n.removeResourceBundle(lang, 'translation');
    }

    let startedLanguage = {};

    try {
      startedLanguage = await get(
        '/translations/loader/admin/default',
        {},
        { forcedLanguage: lang }
      );
    } catch (e) {}
    await i18n.addResourceBundle(lang, 'translation', {
      ...(DEFAULT_BUNDLES[lang ?? i18n.language ?? 'pl'] ?? {}),
      ...startedLanguage,
    });
    res(true);
  });
};

export const fetchLanguages = () => {
  return dispatch =>
    get('languages').then(async response => {
      const languages = response['hydra:member'].filter(lang => {
        return lang.enabled === true;
      });
      const selectedLanguage =
        languages.find(language => {
          return language.isoCode === store.getState().Auth.user.language;
        }) ?? languages[0];

      await dispatch({
        type: 'SET_LANGUAGES',
        payload: {
          languages,
          selectedLanguage,
        },
      });
    });
};

export const setSelectedLanguage = selectedLanguage => dispatch => {
  dispatch({
    type: 'SET_SELECTED_LANGUAGE',
    payload: selectedLanguage,
  });
};
