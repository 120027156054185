import React from 'react';
import decode from 'jwt-decode';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRoles, refreshToken as doRefreshToken } from 'actions/Auth';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';

import Button from 'components/CustomButtons/Button';
import { isEmpty, isGranted } from 'helpers/helpers';
import { ROLE_IMPERSONATE_USER } from 'helpers/roles';

const FakeUserIcon = ({
  domain,
  mainDomain,
  email,
  token,
  getRoles,
  refreshToken,
  doRefreshToken,
}) => {
  const redirectDomain = domain ?? mainDomain;

  if (!isGranted(ROLE_IMPERSONATE_USER) || isEmpty(redirectDomain)) {
    return null;
  }

  const handleImpersonate = async () => {
    const { exp } = decode(token);

    // EXP is in seconds, so we convert it to milisceonds and remove 60000ms (1min)
    if (Date.now() >= exp * 1000 - 60000) {
      const newToken = await doRefreshToken();
      await getRoles();

      return window.open(
        `https://${redirectDomain}/auth/switch?token=${newToken}&refreshToken=${refreshToken}&email=${email}`,
        '_blank'
      );
    }

    window.open(
      `https://${redirectDomain}/auth/switch?token=${token}&refreshToken=${refreshToken}&email=${email}`,
      '_blank'
    );
  };

  return (
    <Button
      simple={true}
      round={true}
      color="primary"
      style={{
        margin: '10px 1px 10px 18px',
        padding: '0',
        width: '20px',
      }}
      onClick={handleImpersonate}
    >
      <SupervisedUserCircleIcon />
    </Button>
  );
};

const mapStateToProps = ({
  Brands: { brand },
  Auth: { token, refreshToken, selectedBrand },
}) => ({
  mainDomain: brand.mainDomain || '',
  refreshToken,
  selectedBrand,
  token,
});

const mapDispatchToProps = { doRefreshToken, getRoles };

FakeUserIcon.propTypes = {
  domain: PropTypes.string,
  mainDomain: PropTypes.string,
  email: PropTypes.string.isRequired,
  refreshToken: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(FakeUserIcon);
