import React from 'react';
import moment from 'moment';
import Datetime from 'react-datetime';
import { useTranslation } from 'react-i18next';

import AdminTable from 'layouts/AdminTable';

import { combineStyles } from 'helpers/helpers';

import GridItem from 'components/Grid/GridItem';
import FormSelectMultiple from 'components/FormSelect/FormSelectMultiple';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import GridContainer from 'components/Grid/GridContainer';

import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import ResultDialog from './components/ResultDialog';
import TargetBrandMappingCard from './components/TargetBrandMappingCard';
import SelectInput from '../../../components/FormSelect/SelectInput';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

const MenuCopyingComponent = ({
  classes,
  results = [],
  isCopying = false,
  targets = [],
  sourceBrand = {},
  dateRangeTo = new Date(),
  dateRangeFrom = new Date(),
  targetBrands = [],
  brandsOptions = [],
  copyFromPublished = false,
  sourceDietOptions = [],
  sourceVariantOptions = [],
  publishMenuAfterCopy = false,
  isResultDialogOpened = false,
  sourceMealTypeOptions = [],
  setTargets = () => {},
  handleSubmit = () => {},
  setDateRangeTo = () => {},
  setTargetBrands = () => {},
  setDateRangeFrom = () => {},
  setIsResultDialogOpened = () => {},
  setCopyFromPublished = () => {},
  setPublishMenuAfterCopy = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <>
      <AdminTable
        title={t(
          '$*form.menuPlanning.menuCopyingSettings',
          '$$Ustawienia kopiowania menu'
        )}
        isMarginLeft={true}
      >
        <GridContainer>
          <GridItem sm={3}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '5px' }}
            >
              {t(
                '$*form.menuPlanning.selectDate1',
                '$$Wybierz datę początkową'
              )}{' '}
              *
            </FormLabel>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                dateFormat={moment.localeData().longDateFormat('L')}
                closeOnSelect={true}
                value={new moment(dateRangeFrom)}
                onChange={ev => setDateRangeFrom(ev.format('YYYY-MM-DD'))}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem sm={3}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '5px' }}
            >
              {t('$*form.menuPlanning.selectDate2', '$$Wybierz datę końcową')} *
            </FormLabel>
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                dateFormat={moment.localeData().longDateFormat('L')}
                closeOnSelect={true}
                value={new moment(dateRangeTo)}
                onChange={ev => setDateRangeTo(ev.format('YYYY-MM-DD'))}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem sm={2}>
            <SelectInput
              name="copyFromPublished"
              label={
                <Tooltip
                  title={
                    <>
                      <h4>
                        {t(
                          '$*form.menuPlanning.copyFromPublished',
                          '$$Źródło do kopiowania'
                        )}
                      </h4>
                      <h6>
                        {t(
                          '$*form.menuPlanning.copyFromPublished.description',
                          '$$Zdecyduj czy chcesz kopiować opublikowane menu czy zaplanowane. Niezależnie od tego wyboru menu zawsze zostanie skopiowane na szkic do marki docelowej'
                        )}
                      </h6>
                    </>
                  }
                  placement="right"
                >
                  <div>
                    {t(
                      '$*form.menuPlanning.copyFromPublished',
                      '$$Źródło do kopiowania'
                    )}{' '}
                    <Info fontSize={'small'} />
                  </div>
                </Tooltip>
              }
              classes={classes}
              mapBy="label"
              trackBy="value"
              value={copyFromPublished}
              handleChange={(e, { value }) => {
                setCopyFromPublished(value);
                setPublishMenuAfterCopy(value);
              }}
              options={[
                {
                  label: t(
                    '$*form.menuPlanning.copyFromPublished.true',
                    '$$Opublikowane menu'
                  ),
                  value: true,
                },
                {
                  label: t(
                    '$*form.menuPlanning.copyFromPublished.false',
                    '$$Szkic menu'
                  ),
                  value: false,
                },
              ]}
            />
          </GridItem>
          <GridItem sm={2}>
            <SelectInput
              name="copyFromPublished"
              label={
                <Tooltip
                  title={
                    <>
                      <h4>
                        {t(
                          '$*form.menuPlanning.publishMenuAfterCopy',
                          '$$Opublikuj menu po skopiowaniu'
                        )}
                      </h4>
                      <h6>
                        {t(
                          '$*form.menuPlanning.publishMenuAfterCopy.description',
                          '$$Zdecyduj czy chcesz opublikować menu po zakończeniu procesu kopiowania'
                        )}
                      </h6>
                    </>
                  }
                  placement="right"
                >
                  <div>
                    {t(
                      '$*form.menuPlanning.publishMenuAfterCopy',
                      '$$Czy opublikować menu?'
                    )}{' '}
                    <Info fontSize={'small'} />
                  </div>
                </Tooltip>
              }
              classes={classes}
              mapBy="label"
              trackBy="value"
              value={publishMenuAfterCopy}
              handleChange={(e, { value }) => setPublishMenuAfterCopy(value)}
              options={[
                {
                  label: t('common.yes'),
                  value: true,
                },
                {
                  label: t('common.no'),
                  value: false,
                },
              ]}
            />
          </GridItem>
          <GridItem sm={2}>
            <FormSelectMultiple
              width="100%"
              classes={classes}
              label={
                t('$*form.menuPlanning.targetedBrands', '$$Marki docelowe') +
                ' *'
              }
              mapBy="name"
              trackBy="id"
              value={targetBrands}
              options={brandsOptions}
              handleChange={event => {
                setTargetBrands(event.target.value);
              }}
              id="selectedBrands"
              name="selectedBrands"
            />
          </GridItem>
        </GridContainer>
      </AdminTable>
      {(targetBrands ?? []).map(targetBrand => (
        <div style={{ marginTop: '-35px' }} key={targetBrand['@id']}>
          <TargetBrandMappingCard
            classes={classes}
            targets={targets}
            setTargets={setTargets}
            sourceBrand={sourceBrand}
            targetBrand={targetBrand}
            sourceDiets={sourceDietOptions}
            sourceVariants={sourceVariantOptions}
            sourceMealTypes={sourceMealTypeOptions}
          />
        </div>
      ))}
      <FormControlButtons
        classes={classes}
        submitText={t('form.save')}
        handleSubmit={handleSubmit}
      />
      <ResultDialog
        isOpened={isResultDialogOpened}
        isLoading={isCopying}
        handleClose={() => setIsResultDialogOpened(false)}
        results={results}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(MenuCopyingComponent);
