import { get } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchDeliveryTypes = () => {
  return dispatch =>
    get('/delivery-types', { pagination: false }).then(response => {
      dispatch({
        type: types.FETCH_DELIVERYTYPES,
        payload: {
          deliveryTypes: response['hydra:member'],
        },
      });
    });
};
