import React from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

const PayUModule = ({ classes, handleModuleUpdate, module }) => {
  const { t } = useTranslation();

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'PayUModule',
        })}
      </div>
    );
  }

  return (
    <>
      <GridContainer>
        <GridItem xs={11}>
          <h3 style={{ marginLeft: '15px' }}>
            {t('paymentType.title.PayU', 'Ustawienia PayU')}
          </h3>
        </GridItem>
        <GridItem xs={1} style={{ display: 'flex', justifyContent: 'end' }}>
          <div
            style={{
              height: '100%',
              width: '55%',
              background: 'RGB(0,172,193)',
            }}
          ></div>
        </GridItem>
      </GridContainer>
      <GridItem xs={6}>
        <NumberFormat
          label="Protokół OAuth - client_id:"
          classes={classes}
          customInput={FormTextInput}
          value={module.clientId}
          onChange={e =>
            handleModuleUpdate({ ...module, clientId: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <NumberFormat
          label="Id punktu płatności (pos_id):"
          classes={classes}
          customInput={FormTextInput}
          value={module.posId}
          onChange={e =>
            handleModuleUpdate({ ...module, posId: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Protokół OAuth - client_secret:"
          classes={classes}
          name="payUClientSecret"
          value={module.clientSecret}
          handleChange={e =>
            handleModuleUpdate({ ...module, clientSecret: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Drugi klucz (MD5):"
          classes={classes}
          name="payUmd5Key"
          value={module.md5Key}
          handleChange={e =>
            handleModuleUpdate({ ...module, md5Key: e.target.value })
          }
          inputSize={12}
        />
      </GridItem>
      <GridItem xs={6}>
        <FormTextInput
          label="Google Pay - Merchant ID:"
          classes={classes}
          name="payUGooglePayMerchantId"
          value={module.googlePayMerchantId}
          handleChange={e => {
            handleModuleUpdate({
              ...module,
              googlePayMerchantId: e.target.value,
            });
          }}
          inputSize={12}
        />
      </GridItem>
    </>
  );
};

export default PayUModule;
