import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import Countdown from 'components/Countdown/Countdown';
import CustomTooltip from 'components/CustomTooltip/CustomTooltip';

import CATERINGS_FLOW_STATUSES_LABELS from 'views/CateringsFlow/consts/cateringsFlowStatusesLabels';

const StatusChangeCountdown = ({
  endDate = new Date(),
  startDate = new Date(),
  plannedStatus,
  onCountdownTimeout = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
      }}
    >
      <div
        style={{ display: 'flex', alignItems: 'center', marginRight: '2px' }}
      >
        <CustomTooltip
          title={
            <div style={{ textAlign: 'center' }}>
              <h4 style={{ fontWeight: 'bold' }}>
                {t(
                  '$*cateringsFlow.kitchen.amountOfDoneModal.sheduleStatusChange.countdown.tooltip.title',
                  {
                    defaultValue:
                      '$$Zaplanowana zmiana statusu na "{{ plannedStatus }}"',
                    replace: {
                      plannedStatus: t(
                        CATERINGS_FLOW_STATUSES_LABELS[plannedStatus]
                          .translationKey,
                        CATERINGS_FLOW_STATUSES_LABELS[plannedStatus]
                          .defaultValue
                      ),
                    },
                  }
                )}
              </h4>
              <p>
                {t(
                  '$*cateringsFlow.kitchen.amountOfDoneModal.sheduleStatusChange.countdown.tooltip.startDate',
                  {
                    defaultValue: `$$Odliczanie rozpoczęte o: {{startDate}}`,
                    replace: {
                      startDate: moment(startDate).format('HH:mm, DD.MM.YYYY'),
                    },
                  }
                )}
              </p>
              <p>
                {t(
                  '$*cateringsFlow.kitchen.amountOfDoneModal.sheduleStatusChange.countdown.tooltip.endDate',
                  {
                    defaultValue: '$$Zmiana statusu nastąpi o: {{endDate}}',
                    replace: {
                      endDate: moment(endDate).format('HH:mm, DD.MM.YYYY'),
                    },
                  }
                )}
              </p>
            </div>
          }
        />
      </div>
      <Countdown endDate={endDate} onCountdownTimeout={onCountdownTimeout} />
    </div>
  );
};

export default StatusChangeCountdown;
