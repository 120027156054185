const columnConfig = custom => {
  const config = [
    {
      title: 'navLinks.fields.id',
      accessor: 'id',
      name: 'id',
      filterable: false,
      sortable: false,
      width: 100,
    },
    {
      title: 'navLinks.fields.symbol',
      accessor: 'symbol',
      name: 'symbol',
      filterable: true,
      sortable: true,
    },
    {
      title: 'navLinks.fields.label',
      accessor: 'label',
      name: 'label',
      filterable: true,
      sortable: true,
    },
    {
      title: 'navLinks.fields.link',
      accessor: 'link',
      name: 'link',
      filterable: true,
      sortable: true,
    },
    {
      title: 'navLinks.fields.priority',
      accessor: 'priority',
      name: 'priority',
      filterable: false,
      sortable: true,
    },
  ];

  if (!custom) {
    config.push(
      {
        title: 'navLinks.fields.brands',
        accessor: row => row.brands.map(el => el.name).join(', '),
        name: 'brands.name',
        filterable: true,
        sortable: false,
      },
      {
        title: 'navLinks.fields.roles',
        accessor: row => row.allowedRoles.map(el => el.name).join(', '),
        name: 'allowedRoles.name',
        filterable: true,
        sortable: false,
        width: 100,
      }
    );
  }

  return config;
};

export default columnConfig;
