import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import { combineStyles } from 'helpers/helpers';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import moment from 'moment';

import { connect } from 'react-redux';
import { fetchMessage } from 'actions/Messages';

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { post, put } from 'helpers/apiHelpers';
import Datetime from 'react-datetime';

import SimpleWysiwyg from 'components/FormTextInput/SimpleWysiwyg';

import { withTranslation } from 'react-i18next';

class Form extends Component {
  state = {
    date: '',
    content: '',
  };

  sizeId = this.props.match.params.id;
  isEdit = this.props.location.pathname.includes('edit');

  componentDidMount() {
    this.sizeId &&
      this.props.fetchMessage(this.sizeId).then(response => {
        const date = moment(response.date).format('DD-MM-YYYY');
        const content = response.content;
        this.setState({ date, content });
      });
  }

  handleInputChange = event => {
    const content = event.target.value;
    this.setState({ content });
  };

  validateForm = () => {
    return this.state.content || this.state.position !== '';
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    const data = {
      content: this.state.content,
      date: moment(this.state.date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
    };

    const action = this.isEdit
      ? put(`/notifications/${this.sizeId}`, data)
      : post('/notifications', data);

    action.then(() => this.props.history.push('/admin/messages'));
  };

  render() {
    const { classes } = this.props;
    return (
      <AdminTable title={this.props.t('form.addNewMessage')}>
        <p
          style={{
            margin: '10px 0',
            color: 'rgba(0,0,0,.46)',
            fontWeight: 400,
          }}
        >
          {this.props.t('form.messageContent')}*
        </p>
        <SimpleWysiwyg
          handleChange={this.handleInputChange}
          value={this.state.content}
        />
        <div style={{ width: '140px' }}>
          <p
            style={{
              margin: '10px 0 0 0',
              color: 'rgba(0,0,0,.46)',
              fontWeight: 400,
            }}
          >
            {this.props.t('form.displayDate')}*
          </p>
          <Datetime
            timeFormat={false}
            closeOnSelect={true}
            value={this.state.date}
            onChange={ev => this.setState({ date: ev.format('DD-MM-YYYY') })}
            inputProps={{
              placeholder: this.props.t('form.selectDate'),
              readOnly: true,
            }}
          />
        </div>
        <FormControlButtons
          classes={classes}
          discardText={this.props.t('form.cancel')}
          submitText={this.props.t('form.save')}
          cancelPath="/admin/messages"
          handleSubmit={this.handleSubmit}
        />
      </AdminTable>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    mealSize: state.MealSizes.mealSize,
    loading: state.MealSizes.loading,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMessage: id => dispatch(fetchMessage(id)),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withToast(withStyles(combinedStyles)(Form)))
);
