import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from 'views/Recipes/List/Recipes';
import Form from 'views/Recipes/Form/RecipeForm';
import RecipesContainer from './Form/RecipesContainer';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_RECIPE,
  ROLE_EDIT_RECIPE,
  ROLE_SHOW_RECIPE,
} from 'helpers/roles';

const RenderForm = RecipesContainer(Form);

class Recipes extends Component {
  render() {
    const props = this.props;

    const ListSecured = withRole(ROLE_SHOW_RECIPE, List);
    const CreateForm = withRole(ROLE_CREATE_RECIPE, RenderForm);
    const EditForm = withRole(ROLE_EDIT_RECIPE, RenderForm);

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={props => <CreateForm {...props} />}
          />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            render={parentProps => <EditForm {...props} {...parentProps} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(Recipes);
