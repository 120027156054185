import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

class ActiveBrandRedirect extends React.Component {
  componentDidMount() {
    this.props.history.push(`/admin/brands/edit/${this.props.brand}`);
  }

  render() {
    return null;
  }
}

const mapStateToProps = state => {
  return {
    brand: state.Auth.selectedBrand,
  };
};

export default connect(mapStateToProps, null)(withRouter(ActiveBrandRedirect));
