import { useState, useEffect } from 'react';

import { orderStyles } from '../../../styles';
import { useTranslation } from 'react-i18next';

import { put } from 'helpers/apiHelpers';

import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import DialogContent from '@material-ui/core/DialogContent';

import Button from 'components/CustomButtons/Button';
import CustomInput from 'components/CustomInput/CustomInput';
import ModalButton from 'components/CustomButtons/Button';

const DeleteBagConfirmModal = ({
  classes,
  isOpened,
  isLoading,
  returnCost = 0,
  toggleModal,
  bags = [],
  dates = [],
  dietInfo = {},
  openToast = () => {},
  setIsLoading,
  setCalendarNeedsUpdate,
}) => {
  const { t } = useTranslation();

  const [overrideReturnedPoints, setOverrideReturnedPoints] = useState(0);
  const [sendEmail, setSendEmail] = useState(true);

  useEffect(() => {
    setOverrideReturnedPoints(returnCost);
  }, [returnCost]);

  const handleSubmit = () => {
    setIsLoading(true);
    put(`/v2/calendar/${dietInfo?.id}/cancel-bags`, {
      bags,
      dates,
      sendMail: sendEmail,
      overrideReturnedPoints:
        overrideReturnedPoints !== returnCost
          ? parseFloat((overrideReturnedPoints ?? 0).replace(',', '.')) || 0
          : null,
      acceptedReturnCost: parseFloat(returnCost),
    })
      .then(() => {
        try {
          openToast({
            messages: [
              t('success.changesSaved', 'Zmiany zostały zapisane'),
              `Zwrócone punkty: ${overrideReturnedPoints || 0}`,
            ],
            type: 'success',
            autoHideDuration: 3000,
          });
          toggleModal(false);
          setIsLoading(false);
          setCalendarNeedsUpdate(true);
        } catch (e) {
          console.log({ e });
        }
      })
      .catch(({ response }) => {
        switch (response.status) {
          case 402:
            openToast({
              messages: [
                t(
                  'singleDayDeteils.bagDeletingIsImposible',
                  'Anulowanie torby jest niemożliwe, prosimy o przeniesienie torby na inny dzień.'
                ),
              ],
              type: 'info',
              autoHideDuration: 3000,
            });
            break;
          default:
            break;
        }

        setIsLoading(false);
      });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth={true}
      open={isOpened}
      onClose={() => toggleModal(false)}
    >
      <DialogContent>
        <ModalButton
          style={orderStyles.closeButton}
          color={'transparent'}
          justIcon
          round
          onClick={() => toggleModal(false)}
        >
          <Close />
        </ModalButton>
        <h1 style={{ textAlign: 'center' }}>
          {t(
            'singleDayDeteils.deleteBagConfirmModal.title',
            'Czy na pewno chcesz usunąć wybrane torby?'
          )}
        </h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <h3 style={{ textAlign: 'center' }}>
            <b>
              {t(
                'singleDayDeteils.deleteBagConfirmModal.pointsToReturn',
                'Zwrócona zostanie następująca liczba punktów: '
              )}
            </b>
          </h3>
          <div style={{ marginLeft: '10px' }}>
            <CustomInput
              formControlProps={{ fullWidth: true }}
              inputProps={{
                value: overrideReturnedPoints,
                onChange: e => setOverrideReturnedPoints(e.target.value),
                type: 'number',
                style: { textAlign: 'center !important' },
              }}
            />
          </div>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            setSendEmail(!sendEmail);
          }}
        >
          <Checkbox
            checked={sendEmail}
            checkedIcon={<Check className={classes?.checkedIcon} />}
            icon={<Check className={classes?.uncheckedIcon} />}
            classes={{
              checked: classes?.checked,
              root: classes?.checkRoot,
            }}
            style={{ padding: 0 }}
          />
          <h3 style={{ marginLeft: '15px', cursor: 'pointer' }}>
            {t(
              'singleDayDeteils.deleteBagConfirmModal.mailNotify',
              '$$Wyślij powiadomienie mailowe'
            )}
          </h3>
        </div>

        <div
          style={{
            display: 'flex',
            marginTop: '25px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            color="danger"
            style={{ width: '25%', marginRight: '5px' }}
            onClick={() => toggleModal(false)}
          >
            {t('common.shared.cancel', 'Anuluj')}
          </Button>
          <Button
            color="success"
            style={{ width: '25%', marginLeft: '5px' }}
            disabled={isLoading}
            onClick={handleSubmit}
          >
            {t('common.shared.save', 'Zapisz')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteBagConfirmModal;
