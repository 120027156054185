import React, { Component } from 'react';
import { get, post, put } from '../../helpers/apiHelpers';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { withToast } from 'material-ui-toast-redux';
import AssignmentReturned from '@material-ui/icons/AssignmentReturned';
import AssignDriverModal from './AssignDriverModal';
import AssignZonesSection from './AssignZonesSection';
import AssignZonesWithClearAllSection from './AssignZonesWithClearAllSection';
import { Divider } from '@material-ui/core';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

class List extends Component {
  state = {
    modalOpen: false,
    selectedAddress: null,
    zone: null,
    selectedDriver: null,
    isLoading: false,
  };

  gridProps = null;

  fetchZone = zone => {
    this.setState({ isLoading: true });
    get(zone).then(
      res => this.setState({ zone: res, isLoading: false }),
      error => {
        this.setState({ isLoading: false });
        return this.props.openToast({
          messages: [this.props.t('addresses.cannotDownloadZone')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      selectedAddress: null,
      zone: null,
      selectedDriver: null,
    });
  };

  openModal = (row, props) => {
    if (row.zone) {
      this.fetchZone(row.zone['@id']);
    }

    this.gridProps = props;

    this.setState({
      modalOpen: true,
      selectedAddress: row,
      selectedDriver: row.driver,
    });
  };

  handleDriverSelect = (ev, driver) => {
    this.setState({ selectedDriver: driver });
  };

  handleDriverChange = () => {
    const { selectedAddress, selectedDriver } = this.state;

    this.setState({ isLoading: true });

    put(selectedAddress['@id'], { driver: selectedDriver['@id'] }).then(
      res => {
        this.gridProps.grid.current.fireFetchData();

        this.closeModal();
        this.setState({ isLoading: false });

        this.props.openToast({
          messages: [this.props.t('addresses.changedDriver')],
          type: 'success',
          autoHideDuration: 3000,
        });
      },
      error => {
        this.setState({ isLoading: false });
        this.props.openToast({
          messages: [this.props.t('addresses.cantChangeDriver')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  handleAssignZones = () => {
    this.setState({ isLoading: true });
    return post('/actions/assign-zone-to-addresses').then(
      res => {
        this.setState({ isLoading: false });
        this.props.openToast({
          messages: [
            this.props.t('addresses.mapZones'),
            this.props.t('addresses.mapZones2'),
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      },
      error => {
        this.setState({ isLoading: false });
        this.props.openToast({
          messages: [this.props.t('addresses.cantMap')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  handleAssignZonesWithClearAll = () => {
    this.setState({ isLoading: true });
    return post('/actions/assign-zone-to-addresses?clearAll=true').then(
      res => {
        this.setState({ isLoading: false });
        this.props.openToast({
          messages: [
            this.props.t('addresses.mapZones'),
            this.props.t('addresses.mapZones2'),
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      },
      error => {
        this.setState({ isLoading: false });
        this.props.openToast({
          messages: [this.props.t('addresses.cantMap')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  manipulateQuery = (requestData, query) => {
    // if (query['driver.name']) {
    //   if(query['driver.name'] === '-') {
    //     delete query['driver.name']
    //     query['driver'] = '-'
    //   }
    // }

    return query;
  };

  render() {
    const { classes } = this.props;
    const customActions = row => {
      return [
        {
          action: props => this.openModal(row, props),
          icon: <AssignmentReturned />,
          disabled: false,
          color: 'primary',
          buttonText: this.props.t('addresses.changeDriver'),
          simple: true,
          style: { width: 'auto' },
        },
      ];
    };

    return (
      <AdminTable title={this.props.t('addresses.list')}>
        <AssignDriverModal
          classes={classes}
          isLoading={this.state.isLoading}
          modalOpen={this.state.modalOpen}
          closeModal={this.closeModal}
          selectedAddress={this.state.selectedAddress}
          selectedDriver={this.state.selectedDriver}
          handleDriverSelect={this.handleDriverSelect}
          handleDriverChange={this.handleDriverChange}
          zone={this.state.zone}
        />
        <GridContainer>
          <GridItem>
            <AssignZonesSection
              disabled={this.state.isLoading}
              handleAssignZones={this.handleAssignZones}
            />
          </GridItem>
          <GridItem>
            <AssignZonesWithClearAllSection
              disabled={this.state.isLoading}
              handleAssignZones={this.handleAssignZonesWithClearAll}
            />
          </GridItem>
        </GridContainer>
        <Divider />
        <DataGrid
          refreshOnFetch
          actions={true}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          customActions={customActions}
          customActionsWidth={200}
          url="/addresses"
          reportName={'address'}
          role="ADDRESSES"
          columns={columnConfig(this.props.t)}
          manipulateQuery={this.manipulateQuery}
          customActionsColumnDirection={'actions-left'}
        />
      </AdminTable>
    );
  }
}

const enhance = compose(withToast, withStyles(buttonsStyle), withTranslation());

export default enhance(List);
