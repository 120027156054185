import React from 'react';
import { useSelector } from 'react-redux';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/icons
import { getHexOfThemeColor } from 'helpers/themeHelpers';
// core components
import { hexToRgb } from 'assets/jss/material-dashboard-pro-react.jsx';
import cardIconStyle from 'assets/jss/material-dashboard-pro-react/components/cardIconStyle.jsx';

const CardIcon = ({ classes, className, children, color, ...rest }) => {
  const { mainColor } = useSelector(({ Theme: { mainColor } }) => ({
    mainColor,
  }));

  const cardIconClasses = classNames({
    [classes.cardIcon]: true,
    [classes[color + 'CardHeader']]: color,
    [className]: className !== undefined,
  });
  return (
    <div
      className={cardIconClasses}
      style={{
        background: getHexOfThemeColor(mainColor),
        boxShadow:
          '0 4px 20px 0 rgba(' +
          hexToRgb(getHexOfThemeColor(mainColor)) +
          ',.14), 0 7px 10px -5px rgba(' +
          hexToRgb(getHexOfThemeColor(mainColor)) +
          ',.4)',
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

CardIcon.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'warning',
    'success',
    'danger',
    'info',
    'primary',
    'rose',
    'caterings',
  ]),
};

export default withStyles(cardIconStyle)(CardIcon);
