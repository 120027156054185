import { get, remove } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchZones = () => {
  return dispatch => {
    return get(
      '/zones?properties[]=name&properties[]=id&properties[]=goodSpeedIntegrationEnabled&properties[]=category&properties[category][]=id&properties[category][]=name',
      {
        pagination: false,
      }
    ).then(response => {
      dispatch({
        type: types.FETCH_ZONES,
        payload: {
          zones: response['hydra:member'],
        },
      });
      return response['hydra:member'];
    });
  };
};

export const fetchZone = id => {
  return dispatch => {
    return get(`/zones/${id}`).then(response => {
      dispatch({
        type: types.FETCH_ZONE,
        payload: {
          name: response.name,
        },
      });
      return response;
    });
  };
};

export const removeZone = id => {
  return dispatch =>
    remove(`/zones/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_ZONE,
        payload: {
          zoneId: id,
        },
      });
    });
};
