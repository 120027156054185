import React, { Component } from 'react';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import { withRouter } from 'react-router-dom';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormLabel from '@material-ui/core/FormLabel';
import CustomInput from 'components/CustomInput/CustomInput';
import { get, post, put } from 'helpers/apiHelpers';
import Button from 'components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import Datetime from 'react-datetime';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import SelectInput from 'components/FormSelect/SelectInput';
import { callTypes } from './ItemTypes';
import Moment from 'moment';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class Form extends Component {
  state = {
    openModal: false,
    type: 'INCOME',
    endAt: new Moment(),
    duration: 1,
    comment: '',
    id: null,
  };

  userId = this.props.userId ? this.props.userId : this.props.match.params.id;

  componentDidMount() {
    this.props.openModal(this.openModal);
  }

  openModal = row => {
    this.setState(
      {
        openModal: true,
        id: row.hasOwnProperty('id') ? row.id : null,
      },
      () => {
        if (this.state.id) {
          this.load();
        }
      }
    );
  };

  closeModal = () => {
    this.setState({
      type: 'INCOME',
      endAt: new Moment(),
      duration: 1,
      comment: '',
      openModal: false,
    });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  validateForm = () => {
    return this.state.endAt && this.state.duration;
  };

  handleSubmit = () => {
    if (!this.validateForm()) {
      return this.props.openToast({
        messages: [this.props.t('clients.fillAllFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    let data = {
      type: this.state.type,
      endAt: this.state.endAt,
      duration: parseInt(this.state.duration),
      comment: this.state.comment,
      client: `/clients/${this.userId}`,
    };

    this.state.id ? this.update(data) : this.save(data);
  };

  load = () => {
    get(`client-calls/${this.state.id}`, {
      pagination: false,
      'order[id]': 'DESC',
    }).then(response => {
      this.setState({
        type: response.type,
        endAt: Moment(response.endAt).format('DD.MM.YYYY HH:mm'),
        duration: parseInt(
          Moment.duration(
            Moment(response.endAt).diff(response.startAt)
          ).asMinutes()
        ),
        comment: response.comment,
      });
    });
  };

  save = data => {
    post('/client-calls', data).then(
      () => {
        this.closeModal();
        this.props.refreshList();
      },
      error => {
        return this.renderError(error);
      }
    );
  };

  update = data => {
    put(`client-calls/${this.state.id}`, data).then(
      () => {
        this.closeModal();
        this.props.refreshList();
      },
      error => {
        return this.renderError(error);
      }
    );
  };

  renderError = error => {
    if (error.response.status === 500) {
      return this.props.openToast({
        messages: [
          this.props.t('clients.smthWentWrong'),
          this.props.t('clients.checkForm'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    this.props.openToast({
      messages: [error.response.data.violations[0].message],
      type: 'error',
      autoHideDuration: 3000,
    });
  };

  render() {
    const { classes, t } = this.props;
    return (
      <React.Fragment>
        <Dialog
          maxWidth={'sm'}
          fullWidth={true}
          open={this.state.openModal}
          onClose={this.closeModal}
        >
          <DialogContent>
            <GridContainer>
              <GridItem xs={12}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('clientCalls.type', 'Typ połączenia')}
                </FormLabel>
                <SelectInput
                  classes={classes}
                  options={callTypes(t)}
                  value={this.state.type}
                  mapBy="name"
                  trackBy="value"
                  name="type"
                  handleChange={ev => this.handleChange(ev)}
                  size={12}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={6}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('clientCall.endDate', 'Data zakończenia')}
                </FormLabel>
                <Datetime
                  timeFormat={true}
                  value={this.state.endAt}
                  onChange={ev =>
                    this.setState({
                      endAt: ev.format('DD.MM.YYYY HH:mm'),
                    })
                  }
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>
              <GridItem xs={6}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('clientCall.duration', 'Czas trwania[min]')}
                </FormLabel>
                <CustomInput
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: 'duration',
                    value: this.state.duration,
                    type: 'number',
                    onChange: ev => this.handleChange(ev),
                  }}
                />
              </GridItem>
            </GridContainer>
            <GridContainer>
              <GridItem xs={12}>
                <FormLabel className={classes.labelHorizontal}>
                  {t('clientCall.comment', 'Komentarz')}
                </FormLabel>
                <FormTextInput
                  classes={classes}
                  name="comment"
                  value={this.state.comment}
                  handleChange={ev => this.handleChange(ev)}
                  inputSize={6}
                  multiline={true}
                  rows={4}
                  rowsMax={20}
                  maxLength={2000}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem>
                <Button
                  className={classes.marginRight}
                  onClick={() => this.closeModal()}
                >
                  {this.props.t('clients.cancel')}
                </Button>
                <Button
                  color="success"
                  className={classes.marginLeft}
                  onClick={this.handleSubmit}
                >
                  {this.props.t(this.state.id ? 'clients.edit' : 'clients.add')}
                </Button>
              </GridItem>
            </GridContainer>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withStyles(combinedStyles),
  withToast,
  withRouter,
  withTranslation()
);

export default enhance(Form);
