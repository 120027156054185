import { get } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchProduct = id => {
  return dispatch =>
    get(`/products/${id}`).then(product => {
      dispatch({
        type: types.FETCH_INGREDIENT,
        payload: {
          product: product,
        },
      });

      return product;
    });
};
