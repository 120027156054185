const statusPickerStyle = {
  statusPickerSelectBlack: {
    padding: '12px 0 7px',
    fontSize: '.75rem',
    fontWeight: '400',
    lineHeight: '1.42857',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#000000',
    letterSpacing: '0',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&[aria-owns] + input + svg': {
      transform: 'rotate(180deg)',
    },
    '& + input + svg': {
      transition: 'all 300ms linear',
    },
  },
  statusPickerSelectWhite: {
    padding: '12px 0 7px',
    fontSize: '.75rem',
    fontWeight: '400',
    lineHeight: '1.42857',
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    letterSpacing: '0',
    '&:focus': {
      backgroundColor: 'transparent',
    },
    '&[aria-owns] + input + svg': {
      transform: 'rotate(180deg)',
    },
    '& + input + svg': {
      transition: 'all 300ms linear',
    },
  },
  statusPickerSelectFormControl: {
    margin: '0 0 5px 0 !important',
    '& > div': {
      '&:before': {
        borderBottomWidth: '0 !important',
      },
      '&:after': {
        borderBottomWidth: '0 !important',
      },
    },
  },
  statusPickerIconBlack: {
    color: '#000000',
  },
  statusPickerIconWhite: {
    color: '#ffffff',
  },
};

export default statusPickerStyle;
