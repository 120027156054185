import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter';
import GridContainer from 'components/Grid/GridContainer';

import { getHexOfThemeColor } from 'helpers/themeHelpers';

import DetailsDialog from './DetailsDialog';
import CardWrapper from 'components/Card/CardWrapper';

const EllipsisText = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  white-space: normal;
  min-height: 54px;
`;

const AdditionalOfferTile = ({ title, description, ...restProps }) => {
  const { t } = useTranslation();
  const [isDialogOpened, setIsDialogOpened] = useState(false);

  return (
    <>
      {/* POPUP WITH DETAILS */}
      <DetailsDialog
        title={title}
        description={description}
        isDialogOpened={isDialogOpened}
        setIsDialogOpened={setIsDialogOpened}
        {...restProps}
      />

      {/* CARD WITH DATA */}
      <CardWrapper title={title}>
        <CardBody>
          <EllipsisText>{description}</EllipsisText>
        </CardBody>
        <CardFooter>
          <GridContainer justify="flex-end">
            <Button
              style={{ backgroundColor: getHexOfThemeColor('caterings') }}
              onClick={() => setIsDialogOpened(true)}
            >
              {t(
                '$*additionalOffer.detailsDialog.readMore',
                'Czytaj więcej ...'
              )}
            </Button>
          </GridContainer>
        </CardFooter>
      </CardWrapper>
    </>
  );
};

export default AdditionalOfferTile;
