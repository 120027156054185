import React from 'react';
import { useSelector } from 'react-redux';

// material-ui components
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { getHexOfThemeColor } from 'helpers/themeHelpers';

const RegularCheckbox = ({ classes, onClick = () => {}, checked }) => {
  const { mainColor } = useSelector(state => {
    return {
      mainColor: state?.Theme?.mainColor,
    };
  });

  return (
    <Checkbox
      tabIndex={-1}
      name="active"
      onClick={onClick}
      checked={checked}
      checkedIcon={
        <Check
          className={classes.checkedIcon}
          style={{ color: getHexOfThemeColor(mainColor) }}
        />
      }
      icon={
        <Check
          style={{ color: getHexOfThemeColor(mainColor) }}
          className={classes.uncheckedIcon}
        />
      }
      classes={{
        checked: classes.checked,
        root: classes.checkRoot,
      }}
      style={{ color: `${getHexOfThemeColor(mainColor)} !important` }}
    />
  );
};

export default withStyles(extendedFormsStyle)(RegularCheckbox);
