/*eslint-disable*/
import React, { Component } from 'react';

import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';

import styles from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx';

import imagine1 from 'assets/img/sidebar-1.png';
import imagine2 from 'assets/img/sidebar-2.png';
import imagine3 from 'assets/img/sidebar-3.png';
import imagine4 from 'assets/img/sidebar-4.png';
import imagine5 from 'assets/img/sidebar-5.png';
import imagine6 from 'assets/img/sidebar-6.png';
import imagine7 from 'assets/img/sidebar-7.png';
import imagine8 from 'assets/img/sidebar-8.png';
import imagine9 from 'assets/img/sidebar-9.png';
import imagine10 from 'assets/img/sidebar-10.png';
import imagine11 from 'assets/img/sidebar-11.png';
import imagine12 from 'assets/img/sidebar-12.png';
import imagine13 from 'assets/img/sidebar-13.png';
import imagine14 from 'assets/img/sidebar-14.png';
import imagine15 from 'assets/img/sidebar-15.png';
import imagine16 from 'assets/img/sidebar-16.png';

class FixedPlugin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      classes: 'dropdown show',
      bg_checked: false,
      bgImage: this.props.bgImage,
      showImage: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.props.handleFixedClick();
  }
  handleChange = name => event => {
    switch (name) {
      case 'miniActive':
        this.props.sidebarMinimize();
        break;
      case 'image':
        if (event.target.checked) {
          this.props.handleImageClick(this.state.bgImage);
        } else {
          this.props.handleImageClick();
        }
        this.setState({ showImage: event.target.checked });
        break;
      default:
        break;
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div
        className={
          'fixed-plugin' + (this.props.rtlActive ? ' fixed-plugin-rtl' : '')
        }
      >
        <div id="fixedPluginClasses" className={this.props.fixedClasses}>
          <div onClick={this.handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu">
            <li className="header-title">PODŚWIETLENIE MENU</li>
            <li className="adjustments-line">
              <a className="switch-trigger active-color">
                <div className="badge-colors text-center">
                  <span
                    className={
                      this.props.color === 'purple'
                        ? 'badge filter badge-purple active'
                        : 'badge filter badge-purple'
                    }
                    data-color="purple"
                    onClick={() => {
                      this.props.handleColorClick('purple');
                    }}
                  />
                  <span
                    className={
                      this.props.color === 'blue'
                        ? 'badge filter badge-blue active'
                        : 'badge filter badge-blue'
                    }
                    data-color="blue"
                    onClick={() => {
                      this.props.handleColorClick('blue');
                    }}
                  />
                  <span
                    className={
                      this.props.color === 'green'
                        ? 'badge filter badge-green active'
                        : 'badge filter badge-green'
                    }
                    data-color="green"
                    onClick={() => {
                      this.props.handleColorClick('green');
                    }}
                  />
                  <span
                    className={
                      this.props.color === 'red'
                        ? 'badge filter badge-red active'
                        : 'badge filter badge-red'
                    }
                    data-color="red"
                    onClick={() => {
                      this.props.handleColorClick('red');
                    }}
                  />
                  <span
                    className={
                      this.props.color === 'orange'
                        ? 'badge filter badge-orange active'
                        : 'badge filter badge-orange'
                    }
                    data-color="orange"
                    onClick={() => {
                      this.props.handleColorClick('orange');
                    }}
                  />
                  <span
                    className={
                      this.props.color === 'caterings'
                        ? 'badge filter badge-caterings active'
                        : 'badge filter badge-caterings'
                    }
                    data-color="gold"
                    onClick={() => {
                      this.props.handleColorClick('caterings');
                    }}
                  />
                  <span
                    className={
                      this.props.color === 'gold'
                        ? 'badge filter badge-gold active'
                        : 'badge filter badge-gold'
                    }
                    data-color="gold"
                    onClick={() => {
                      this.props.handleColorClick('gold');
                    }}
                  />

                  <span
                    className={
                      this.props.color === 'rose'
                        ? 'badge filter badge-rose active'
                        : 'badge filter badge-rose'
                    }
                    data-color="orange"
                    onClick={() => {
                      this.props.handleColorClick('rose');
                    }}
                  />
                  <span
                    className={
                      this.props.color === 'navyblue'
                        ? 'badge filter badge-navyblue active'
                        : 'badge filter badge-navyblue'
                    }
                    data-color="navyblue"
                    onClick={() => {
                      this.props.handleColorClick('navyblue');
                    }}
                  />
                </div>
                <div className="clearfix" />
              </a>
            </li>
            <li className="header-title">Kolor tła menu</li>
            <li className="adjustments-line">
              <a className="switch-trigger active-color">
                <div className="badge-colors text-center">
                  <span
                    className={
                      this.props.bgColor === 'white'
                        ? 'badge filter badge-white active'
                        : 'badge filter badge-white'
                    }
                    data-color="white"
                    onClick={() => {
                      this.props.handleBgColorClick('white');
                    }}
                  />
                  <span
                    className={
                      this.props.bgColor === 'black'
                        ? 'badge filter badge-black active'
                        : 'badge filter badge-black'
                    }
                    data-color="black"
                    onClick={() => {
                      this.props.handleBgColorClick('black');
                    }}
                  />
                  <span
                    className={
                      this.props.bgColor === 'creamy'
                        ? 'badge filter badge-creamy active'
                        : 'badge filter badge-creamy'
                    }
                    data-color="creamy"
                    onClick={() => {
                      this.props.handleBgColorClick('creamy');
                    }}
                  />
                  <span
                    className={
                      this.props.bgColor === 'blue'
                        ? 'badge filter badge-blue active'
                        : 'badge filter badge-blue'
                    }
                    data-color="blue"
                    onClick={() => {
                      this.props.handleBgColorClick('blue');
                    }}
                  />
                </div>
                <div className="clearfix" />
              </a>
            </li>
            <li className="adjustments-line">
              <a className="switch-trigger">
                <p className="switch-label">Zminimalizuj pasek menu</p>
                <Switch
                  checked={this.props.miniActive}
                  onChange={this.handleChange('miniActive')}
                  value="sidebarMini"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    icon: classes.switchIcon,
                    iconChecked: classes.switchIconChecked,
                    bar: classes.switchBar,
                  }}
                />
                <div className="clearfix" />
              </a>
            </li>
            <li className="adjustments-line">
              <a className="switch-trigger">
                <p className="switch-label">Obrazek tła</p>
                <Switch
                  checked={this.state.showImage}
                  onChange={this.handleChange('image')}
                  value="sidebarMini"
                  classes={{
                    switchBase: classes.switchBase,
                    checked: classes.switchChecked,
                    icon: classes.switchIcon,
                    iconChecked: classes.switchIconChecked,
                    bar: classes.switchBar,
                  }}
                />
                <div className="clearfix" />
              </a>
            </li>
            <li className="header-title">Wybierz obrazek</li>
            <li className={this.state['bgImage'] === imagine1 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine1 });
                  this.props.handleImageClick(imagine1);
                }}
              >
                <img src={imagine1} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine2 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine2 });
                  this.props.handleImageClick(imagine2);
                }}
              >
                <img src={imagine2} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine3 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine3 });
                  this.props.handleImageClick(imagine3);
                }}
              >
                <img src={imagine3} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine4 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine4 });
                  this.props.handleImageClick(imagine4);
                }}
              >
                <img src={imagine4} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine5 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine5 });
                  this.props.handleImageClick(imagine5);
                }}
              >
                <img src={imagine5} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine6 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine6 });
                  this.props.handleImageClick(imagine6);
                }}
              >
                <img src={imagine6} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine7 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine7 });
                  this.props.handleImageClick(imagine7);
                }}
              >
                <img src={imagine7} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine8 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine8 });
                  this.props.handleImageClick(imagine8);
                }}
              >
                <img src={imagine8} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine9 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine9 });
                  this.props.handleImageClick(imagine9);
                }}
              >
                <img src={imagine9} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine10 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine10 });
                  this.props.handleImageClick(imagine10);
                }}
              >
                <img src={imagine10} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine11 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine11 });
                  this.props.handleImageClick(imagine11);
                }}
              >
                <img src={imagine11} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine12 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine12 });
                  this.props.handleImageClick(imagine12);
                }}
              >
                <img src={imagine12} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine13 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine13 });
                  this.props.handleImageClick(imagine13);
                }}
              >
                <img src={imagine13} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine14 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine14 });
                  this.props.handleImageClick(imagine14);
                }}
              >
                <img src={imagine14} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine15 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine15 });
                  this.props.handleImageClick(imagine15);
                }}
              >
                <img src={imagine15} alt="..." />
              </a>
            </li>
            <li className={this.state['bgImage'] === imagine16 ? 'active' : ''}>
              <a
                className="img-holder switch-trigger"
                onClick={() => {
                  this.setState({ showImage: true, bgImage: imagine16 });
                  this.props.handleImageClick(imagine16);
                }}
              >
                <img src={imagine16} alt="..." />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(FixedPlugin);
