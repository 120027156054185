import React from 'react';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { Check } from '@material-ui/icons';
import RootCheckbox from '@material-ui/core/Checkbox';
import { combineStyles } from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import { Info } from '@material-ui/icons';

import makeStyles from '@material-ui/styles/makeStyles';
const useStyles = makeStyles({
  select_cell: {
    minWidth: 280,
  },
});

const PaymentTypeConfig = ({
  classes,
  t,
  openToast,
  paymentTypesTranslations,
  modules,
  setModules,
  paymentTypes,
  setPaymentTypes,
}) => {
  const customStyles = useStyles();

  const validateChangeStatusPaymentType = (value, type) => {
    if (
      (type === 'standard' &&
        value.paymentTypeStandard ===
          paymentTypes.standard.defaultPaymentType) ||
      (type === 'subscription' &&
        value.paymentTypeSubscription ===
          paymentTypes.subscription.defaultPaymentType)
    ) {
      return openToast({
        messages: [
          t(
            'errors.paymentMethod.dufaultPaymentMethod.description',
            'Domyślny tryb płatności nie może zostać wyłączony'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const validateSetDefaultPaymentTypes = (e, subscriptionEnabled = '') => {
    if (e.target.value === 'PAYPO') {
      return openToast({
        messages: [
          t(
            'errors.paymentMethod.dufaultPaymentMethod.paypo',
            'Typ płatności PayPo nie może zostać domyślnym typem płatności'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    if (
      (!subscriptionEnabled &&
        !paymentTypes.standard.enabledPaymentTypes.includes(e.target.value)) ||
      (subscriptionEnabled &&
        !paymentTypes.subscription.enabledPaymentTypes.includes(e.target.value))
    ) {
      return openToast({
        messages: [
          t(
            'errors.paymentMethod.dufaultPaymentMethod.regular.disabledPaymentType',
            'Wyłączony typ płatności nie może być domyślnym trybem płatności.'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  return (
    <>
      <div style={{ overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                {t('paymentType.operator', 'Operator płatności')}
              </TableCell>
              <TableCell>
                {t('paymentType.method', 'Metoda płatności')}
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={modules.ConfigClientPanel.isOneTimePayPaymentMode}
                  onChange={() => {
                    setModules(prev => ({
                      ...prev,
                      ConfigClientPanel: {
                        ...prev.ConfigClientPanel,
                        isOneTimePayPaymentMode:
                          !prev.ConfigClientPanel.isOneTimePayPaymentMode,
                      },
                    }));
                  }}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                  disabled={
                    !modules.ConfigClientPanel.isSubscriptionPaymentMode
                  }
                />
                {t('paymentType.oneTimePayment', 'Płatność jednorazowa')}
              </TableCell>
              <TableCell>
                <Checkbox
                  checked={modules.ConfigClientPanel.isSubscriptionPaymentMode}
                  onChange={() => {
                    setModules(prev => ({
                      ...prev,
                      ConfigClientPanel: {
                        ...prev.ConfigClientPanel,
                        isSubscriptionPaymentMode:
                          !prev.ConfigClientPanel.isSubscriptionPaymentMode,
                      },
                    }));
                  }}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                  disabled={!modules.ConfigClientPanel.isOneTimePayPaymentMode}
                />
                {t('paymentType.subscription', 'Subskrypcja')}
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(paymentTypesTranslations(t)).map(
              ([key, value], index) => {
                return (
                  <TableRow>
                    {index === 0 && (
                      <TableCell rowSpan={7}>{value.title}</TableCell>
                    )}
                    {index === 7 && (
                      <TableCell rowSpan={2}>{value.title}</TableCell>
                    )}

                    {index === 9 && (
                      <TableCell rowSpan={1}>{value.title}</TableCell>
                    )}
                    {index === 10 && (
                      <TableCell rowSpan={2}>{value.title}</TableCell>
                    )}
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        {value.method}
                        {value.tooltip && (
                          <div style={{ margin: '0 0 0 10px' }}>
                            <Tooltip
                              title={
                                <div>
                                  <h4>{value.tooltip}</h4>
                                </div>
                              }
                              placement="right"
                            >
                              <Info fontSize={'small'} />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </TableCell>

                    <TableCell>
                      {value?.paymentTypeStandard ? (
                        <RootCheckbox
                          checked={
                            value.option === 'sandbox'
                              ? modules[value.title].sandbox
                              : paymentTypes?.standard?.enabledPaymentTypes?.includes(
                                  value.paymentTypeStandard
                                )
                          }
                          onChange={() => {
                            //handle sandbox payment
                            if (value.option === 'sandbox') {
                              setModules(prev => ({
                                ...prev,
                                [value.title]: {
                                  ...prev[value.title],
                                  sandbox: !prev[value.title].sandbox,
                                },
                              }));
                              return null;
                            }

                            //handle standard payment types
                            if (
                              validateChangeStatusPaymentType(value, 'standard')
                            ) {
                              return null;
                            }
                            const newPaymentMethod = [
                              ...paymentTypes?.standard?.enabledPaymentTypes,
                            ];

                            if (
                              newPaymentMethod.includes(
                                value.paymentTypeStandard
                              )
                            ) {
                              newPaymentMethod.splice(
                                newPaymentMethod.indexOf(
                                  value.paymentTypeStandard
                                ),
                                1
                              );
                            } else {
                              newPaymentMethod.push(value.paymentTypeStandard);
                            }

                            setPaymentTypes(prev => ({
                              ...prev,
                              standard: {
                                ...prev?.standard,
                                enabledPaymentTypes: newPaymentMethod,
                              },
                            }));
                          }}
                          disabled={
                            !modules.ConfigClientPanel.isOneTimePayPaymentMode
                          }
                        />
                      ) : null}
                    </TableCell>

                    <TableCell>
                      {value?.paymentTypeSubscription ? (
                        <RootCheckbox
                          checked={paymentTypes?.subscription?.enabledPaymentTypes?.includes(
                            value.paymentTypeSubscription
                          )}
                          onChange={() => {
                            if (
                              validateChangeStatusPaymentType(
                                value,
                                'subscription'
                              )
                            ) {
                              return null;
                            }

                            const newPaymentMethod = [
                              ...paymentTypes?.subscription
                                ?.enabledPaymentTypes,
                            ];

                            if (
                              newPaymentMethod.includes(
                                value.paymentTypeSubscription
                              )
                            ) {
                              newPaymentMethod.splice(
                                newPaymentMethod.indexOf(
                                  value.paymentTypeSubscription
                                ),
                                1
                              );
                            } else {
                              newPaymentMethod.push(
                                value.paymentTypeSubscription
                              );
                            }

                            setPaymentTypes(prev => ({
                              ...prev,
                              subscription: {
                                ...prev?.subscription,
                                enabledPaymentTypes: newPaymentMethod,
                              },
                            }));
                          }}
                          disabled={
                            !modules.ConfigClientPanel.isSubscriptionPaymentMode
                          }
                        />
                      ) : null}
                    </TableCell>
                    <TableCell
                      style={{ background: `${value.designation}` }}
                    ></TableCell>
                  </TableRow>
                );
              }
            )}

            <TableRow>
              <TableCell className={customStyles.select_cell}>
                {t(
                  'module.configClientPanel.defaultPaymentMode',
                  'Domyślny tryb Płatności'
                )}
              </TableCell>
              <TableCell></TableCell>
              <TableCell className={customStyles.select_cell}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    value={paymentTypes?.standard?.defaultPaymentType}
                    onChange={e => {
                      if (validateSetDefaultPaymentTypes(e)) {
                        return null;
                      }

                      setPaymentTypes(prev => ({
                        ...prev,
                        standard: {
                          ...prev.standard,
                          defaultPaymentType: e.target.value,
                        },
                      }));
                    }}
                    displayEmpty
                    name="dietStandard"
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                  >
                    {Object.entries(paymentTypesTranslations(t)).map(
                      ([key, value]) => {
                        if (value.method === 'Sandbox') {
                          return null;
                        }

                        return (
                          <MenuItem
                            value={value.paymentTypeStandard}
                            classes={{
                              root: classes.selectMenuItem,
                              selected: classes.selectMenuItemSelectedMultiple,
                            }}
                          >
                            {value.method}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell className={customStyles.select_cell}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <Select
                    value={paymentTypes?.subscription?.defaultPaymentType}
                    onChange={e => {
                      if (
                        validateSetDefaultPaymentTypes(e, 'subscriptionEnabled')
                      ) {
                        return null;
                      }
                      setPaymentTypes(prev => ({
                        ...prev,
                        subscription: {
                          ...prev.subscription,
                          defaultPaymentType: e.target.value,
                        },
                      }));
                    }}
                    displayEmpty
                    name="dietSubscription"
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    disabled={
                      !modules.ConfigClientPanel.isSubscriptionPaymentMode
                    }
                  >
                    {Object.entries(paymentTypesTranslations(t)).map(
                      ([key, value]) => {
                        if (value.method === 'Sandbox') {
                          return null;
                        }

                        if (value?.paymentTypeSubscription) {
                          return (
                            <MenuItem
                              value={value.paymentTypeSubscription}
                              classes={{
                                root: classes.selectMenuItem,
                                selected:
                                  classes.selectMenuItemSelectedMultiple,
                              }}
                            >
                              {value.method}
                            </MenuItem>
                          );
                        }
                      }
                    )}
                  </Select>
                </FormControl>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(PaymentTypeConfig);
