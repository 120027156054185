import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';
import produce from 'immer';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import AdminTable from 'layouts/AdminTable';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Checkbox from '@material-ui/core/Checkbox';
import Button from 'components/CustomButtons/Button.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Check, Info, Extension as ExtensionIcon } from '@material-ui/icons';
import { Divider, Tooltip } from '@material-ui/core';

import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles, isGranted } from 'helpers/helpers';
import { get, put } from 'helpers/apiHelpers';
import { ROLE_EDIT_MODULE_ADDONS } from 'helpers/roles';

const AddonsSettings = ({ classes, openToast }) => {
  const { t } = useTranslation();
  const brandId = useSelector(state => state.Auth.selectedBrand);
  const [formData, setFormData] = useState({
    addToOrder: false,
    extendDiet: false,
    groupByCategory: false,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get(`/brands/${brandId}/module/Addons`);
        const { '@id': iriId, '@type': iriType, ...onlyFormData } = response;

        setFormData({
          ...onlyFormData,
        });
      } catch (error) {}
    };

    fetchData();
  }, []);

  const handleCheckboxClick = ({ target: { name } }) => {
    setFormData(
      produce(draft => {
        draft[name] = !draft[name];
      })
    );
  };

  const handleSubmit = async () => {
    await put(`/brands/${brandId}/module`, {
      module: 'Addons',
      configuration: {
        ...formData,
      },
    });

    openToast({
      messages: [t('status.success.save')],
      type: 'success',
      autoHideDuration: 3000,
    });
  };

  return (
    <AdminTable
      title={t('views.addons.addonsSettings.list')}
      iconComponent={<ExtensionIcon />}
    >
      <GridContainer>
        <GridItem md={12}>
          <h3>{t('views.addons.addonsSettings.clientPanel')}</h3>
          <Divider style={{ marginBottom: '10px' }} />
        </GridItem>
        <GridItem sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                name="addToOrder"
                onClick={handleCheckboxClick}
                checked={formData.addToOrder}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                disabled={!isGranted(ROLE_EDIT_MODULE_ADDONS)}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={t('views.addons.addonsSettings.addToOrder')}
          />
        </GridItem>
        <GridItem sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                name="extendDiet"
                onClick={handleCheckboxClick}
                checked={formData.extendDiet}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                disabled={!isGranted(ROLE_EDIT_MODULE_ADDONS)}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={t('views.addons.addonsSettings.extendDiet')}
          />
        </GridItem>
        <GridItem sm={12}>
          <FormControlLabel
            control={
              <Checkbox
                tabIndex={-1}
                name="groupByCategory"
                onClick={handleCheckboxClick}
                checked={formData.groupByCategory}
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                disabled={!isGranted(ROLE_EDIT_MODULE_ADDONS)}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={t('views.addons.addonsSettings.groupByCategory')}
          />
          <Tooltip
            style={{ color: 'grey', marginBottom: '-4px' }}
            title={
              <h4>
                {t('views.addons.addonsSettings.groupByCategory.tooltip')}
              </h4>
            }
            placement="right"
          >
            <Info fontSize={'small'} />
          </Tooltip>
        </GridItem>
      </GridContainer>

      {isGranted(ROLE_EDIT_MODULE_ADDONS) && (
        <GridContainer justify="flex-end">
          <GridItem>
            <Button color="success" onClick={handleSubmit}>
              {t('common.shared.save')}
            </Button>
          </GridItem>
        </GridContainer>
      )}
    </AdminTable>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(withToast, withStyles(combinedStyles));

export default enhance(AddonsSettings);
