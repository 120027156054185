import { get } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchDepartments = () => {
  return dispatch =>
    get('/departments', { pagination: false }).then(departments => {
      dispatch({
        type: types.FETCH_DEPARTMENTS,
        payload: {
          departments: departments['hydra:member'],
        },
      });
    });
};
