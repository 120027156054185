import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ClearIcon from '@material-ui/icons/Clear';

import { grayColor } from 'assets/jss/material-dashboard-pro-react.jsx';

import { currencyToSymbol } from 'utils/currencies';

import { getHexOfThemeColor } from 'helpers/themeHelpers';
import Select from 'components/CustomSelect/Select';
import MaterialButton from '@material-ui/core/Button';
import AdditionalMealTypeModalCalendar from './AdditionalMealTypeModalCalendar';
import moment from 'moment';

const BoxSectionTitle = styled.h3`
  color: ${props => props.color};
  margin: 0;
  font-weight: 500;
`;

const BoxSectionContainer = styled.div`
  width: 100%;
  margin: ${props => props?.margin ?? 0};
  padding: 15px;
  border: ${`1px solid ${grayColor[4]}`};
  border-radius: 5px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: ${`1px solid ${grayColor[4]}`};
`;

const IconWrapper = styled.div`
  color: ${getHexOfThemeColor('red')};
  cursor: pointer;
`;

const MAX_ADDONS_AMOUNT = 15;

const AdditionalMealTypeBox = ({
  price,
  label = '',
  value = '',
  style = {},
  dateWithAmount = [],
  onRemoveClick = () => {},
  onRemoveDay = () => {},
  calendarSettings,
  diet,
  updateDiet,
  selectedDietAdditionalCalendar,
  setSelectedDietAdditionalCalendar,
  isOpened,
  setIsOpened,
  editAdditionalMealTypes,
  setEditAdditionalMealTypes,
} = {}) => {
  const { t } = useTranslation();

  const { currency, mainColor } = useSelector(state => {
    return {
      currency: state?.Brands.brand.multinational.currency,
      mainColor: state?.Theme?.mainColor,
    };
  });

  const getAmountOptions = () => {
    let amountOptions = [];

    for (let index = 0; index < MAX_ADDONS_AMOUNT; index++) {
      amountOptions.push({
        value: index + 1,
        label: `${index + 1} ${t('orders.addons.quantity', '$$szt.')}`,
      });
    }

    return amountOptions;
  };

  return (
    <>
      <BoxSectionContainer margin={style?.margin}>
        <HeaderWrapper>
          <BoxSectionTitle color={getHexOfThemeColor(mainColor)}>
            {`${label} (${price} ${currencyToSymbol(currency)})`}
          </BoxSectionTitle>
          <div>
            <MaterialButton
              variant={'outlined'}
              fullWidth
              style={{
                marginBottom: '5px',
                color: getHexOfThemeColor(mainColor),
                borderColor: getHexOfThemeColor(mainColor),
              }}
              onClick={() => {
                setEditAdditionalMealTypes(value);
                setIsOpened(true);
              }}
            >
              {t('orders.additionalMealTypes.edit', 'Edytuj')}
            </MaterialButton>
          </div>

          <IconWrapper onClick={() => onRemoveClick(value)}>
            <ClearIcon style={{ width: '17px' }} />
          </IconWrapper>
        </HeaderWrapper>

        <div>
          {dateWithAmount &&
            dateWithAmount.map(el => {
              return (
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <div
                    style={{
                      width: '50%',
                      border: '1px solid RGB(210,210,210)',
                      borderRadius: '5px',
                      textAlign: 'center',
                      padding: '10px 10px 5px 10px',
                      margin: '10px 0 0 0',
                    }}
                  >
                    {moment(el.date).format('DD.MM.YYYY')}
                  </div>
                  <div
                    style={{
                      width: '50%',
                      textAlign: 'center',
                      margin: '10px 10px 0 10px',
                    }}
                  >
                    <Select
                      label={t('orders.select', '$$Wybierz')}
                      value={{
                        value: el.amount,
                        label: `${el.amount} ${t(
                          'orders.addons.quantity',
                          '$$szt.'
                        )}`,
                      }}
                      options={getAmountOptions()}
                      handleChange={e => {
                        const newAmount = e.value;
                        const dietReferences = { ...diet };
                        dietReferences.selectedAdditionalMealTypes.forEach(
                          mealTypes => {
                            if (mealTypes.value === value)
                              mealTypes.dateWithAmount.forEach(date => {
                                if (date.date === el.date) {
                                  date.amount = newAmount;
                                }
                              });
                          }
                        );
                        updateDiet({ ...dietReferences });
                      }}
                    />
                  </div>
                  <IconWrapper
                    style={{ display: 'flex', alignItems: 'center' }}
                    onClick={() => onRemoveDay(el.date, value)}
                  >
                    <ClearIcon style={{ width: '17px' }} />
                  </IconWrapper>
                </div>
              );
            })}
        </div>
      </BoxSectionContainer>
      <AdditionalMealTypeModalCalendar
        diet={diet}
        calendarSettings={calendarSettings}
        isOpened={isOpened}
        onClose={setIsOpened}
        onSubmit={() => {}}
        value={value}
        label={label}
        updateDiet={updateDiet}
        selectedDietAdditionalCalendar={selectedDietAdditionalCalendar}
        setSelectedDietAdditionalCalendar={setSelectedDietAdditionalCalendar}
        editAdditionalMealTypes={editAdditionalMealTypes}
        setEditAdditionalMealTypes={setEditAdditionalMealTypes}
      />
    </>
  );
};

export default AdditionalMealTypeBox;
