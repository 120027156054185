const countdownStyle = {
  countdownWrapper: {
    display: 'flex',
  },
  timeUnit: {
    margin: '0 1px',
  },
};

export default countdownStyle;
