import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import types from 'helpers/constants';

export const TranslatableObjectKey = '@ContentTranslation';

export const useTranslatableSelectedLanguage = () => {
  return useSelector(
    root => root.Translatable.selectedLanguage ?? 'pl',
    shallowEqual
  );
};

export const useTranslatableLanguageHandler = () => {
  const dispatch = useDispatch();
  return (ev, obj) => {
    dispatch({
      type: types.TRANSLATABLE_SELECT_LANGUAGE,
      payload: {
        selectedLanguage: obj.lang,
      },
    });
  };
};

export const useTranslatableLanguages = () => {
  return useSelector(root => {
    const stateLanguages = root.Auth.languages ?? [];

    return (stateLanguages.length === 0
      ? [{ isoCode: 'pl' }]
      : stateLanguages
    ).map(el => el.isoCode);
  }, shallowEqual);
};
/** @return bool true when values are identical */
export const compareTranslatableValues = (val1, val2) => {
  return JSON.stringify(val1) === JSON.stringify(val2);
};

export const isTranslatableValuesObjectEmpty = value => {
  if (!value || value === '') {
    return true;
  }
  const translations = value[TranslatableObjectKey] ?? {};
  return (
    Object.keys(translations).filter(
      el => !translations[el] || translations[el] === ''
    ).length === Object.keys(translations).length
  );
};

export default useTranslatableSelectedLanguage;
