import React, { Component } from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import List from './AdminNavLinksList';
import Form from './AdminNavLinksForm';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_ADMIN_NAV_LINK,
  ROLE_EDIT_ADMIN_NAV_LINK,
  ROLE_SHOW_ADMIN_NAV_LINK,
} from 'helpers/roles';

class AdminNavLinks extends Component {
  render() {
    const props = this.props;

    const ListSecured = props.custom
      ? List
      : withRole(ROLE_SHOW_ADMIN_NAV_LINK, List);
    const CreateForm = props.custom
      ? Form
      : withRole(ROLE_CREATE_ADMIN_NAV_LINK, Form);
    const EditForm = props.custom
      ? Form
      : withRole(ROLE_EDIT_ADMIN_NAV_LINK, Form);

    return (
      <Router history={this.props.history}>
        <Switch>
          <Route
            exact
            path={`${this.props.match.url}/`}
            render={parentProps => <ListSecured {...props} {...parentProps} />}
          />
          <Route
            path={`${this.props.match.url}/add`}
            render={() => <CreateForm {...props} />}
          />
          <Route
            path={`${this.props.match.url}/edit/:id`}
            render={parentProps => <EditForm {...props} {...parentProps} />}
          />
        </Switch>
      </Router>
    );
  }
}

export default withRouter(AdminNavLinks);
