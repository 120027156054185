import React from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import DataGrid from 'components/DataGrid/DataGrid';
import columnConfig from './columnConfig';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import FormControlButtons from '../../../components/FormControlButtons/FormControlButtons';
import { ROLE_CREATE_MASS_ACTION_DIET_ELEMENTS } from 'helpers/roles';
import { isGranted } from 'helpers/helpers';
const List = ({ history, classes, t }) => {
  const createNew = () => history.push(`/admin/mass-actions/diet-elements-add`);

  return (
    <AdminTable>
      {isGranted(ROLE_CREATE_MASS_ACTION_DIET_ELEMENTS) && (
        <FormControlButtons
          classes={classes}
          handleSubmit={createNew}
          submitText={`${t('common.mainMenu.listAdd')} +`}
        />
      )}
      <DataGrid
        remove={true}
        export={true}
        paginationTop={true}
        paginationBottom={false}
        url="/diet-elements-configurations"
        reportName={'dietelementsconfigurations'}
        columns={columnConfig(t)}
        role="DIET_ELEMENTS_CONFIGURATIONS"
        defaultSorted={[{ id: 'createdDate', desc: true }]}
        minRows={20}
      />
    </AdminTable>
  );
};

const enhance = compose(withStyles(buttonsStyle), withTranslation());

export default enhance(List);
