import SUB_PAGES from './consts/SubPages';

const defaultState = {
  name: '',
  workName: '',
  code: '',
  description: '',
  checked: false,
  allowStepWithAdditionalMealTypes: false,
  color: '#000000',
  clientImage: null,
  clientImageUrl: '',
  mainImage: null,
  mainImageUrl: '',
  invoiceOverride: false,
  invoiceOverrideName: null,
  allowSelectMenuFromDiets: [],
  carbohydratesAssumption: '',
  carbohydratesMargin: '',
  fatsAssumption: '',
  fatsMargin: '',
  proteinAssumption: '',
  proteinMargin: '',
  position: '',
  hideDisabledMenuPlannerOptions: false,
  selectedAllergens: [],
  selectedIngredients: [],
  selectedIngredientCategories: [],
  isLoading: false,
  isSubmitting: false,
  reactTablePageSize: 10,
  subpage: SUB_PAGES.CLIENT_PANEL,
  urlSlug: '',
  metaTitle: '',
  metaDescription: '',
  dietDescription: '',
  bannerEnabled: false,
  bannerHeader: '',
  bannerText: '',
  infoBannerDesktop: null,
  infoBannerDesktopUrl: null,
  infoBannerMobile: null,
  infoBannerMobileUrl: null,
  bannerId: null,
  bagContainer: null,
  tableHeaderDates: {
    monday: '2021-09-20',
    tuesday: '2021-09-21',
    wednesday: '2021-09-22',
    thursday: '2021-09-23',
    friday: '2021-09-24',
    saturday: '2021-09-25',
    sunday: '2021-09-26',
  },
};

export default defaultState;
