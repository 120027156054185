import React from 'react';
import { TableCell } from '@material-ui/core';
const TableWhatMailSection = ({ mailingOptions, mailingOption, t }) => {
  return (
    <TableCell>
      {`${mailingOptions(t)[mailingOption.mailKey] || mailingOption.mailKey}`}
      <div>
        <b>
          {mailingOption.managedBySystem
            ? t(
                'mailNotifications.systemNotification',
                'Powiadomienie systemowe'
              )
            : ''}
        </b>
      </div>
    </TableCell>
  );
};

export default TableWhatMailSection;
