import React from 'react';
import { Trans } from 'react-i18next';

class EasyreportsRedirect extends React.Component {
  componentDidMount() {
    window.open('http://easygastro.onecrm.pl/admin/dashboard', '_blank');
  }

  render() {
    const href = 'http://easygastro.onecrm.pl/admin/dashboard';
    const blank = '_blank';

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginTop: '350px',
        }}
      >
        <Trans i18nKey="easyreports.content" href={href} blank={blank}>
          <h1>
            <b>Easyreports</b> zostało otwarte w nowej karcie.
          </h1>
          <h3>
            Możesz też otworzyć nową kartę ręcznie,{' '}
            <b>
              <a href={{ href }} target={{ blank }}>
                klikając tutaj
              </a>
            </b>
          </h3>
        </Trans>
      </div>
    );
  }
}

export default EasyreportsRedirect;
