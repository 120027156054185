import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Async } from 'react-select';

import Close from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MaterialButton from '@material-ui/core/Button';

import ModalButton from 'components/CustomButtons/Button';

import AddNewCustomerForm from 'views/Clients/FormTabs/GeneralInfo/GeneralInfo';
import { orderStyles, asyncSelectStyles } from 'views/Orders/styles';

import { getHexOfThemeColor } from 'helpers/themeHelpers';

const SelectUserWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;
  align-itmes: center;
  justify-content: space-between;

  @media (min-width: 769px) {
    flex-flow: row;
  }
`;

const AsyncInputWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;

  @media (min-width: 769px) {
    width: 500px;
    margin-bottom: 0;
  }
`;

const SelectUserSection = ({
  classes,
  mainColor,
  promiseOptions,
  selectedCustomer,
  selectCurrentUser,
}) => {
  const { t } = useTranslation();

  const [isAddNewCustomerFormOpened, setIsAddNewCustomerFormOpened] =
    useState(false);

  const setNewUser = newUser => {
    selectCurrentUser({
      id: newUser?.id,
      label: `${newUser.firstName} ${newUser.lastName} (${newUser.email})`,
      value: newUser?.['@id'],
      invoiceInfo: newUser?.invoiceCompany
        ? {
            invoiceCompany: newUser?.invoiceCompany,
            invoiceVatNumber: newUser?.invoiceVatNumber,
            invoiceAddressCity: newUser?.invoiceAddressCity,
            invoiceAddressStreet: newUser?.invoiceAddressStreet,
            invoiceAddressPostCode: newUser?.invoiceAddressPostCode,
            invoiceAddressBuildNumber: newUser?.invoiceAddressBuildNumber,
            invoiceAddressPlaceNumber: newUser?.invoiceAddressPlaceNumber,
          }
        : {},
    });
    setIsAddNewCustomerFormOpened(false);
  };

  return (
    <>
      <SelectUserWrapper>
        <AsyncInputWrapper data-cy="select_client_new-order">
          <Async
            value={selectedCustomer}
            styles={asyncSelectStyles}
            onChange={selectCurrentUser}
            clearValue={() => selectCurrentUser(null)}
            isClearable={true}
            loadOptions={promiseOptions}
            placeholder={t('orders.selectClient')}
            noOptionsMessage={() => t('orders.typeNameAndEmail')}
          />
        </AsyncInputWrapper>
        <div>
          <MaterialButton
            data-cy="button_client_new-order"
            variant={'outlined'}
            fullWidth
            style={{
              marginBottom: '5px',
              color: getHexOfThemeColor(mainColor),
              borderColor: getHexOfThemeColor(mainColor),
            }}
            onClick={() => setIsAddNewCustomerFormOpened(true)}
          >
            {t('orders.newClient')} +
          </MaterialButton>
        </div>
      </SelectUserWrapper>
      {isAddNewCustomerFormOpened && (
        <Dialog
          maxWidth="lg"
          fullWidth={true}
          open={isAddNewCustomerFormOpened}
          onClose={() => {
            setIsAddNewCustomerFormOpened(false);
          }}
        >
          <DialogContent>
            <ModalButton
              style={orderStyles.closeButton}
              color={'transparent'}
              justIcon
              round
              onClick={() => {
                setIsAddNewCustomerFormOpened(false);
              }}
            >
              <Close />
            </ModalButton>
            <h2>{t('clients.clientCreation', '$$Tworzenie klienta')}</h2>
            <AddNewCustomerForm
              classes={classes}
              onAfterSubmit={setNewUser}
              addNewUserOnNewOrderForm={true}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default SelectUserSection;
