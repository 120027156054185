import React, { useEffect, useState } from 'react';
import { withToast } from 'material-ui-toast-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import isEmpty from 'lodash/isEmpty';

import EditIcon from '@material-ui/icons/Edit';
import FormLabel from '@material-ui/core/FormLabel';
import NumberFormat from 'react-number-format';
import MaterialButton from '@material-ui/core/Button';
import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';

import { getHexOfThemeColor } from 'helpers/themeHelpers';

import BoxSection from '../BoxSection';

const CustomerInvoiceDataWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const InvoiceDataWrapper = styled.div`
  padding-top: 15px;
  font-weight: 400;
`;

const InvoiceDataFormWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
`;

const InvoiceDataFormInputWrapper = styled.div`
  width: ${props => props?.width ?? '50%'};
  padding: 5px;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
`;

const CustomerInvoiceSection = ({
  classes,
  openToast = () => {},
  customerInvoiceData = {},
  setCustomerInvoiceData = () => {},
} = {}) => {
  const { t } = useTranslation();

  const { mainColor } = useSelector(state => {
    return {
      mainColor: state?.Theme?.mainColor,
    };
  });

  const [isEditMode, setIsEditMode] = useState(false);
  const [editModeInvoiceData, setEditModeInvoiceData] = useState({
    invoiceType: '',
    invoiceCompany: '',
    invoiceVatNumber: '',
    invoiceAddressCity: '',
    invoiceAddressStreet: '',
    invoiceAddressPostCode: '',
    invoiceAddressBuildNumber: '',
    invoiceAddressPlaceNumber: '',
  });

  useEffect(() => {
    if (!isEmpty(customerInvoiceData)) {
      setEditModeInvoiceData(customerInvoiceData);
      setIsEditMode(false);
    } else {
      setEditModeInvoiceData({
        invoiceType: '',
        invoiceCompany: '',
        invoiceVatNumber: '',
        invoiceAddressCity: '',
        invoiceAddressStreet: '',
        invoiceAddressPostCode: '',
        invoiceAddressBuildNumber: '',
        invoiceAddressPlaceNumber: '',
      });
      setIsEditMode(true);
    }
  }, [customerInvoiceData]);

  const validateInvoiceData = invoiceData => {
    const {
      invoiceCompany,
      invoiceVatNumber,
      invoiceAddressCity,
      invoiceAddressStreet,
      invoiceAddressPostCode,
      invoiceAddressBuildNumber,
    } = invoiceData ?? {};

    return (
      !isEmpty(invoiceCompany) &&
      !isEmpty(invoiceVatNumber) &&
      !isEmpty(invoiceAddressCity) &&
      !isEmpty(invoiceAddressStreet) &&
      !isEmpty(invoiceAddressPostCode) &&
      !isEmpty(invoiceAddressBuildNumber)
    );
  };

  const updateInvoiceData = () => {
    const isInvoiceDataValid = validateInvoiceData(editModeInvoiceData);

    if (!isInvoiceDataValid) {
      return openToast({
        messages: [
          t(
            'orders.customerInvoiceSection.fillAllRequiredData',
            '$$Uzupełnij wszystkie wymagane dane'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    setCustomerInvoiceData(editModeInvoiceData);
    setIsEditMode(false);
  };

  return (
    <div>
      <BoxSection title={t('orders.invoiceData', '$$Dane do faktury')}>
        {isEditMode ? (
          <InvoiceDataFormWrapper>
            <InvoiceDataFormInputWrapper>
              <FormLabel className={classes.labelHorizontal}>
                {t('orders.companyName')}*
              </FormLabel>
              <FormTextInputNoGrid
                data-cy="new-order_billing_company-name"
                classes={classes}
                value={editModeInvoiceData?.invoiceCompany}
                handleChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceCompany: ev.target.value,
                  })
                }
              />
            </InvoiceDataFormInputWrapper>
            <InvoiceDataFormInputWrapper>
              <FormLabel className={classes.labelHorizontal}>
                {t('orders.taxId')}*
              </FormLabel>
              <NumberFormat
                data-cy="new-order_billing_company-number"
                classes={classes}
                customInput={FormTextInputNoGrid}
                value={editModeInvoiceData?.invoiceVatNumber}
                format="##########"
                onChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceVatNumber: ev.target.value,
                  })
                }
              />
            </InvoiceDataFormInputWrapper>
            <InvoiceDataFormInputWrapper>
              <FormLabel className={classes.labelHorizontal}>
                {t('orders.city')}*
              </FormLabel>
              <FormTextInputNoGrid
                data-cy="new-order_billing_city"
                classes={classes}
                value={editModeInvoiceData?.invoiceAddressCity}
                handleChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceAddressCity: ev.target.value,
                  })
                }
              />
            </InvoiceDataFormInputWrapper>
            <InvoiceDataFormInputWrapper>
              <FormLabel className={classes.labelHorizontal}>
                {t('orders.street')}*
              </FormLabel>
              <FormTextInputNoGrid
                data-cy="new-order_billing_street"
                classes={classes}
                value={editModeInvoiceData?.invoiceAddressStreet}
                handleChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceAddressStreet: ev.target.value,
                  })
                }
              />
            </InvoiceDataFormInputWrapper>
            <InvoiceDataFormInputWrapper width="33%">
              <FormLabel className={classes.labelHorizontal}>
                {t('orders.postcode')}*
              </FormLabel>
              <NumberFormat
                data-cy="new-order_billing_postcode"
                classes={classes}
                customInput={FormTextInputNoGrid}
                value={editModeInvoiceData?.invoiceAddressPostCode}
                format="##-###"
                onChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceAddressPostCode: ev.target.value,
                  })
                }
              />
            </InvoiceDataFormInputWrapper>
            <InvoiceDataFormInputWrapper width="33%">
              <FormLabel className={classes.labelHorizontal}>
                {t('orders.buildingNo')}*
              </FormLabel>
              <FormTextInputNoGrid
                data-cy="new-order_billing_build-number"
                classes={classes}
                value={editModeInvoiceData?.invoiceAddressBuildNumber}
                handleChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceAddressBuildNumber: ev.target.value,
                  })
                }
              />
            </InvoiceDataFormInputWrapper>
            <InvoiceDataFormInputWrapper width="33%">
              <FormLabel className={classes.labelHorizontal}>
                {t('orders.localNo')}
              </FormLabel>
              <FormTextInputNoGrid
                data-cy="new-order_billing_locale-number"
                classes={classes}
                value={editModeInvoiceData?.invoiceAddressPlaceNumber}
                handleChange={ev =>
                  setEditModeInvoiceData({
                    ...editModeInvoiceData,
                    invoiceAddressPlaceNumber: ev.target.value,
                  })
                }
              />
            </InvoiceDataFormInputWrapper>
            <MaterialButton
              data-cy="new-order_billing_confirm-button"
              variant={'outlined'}
              fullWidth
              style={{
                marginTop: '15px',
                color: getHexOfThemeColor(mainColor),
                borderColor: getHexOfThemeColor(mainColor),
              }}
              onClick={updateInvoiceData}
            >
              {t('orders.save', '$$Zapisz')}
            </MaterialButton>
          </InvoiceDataFormWrapper>
        ) : (
          <CustomerInvoiceDataWrapper>
            <InvoiceDataWrapper>
              <p>{`${customerInvoiceData?.invoiceCompany}, ${customerInvoiceData?.invoiceVatNumber}`}</p>
              <p>{`${customerInvoiceData?.invoiceAddressStreet} ${
                customerInvoiceData?.invoiceAddressBuildNumber
              }${
                customerInvoiceData?.invoiceAddressPlaceNumber
                  ? `/${customerInvoiceData?.invoiceAddressPlaceNumber}`
                  : ''
              }, ${customerInvoiceData?.invoiceAddressPostCode} ${
                customerInvoiceData?.invoiceAddressCity
              }`}</p>
            </InvoiceDataWrapper>
            <EditIcon
              style={{
                width: '15px',
                cursor: 'pointer',
                color: getHexOfThemeColor(mainColor),
              }}
              onClick={() => setIsEditMode(true)}
            />
          </CustomerInvoiceDataWrapper>
        )}
      </BoxSection>
    </div>
  );
};

export default withToast(CustomerInvoiceSection);
