import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

const CustomCheckbox = ({
  checked = false,
  onChange,
  classes,
  label,
  disabled = false,
  name,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          checked={checked}
          onChange={onChange}
          disabled={disabled}
          checkedIcon={<Check className={classes.checkedIcon} />}
          icon={<Check className={classes.uncheckedIcon} />}
          classes={{
            checked: classes.checked,
            root: classes.checkRoot,
          }}
        />
      }
      label={label}
    />
  );
};

const enhance = compose(withStyles(buttonsStyle));

export default enhance(CustomCheckbox);
