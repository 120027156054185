import cx from 'classnames';

import ArrowRightButtonIcon from 'assets/img/ArrowRightButtonIcon';

const SidebarMinimizeButton = ({
  classes,
  rtlActive,
  miniModeActive,
  minimizeSidebar = () => {},
  setIsMenuExpanded = () => {},
}) => {
  const sidebarMinimizeStyle = cx(classes.sidebarMinimize, {
    [classes.sidebarMinimizeRTL]: rtlActive,
  });

  return (
    <div className={sidebarMinimizeStyle}>
      {miniModeActive ? (
        <ArrowRightButtonIcon onClick={minimizeSidebar} />
      ) : (
        <ArrowRightButtonIcon
          onClick={e => {
            e.preventDefault();
            minimizeSidebar();
            setIsMenuExpanded(false);
          }}
          style={{ transform: 'rotate(180deg)' }}
        />
      )}
    </div>
  );
};

export default SidebarMinimizeButton;
