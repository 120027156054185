import React from 'react';
import { ROLE_SHOW_CLIENT_CALL } from 'helpers/roles';
import moment from 'moment';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import axios from 'helpers/gastro';
//styles
import { combineStyles, isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import SelectAll from 'components/SelectAll';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Dialog, DialogContent } from '@material-ui/core';
import AccessDenied from '../../components/Security/AccessDenied';
import { withTranslation } from 'react-i18next';
import Archive from '../../components/Archive/Archive';
import { callTypes } from '../Clients/FormTabs/Calls/ItemTypes';
import getReportFileName from 'helpers/getReportFileName';
import CardWrapper from 'components/Card/CardWrapper';

// translate
class BillingReport extends React.Component {
  state = {
    selectedBrands: [],
    selectedTypes: [],
    dateFrom: '',
    dateTo: '',
    isGenerating: false,
  };

  url = 'reports/billing';

  getFileName = getReportFileName(
    this.props.t('common.mainMenu.reportsBilling')
  );

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validateDate = () => {
    return this.state.dateFrom && this.state.dateTo;
  };

  validateBrands = () => {
    return this.state.selectedBrands.length !== 0;
  };

  validateTypes = () => {
    return this.state.selectedTypes.length !== 0;
  };

  handleGenerate = () => {
    const { t } = this.props;
    this.setState({ isGenerating: true });
    if (!this.validateDate()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          t(
            'errors.raportRequiresDateRange',
            'Wybierz daty, dla których chcesz wygenerować raport'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (!this.validateBrands()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          t('errors.raportRequireBrand', 'Wybierz przynajmniej jedną markę'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    if (!this.validateTypes()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          t(
            'errors.raportRequireBilling',
            'Wybierz przynajmniej jeden typ połączenia'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const params = {
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      brands: this.state.selectedBrands.map(brand => brand.value),
      types: this.state.selectedTypes.map(type => type.value),
    };

    axios
      .get(this.url, {
        responseType: 'blob',
        params: params,
        headers: { accept: 'application/vnd.ms-excel' },
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${this.getFileName(this.state)}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            alert(
              t(
                'reports.noAccess',
                'Nie posiadasz uprawnień do wygenerowania tego raportu. Skontaktuj się z administratorem.'
              )
            );
            this.setState({ isGenerating: false });
          }
        },
        error => {
          this.props.openToast({
            messages: [
              t('reports.cannotGenerate', 'Nie udało się wygenerować raportu'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
          this.setState({ isGenerating: false });
        }
      )
      .finally(() => {
        this.setState({ isGenerating: false });
      });
  };

  // translate
  render() {
    const { classes, t } = this.props;

    return isGranted(ROLE_SHOW_CLIENT_CALL) ? (
      <>
        <CardWrapper title={t('common.mainMenu.reportsBilling')}>
          <Dialog open={this.state.isGenerating}>
            <DialogContent>
              <div style={{ textAlign: 'center' }}>
                <h1>{t('reports.generate', 'Trwa generowanie raportu')}</h1>
                <CircularProgress />
              </div>
            </DialogContent>
          </Dialog>

          <CardBody>
            <GridContainer justify="flex-end">
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '2px' }}
                >
                  {t('reports.selectBrands', 'Wybierz marki')}*
                </FormLabel>
                <SelectAll
                  options={this.props.brands}
                  trackBy={['@id']}
                  mapBy={'name'}
                  optionSelected={this.state.selectedBrands}
                  handleChange={selected => {
                    this.setState({
                      selectedBrands: selected,
                    });
                  }}
                />
              </GridItem>
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {t('reports.selectDate1', 'Wybierz datę początkową')}*
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(this.state.dateFrom)}
                    onChange={ev =>
                      this.setState({ dateFrom: ev.format('YYYY-MM-DD') })
                    }
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem sm={4}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {t('reports.selectDate2', 'Wybierz datę końcową')}*
                </FormLabel>
                <FormControl fullWidth>
                  <Datetime
                    isValidDate={isDatepickerValidDay}
                    timeFormat={false}
                    dateFormat={moment.localeData().longDateFormat('L')}
                    closeOnSelect={true}
                    value={new moment(this.state.dateTo)}
                    onChange={ev =>
                      this.setState({ dateTo: ev.format('YYYY-MM-DD') })
                    }
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </FormControl>
              </GridItem>
              <GridItem sm={12}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '2px' }}
                >
                  {t('clientCalls.type', 'Typ połączenia')}*
                </FormLabel>
                <SelectAll
                  classes={classes}
                  options={callTypes(t)}
                  value={this.state.types}
                  mapBy="name"
                  x
                  trackBy="value"
                  name="type"
                  optionSelected={this.state.selectedTypes}
                  handleChange={selected => {
                    this.setState({
                      selectedTypes: selected,
                    });
                  }}
                  size={12}
                />
              </GridItem>
              <GridItem>
                <Button
                  onClick={() => this.handleGenerate()}
                  disabled={this.state.isGenerating}
                  color={'success'}
                  round
                >
                  {t('reports.gReport', 'Generuj Excel (XLSX)')}
                </Button>
              </GridItem>
            </GridContainer>
          </CardBody>
        </CardWrapper>
        <Card>
          <CardBody>
            <Archive type={['REPORT']} reportName={['BILLING']} />
          </CardBody>
        </Card>
      </>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  brands: state.Auth.user.brands,
});

BillingReport.propTypes = {
  brands: PropTypes.array,
  types: PropTypes.array,
  classes: PropTypes.any,
  fileName: PropTypes.func.isRequired,
  mimeType: PropTypes.string.isRequired,
  openToast: PropTypes.any,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
};

const enhance = compose(
  connect(mapStateToProps, null),
  withStyles(combinedStyles),
  withToast,
  withTranslation()
);

export default enhance(BillingReport);
