import cx from 'classnames';

import SidebarMinimizeButton from './SidebarMinimizeButton';

const BrandBar = ({
  classes,
  bgColor,
  logoText,
  rtlActive,
  isMenuExpanded,
  miniModeActive,
  minimizeSidebar = () => {},
  setIsMenuExpanded = () => {},
  showMinimizeButton = false,
}) => {
  const logoClasses = cx(classes.logo, classes.logoNormalBrand, {
    [classes.whiteAfter]: bgColor === 'white',
  });

  const logoNormal = cx(classes.logoNormal, {
    [classes.logoNormalSidebarMini]: !isMenuExpanded,
    [classes.logoNormalSidebarMiniRTL]: rtlActive && !isMenuExpanded,
    [classes.logoNormalRTL]: rtlActive,
  });

  return (
    <div className={logoClasses}>
      {isMenuExpanded ? (
        <>
          <a href="/" className={logoNormal}>
            {logoText}
          </a>

          {showMinimizeButton && (
            <div className={classes.sidebarMinimizeButton}>
              <SidebarMinimizeButton
                classes={classes}
                rtlActive={rtlActive}
                miniModeActive={miniModeActive}
                minimizeSidebar={minimizeSidebar}
              />
            </div>
          )}
        </>
      ) : (
        <div className={classes.sidebarMinimizeButtonExpanded}>
          <SidebarMinimizeButton
            classes={classes}
            rtlActive={rtlActive}
            miniModeActive={miniModeActive}
            minimizeSidebar={minimizeSidebar}
            setIsMenuExpanded={setIsMenuExpanded}
          />
        </div>
      )}
    </div>
  );
};

export default BrandBar;
