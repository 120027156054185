import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import ArrowDown from 'assets/img/arrow-down.svg';
import ArrowRight from 'assets/img/arrow-right.svg';
import ArrowDiagonal from 'assets/img/arrow-diagonal.svg';

import React from 'react';

const SlotsSectionSettings = ({
  classes,
  t,
  daysOfWeek,
  dayFields,
  translateDays,
  translateFields,
  returnTableCell,
}) => {
  return (
    <>
      <h3>{t('brandCfg.timeSettings')}</h3>
      <Divider style={{ marginBottom: '20px' }} />
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={'250px'}>
              Aby dokonać [akcja] <img src={ArrowDown} alt="arrow" /> na [dzień]{' '}
              <img src={ArrowRight} alt="arrow" /> należy to zrobić przed{' '}
              <img src={ArrowDiagonal} alt="arrow" />
            </TableCell>
            {daysOfWeek.map((dayOfWeek, dayOfWeekIndex) => {
              return (
                <TableCell padding={'none'} key={dayOfWeekIndex}>
                  {translateDays(t)[dayOfWeek]}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dayFields.map((field, fieldIndex) => {
            return (
              <TableRow key={fieldIndex}>
                <TableCell>
                  {translateFields(t)[field]}{' '}
                  {field === 'newOrderSubscription' && (
                    <span style={{ color: 'grey' }}>
                      {t(
                        'brands.newBrandForm.translateHelpText.newOrderSubscription'
                      )}
                    </span>
                  )}{' '}
                  {field === 'changeDate' && (
                    <span style={{ color: 'grey' }}>
                      {t('brands.newBrandForm.translateHelpText.changeDate')}
                    </span>
                  )}
                </TableCell>
                {daysOfWeek.map((dayOfWeek, dayOfWeekIndex) => {
                  return (
                    <TableCell padding={'none'} key={dayOfWeekIndex}>
                      <div
                        style={{
                          padding: '10px 5px',
                          minWidth: '120px',
                        }}
                      >
                        {returnTableCell(classes, dayOfWeek, field)}
                      </div>
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default SlotsSectionSettings;
