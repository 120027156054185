import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { calendarStyles } from 'views/Orders/styles';

import PriceWrapper from 'components/PriceWrapper/PriceWrapper';

import { Divider, Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import { currencyToSymbol } from 'utils/currencies';

import { getHexOfThemeColor } from 'helpers/themeHelpers';

import BoxSection from '../BoxSection';
import ADDONS_TYPES from '../../consts/addonsTypes';

const DietSummarySection = ({ diet, dietCost, dietIndex } = {}) => {
  const { t } = useTranslation();

  const {
    address,
    dietLength,
    pickUpPoint,
    selectedDays,
    selectedDiet,
    selectedAddons,
    selectedVariant,
    selectedCalorific,
  } = diet ?? {};

  const dietFilteredWithRows = dietCost?.rows?.filter(diet => {
    return diet.connectedTo === null;
  });

  const priceAdditionalMealTypeBeforeDiscount = dietCost?.rows
    ?.filter(
      el =>
        el?.connectedTo?.['@id'] === dietFilteredWithRows?.[dietIndex]?.['@id']
    )
    .reduce((acc, current) => acc + current?.price?.beforeDiscount, 0);

  const priceAdditionalMealTypeAfterDiscount = dietCost?.rows
    ?.filter(
      el =>
        el?.connectedTo?.['@id'] === dietFilteredWithRows?.[dietIndex]?.['@id']
    )
    .reduce((acc, current) => acc + current?.price?.afterDiscount, 0);

  const priceBeforeDiscount =
    dietFilteredWithRows?.[dietIndex]?.price?.beforeDiscount +
    priceAdditionalMealTypeBeforeDiscount;

  const priceDietPerDayBeforeDiscount =
    dietFilteredWithRows?.[dietIndex]?.price?.priceParts?.DIET / dietLength;

  const priceAfterDiscount =
    dietFilteredWithRows?.[dietIndex]?.price?.afterDiscount +
    priceAdditionalMealTypeAfterDiscount;

  const deliveryBeforeDiscount =
    dietFilteredWithRows?.[dietIndex]?.price?.priceParts?.DELIVERY ?? 0;
  const deliveryPricePerDayBeforeDiscount = deliveryBeforeDiscount / dietLength;

  const discountParts =
    dietFilteredWithRows?.[dietIndex]?.price?.discountParts ?? 0;

  const discountPriceAllValue =
    discountParts &&
    Object.keys(discountParts)
      .map(el => discountParts[el])
      .reduce((acc, discount) => acc + discount, 0);

  const priceAllAddonsBeforeDiscount =
    dietFilteredWithRows?.[dietIndex]?.price?.priceParts?.ADDON ?? 0;

  const priceAllAddonsPerDaysBeforeDiscount = priceAllAddonsBeforeDiscount
    ? priceAllAddonsBeforeDiscount / dietLength
    : 0;

  const priceAddonsFirstDayBeforeDiscount = dietFilteredWithRows?.[
    dietIndex
  ]?.addons
    .map(addon => addon?.quantity * addon?.addon?.clientCost)
    .reduce((acc, addon) => acc + addon, 0);

  const priceAddonsOtherDaysPerDaysBeforeDiscount =
    (priceAllAddonsBeforeDiscount - priceAddonsFirstDayBeforeDiscount) /
    (dietLength - 1);

  const { currency, mainColor } = useSelector(state => {
    return {
      currency: state?.Brands.brand.multinational.currency,
      mainColor: state?.Theme?.mainColor,
    };
  });

  const priceEcoContainer =
    dietFilteredWithRows?.[dietIndex]?.price?.priceParts
      ?.OPTION_ECO_CONTAINERS ?? 0;

  const isDietCostAvailable =
    !isEmpty(dietCost) &&
    !isEmpty(selectedDiet) &&
    !isEmpty(selectedVariant) &&
    !isEmpty(selectedCalorific) &&
    dietLength === selectedDays?.length &&
    (!isEmpty(address) || !isEmpty(pickUpPoint));

  return (
    <BoxSection
      title={t('orders.dietCost', '$$Koszt diety')}
      headerRightCornerComponent={
        <div data-cy="new-order_diet-cost_price-wrapper">
          {isDietCostAvailable ? (
            <PriceWrapper
              beforeDiscount={priceBeforeDiscount}
              afterDiscount={priceAfterDiscount}
              style={{
                wrapper: { fontWeight: '500' },
                afterDiscount: { color: getHexOfThemeColor(mainColor) },
              }}
            />
          ) : (
            <></>
          )}
        </div>
      }
    >
      <div>
        {isDietCostAvailable &&
          (priceAddonsFirstDayBeforeDiscount > 0 &&
          priceAddonsFirstDayBeforeDiscount !==
            priceAddonsOtherDaysPerDaysBeforeDiscount ? (
            <div>
              {/* PRICE PER FIRST DAY */}
              <h4
                style={{
                  ...calendarStyles.spaceBetween,
                  color: getHexOfThemeColor(mainColor),
                }}
              >
                <b>
                  {t('orders.pricePerFirstDay', '$$Cena za pierwszy dzień')}:
                </b>
                <b>
                  {priceDietPerDayBeforeDiscount !== ''
                    ? `${(
                        deliveryPricePerDayBeforeDiscount +
                        priceDietPerDayBeforeDiscount +
                        priceAddonsFirstDayBeforeDiscount +
                        priceEcoContainer
                      ).toFixed(2)} ${currencyToSymbol(currency)}`
                    : '-'}
                </b>
              </h4>
              <h4 style={calendarStyles.spaceBetween}>
                {t('orders.diet', '$$Dieta')}:{' '}
                <span>
                  {priceDietPerDayBeforeDiscount !== ''
                    ? `${(priceDietPerDayBeforeDiscount ?? 0).toFixed(
                        2
                      )} ${currencyToSymbol(currency)}`
                    : '-'}
                </span>
              </h4>
              <h4 style={calendarStyles.spaceBetween}>
                {t(
                  'orders.addonsForFirstDayPriceAfterDiscount',
                  'Dodatki pierwszego dnia'
                )}
                :{' '}
                <span>
                  {priceAddonsFirstDayBeforeDiscount !== ''
                    ? `${(priceAddonsFirstDayBeforeDiscount ?? 0)?.toFixed(
                        2
                      )} ${currencyToSymbol(currency)}`
                    : '-'}
                </span>
              </h4>
              <ul>
                {selectedAddons?.map(addon => {
                  return <li>{`${addon.label}  x ${addon.amount}`}</li>;
                })}
              </ul>
              <h4 style={calendarStyles.spaceBetween}>
                {t('orders.delivery')}:{' '}
                <span>
                  {deliveryPricePerDayBeforeDiscount !== ''
                    ? `${(deliveryPricePerDayBeforeDiscount ?? 0).toFixed(
                        2
                      )} ${currencyToSymbol(currency)}`
                    : '-'}
                </span>
              </h4>
              <h4 style={calendarStyles.spaceBetween}>
                {t('orders.ecoContainer')}:{' '}
                <span>
                  {priceEcoContainer !== ''
                    ? `${(priceEcoContainer ?? 0).toFixed(
                        2
                      )} ${currencyToSymbol(currency)}`
                    : '-'}
                </span>
              </h4>
              {/* PRICE PER NEXT DAYS */}
              {dietLength > 1 && (
                <>
                  <h4
                    style={{
                      ...calendarStyles.spaceBetween,
                      color: getHexOfThemeColor(mainColor),
                    }}
                  >
                    <b>
                      {t('orders.pricePerNextDays', '$$Cena za kolejne dni')}:
                    </b>
                    <b>
                      {priceAddonsOtherDaysPerDaysBeforeDiscount !== ''
                        ? `${(
                            deliveryPricePerDayBeforeDiscount +
                            priceDietPerDayBeforeDiscount +
                            priceAddonsOtherDaysPerDaysBeforeDiscount +
                            priceEcoContainer
                          ).toFixed(2)} ${currencyToSymbol(currency)}`
                        : '-'}
                    </b>
                  </h4>

                  <h4 style={calendarStyles.spaceBetween}>
                    {t('orders.diet', '$$Dieta')}:{' '}
                    <span>
                      {priceDietPerDayBeforeDiscount !== ''
                        ? `${(priceDietPerDayBeforeDiscount ?? 0).toFixed(
                            2
                          )} ${currencyToSymbol(currency)}`
                        : '-'}
                    </span>
                  </h4>
                  {!!priceAddonsOtherDaysPerDaysBeforeDiscount && (
                    <>
                      <h4 style={calendarStyles.spaceBetween}>
                        {t(
                          'orders.addonsForAnotherDaysPriceAfterDiscount',
                          'Dodatki kolejnego dnia"'
                        )}
                        :{' '}
                        <span>
                          {priceAddonsOtherDaysPerDaysBeforeDiscount !== ''
                            ? `${(
                                priceAddonsOtherDaysPerDaysBeforeDiscount ?? 0
                              )?.toFixed(2)} ${currencyToSymbol(currency)}`
                            : '-'}
                        </span>
                      </h4>
                      <ul>
                        {selectedAddons
                          ?.filter(addon => addon.type === ADDONS_TYPES.FOR_BAG)
                          .map(addon => (
                            <li>{`${addon.label}  x ${addon.amount}`}</li>
                          ))}
                      </ul>
                    </>
                  )}
                  <h4 style={calendarStyles.spaceBetween}>
                    {t('orders.delivery')}:{' '}
                    <span>
                      {deliveryPricePerDayBeforeDiscount !== ''
                        ? `${(deliveryPricePerDayBeforeDiscount ?? 0).toFixed(
                            2
                          )} ${currencyToSymbol(currency)}`
                        : '-'}
                    </span>
                  </h4>
                  <h4 style={calendarStyles.spaceBetween}>
                    {t('orders.ecoContainer')}:{' '}
                    <span>
                      {priceEcoContainer !== ''
                        ? `${(priceEcoContainer ?? 0).toFixed(
                            2
                          )} ${currencyToSymbol(currency)}`
                        : '-'}
                    </span>
                  </h4>
                </>
              )}
            </div>
          ) : (
            <div>
              <h4
                style={{
                  ...calendarStyles.spaceBetween,
                  color: getHexOfThemeColor(mainColor),
                }}
              >
                <b>{t('orders.pricePerDay', '$$Cena za dzień')}: </b>
                <b>
                  {priceDietPerDayBeforeDiscount !== ''
                    ? `${(
                        deliveryPricePerDayBeforeDiscount +
                        priceDietPerDayBeforeDiscount +
                        priceAllAddonsPerDaysBeforeDiscount +
                        priceEcoContainer / dietLength
                      ).toFixed(2)} ${currencyToSymbol(currency)}`
                    : '-'}
                </b>
              </h4>
              <h4 style={calendarStyles.spaceBetween}>
                {t('orders.diet', '$$Dieta')}:{' '}
                <span>
                  {priceDietPerDayBeforeDiscount !== ''
                    ? `${(priceDietPerDayBeforeDiscount ?? 0).toFixed(
                        2
                      )} ${currencyToSymbol(currency)}`
                    : '-'}
                </span>
              </h4>

              {!!priceAllAddonsPerDaysBeforeDiscount && (
                <>
                  <h4 style={calendarStyles.spaceBetween}>
                    {t('orders.addonsForAnotherDaysPriceAfterDiscount')}:{' '}
                    <span>
                      {priceAllAddonsPerDaysBeforeDiscount !== ''
                        ? `${(
                            priceAllAddonsPerDaysBeforeDiscount ?? 0
                          )?.toFixed(2)} ${currencyToSymbol(currency)}`
                        : '-'}
                    </span>
                  </h4>
                  <ul>
                    {selectedAddons
                      ?.filter(addon => addon.type === ADDONS_TYPES.FOR_BAG)
                      .map(addon => (
                        <li>{`${addon.label} x${addon.amount}`}</li>
                      ))}
                  </ul>
                </>
              )}

              <h4 style={calendarStyles.spaceBetween}>
                {t('orders.delivery')}:{' '}
                <span>
                  {deliveryPricePerDayBeforeDiscount !== ''
                    ? `${(deliveryPricePerDayBeforeDiscount ?? 0).toFixed(
                        2
                      )} ${currencyToSymbol(currency)}`
                    : '-'}
                </span>
              </h4>
              <h4 style={calendarStyles.spaceBetween}>
                {t('orders.ecoContainer')}:{' '}
                <span>
                  {priceEcoContainer !== ''
                    ? `${(priceEcoContainer ?? 0).toFixed(
                        2
                      )} ${currencyToSymbol(currency)}`
                    : '-'}
                </span>
              </h4>
            </div>
          ))}

        {priceAdditionalMealTypeBeforeDiscount > 0 && (
          <div>
            <h4
              style={{
                ...calendarStyles.spaceBetween,
                color: getHexOfThemeColor(mainColor),
              }}
            >
              <b> {t('orders.additionalMealTypes', 'Dodatkowe porcje')}: </b>
              <span>
                <Tooltip
                  style={{ color: 'RGB(99,108,121)' }}
                  title={
                    <h4>
                      {t(
                        'orders.summary.additionalMealTypes.tooltip',
                        'Cena zawiera koszt eko pojemników, jeśli są dodane do zamówienia'
                      )}
                    </h4>
                  }
                  placement="right"
                >
                  <Info
                    style={{
                      marginLeft: '4px',
                      paddingTop: '1px',
                      fontSize: '16px',
                      color: 'RGB(99,108,121)',
                    }}
                  />
                </Tooltip>
                <b>
                  {' '}
                  {priceAdditionalMealTypeBeforeDiscount !== ''
                    ? `${(priceAdditionalMealTypeBeforeDiscount ?? 0).toFixed(
                        2
                      )} ${currencyToSymbol(currency)}`
                    : '-'}
                </b>
              </span>
            </h4>
          </div>
        )}

        {isDietCostAvailable &&
          Object.values(discountParts ?? []).length > 0 && (
            <>
              <Divider />
              <h4
                style={{
                  ...calendarStyles.spaceBetween,
                  color: getHexOfThemeColor(mainColor),
                }}
              >
                <b>{t('orders.discounts', '$$Rabaty')} </b>
                <b>
                  {discountPriceAllValue !== ''
                    ? `-${discountPriceAllValue.toFixed(2)} ${currencyToSymbol(
                        currency
                      )}`
                    : '-'}
                </b>
              </h4>
              {Object.keys(discountParts ?? []).map(discountKey => (
                <h4 key={discountKey} style={calendarStyles.spaceBetween}>
                  {t('basket.priceDetails.discountInfo.' + [discountKey])}:{' '}
                  <span>
                    -{discountParts[discountKey].toFixed(2)}{' '}
                    {currencyToSymbol(currency)}
                  </span>
                </h4>
              ))}{' '}
            </>
          )}
        {isDietCostAvailable && (
          <>
            <Divider />
            <h4
              style={{
                ...calendarStyles.spaceBetween,
                color: getHexOfThemeColor(mainColor),
              }}
            >
              <b>{t('orders.priceAfter')}: </b>
              <b>
                {priceAfterDiscount
                  ? `${priceAfterDiscount.toFixed(2)} ${currencyToSymbol(
                      currency
                    )}`
                  : '-'}
              </b>
            </h4>
          </>
        )}

        {!isDietCostAvailable && (
          <div>
            <p style={{ marginTop: '15px' }}>
              {t(
                'orders.summaryShow',
                '$$Aby zobaczyć podsumowanie, wypełnij wszystkie wymagane pola'
              )}
            </p>
          </div>
        )}
      </div>
    </BoxSection>
  );
};

export default DietSummarySection;
