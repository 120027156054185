import React from 'react';
import Card from './Card';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles({
  title: { marginLeft: '15px', marginBottom: 0 },
});

const CardWrapper = ({ children, title }) => {
  const classes = useStyles();
  return (
    <Card>
      {title && <h3 className={classes.title}>{title}</h3>}
      {children}
    </Card>
  );
};

export default CardWrapper;
