import React from 'react';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import BaseBrandReport from '../BaseBrandReport';

class DepositAndRefunds extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('reports.accountant.depositAndRefunds');

    return (
      <BaseBrandReport
        title={title}
        excelUrl="/reports/accountant/deposit-and-refunds"
        mimeType="application/vnd.ms-excel"
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_ACTIVE_DIET}
        pdfAvaliable={false}
        excelAvaliable={true}
        dateType="range"
        archiveReportNames={['ACCOUNTANT_DEPOSIT_AND_REFUNDS']}
        useZoneCategories={false}
      />
    );
  }
}

export default withTranslation()(DepositAndRefunds);
