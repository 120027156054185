import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import DataGrid from 'components/DataGrid/DataGrid';
import translateColumnConfig from './columnConfig';
import { isEmpty, isGranted } from 'helpers/helpers';
import {
  ROLE_CREATE_IMPORTER_TASK,
  ROLE_SHOW_IMPORTER_TASK,
  ROLE_CREATE_USER,
  ROLE_IMPERSONATE_USER,
  ROLE_IMPORT_USER,
  ROLE_REMOVE_USER,
} from 'helpers/roles';
import Button from 'components/CustomButtons/Button';
import InfoModal from 'components/InfoModal/InfoModal';
import MediaUpload from 'components/MediaUpload/MediaUpload';
import FakeUserIcon from 'components/FakeUserIcon/FakeUserIcon';
import importCSVExample from './import-csv-example.csv';
import importCSVExampleFI from './import-csv-example-fi.csv';
import importCSVExampleEN from './import-csv-example-en.csv';
import moneyboxCSVExample from './import-points-example.xlsx';
import moneyboxCSVExampleEN from './import-points-example-en.xlsx';
import moneyboxCSVExampleFI from './import-points-example-fi.xlsx';
import gastro from 'helpers/gastro';
import { get, post } from 'helpers/apiHelpers';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { fetchBrandsList } from 'actions/Brands';
import { fetchSexes } from 'actions/Dictionary';
import { fetchUsers } from 'actions/Users';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';
import { Dialog } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import { remove } from 'helpers/apiHelpers';
import moment from 'moment';
import axios from 'helpers/gastro';

class List extends Component {
  state = {
    openedModal: false,
    file: null,
    isImporting: false,
    importButtonClicked: false,
    showMoneyboxModal: false,
    moneyboxFile: null,
    deleteAllCheckedClients: false,
    checkedRowIds: [],
    isConfirmModalOpened: false,
    refreshList: false,
    dishesWithIngredient: [],
    clientTags: [],
    exporting: false,
  };

  componentDidMount() {
    this.props.fetchBrandsList();
    this.props.fetchSexes();
    get('/sms-client-tags').then(res =>
      this.setState({ clientTags: res['hydra:member'] })
    );
  }

  refreshList = () => {
    this.setState(prevState => ({
      ...prevState,
      refreshList: !prevState.refreshList,
    }));
  };

  massDeleteHandleMultipleCheckboxes = array => {
    this.setState(prevState => ({ ...prevState, checkedRowIds: array }));
  };

  massDeleteHandleSingleCheckbox = id => {
    const checkedIdsUpdated = [...this.state.checkedRowIds];
    const checkedIdIndex = checkedIdsUpdated.indexOf(id);
    if (checkedIdIndex >= 0) {
      checkedIdsUpdated.splice(checkedIdIndex, 1);
    } else {
      checkedIdsUpdated.push(id);
    }
    this.setState(prevState => ({
      ...prevState,
      checkedRowIds: checkedIdsUpdated,
    }));
  };

  massDeleteSubmit = () => {
    this.setState(prevState => ({
      ...prevState,
      isConfirmModalOpened: false,
      isDeleting: false,
    }));

    let url = `/clients?`;
    this.state.checkedRowIds.forEach((id, index) => {
      if (index === 0) {
        url = url.concat(`id[]=${id}`);
      } else {
        url = url.concat(`&id[]=${id}`);
      }
    });
    url = url.concat(`&removeAllAction=true`);
    remove(url)
      .then(res => {
        if (this.state.deleteAllCheckedClients) {
          this.props.openToast({
            messages: [
              this.props.t(
                'dictionary.massdelete.success',
                'Wybrane elementy zostały usunięte'
              ),
            ],
            type: 'success',
            autoHideDuration: 5000,
          });
        } else {
          this.props.openToast({
            messages: [
              this.props.t(
                'clients.massdelete.warning',
                'Z pośród wybranych klientów, zostali usunięci tylko Ci, którzy nie mieli aktywnej diety'
              ),
            ],
            type: 'warning',
            autoHideDuration: 5000,
          });
        }

        this.setState(prevState => ({
          ...prevState,
          deleteAllCheckedClients: false,
          checkedRowIds: [],
        }));
        this.refreshList();
      })
      .catch(err => {
        this.props.openToast({
          messages: [
            this.props.t(
              'dictionary.massdelete.error',
              'Usunięcie wybranych elementów nie powiodło się'
            ),
          ],
          type: 'error',
          autoHideDuration: 5000,
        });
      });
  };

  newSize = () => this.props.history.push(`${window.location.pathname}/add`);

  toggleModal = () => {
    this.setState(prevState => ({
      openedModal: !prevState.openedModal,
    }));
  };

  toggleMoneyboxModal = () => {
    this.setState(prevState => ({
      showMoneyboxModal: !prevState.showMoneyboxModal,
    }));
  };

  importClients = () => {
    this.setState({ importButtonClicked: true });
    this.uploadFile(this.state.file).then(file => {
      this.postImporterTask(file);
    });
  };

  importMoneyboxState = () => {
    this.setState({ importButtonClicked: true });
    this.uploadFile(this.state.moneyboxFile).then(file => {
      this.postMoneyboxImportTask(file);
    });
  };

  postMoneyboxImportTask = file => {
    this.setState({ isImporting: true });

    const data = {
      type: 'MONEY_BOX_CHANGE',
      file: file['@id'],
      config: {
        skipHeader: true,
        delimiter: ';',
      },
    };

    post('/importer-tasks', data).then(
      res => {
        this.setState({
          showMoneyboxModal: false,
          moneyboxFile: null,
          importButtonClicked: false,
        });
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [
            this.props.t('clients.moneyboxImportAdded'),
            this.props.t('clients.refreshPage'),
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      },
      error => {
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [this.props.t('clients.moneyboxCannotImport')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  postImporterTask = file => {
    this.setState({ isImporting: true });
    const data = {
      type: 'CLIENTS',
      file: file['@id'],
      config: {
        skipHeader: true,
        delimiter: ';',
      },
    };

    post('/importer-tasks', data).then(
      res => {
        this.setState({
          openedModal: false,
          file: null,
          importButtonClicked: false,
        });
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [
            this.props.t('clients.importAdded'),
            this.props.t('clients.refreshPage'),
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      },
      error => {
        this.setState({ isImporting: false });
        return this.props.openToast({
          messages: [this.props.t('clients.cannotImport')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  uploadFile = async file => {
    let data = new FormData();
    data.append('file', file);

    let response = await gastro.post('/media', data);

    return new Promise((resolve, reject) => {
      resolve(response.data);
    });
  };

  getExampleImportFile(language) {
    switch (language) {
      case 'fi':
        return importCSVExampleFI;
      case 'en':
        return importCSVExampleEN;
      default:
        return importCSVExample;
    }
  }

  getExampleMoneyboxImportFile(language) {
    switch (language) {
      case 'fi':
        return moneyboxCSVExampleFI;
      case 'en':
        return moneyboxCSVExampleEN;
      default:
        return moneyboxCSVExample;
    }
  }

  showInfo = () => {
    const activeUser = this.props.users.filter(
      user => user.hasActiveDiets === true
    );

    if (activeUser.length > 0) {
      const userId = activeUser.map(el => {
        return `${el.id}, `;
      });

      return (
        <p>
          <b>Użytkownicy o id: </b> {userId} mają aktywną dietę i{' '}
          <b>nie zostaną usunięci.</b>
        </p>
      );
    }

    if (activeUser.length === 0 && !this.state.deleteAllCheckedClients) {
      this.setState(prevState => ({
        ...prevState,
        deleteAllCheckedClients: true,
      }));
    }
    return;
  };

  exportClientsToGoogleAds = () => {
    const fileName = 'client-google-ads';
    const endpoint = '/client-google-ads-export';
    const format = 'csv';
    const date = moment().format('DD-MM-YYYY_HH-mm');
    const exportedName = `${fileName}_${date}`;

    const params = {
      'order[id]': 'DESC',
      properties: [
        'email',
        'phone',
        'firstName',
        'lastName',
        'Country',
        'postCode',
      ],
    };

    axios
      .get(`${endpoint}.${format}`, {
        responseType: 'blob',
        params: params,
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${exportedName}.${format}`);
          document.body.appendChild(link);
          link.click();
          this.setState({ exporting: false });
        },
        error => {
          this.setState({ exporting: false });
          return this.props.openToast({
            messages: [
              this.props.t(
                'errors.dataExportFailed',
                'Nie udało się wyeksportować danych'
              ),
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
      );
  };

  render() {
    const {
      classes,
      t,
      i18n: { language },
    } = this.props;

    const customActions = () => {
      return [
        {
          component: ({ row }) => {
            if (!isGranted(ROLE_IMPERSONATE_USER) || isEmpty(row.homeDomain)) {
              return null;
            }

            return <FakeUserIcon email={row.email} domain={row.homeDomain} />;
          },
          type: 'customComponent',
        },
      ];
    };
    return (
      <AdminTable title={t('clients.clientsList', 'Lista klientów')}>
        <InfoModal
          modalState={this.state.openedModal}
          handleClose={() => this.toggleModal}
          modalContent={
            <div>
              <h1>{t('clients.clientsImport')}</h1>
              <p style={{ textAlign: 'left' }}>
                <ul
                  dangerouslySetInnerHTML={{
                    __html: t('clients.importerModalInfo', {
                      importCSVExample: this.getExampleImportFile(language),
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
              </p>
              <MediaUpload
                file={this.state}
                setFile={file => this.setState({ file: file })}
                buttonText={t('clients.selectCSVFile')}
                addButtonProps={{
                  color: 'info',
                  round: false,
                }}
                changeButtonProps={{
                  color: 'info',
                  round: false,
                }}
                removeButtonProps={{
                  color: 'danger',
                  round: false,
                }}
              />
              <div>
                {isGranted(ROLE_CREATE_IMPORTER_TASK) &&
                  isGranted(ROLE_IMPORT_USER) && (
                    <Button
                      color={this.state.file ? 'success' : 'default'}
                      disabled={
                        !this.state.file ||
                        this.state.isImporting ||
                        this.state.importButtonClicked
                      }
                      onClick={this.importClients}
                    >
                      {t('clients.import')}
                    </Button>
                  )}
                <Button onClick={this.toggleModal}>{t('clients.close')}</Button>
              </div>
            </div>
          }
          closeButtonText={t('clients.close')}
          hideDefaultCloseButton
        />

        <InfoModal
          modalState={this.state.showMoneyboxModal}
          handleClose={() => this.toggleMoneyboxModal}
          modalContent={
            <div>
              <h1>{t('clients.moneyboxModalHeader')}</h1>
              <p style={{ textAlign: 'left' }}>
                <ol
                  dangerouslySetInnerHTML={{
                    __html: t('clients.moneyboxModal', {
                      moneyboxCSVExample:
                        this.getExampleMoneyboxImportFile(language),
                      interpolation: { escapeValue: false },
                    }),
                  }}
                />
              </p>
              <MediaUpload
                file={this.state}
                setFile={file => this.setState({ moneyboxFile: file })}
                buttonText={t('clients.selectCSVFile')}
                addButtonProps={{
                  color: 'info',
                  round: false,
                }}
                changeButtonProps={{
                  color: 'info',
                  round: false,
                }}
                removeButtonProps={{
                  color: 'danger',
                  round: false,
                }}
              />
              <div>
                {isGranted(ROLE_CREATE_IMPORTER_TASK) &&
                  isGranted(ROLE_IMPORT_USER) && (
                    <Button
                      color={this.state.moneyboxFile ? 'success' : 'default'}
                      disabled={
                        !this.state.moneyboxFile ||
                        this.state.isImporting ||
                        this.state.importButtonClicked
                      }
                      onClick={this.importMoneyboxState}
                    >
                      {t('clients.import')}
                    </Button>
                  )}
                <Button onClick={this.toggleMoneyboxModal}>
                  {t('clients.close')}
                </Button>
              </div>
            </div>
          }
          closeButtonText={t('clients.close')}
          hideDefaultCloseButton
        />

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          {isGranted(ROLE_SHOW_IMPORTER_TASK) &&
            isGranted(ROLE_IMPORT_USER) && (
              <Button
                style={{ margin: '5px', width: '158px' }}
                color="info"
                onClick={this.toggleModal}
              >
                {t('clients.importClients')}
              </Button>
            )}
          {isGranted(ROLE_SHOW_IMPORTER_TASK) &&
            isGranted(ROLE_IMPORT_USER) && (
              <Button
                style={{ margin: '5px', width: '300px' }}
                color="info"
                onClick={this.toggleMoneyboxModal}
              >
                {t('clients.moneyboxModalHeader')}
              </Button>
            )}
          {isGranted(ROLE_REMOVE_USER) && (
            <Button
              data-cy="__remove_dictionary_button"
              color="danger"
              onClick={() => {
                this.props.fetchUsers({
                  filtered: [{ id: 'id', value: this.state.checkedRowIds }],
                });

                this.setState({
                  isConfirmModalOpened: true,
                });
              }}
              disabled={this.state?.checkedRowIds?.length === 0}
            >
              {t(
                'dictionary.deleteDictionaryElement',
                'Usuń zaznaczone elementy'
              )}
            </Button>
          )}
          {isGranted(ROLE_REMOVE_USER) && (
            <Button
              data-cy="__remove_dictionary_button"
              color="info"
              onClick={() => {
                this.exportClientsToGoogleAds();
              }}
            >
              {this.props.t(
                'clients.export.googleAds',
                'Eksport do Google Ads'
              )}
            </Button>
          )}
          {isGranted(ROLE_CREATE_USER) && (
            <FormControlButtons
              classes={classes}
              handleSubmit={this.newSize}
              submitText={this.props.t('clients.addClient') + ' +'}
            />
          )}
        </div>
        <DataGrid
          refresh={this.state.refreshList}
          massDelete={true}
          massDeleteItemsIds={this.state.checkedRowIds}
          massDeleteHandleSingleCheckbox={this.massDeleteHandleSingleCheckbox}
          massDeleteHandleMultipleCheckboxes={
            this.massDeleteHandleMultipleCheckboxes
          }
          refreshOnFetch
          actions={true}
          editPath={`${window.location.pathname}/edit`}
          export={true}
          paginationTop={true}
          paginationBottom={false}
          url="/clients"
          reportName={'client'}
          columns={translateColumnConfig(
            t,
            this.props.sexes,
            this.state.clientTags
          )}
          role="USER"
          customActions={customActions}
          customActionsWidth={150}
          customActionsColumnDirection={'actions-left'}
          canRemoveChecker={(canRemove, row) => {
            return canRemove && !row.hasActiveDiets;
          }}
          manipulateQuery={(requestData, query) => {
            if (query.smsClientTags) {
              if (query['smsClientTags'] !== 'noTags') {
                query['smsClientTags.value'] = query.smsClientTags;
                query['smsClientTags'] = null;
              }
              if (query['smsClientTags'] === 'noTags') {
                query['exists[smsClientTags]'] = false;
                query['smsClientTags'] = null;
              }
            }

            if (query.gender) {
              query['gender[]'] = query.gender;
              query['gender[_operator]'] = 'eq';
            }
            if (query.createdAt) {
              const filters = query.createdAt;
              delete query.createdAt;
              if (filters.after) {
                query['createdAt[after]'] = filters.after;
              }
              if (filters.before) {
                query['createdAt[before]'] = filters.before;
              }
            }

            if (query.firstName) {
              const splicedName = query.firstName.split(' ');
              let _andX = [
                [
                  {
                    firstName: splicedName[0],
                    lastName: splicedName[0],
                  },
                ],
              ];

              if (splicedName.length > 1) {
                _andX[0][0].lastName = splicedName[1];
              }

              const operator = splicedName.length > 1 ? '_andX' : '_orX';

              delete query.firstName;
              query[operator] = _andX;
            }

            query.type = 'CLIENT';

            return query;
          }}
        />

        <Dialog fullWidth={true} open={this.state.isConfirmModalOpened}>
          <SweetAlert
            warning
            style={{ display: 'block' }}
            title={t('clients.areYouSure')}
            onConfirm={this.massDeleteSubmit}
            onCancel={() =>
              this.setState(prevState => ({
                ...prevState,
                isConfirmModalOpened: !prevState.isConfirmModalOpened,
              }))
            }
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText={t('common.shared.yes')}
            cancelBtnText={t('common.shared.no')}
            showCancel
          >
            {this.state.isConfirmModalOpened ? this.showInfo() : null}
          </SweetAlert>
        </Dialog>
      </AdminTable>
    );
  }
}

const mapStateToProps = state => {
  return {
    brands: state.Brands.brandsList,
    auth: state.Auth,
    sexes: state.Dictionary.sexes,
    users: state.Users.users,
  };
};

const mapDispatchToProps = {
  fetchBrandsList,
  fetchSexes,
  fetchUsers,
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withStyles(buttonsStyle),
  withTranslation()
);

export default enhance(List);
