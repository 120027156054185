import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import ItemTypes from './ItemTypes';
import TableCell from '@material-ui/core/TableCell';
import Close from '@material-ui/icons/Close';
import CustomInput from 'components/CustomInput/CustomInput';
import { Table, TableRow, TableBody } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import withStyles from '@material-ui/core/styles/withStyles';

const style = {
  cursor: 'move',
};
const Row = ({
  index,
  id,
  calorie,
  classes,
  variant,
  active,
  getSizeName,
  getRowSum,
  openModal,
  moveCard,
  handleVariantName,
  handleVariantWorkName,
  toggleCalorificActivness,
  removeRow,
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();
  const [, drop] = useDrop({
    accept: ItemTypes.ROW,
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.ROW, id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div ref={ref} style={{ ...style, opacity }}>
      <Table style={{ tableLayout: 'fixed' }}>
        <TableBody>
          <TableRow>
            <TableCell align={'center'} padding={'none'}>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  type: 'text',
                  placeholder: t(
                    'components.formSelectDropDown.nameEnter',
                    'Wpisz nazwę'
                  ),
                  value: calorie.name,
                  onChange: e => handleVariantName(calorie, variant, e),
                }}
              />
            </TableCell>
            <TableCell align={'center'} padding={'none'}>
              <CustomInput
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  type: 'text',
                  placeholder: t('form.field.workingName'),
                  value: calorie.workName,
                  onChange: e => handleVariantWorkName(calorie, variant, e),
                }}
              />
            </TableCell>
            {variant.mealTypes.map((mealType, cellIndex) => {
              return (
                <TableCell key={cellIndex} align={'center'} padding={'none'}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span
                      onClick={() =>
                        openModal(mealType.sizes, index, mealType['@id'])
                      }
                    >
                      {getSizeName(index, mealType['@id'])}
                    </span>
                  </div>
                </TableCell>
              );
            })}
            <TableCell>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Checkbox
                  onClick={() => toggleCalorificActivness(calorie, variant)}
                  checked={active}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  {getRowSum(calorie)}
                </div>
                <button
                  className={'variant-removeRow'}
                  onClick={() => removeRow(variant, index)}
                >
                  <Close style={{ color: 'red', cursor: 'pointer' }} />
                </button>
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(Row);
