import types from 'helpers/constants';

export const setMainColor = colorName => {
  return dispatch => {
    dispatch({
      type: types.THEME_SET_MAIN_COLOR,
      payload: { mainColor: colorName },
    });
  };
};
