import React from 'react';
import AdminTable from 'layouts/AdminTable';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import GridContainer from '../../../components/Grid/GridContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import GridItem from '../../../components/Grid/GridItem';
import PlannerCard from './PlannerCard';
import PlannerSummary from './PlannerSummary';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import { withTranslation } from 'react-i18next';

const FIRST_COLUMN_WIDTH = 100;

const DishTell = withStyles({
  root: {
    padding: '8px',
  },
})(TableCell);

class Variant extends React.Component {
  state = {
    calorific: '',
    cacheKey: '',
  };

  getMealTypeProperty = (id, collection, property) => {
    let element = collection.find(el => {
      return el['@id'] === id;
    });

    if (element) {
      return element[property];
    }

    return '';
  };

  findViewMenuPlanners = () => {
    return this.props.menuPlanners.filter(menuPlanner => {
      if (this.props.viewMode === 'DRAFT') {
        if (this.props.showPublishedPlanning) {
          return true;
        } else {
          return menuPlanner.draft === true;
        }
      } else {
        return menuPlanner.draft === false;
      }
    });
  };

  findBlockMenuPlanners = (mealType, diet) => {
    return this.findViewMenuPlanners()
      .filter(
        menuPlanner =>
          menuPlanner.mealType === mealType && menuPlanner.diet['@id'] === diet
      )
      .sort((x, y) => (x.draft === y.draft ? 0 : x.draft ? -1 : 1));
  };

  findDietMenuPlanners = diet => {
    return this.findViewMenuPlanners().filter(
      menuPlanner => menuPlanner.diet['@id'] === diet
    );
  };

  createPlannerKey = planner =>
    `${planner.variant}${planner.mealType}${planner.diet['@id']}`;

  removeOverriddenMenu = planners => {
    const draftKeys = planners
      .filter(planner => planner.draft)
      .map(planner => this.createPlannerKey(planner));

    return planners.filter(planner => {
      return (
        planner.draft || !draftKeys.includes(this.createPlannerKey(planner))
      );
    });
  };

  componentDidMount() {
    this.setFirstCalorific();
  }

  componentDidUpdate() {
    this.setFirstCalorific();
  }

  setFirstCalorific() {
    let { variant } = this.props;

    const cacheKey = JSON.stringify(variant.calories[0]);

    if (this.state.cacheKey === cacheKey) {
      return;
    }

    this.setState(() => {
      return {
        calorific: variant.calories[0],
        cacheKey,
      };
    });
  }

  handleSelectChange = (event, value) => {
    this.setState(() => {
      return {
        calorific: value,
      };
    });
  };

  onAssign = (mealType, diet) => {
    this.props.onAssignMenu({
      mealType: mealType,
      variant: this.props.variant['@id'],
      diet: diet['@id'],
      dish: this.props.dish,
      reportCode: diet.code,
    });
  };

  render() {
    const { t, classes, variant, mealTypes, diets } = this.props;

    let fillHeaders = [];
    let i = diets.length - 3;
    while (i-- > 0) {
      fillHeaders.push(i);
    }

    return (
      <AdminTable
        title={
          <>
            {variant.name}{' '}
            {!variant.active && (
              <span style={{ color: '#bebebe' }}>
                {t('menuPlanner.variantInactive')}
              </span>
            )}
          </>
        }
        icon
        iconComponent={variant.shortName}
        iconColor={variant.active ? 'primary' : 'danger'}
      >
        <GridContainer>
          <GridItem xs={12}>
            <GridContainer justify={'flex-end'} style={{ marginTop: -50 }}>
              <GridItem xs={2}>
                <FormSelectSingle
                  classes={classes}
                  name="calorificLabel"
                  options={variant.calories}
                  mapBy="name"
                  trackBy="@id"
                  customLabel={calorific =>
                    `${calorific?.name} ${
                      calorific?.active
                        ? t('menuPlanner.calorific.active')
                        : t('menuPlanner.calorific.inactive')
                    }`
                  }
                  value={this.state.calorific}
                  handleChange={this.handleSelectChange}
                  label={t('menuPlanner.statisticsFor')}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12}>
            <div
              className="container"
              style={{ overflowX: 'scroll', display: 'block' }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <DishTell
                      padding="dense"
                      style={{ borderBottom: 0, width: FIRST_COLUMN_WIDTH }}
                    />
                    {diets.map((diet, index) => (
                      <DishTell
                        key={index}
                        align="center"
                        padding="dense"
                        style={{ borderBottom: 0 }}
                      >
                        {diet.name} ({diet.code})
                        {!diet.active && (
                          <>
                            <br />{' '}
                            <span style={{ color: '#bebebe' }}>
                              {t('menuPlanner.variantInactive')}
                            </span>
                          </>
                        )}
                      </DishTell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {variant.mealTypes.map((mealType, index) => (
                    <TableRow key={index}>
                      <DishTell
                        component="th"
                        padding="dense"
                        style={{ width: FIRST_COLUMN_WIDTH }}
                      >
                        {this.getMealTypeProperty(mealType, mealTypes, 'name')}
                      </DishTell>
                      {diets.map((diet, index) => (
                        <DishTell key={index} padding="dense">
                          <PlannerCard
                            menuPlanners={this.findBlockMenuPlanners(
                              mealType,
                              diet['@id']
                            )}
                            statisticsFor={
                              this.state.calorific === null
                                ? null
                                : this.state.calorific.id +
                                  '_' +
                                  this.getMealTypeProperty(
                                    mealType,
                                    mealTypes,
                                    'id'
                                  )
                            }
                            canAssign={
                              !this.props.isAssigning &&
                              this.props.dish &&
                              this.props.canAssignTo.includes(mealType)
                            }
                            onAssign={() => this.onAssign(mealType, diet)}
                            onRemove={this.props.onRemove}
                            onMenuPlannerUpdated={
                              this.props.onMenuPlannerUpdated
                            }
                            viewMode={this.props.viewMode}
                            showDishStats={this.props.showDishStats}
                          />
                        </DishTell>
                      ))}
                    </TableRow>
                  ))}
                  <TableRow>
                    <DishTell
                      component="th"
                      padding="dense"
                      style={{ width: FIRST_COLUMN_WIDTH }}
                    >
                      {t('menuPlanner.sum')}
                    </DishTell>
                    {diets.map((diet, index) => (
                      <PlannerSummary
                        key={index}
                        calorific={this.state.calorific.id}
                        menuPlanners={this.removeOverriddenMenu(
                          this.findDietMenuPlanners(diet['@id'])
                        )}
                        assumptionOfCarbohydrates={
                          diet.assumptionOfCarbohydrates
                        }
                        assumptionOfFats={diet.assumptionOfFats}
                        assumptionOfProtein={diet.assumptionOfProtein}
                        dietName={diet.name}
                      />
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </GridItem>
        </GridContainer>
      </AdminTable>
    );
  }
}

const combinedStyles = combineStyles(
  buttonsStyle,
  extendedFormsStyle,
  sweetAlertStyle
);

export default withTranslation()(
  withStyles(combinedStyles)(withToast(Variant))
);
