import React, { Component } from 'react';
import { store } from 'store';
import { compose } from 'recompose';
import { connect } from 'react-redux';

import { get, put } from 'helpers/apiHelpers';

import { ROLE_SHOW_BRAND, ROLE_EDIT_SHOW_MENU_PAGE } from 'helpers/roles';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { withTranslation } from 'react-i18next';
import { withToast } from 'material-ui-toast-redux';
import { cloneDeep } from 'lodash';

const translateSelectOptions = t => [
  {
    label: t('module.MenuPage.off'),
    value: 'DISABLED',
  },
  {
    label: t('module.MenuPage.weekView'),
    value: 'WEEK_VIEW',
  },
  {
    label: t('module.MenuPage.dayView'),
    value: 'DAY_VIEW',
  },
  {
    label: t('module.MenuPage.defaultDiet'),
    value: 'DEFAULT_DIET',
  },
];

const DietDelivery = WrappedComponent =>
  class extends Component {
    state = {
      resources: {},
      activeDiets: [],
      activeVariants: [],
      defaultVariant: null,
      defaultDiet: null,
      pageDescription: '',
      modules: {},
    };

    async componentDidMount() {
      const { selectedBrand } = this.props;
      const [variants, diets, modules] = await Promise.all([
        get(`variants`),
        get(`diets`),
        get(
          `brands/${selectedBrand}/modules?module[]=MenuPage&module[]=ConfigClientPanel&module[]=SeoCmsMenuSubpage`
        ),
      ]);

      const activeVariants = variants['hydra:member'].filter(
        ({ active }) => active
      );
      const activeDiets = diets['hydra:member'].filter(({ active }) => active);

      const {
        '@resources': resources,
        ...menuPageConfigs
      } = modules.configuration.MenuPage;

      this.setState({
        resources,
        activeDiets,
        activeVariants,
        modules,
        ...menuPageConfigs,
      });
    }

    handleInputChange = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    handleDietChange = event => {
      this.setState({ defaultDiet: event.target.value, defaultVariant: null });
    };

    handleToggle = event => {
      this.setState({
        [event.target.name]: !this.state[event.target.name],
      });
    };

    handleFilterShownMenuByDietChange = () => {
      const modulesCopy = cloneDeep(this.state.modules);

      modulesCopy.configuration.ConfigClientPanel.filterShownMenuByDiet = !this
        .state.modules.configuration.ConfigClientPanel?.filterShownMenuByDiet;

      this.setState({
        modules: modulesCopy,
      });
    };

    getImage = (stateName, data) => {
      this.setState(prevState => ({
        resources: {
          ...prevState.resources,
          [data['@id']]: data,
        },
        [stateName]: data?.['@id'] || null,
      }));
    };

    removeImage = stateName => {
      this.setState({
        [stateName]: null,
      });
    };

    setModules = setState => {
      this.setState(prevState => ({
        modules: setState(prevState.modules),
      }));
    };

    handleSubmit = () => {
      const { selectedBrand, openToast, t } = this.props;

      const {
        showMenuButton,
        bannerMobile,
        bannerDesktop,
        bannerInfoEnabled,
        bannerTitle,
        pageDescription,
        menuButtonLink,
        bannerDescription,
        mode,
        defaultDiet,
        defaultVariant,
        showDishIngredients,
        autoScrollToVariant,
      } = this.state;

      put(`brands/${selectedBrand}/modules`, {
        configuration: {
          MenuPage: {
            mode,
            allowChangeDiet: false,
            allowChangeVariant: false,
            allowVariantAutoScroll: false,
            allowDietScroll: false,
            pageDescription,
            bannerInfoEnabled,
            bannerTitle,
            bannerDescription,
            bannerMobile,
            bannerDesktop,
            showMenuButton,
            menuButtonLink,
            defaultDiet,
            defaultVariant,
            showDishIngredients,
            autoScrollToVariant,
          },
          ConfigClientPanel: this.state.modules.configuration.ConfigClientPanel,
          SeoCmsMenuSubpage: this.state.modules.configuration.SeoCmsMenuSubpage,
        },
      }).then(response => {
        openToast({
          messages: [
            t('status.success.save', 'Zmiany zostały pomyślnie zapisane.'),
          ],
          type: 'success',
          autoHideDuration: 3000,
        });
      });
    };

    render() {
      const { t } = this.props;
      const roles = store.getState().Auth.user.roles;
      const allowedToModify = roles.includes(ROLE_EDIT_SHOW_MENU_PAGE);
      const allowToModifyFiltering = roles.includes(ROLE_SHOW_BRAND);

      return (
        <WrappedComponent
          {...this.state}
          saveAll={this.saveAll}
          classes={this.props.classes}
          getImage={this.getImage}
          removeImage={this.removeImage}
          handleToggle={this.handleToggle}
          handleSubmit={this.handleSubmit}
          allowedToModify={allowedToModify}
          handleInputChange={this.handleInputChange}
          allowToModifyFiltering={allowToModifyFiltering}
          filterShownMenuByDiet={
            this.state.modules.configuration?.ConfigClientPanel
              ?.filterShownMenuByDiet
          }
          handleFilterShownMenuByDietChange={
            this.handleFilterShownMenuByDietChange
          }
          bannerMobile={this.state.resources[this.state.bannerMobile]}
          bannerDesktop={this.state.resources[this.state.bannerDesktop]}
          selectOptions={translateSelectOptions(t)}
          handleDietChange={this.handleDietChange}
          mainDomain={this.props.mainDomain}
          modules={this.state.modules}
          setModules={this.setModules}
        />
      );
    }
  };

export default compose(
  withTranslation(),
  withStyles(extendedFormsStyle),
  withToast,
  connect(
    ({
      Auth: { selectedBrand },
      Diets: { diets },
      Brands: {
        brand: { mainDomain },
      },
    }) => ({
      selectedBrand,
      diets,
      mainDomain,
    })
  ),
  DietDelivery
);
