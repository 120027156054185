import { compose } from 'recompose';
import { withToast } from 'material-ui-toast-redux';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import moment from 'moment';
import isEmpty from 'lodash/isEmpty';

import { get, post } from 'helpers/apiHelpers';

const MenuCopyingContainer = Component => {
  const WrappedComponent = ({ openToast }) => {
    const { t } = useTranslation();

    const [targets, setTargets] = useState({});
    const [results, setResults] = useState([]);
    const [isCopying, setIsCopying] = useState(false);
    const [copyFromPublished, setCopyFromPublished] = useState(false);
    const [publishMenuAfterCopy, setPublishMenuAfterCopy] = useState(false);
    const [sourceBrand, setSourceBrand] = useState({});
    const [dateRangeTo, setDateRangeTo] = useState(new Date());
    const [targetBrands, setTargetBrands] = useState([]);
    const [brandsOptions, setBrandsOptions] = useState([]);
    const [dateRangeFrom, setDateRangeFrom] = useState(new Date());
    const [sourceDietOptions, setSourceDietOptions] = useState([]);
    const [sourceVariantOptions, setSourceVariantOptions] = useState([]);
    const [isResultDialogOpened, setIsResultDialogOpened] = useState(false);
    const [sourceMealTypeOptions, setSourceMealTypeOptions] = useState([]);

    const { brands, selectedBrand } = useSelector(
      ({
        Auth: {
          user: { brands },
          selectedBrand,
        },
      }) => {
        return { brands, selectedBrand };
      },
      shallowEqual
    );

    useEffect(() => {
      Promise.all([
        get('/meal-types', {
          pagination: false,
        }),
        get('/diets', {
          pagination: false,
        }),
        get('/variants', {
          pagination: false,
        }),
      ]).then(res => {
        const { 'hydra:member': sourceMealTypes } = res[0];
        const { 'hydra:member': sourceDiets } = res[1];
        const { 'hydra:member': sourceVariants } = res[2];

        setSourceDietOptions(sourceDiets);
        setSourceVariantOptions(sourceVariants);
        setSourceMealTypeOptions(sourceMealTypes);
        setBrandsOptions(
          brands.map(brand => ({ ...brand, value: brand?.name }))
        );
        setSourceBrand(brands.find(({ id }) => id === selectedBrand));
      });
    }, []);

    // When selecting brands it updates object format of targets (out of which is prepared payload on submit)
    useEffect(() => {
      let newTargets = {};

      (targetBrands ?? []).map(targetBrand => {
        const targetBrandSettings = targets?.[targetBrand?.['@id']];
        newTargets = {
          ...newTargets,
          [targetBrand?.['@id']]: {
            ...targetBrandSettings,
          },
        };
        return null;
      });

      setTargets(newTargets);
    }, [targetBrands]);

    const handleSubmit = () => {
      let targetsPayload = [];

      for (const [key, value] of Object.entries(targets)) {
        const { dietMappings, mealTypeMapping } = value;
        if (!isEmpty(dietMappings) || !isEmpty(mealTypeMapping)) {
          let dietMappingsPayload = [];
          let mealTypeMappingPayload = [];

          if (!isEmpty(dietMappings)) {
            for (const [key, value] of Object.entries(dietMappings)) {
              const { variantMappings } = value;
              const variantMappingsPayload = [];

              if (!isEmpty(variantMappings)) {
                for (const [key, value] of Object.entries(variantMappings)) {
                  variantMappingsPayload.push({
                    source: key,
                    target: value?.['@id'],
                  });
                }
              }

              let newDietMapping = {
                source: key,
                target: value?.targetDiet?.['@id'],
              };

              if (!isEmpty(variantMappingsPayload)) {
                newDietMapping = {
                  ...newDietMapping,
                  variantMappings: variantMappingsPayload,
                };
              }

              dietMappingsPayload.push(newDietMapping);
            }
          }

          if (!isEmpty(mealTypeMapping)) {
            for (const [key, value] of Object.entries(mealTypeMapping)) {
              mealTypeMappingPayload.push({
                source: key,
                target: value?.['@id'],
              });
            }
          }

          targetsPayload.push({
            target: key,
            dietMappings: dietMappingsPayload,
            mealTypeMapping: mealTypeMappingPayload,
          });
        }
      }

      if (!validateTargetsPayload(targetsPayload)) {
        openToast({
          messages: [
            t(
              '$*form.menuPlanning.copyingFailed',
              '$$Aby skopiować menu do danej marki, zmapowany musi być co najmniej jeden typ posiłku, co najmniej jedna dieta a dla każdej zmapowanej diety co najmniej jeden wariant'
            ),
          ],
          type: 'warning',
          autoHideDuration: 10000,
        });
        return;
      }

      setIsResultDialogOpened(true);
      setIsCopying(true);

      post(
        '/menu-planners/copy',
        {
          source: sourceBrand?.['@id'],
          dateRangeFrom: new moment(dateRangeFrom).format('YYYY-MM-DD'),
          dateRangeTo: new moment(dateRangeTo).format('YYYY-MM-DD'),
          copyFromPublished: copyFromPublished,
          publishMenuAfterCopy: publishMenuAfterCopy,
          targets: targetsPayload,
        },
        {
          params: {
            selectInBrands: [
              sourceBrand?.['@id'],
              ...targetBrands.map(({ '@id': iri }) => iri),
            ],
          },
        }
      )
        .then(res => {
          setIsCopying(false);
          setResults(res?.['hydra:member'] ?? []);
        })
        .catch(err => {
          setIsCopying(false);
          setIsResultDialogOpened(false);
        });
    };

    const validateTargetsPayload = targetsPayload => {
      return !targetsPayload.some(
        ({ dietMappings, mealTypeMapping }) =>
          isEmpty(dietMappings) ||
          isEmpty(mealTypeMapping) ||
          dietMappings.some(({ variantMappings }) => isEmpty(variantMappings))
      );
    };

    return (
      <Component
        targets={targets}
        results={results}
        isCopying={isCopying}
        setTargets={setTargets}
        sourceBrand={sourceBrand}
        dateRangeTo={dateRangeTo}
        targetBrands={targetBrands}
        handleSubmit={handleSubmit}
        dateRangeFrom={dateRangeFrom}
        brandsOptions={brandsOptions}
        setDateRangeTo={setDateRangeTo}
        setTargetBrands={setTargetBrands}
        setDateRangeFrom={setDateRangeFrom}
        copyFromPublished={copyFromPublished}
        sourceDietOptions={sourceDietOptions}
        publishMenuAfterCopy={publishMenuAfterCopy}
        setCopyFromPublished={setCopyFromPublished}
        sourceVariantOptions={sourceVariantOptions}
        sourceMealTypeOptions={sourceMealTypeOptions}
        isResultDialogOpened={isResultDialogOpened}
        setIsResultDialogOpened={setIsResultDialogOpened}
        setPublishMenuAfterCopy={setPublishMenuAfterCopy}
      />
    );
  };

  return WrappedComponent;
};

export default compose(withToast, MenuCopyingContainer);
