import React, { useState, useEffect, Fragment } from 'react';
import { put, get } from 'helpers/apiHelpers';
import { combineStyles, isGranted } from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import navPillsStyle from '../../../assets/jss/material-dashboard-pro-react/components/navPillsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';
import { connect } from 'react-redux';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomInput from '../../../components/CustomInput/CustomInput';
import FormSelectSingle from '../../../components/FormSelect/FormSelectSingle';
import FormControlButtons from '../../../components/FormControlButtons/FormControlButtons';
import {
  ROLE_EDIT_MODULE_SETTLEMENTS,
  ROLE_SHOW_STATISTICS_ECOMMERCE_CALCULATION_DATA,
} from '../../../helpers/roles';
import DataGrid from 'components/DataGrid/DataGrid';
import Button from 'components/CustomButtons/Button';
import CardWrapper from 'components/Card/CardWrapper';

const SettlementsSettings = ({ classes, t, selectedBrand, openToast }) => {
  const [data, setData] = useState(null);

  const selectOptions = [
    { name: t('gross'), val: 'GROSS' },
    { name: t('net'), val: 'NET' },
  ];

  useEffect(() => {
    get(`brands/${selectedBrand}/modules?module[]=Settlements`).then(
      response => {
        setData(response.configuration.Settlements);
      }
    );
  }, []);

  const handleSubmit = async () => {
    try {
      await put(`/brands/${selectedBrand}/modules`, {
        module: 'Settlements',
        configuration: {
          Settlements: {
            ...data,
            commissionOperator: parseFloat(
              data.commissionOperator.toString().replace(',', '.')
            ),
            commissionDirect: parseFloat(
              data.commissionDirect.toString().replace(',', '.')
            ),
            subscriptionFeeNet: parseFloat(
              data.subscriptionFeeNet.toString().replace(',', '.')
            ),
          },
        },
      });

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (error) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
  };
  return (
    <Fragment>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem sm={12}>
              {data && (
                <Table className={classes.table}>
                  <TableBody>
                    <TableRow>
                      <TableCell>{t('directCommission')}</TableCell>
                      <TableCell>
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          disabled={!isGranted(ROLE_EDIT_MODULE_SETTLEMENTS)}
                          inputProps={{
                            // disabled: !isGranted(ROLE_EDIT_COST),
                            type: 'text',
                            placeholder: t('common.dietPrices.pricePerDayInfo'),
                            name: 'commissionDirect',
                            value: data.commissionDirect,
                            onChange: e => {
                              let value = null;
                              const pattern = /^\d*[.,]?\d{0,2}$/;
                              if (!pattern.test(e.target.value)) {
                                value = data.commissionDirect;
                              }

                              setData(prev => ({
                                ...prev,
                                [e.target.name]: value ? value : e.target.value,
                              }));
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <FormSelectSingle
                          classes={classes}
                          disabled={!isGranted(ROLE_EDIT_MODULE_SETTLEMENTS)}
                          mapBy="name"
                          trackBy="val"
                          value={{
                            name: data.typeDirect,
                            val: data.typeDirect,
                          }}
                          options={selectOptions}
                          handleChange={e =>
                            setData(prev => ({
                              ...prev,
                              [e.target.name]: e.target.value,
                            }))
                          }
                          name="typeDirect"
                          id="typeDirect"
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('operatorCommission')}</TableCell>
                      <TableCell>
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          disabled={!isGranted(ROLE_EDIT_MODULE_SETTLEMENTS)}
                          inputProps={{
                            // disabled: !isGranted(ROLE_EDIT_COST),
                            type: 'text',
                            placeholder: t('common.dietPrices.pricePerDayInfo'),
                            name: 'commissionOperator',
                            value: data.commissionOperator,
                            onChange: e => {
                              let value = null;
                              const pattern = /^\d*[.,]?\d{0,2}$/;
                              if (!pattern.test(e.target.value)) {
                                value = data.commissionOperator;
                              }

                              setData(prev => ({
                                ...prev,
                                [e.target.name]: value ? value : e.target.value,
                              }));
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <FormSelectSingle
                          classes={classes}
                          disabled={!isGranted(ROLE_EDIT_MODULE_SETTLEMENTS)}
                          mapBy="name"
                          trackBy="val"
                          value={{
                            name: data.typeOperator,
                            val: data.typeOperator,
                          }}
                          options={selectOptions}
                          handleChange={e =>
                            setData(prev => ({
                              ...prev,
                              [e.target.name]: e.target.value,
                            }))
                          }
                          name="typeOperator"
                          id="typeOperator"
                          selectSm={8}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{t('subscriptionFeeNet')}</TableCell>
                      <TableCell>
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          disabled={!isGranted(ROLE_EDIT_MODULE_SETTLEMENTS)}
                          inputProps={{
                            type: 'text',
                            name: 'subscriptionFeeNet',
                            value: data.subscriptionFeeNet,
                            onChange: e => {
                              let value = null;
                              const pattern = /^\d*[.,]?\d{0,2}$/;
                              if (!pattern.test(e.target.value)) {
                                value = data.subscriptionFeeNet;
                              }

                              setData(prev => ({
                                ...prev,
                                [e.target.name]: value ? value : e.target.value,
                              }));
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </GridItem>
            {isGranted(ROLE_EDIT_MODULE_SETTLEMENTS) && (
              <FormControlButtons
                classes={classes}
                handleSubmit={handleSubmit}
                submitText={t('common.shared.save')}
              />
            )}
          </GridContainer>
        </CardBody>
      </Card>
      {isGranted(ROLE_SHOW_STATISTICS_ECOMMERCE_CALCULATION_DATA) && (
        <CardWrapper title={t('common.settlements')}>
          <CardBody>
            <GridContainer>
              <GridItem sm={12}>
                <Button
                  style={{
                    padding: '5px 15px',
                  }}
                  onClick={() => {
                    get('/statistics/ecommerce/calculation-data/refresh')
                      .then(() => {
                        return openToast({
                          messages: [
                            t(
                              'common.settlements.update.success',
                              'Proces aktualizacji został rozpoczęty, za kilka minut stan zostanie zaktualizowany'
                            ),
                          ],
                          type: 'success',
                          autoHideDuration: 5000,
                        });
                      })
                      .catch(err =>
                        openToast({
                          messages: [
                            t(
                              'common.settlements.update.error',
                              'Aktualizacja się nie powiodła'
                            ),
                          ],
                          type: 'error',
                          autoHideDuration: 3000,
                        })
                      );
                  }}
                >
                  {t('common.settlements.update')}
                </Button>
              </GridItem>
              <GridItem md={12}>
                <DataGrid
                  export={true}
                  reportName={'settlements'}
                  paginationTop={true}
                  paginationBottom={false}
                  url={`/statistics/ecommerce/calculation-data`}
                  manipulateQuery={(requestData, query) => {
                    query['brandId[]'] = selectedBrand;
                    return query;
                  }}
                  columns={[
                    {
                      title: t('common.settlements.month'),
                      name: 'month',
                      accessor: row => row.month,
                      width: 100,
                      filterable: true,
                      sortable: true,
                    },
                    {
                      title: t('common.settlements.amount'),
                      name: 'amount',
                      accessor: row => row.amount.toFixed(2),
                      filterable: false,
                      sortable: true,
                    },
                    {
                      title: t('common.settlements.bags'),
                      name: 'bags',
                      accessor: row => row.bags,
                      filterable: false,
                      sortable: true,
                    },
                  ]}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </CardWrapper>
      )}
    </Fragment>
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  navPillsStyle
);

const enhance = compose(
  withTranslation(),
  withStyles(combinedStyles),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  })),
  withToast
);

export default enhance(SettlementsSettings);
