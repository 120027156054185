import React, { Fragment, useEffect, useState } from 'react';
import { orderStyles } from 'views/Orders/styles';

import { isGranted } from 'helpers/helpers';
import columnConfig from './columnConfig';

import NotesEditModal from './notesEditModal';
import { useTranslation } from 'react-i18next';

import { get, post, remove } from 'helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';

import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import ModalButton from 'components/CustomButtons/Button';
import roles from 'helpers/roles';
import { Dialog, DialogContent } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import ReactTable from 'react-table';
import TableActions from 'components/DataTable/Actions';
import SweetAlert from 'react-bootstrap-sweetalert';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import { connect, useSelector } from 'react-redux';

// client
const Notes = ({ classes, openToast, urlParam, notePermission, userID }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [notesModalStatus, setNotesModalStatus] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const [editModalData, setEditModalData] = useState({});
  const [notes, setNotes] = useState([]);
  const [noteContent, setNoteContent] = useState('');
  const [selectedNote, setSelectedNote] = useState(null);
  const [isConfirmModalOpened, setIsConfirmModalOpened] = useState(false);
  const user = useSelector(state => state.Users.user);

  const { t } = useTranslation();

  const getNotes = async () => {
    get('/notes', { key: urlParam }).then(notes => {
      setNotes(notes['hydra:member']);
    });
  };

  useEffect(() => {
    getNotes();
  }, []);

  const addNote = e => {
    if (!noteContent) {
      return openToast({
        messages: [t('errors.contentRequired', 'Treść nie może być pusta')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    setIsLoading(true);

    e.preventDefault();
    post('/notes', {
      content: noteContent,
      key: urlParam,
    })
      .then(() => {
        setIsLoading(false);
        setNoteContent('');
        closeModal();
        openToast({
          messages: [t('success.noteAdded', 'Notatka została dodana')],
          type: 'success',
          autoHideDuration: 3000,
        });
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const closeModal = () => {
    getNotes().then(() => {
      setNotesModalStatus(false);
    });
  };

  const hasPermissionToModifyOwnNotes = id => {
    return !!(notePermission && isGranted(notePermission) && userID === id);
  };

  const canEditNote = id => {
    if (isGranted(roles.ROLE_EDIT_NOTE)) {
      return true;
    } else return !id || hasPermissionToModifyOwnNotes(id);
  };

  const canRemoveNote = id => {
    if (isGranted(roles.ROLE_REMOVE_NOTE)) {
      return true;
    } else return !id || hasPermissionToModifyOwnNotes(id);
  };

  const notesWithActions = () => {
    const notesWithActions = notes.map(note => {
      return {
        ...note,
        actions: (
          <TableActions
            handleEdit={
              isGranted(roles.ROLE_EDIT_NOTE) &&
              canEditNote(note.moderator?.['@id'])
                ? () => {
                    setEditModalStatus(true);
                    setEditModalData(note);
                  }
                : false
            }
            handleRemove={
              isGranted(roles.ROLE_REMOVE_NOTE) &&
              canRemoveNote(note.moderator?.['@id'])
                ? () => {
                    setIsConfirmModalOpened(true);
                    setSelectedNote(note);
                  }
                : false
            }
          />
        ),
      };
    });

    return notesWithActions;
  };

  return (
    isGranted(roles.ROLE_SHOW_NOTE) && (
      <Fragment>
        <Fragment>
          {isGranted(roles.ROLE_CREATE_NOTE) && (
            <FormControlButtons
              classes={classes}
              cancelPath={'/admin/clients'}
              discardText={t('dataGrid.backToList')}
              handleSubmit={() => {
                setNotesModalStatus(true);
              }}
              submitText={`${t('notes.addNote', 'Dodaj notatkę')} +`}
            />
          )}

          {notesModalStatus && (
            <Dialog
              maxWidth="xs"
              fullWidth={true}
              open={notesModalStatus}
              onClose={() => closeModal()}
            >
              <DialogContent>
                <ModalButton
                  style={orderStyles.closeButton}
                  color={'transparent'}
                  justIcon
                  round
                  onClick={() => closeModal()}
                >
                  <Close />
                </ModalButton>
                <div style={{ ...orderStyles.box }}>
                  <b>{t('notes.newNote')}</b>
                  <FormTextInput
                    multiline
                    rowsMax={10}
                    label={t('notes.noteContent')}
                    classes={classes}
                    name="phone"
                    value={noteContent}
                    handleChange={e => setNoteContent(e.target.value)}
                    inputSize={12}
                    maxLength={400}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalButton
                      color={'primary'}
                      disabled={isLoading}
                      onClick={e => addNote(e)}
                    >
                      {t('notes.addNote')}
                    </ModalButton>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          )}
        </Fragment>

        <ReactTable
          data={notesWithActions()}
          filterable={false}
          sortable={false}
          resizable={false}
          columns={columnConfig(t)}
          minRows={10}
          showPaginationTop={true}
          showPaginationBottom={false}
          defaultPageSize={10}
          className="-striped -highlight"
          previousText={t('dataGrid.prevPage')}
          nextText={t('dataGrid.nextPage')}
          loadingText={t('dataGrid.loading')}
          noDataText={t('dataGrid.notFound')}
          pageText={t('dataGrid.page')}
          ofText={t('dataGrid.pageOf')}
          rowsText={t('dataGrid.results')}
        />
        {editModalStatus && (
          <NotesEditModal
            classes={classes}
            client={user}
            openToast={openToast}
            setNotes={setNotes}
            setNotesModalStatus={() => {}}
            editModalStatus={editModalStatus}
            setEditModalStatus={setEditModalStatus}
            editModalData={editModalData}
            t={t}
            getNotesAndCloseModal={closeModal}
          />
        )}
        <Dialog fullWidth={true} open={isConfirmModalOpened}>
          <SweetAlert
            warning
            style={{ display: 'block' }}
            title={t('clients.areYouSure')}
            onConfirm={() => {
              remove(selectedNote['@id']).then(() => {
                getNotes();
                setIsConfirmModalOpened(false);
              });
            }}
            onCancel={() => setIsConfirmModalOpened(false)}
            confirmBtnCssClass={classes.button + ' ' + classes.success}
            cancelBtnCssClass={classes.button + ' ' + classes.danger}
            confirmBtnText={t('common.shared.yes')}
            cancelBtnText={t('common.shared.no')}
            showCancel
          />
        </Dialog>
      </Fragment>
    )
  );
};

const combinedStyles = combineStyles(
  sweetAlertStyle,
  extendedFormsStyle,
  buttonsStyle
);

const mapStateToProps = state => ({
  userID: state.Auth.user.id,
});

const enhance = compose(
  connect(mapStateToProps, null),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(Notes);
