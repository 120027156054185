import styled from 'styled-components';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import isNumber from 'lodash/isNumber';

import { currencyToSymbol } from 'utils/currencies';

import { getHexOfThemeColor } from 'helpers/themeHelpers';

const StyledPriceWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const PriceBeforeDiscount = styled.div`
  color: ${getHexOfThemeColor('red')};
  margin-right: 5px;
  text-decoration: line-through;
`;

const PriceWrapper = ({
  beforeDiscount,
  afterDiscount,
  mayNegative = false,
  style = {},
}) => {
  const { currency } = useSelector(state => {
    return {
      currency: state?.Brands.brand.multinational.currency,
    };
  });

  const renderPrice = useCallback(
    price => {
      if (mayNegative) {
        return `${price} ${currencyToSymbol(currency)}`;
      }

      return `${price < 0 ? 0 : (price ?? 0).toFixed(2)} ${currencyToSymbol(
        currency
      )}`;
    },
    [mayNegative]
  );

  return (
    <StyledPriceWrapper style={style?.wrapper}>
      {isNumber(beforeDiscount) && beforeDiscount !== afterDiscount && (
        <PriceBeforeDiscount>{renderPrice(beforeDiscount)}</PriceBeforeDiscount>
      )}
      {isNumber(afterDiscount) && (
        <div style={style?.afterDiscount}>{renderPrice(afterDiscount)}</div>
      )}
    </StyledPriceWrapper>
  );
};

export default PriceWrapper;
