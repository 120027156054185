import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import SelectInput from '../../FormSelect/SelectInput';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from '../../../helpers/helpers';
import extendedGridStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedGridStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import makeStyles from '@material-ui/styles/makeStyles';

const useStatusPickerStyles = makeStyles({
  selectFilter: {
    padding: '7px 0 0',
    fontSize: '.75rem',
    fontWeight: '400',
    lineHeight: '1.42857',
    textDecoration: 'none',
    textTransform: 'uppercase',
    letterSpacing: '0',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
});

const Select = props => {
  const [selected, setSelected] = useState([]);

  const filterSelectClasses = useStatusPickerStyles();

  const handleDiets = (e, selected) => {
    setSelected(selected);

    if (props.multiple) {
      props?.onChange(selected.map(el => el['@id']));
    } else {
      if (props.value === selected?.['@id']) {
        return props?.onChange(null);
      }
      return props?.onChange(selected?.['@id']);
    }
  };

  useEffect(() => {
    if (!props.multiple && props.value !== selected?.['@id']) {
      setSelected({ '@id': props.value });
    }
  }, [props.value]);

  return (
    <SelectInput
      multiple={props.multiple ?? true}
      classes={props.classes}
      label={false}
      mapBy="name"
      trackBy="@id"
      value={props.value || selected}
      options={props.options || []}
      customSelectClasses={{ select: filterSelectClasses.selectFilter }}
      handleChange={(ev, obj) => handleDiets(ev, obj)}
    />
  );
};

const combinedStyles = combineStyles(extendedGridStyle, buttonsStyle);

export default withTranslation()(withStyles(combinedStyles)(Select));
