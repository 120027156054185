import React from 'react';
import { withToast } from 'material-ui-toast-redux';
import { fetchIngredients } from 'actions/Ingredients';
import { fetchProduct } from 'actions/Products';
import { put } from 'helpers/apiHelpers';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import FormLabel from '@material-ui/core/FormLabel';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { withTranslation } from 'react-i18next';
import FormSelectDropdown from 'components/FormSelect/FormSelectDropdown';
import { useState } from 'react';
import { useEffect } from 'react';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import CardWrapper from 'components/Card/CardWrapper';

const Form = ({
  match,
  history,
  openToast,
  t,
  classes,
  fetchProduct,
  fetchIngredients,
}) => {
  const [state, setState] = useState({
    edit: match.path.includes('edit'),
    isLoading: true,
    product: null,
  });

  useEffect(() => {
    state.edit &&
      fetchProduct(match.params.id).then(product => {
        setState(prev => ({ ...prev, isLoading: false, product: product }));
      });
  }, []);

  const submitForm = event => {
    event.preventDefault();
    submitData();
  };

  const getFilteredIngredients = async e => {
    return fetchIngredients({
      pageSize: 30,
      page: 0,
      sorted: [],
      filtered: [
        {
          id: '_orX',
          value: [[{ id: e, workName: e }]],
        },
      ],
    });
  };

  const handleIngredientChange = ingredient => {
    let product = state.product;
    product.ingredient = ingredient;
    setState(prev => ({ ...prev, product: product }));
  };

  const submitData = (nestedUpdates = {}) => {
    if (!state.product?.ingredient) {
      return;
    }

    const data = {
      ingredient: state.product.ingredient['@id'],
    };

    put(`/products/${match.params.id}`, data).then(
      () => history.push('/admin/products'),
      error => {
        return openToast({
          messages: [
            error.response.data['hydra:description'] || t('form.smthWentWrong'),
          ],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  const { product } = state;

  return (
    <form
      id="productForm"
      onSubmit={submitForm}
      style={{
        opacity: state.isLoading ? 0 : 1,
        transition: '0.3s all',
      }}
    >
      <GridContainer>
        <GridItem xs={12}>
          <CardWrapper title={t('form.basicInfo')}>
            <CardBody>
              <GridContainer justify="center">
                <GridItem md={10}>
                  <GridContainer>
                    <GridItem md={6}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.name')}
                      </FormLabel>
                      <div>{product?.name}</div>
                    </GridItem>
                    <GridItem md={6}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.ingredient')}
                      </FormLabel>
                      <FormSelectDropdown
                        includeId
                        filterName="workName"
                        placeholder={product?.ingredient?.workName}
                        handleClick={handleIngredientChange}
                        clearAfterSelect={true}
                        filter={getFilteredIngredients}
                      />
                    </GridItem>
                    <GridItem md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.stock')}
                      </FormLabel>
                      <div>{product?.stock}</div>
                    </GridItem>
                    <GridItem md={3}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.lastPriceGross')}
                      </FormLabel>
                      <div>{product?.lastPriceGross?.toFixed(2)}</div>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>
        </GridItem>
      </GridContainer>
      <FormControlStickyButton
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/products"
        handleSubmit={submitForm}
        customOffsetSmall={'10px'}
        customOffsetLarge={'10px'}
        isFixedToBottom={true}
      />
    </form>
  );
};

const mapStateToProps = state => ({
  ingredients: state.Ingredients.ingredients,
});

export default withTranslation()(
  connect(mapStateToProps, {
    fetchProduct,
    fetchIngredients,
  })(withToast(withStyles(extendedFormsStyle)(Form)))
);
