import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import CardBody from '../../../components/Card/CardBody';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import { Info } from '@material-ui/icons';
import { Divider, Tooltip } from '@material-ui/core';
import SelectInput from 'components/FormSelect/SelectInput';
import CustomCheckbox from 'components/Checkbox/CustomCheckbox';
import { usePanelConfigurationContext } from './PanelConfiguration.context';
import CardWrapper from 'components/Card/CardWrapper';

const DishDetails = ({ t, classes }) => {
  const {
    handleChangeDietViewOption,
    handleGlycemicIndexChange,
    handleSummaryShowChange,
    handleCheckboxChange,
    brandConfig,
    setConfigClientPanelModule,
    setBrandConfig,
  } = usePanelConfigurationContext();

  return (
    <CardWrapper
      title={t(
        'brands.newBrandForm.dietDayDetail',
        'Widoczność szczegółów dania'
      )}
    >
      <CardBody>
        <Divider style={{ marginBottom: '20px' }} />
        <GridContainer>
          <GridItem md={6}>
            <GridContainer>
              <GridItem xs={12}>
                <CustomCheckbox
                  name="dishCalorific"
                  checked={brandConfig.showInformation.dishCalorific}
                  onChange={handleChangeDietViewOption}
                  label={t(
                    'brands.newBrandForm.caloricContent',
                    'Kaloryczność dania'
                  )}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomCheckbox
                  name="macroIngredients"
                  checked={brandConfig.showInformation.macroIngredients}
                  onChange={handleChangeDietViewOption}
                  label={t(
                    'brands.newBrandForm.macronutrients',
                    'Makroskładniki (białka, węglowodany, tłuszcze)'
                  )}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomCheckbox
                  name="secondMacroIngredients"
                  checked={brandConfig.showInformation.secondMacroIngredients}
                  onChange={handleChangeDietViewOption}
                  label={t(
                    'brands.newBrandForm.secondMacronutrients',
                    'Makroskładniki (kw. tłuszcz. nasycone, cukry, sól)'
                  )}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomCheckbox
                  name="allergens"
                  checked={brandConfig.showInformation.allergens}
                  onChange={handleChangeDietViewOption}
                  label={t('common.mainMenu.allergens', 'Alergeny')}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomCheckbox
                  name="show"
                  checked={brandConfig.showInformation.glycemicIndex?.show}
                  onChange={handleGlycemicIndexChange}
                  label={t('glycemicIndex.label', 'Indeks glikemiczny')}
                />
              </GridItem>
              <GridItem xs={12}>
                <FormTextInput
                  label={
                    <div style={{ display: 'flex' }}>
                      <span>
                        {t(
                          'brands.newBrandForm.onMouseOver',
                          'Opis wyświetlany dla klienta po najechaniu myszką'
                        )}
                      </span>
                      <div
                        style={{
                          marginLeft: '10px',
                          display: 'inline-block',
                        }}
                      >
                        <Tooltip
                          title={
                            <div>
                              <h4>{t('brandCfg.textUnderCaptionTooltip')}</h4>
                              <h4>
                                {t('brandCfg.etc')}{' '}
                                {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt pod nagłówkiem</div>`}
                              </h4>
                            </div>
                          }
                          placement="right"
                        >
                          <Info fontSize={'small'} />
                        </Tooltip>
                      </div>
                    </div>
                  }
                  classes={classes}
                  name="tooltip"
                  value={brandConfig.showInformation.glycemicIndex?.tooltip}
                  handleChange={handleGlycemicIndexChange}
                  inputSize={12}
                  maxLength={1000}
                  rows={3}
                  rowsMax={20}
                  multiline={true}
                />
              </GridItem>
              <GridItem xs={12}>
                <CustomCheckbox
                  checked={brandConfig.showNutritionalValuesInMenuPreview}
                  onChange={() => {
                    handleCheckboxChange('showNutritionalValuesInMenuPreview');
                    handleCheckboxChange(
                      'showNutritionalValuesInMenuPreview',
                      setConfigClientPanelModule
                    );
                  }}
                  label={t(
                    '$*module.configClientPanel.showNutritionalValuesInMenuPreview',
                    '$$Podgląd menu - Procentowy wskaźnik makroskładników w diecie'
                  )}
                />
              </GridItem>

              <GridItem xs={12}>
                <CustomCheckbox
                  name="show"
                  checked={brandConfig.showInformation.summary?.show}
                  onChange={handleSummaryShowChange}
                  label={t(
                    '$*module.configClientPanel.dietDaySummary',
                    '$$Podsumowanie kaloryczności i makroskładników w menu'
                  )}
                />
              </GridItem>
              <GridItem xs={12}>
                {' '}
                <FormTextInput
                  label={
                    <div style={{ display: 'flex' }}>
                      <span>
                        {t(
                          '$*module.configClientPanel.dietDaySummary.tooltip',
                          '$$Opis wyświetlany dla klienta po najechaniu myszką'
                        )}
                      </span>
                      <div
                        style={{
                          marginLeft: '10px',
                          display: 'inline-block',
                        }}
                      >
                        <Tooltip
                          title={
                            <div>
                              <h4>{t('brandCfg.textUnderCaptionTooltip')}</h4>
                              <h4>
                                {t('brandCfg.etc')}{' '}
                                {`<div style="margin: 30px; max-width: 50%">Przykładowy teskt pod nagłówkiem</div>`}
                              </h4>
                            </div>
                          }
                          placement="right"
                        >
                          <Info fontSize={'small'} />
                        </Tooltip>
                      </div>
                    </div>
                  }
                  classes={classes}
                  name="tooltip"
                  value={brandConfig.showInformation.summary?.tooltip}
                  handleChange={handleSummaryShowChange}
                  inputSize={12}
                  maxLength={1000}
                  rows={3}
                  rowsMax={20}
                  multiline={true}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem md={6}>
            <GridContainer>
              <GridItem md={12}>
                <CustomCheckbox
                  name="dishImage"
                  checked={brandConfig.showInformation.dishImage}
                  onChange={handleChangeDietViewOption}
                  label={t('brands.newBrandForm.mealPhoto', 'Zdjęcie dania')}
                />
              </GridItem>
              <GridItem md={12}>
                <CustomCheckbox
                  name="dishComposition"
                  checked={brandConfig.showInformation.dishComposition}
                  onChange={() => {
                    setBrandConfig(prevState => ({
                      ...prevState,
                      showInformation: {
                        ...prevState.showInformation,
                        dishComposition:
                          !brandConfig.showInformation.dishComposition,
                        dishCompositionValue: {
                          name: 'Nie pokazuj składu dania',
                          mode: 'OFF',
                        },
                      },
                    }));
                  }}
                  label={t('brands.newBrandForm.composition', 'Skład dania')}
                />
                <SelectInput
                  disabled={!brandConfig.showInformation?.dishComposition}
                  noGrid
                  classes={classes}
                  mapBy="name"
                  trackBy="mode"
                  name="dishCompositionValue"
                  value={brandConfig.showInformation?.dishCompositionValue}
                  options={[
                    {
                      name: t('$*shop.dishDetails.showIngredients.default'),
                      mode: 'DEFAULT',
                    },
                    {
                      name: t('$*shop.dishDetails.showIngredients.percentage'),
                      mode: 'PERCENTAGE',
                    },
                  ]}
                  handleChange={(e, obj) => {
                    setBrandConfig(prevState => ({
                      ...prevState,
                      showInformation: {
                        ...prevState.showInformation,
                        dishCompositionValue: obj,
                      },
                    }));
                  }}
                  size={12}
                />
              </GridItem>
              <GridItem md={12}>
                <CustomCheckbox
                  name="tags"
                  checked={brandConfig.showInformation.tags}
                  onChange={handleChangeDietViewOption}
                  label={t(
                    'brands.newBrandForm.clientTags',
                    'Tagi dla klienta'
                  )}
                />
              </GridItem>
              <GridItem md={12}>
                <CustomCheckbox
                  name="servingProposal"
                  checked={brandConfig.showInformation.servingProposal}
                  onChange={handleChangeDietViewOption}
                  label={t(
                    'brands.newBrandForm.servProposal',
                    'Propozycja podania'
                  )}
                />
              </GridItem>
              <GridItem md={12}>
                <CustomCheckbox
                  name="avgDishRating"
                  checked={brandConfig.showInformation.avgDishRating}
                  onChange={handleChangeDietViewOption}
                  label={t(
                    'brands.newBrandForm.averageRating',
                    'Średnia ocena dania'
                  )}
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

const enhance = compose(
  withTranslation(),
  withToast,
  withStyles(extendedFormsStyle)
);

export default enhance(DishDetails);
