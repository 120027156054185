import { get } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchAllergens = () => {
  return dispatch =>
    get('/allergens', { pagination: false }).then(allergens => {
      dispatch({
        type: types.FETCH_ALLERGENS,
        payload: {
          allergens: allergens['hydra:member'],
        },
      });
    });
};
