import React, { useEffect, useState } from 'react';
import { withToast } from 'material-ui-toast-redux';

import { get, post, put } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

import withStyles from '@material-ui/core/styles/withStyles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { withTranslation } from 'react-i18next';
import FormFiltersView from './FormFilters.view';

const FormFiltersContainer = ({
  match,
  location,
  openToast,
  history,
  t,
  classes,
}) => {
  const [state, setState] = useState({
    modal: null,
    modalOptions: [],
    name: '',
    packages: [],
    image: null,
    position: 0,
    imageUrl: null,
    active: false,
    description: '',
    disabledDiets: [],
    mainImage: null,
    mainImageUrl: null,
  });

  const [packages, setPackages] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const filterLineDietsId = match.params.id;
  const isEdit = location.pathname.includes('edit');

  useEffect(() => {
    get('/packages', { pagination: false })
      .then(response => {
        setPackages(response['hydra:member']);
      })
      .catch(err => console.log(err));

    if (isEdit) {
      get(`package-line-filters/${filterLineDietsId}`)
        .then(response => {
          const {
            name,
            packages,
            active,
            position,
            description,
            image,
            mainImage,
          } = response;

          setState(prev => ({
            ...prev,
            name: name,
            packages: packages,
            active: active,
            position: position,
            description: description,
            image: image?.['@id'] || null,
            imageUrl: image?.contentUrl || null,
            mainImage: mainImage?.['@id'] || null,
            mainImageUrl: mainImage?.contentUrl || null,
          }));
          setIsLoading(true);
        })
        .catch(err => {
          console.log(err);
        });
    }

    !isEdit && setIsLoading(true);
  }, []);

  const handleInputChange = (event, value) => {
    const newValue = event.target.value;
    setState(prev => ({
      ...prev,
      [event.target.name]: value ? value : newValue,
    }));
  };

  const handleSelect = ({ target: { name, value } }) => {
    setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getImage = (stateName, data) => {
    setState(prev => ({
      ...prev,
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    }));
  };

  const removeImage = stateName => {
    setState(prev => ({
      ...prev,
      [stateName]: null,
      [`${stateName}Url`]: null,
    }));
  };

  const handleToggle = e => {
    setState(prev => ({ ...prev, [e.target.name]: !state[e.target.name] }));
  };

  const isValidateForm = () => {
    return !!state.name && state.packages.length > 0;
  };

  const showToast = key => {
    return openToast({
      messages: [t(key)],
      type: 'error',
      autoHideDuration: 3000,
    });
  };

  const handleSubmit = () => {
    if (!isValidateForm()) {
      return showToast('form.fillAllRequiredFields');
    }

    if (state.position < 1) {
      return showToast('form.fillAllRequiredFields');
    }

    const data = {
      name: state.name,
      position: parseInt(state.position),
      description: state.description,
      active: state.active,
      image: state.image,
      disabledDiets: state.disabledDiets,
      packages: state.packages,
      mainImage: state.mainImage,
    };
    const action = isEdit
      ? put(`/package-line-filters/${filterLineDietsId}`, data)
      : post('/package-line-filters', data);
    action.then(() => {
      history.push('/admin/filter-line-diets');
    });
  };

  const props = {
    t,
    packages,
    classes,
    state,
    isLoading,
    isEdit,
    filterLineDietsId,
    handleInputChange,
    handleSelect,
    getImage,
    removeImage,
    handleToggle,
    handleSubmit,
  };
  return <FormFiltersView props={props} />;
};
const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withTranslation()(
  withToast(withStyles(combinedStyles)(FormFiltersContainer))
);
