import React from 'react';
import { useState } from 'react';
import MailNotificationCardSectionView from './MailNotificationCardSection.view';
import { withToast } from 'material-ui-toast-redux';
import { connect } from 'react-redux';

import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

import withStyles from '@material-ui/core/styles/withStyles';
import SweetAlert from 'react-bootstrap-sweetalert';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

const MailNotificationCardSectionContainer = ({
  classes,
  t,
  mailingOptions,
  openToast,
  mailForAdmin,
  title,
  mailing,
  defaultConfigFromBrand,
  brand,
  setAllowToChangedElements,
}) => {
  const [state, setState] = useState({
    mailForAdmin: mailForAdmin,
    mailing: mailing,
    confirmModal: null,
    defaultConfigFromBrand: defaultConfigFromBrand,
  });

  const renderAlert = (onConfirm, onCancel) => {
    const confirmModal = (
      <SweetAlert
        warning
        title={t('notify.resetContent')}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        confirmBtnCssClass={classes.button + ' ' + classes.success}
        cancelBtnCssClass={classes.button + ' ' + classes.danger}
        confirmBtnText={t('notify.yes')}
        cancelBtnText={t('notify.no')}
        showCancel
      />
    );

    setState(prev => ({ ...prev, confirmModal }));
  };

  const props = {
    classes,
    t,
    mailingOptions,
    state,
    setState,
    openToast,
    renderAlert,
    title,
    brand,
    setAllowToChangedElements,
  };

  return <MailNotificationCardSectionView props={props} />;
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => {
  return {
    brand: state.Auth.selectedBrand,
  };
};

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation(),
  connect(mapStateToProps, null)
);

export default enhance(MailNotificationCardSectionContainer);
