import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { combineStyles } from 'helpers/helpers';
import { createMuiTheme } from '@material-ui/core/styles/index';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import { withTranslation } from 'react-i18next';

import NotesRecipeForm from './NotesRecipeForm';
import RegularRecipeForm from './RegularRecipeForm';
import RecipeFormTabsNavigation from './helperComponents/RecipeFromTabsNavigation';
import { RECIPE_FORM_TABS } from './constants/recipeFormTabs';

const theme = createMuiTheme({ typography: { useNextVariants: true } });

class RecipeForm extends Component {
  getCurrentTab = () => {
    const { currentRecipeFormTab } = this.props;
    switch (currentRecipeFormTab) {
      case RECIPE_FORM_TABS.NOTES:
        return <NotesRecipeForm {...this.props} />;
      default:
        return <RegularRecipeForm {...this.props} />;
    }
  };
  render() {
    const { recipeId, isLoading } = this.props;

    return (
      <div style={{ opacity: isLoading ? 0 : 1, transition: '0.5s all' }}>
        <ThemeProvider theme={theme}>
          <h2
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {recipeId
              ? this.props.t('form.editRecipe')
              : this.props.t('form.recipeCreation')}
            <RecipeFormTabsNavigation {...this.props} />
          </h2>
          {this.getCurrentTab()}
        </ThemeProvider>
      </div>
    );
  }
}

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle
);

const mapStateToProps = state => ({
  ingredients: state.Ingredients.ingredients,
  recipes: state.Recipes.recipes,
  selectedBrand: state.Auth.selectedBrand,
});

export default withTranslation()(
  connect(
    mapStateToProps,
    null
  )(withStyles(combinedStyles)(withRouter(RecipeForm)))
);
