import React from 'react';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import ExtensionIcon from '@material-ui/icons/Extension';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import Button from 'components/CustomButtons/Button.jsx';

import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_ADDON, ROLE_SHOW_MODULE_ADDONS } from 'helpers/roles';

import columnConfig from './columnConfig';

const AddonsList = ({ classes }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { pathname } = window.location;

  return (
    <AdminTable
      title={t('views.addons.addons.list')}
      iconComponent={<ExtensionIcon />}
      settingsComponent={
        isGranted(ROLE_SHOW_MODULE_ADDONS) && (
          <Button
            color="secondary"
            onClick={() => history.push(`${pathname}/settings`)}
          >
            {t('views.addons.addons.settings')}
          </Button>
        )
      }
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {isGranted(ROLE_CREATE_ADDON) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={() => history.push(`${pathname}/add`)}
            submitText={`${t('views.addons.addons.addAddon')} +`}
          />
        )}
      </div>
      <DataGrid
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        remove={true}
        export={true}
        settings={true}
        paginationTop={true}
        paginationBottom={false}
        url="/addons"
        reportName={'addon'}
        columns={columnConfig({ t })}
        role="ADDON"
      />
    </AdminTable>
  );
};

const enhance = compose(withStyles(buttonsStyle));

export default enhance(AddonsList);
