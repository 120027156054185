import { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import AdminTable from 'layouts/AdminTable';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import gastro from 'helpers/gastro';
import { post, put } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

import defaultState from './defaultState';
import FormTextInputTranslatable from 'components/FormTextInput/FormTextInputTranslatable';

const Form = ({
  match,
  classes,
  history,
  valueUrl,
  valueKey,
  location,
  openToast,
  translatable = false,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ ...defaultState });

  const isEdit = location.pathname.includes('edit');
  const elementId = match.params.id;

  useEffect(() => {
    if (isEdit) {
      fetchValue();
    }
  }, []);

  const fetchValue = () => {
    gastro
      .get(`${valueUrl}/${elementId}?translations=${translatable}`)
      .then(async response => {
        setState({
          ...state,
          vat: response.data.vat,
          cost: response.data.cost,
          name: response.data.name,
          volume: response.data.volume,
          weight: response.data.weight,
          position: response.data.position,
        });
      });
  };

  const handleInputChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    return state.name;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return openToast({
        messages: [t('form.nameFieldCannotBeEmpty')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const data = {
      vat: state.vat ? parseFloat(state.vat) : 0,
      cost: state.cost ? parseFloat(state.cost) : 0,
      name: state.name,
      weight: state.weight ? parseFloat(state.weight) : 0,
      volume: state?.volume ? parseFloat(state.volume) : 0,
      position: parseInt(state.position),
    };

    const action = isEdit
      ? put(`${valueUrl}/${elementId}`, data)
      : post(valueUrl, data);

    action.then(() => history.push(`/admin/${valueUrl}`));
  };

  const NameComponent =
    true !== translatable ? FormTextInput : FormTextInputTranslatable;

  return (
    <AdminTable title={isEdit ? t('form.editValue') : t('form.addNewValue')}>
      <NameComponent
        label={t('form.field.name') + '*'}
        classes={classes}
        name={'name'}
        value={state.name}
        handleChange={handleInputChange}
        inputSize={4}
      />
      <NumberFormat
        label={t('form.field.weightG')}
        classes={classes}
        name={'weight'}
        customInput={FormTextInput}
        value={state.weight}
        onChange={handleInputChange}
        allowNegative={false}
        allowedDecimalSeparators={[',', '.']}
        allowLeadingZeros={false}
        inputSize={4}
      />
      <NumberFormat
        label={t('form.field.clientCost.label')}
        classes={classes}
        name={'cost'}
        customInput={FormTextInput}
        value={state.cost}
        onChange={handleInputChange}
        allowNegative={false}
        allowedDecimalSeparators={[',', '.']}
        allowLeadingZeros={false}
        inputSize={4}
      />
      <NumberFormat
        label={t('form.field.clientVat.label')}
        classes={classes}
        name={'vat'}
        customInput={FormTextInput}
        value={state.vat}
        onChange={handleInputChange}
        allowNegative={false}
        allowedDecimalSeparators={[',', '.']}
        allowLeadingZeros={false}
        inputSize={4}
      />
      <NumberFormat
        label={t('columns.position')}
        classes={classes}
        name={'position'}
        customInput={FormTextInput}
        value={state.position}
        onChange={handleInputChange}
        allowNegative={false}
        allowLeadingZeros={false}
        format={'###'}
        inputSize={4}
      />
      <NumberFormat
        label={t('columns.volume')}
        classes={classes}
        name={'volume'}
        customInput={FormTextInput}
        value={state.volume}
        onChange={handleInputChange}
        allowNegative={false}
        allowLeadingZeros={false}
        inputSize={4}
      />

      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath={`/admin/${valueUrl}`}
        handleSubmit={handleSubmit}
      />
    </AdminTable>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withToast(withStyles(combinedStyles)(Form));
