import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'react-i18next';

import { combineStyles } from 'helpers/helpers';
import { get, post, put } from 'helpers/apiHelpers';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import { fetchDiets } from 'actions/Diets';
import { fetchVariants } from 'actions/Variants';

import { withToast } from 'material-ui-toast-redux';

import SweetAlert from 'react-bootstrap-sweetalert';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

const DietPrices = WrappedComponent =>
  class extends Component {
    state = {
      alert: null,
      selectedDiet: {},
      dietCosts: [],
      selectedDietCosts: [],
      isLoading: true,
      showSubscriptionAlert: false,
    };

    componentDidMount() {
      Promise.all([
        this.props.fetchDiets(),
        this.props.fetchVariants(),
        get('/diet-costs', { pagination: false }),
      ]).then(([diets, variants, dietCostsData]) => {
        const data = dietCostsData['hydra:member'];

        if (this.props.diets.length > 0) {
          this.setState({
            dietCosts: data,
            selectedDiet: this.props.diets[0]['@id'],
          });
        }
        this.setState({ isLoading: false });

        this.propagateData(data);
        this.props.diets.length > 0 &&
          this.filterDietCosts(this.props.diets[0]['@id']);
      });
    }

    propagateData = dietCostsData => {
      const dietCosts = [];
      this.props.diets.forEach(diet => {
        this.props.variants.forEach(variant => {
          if (!diet.variants.includes(variant['@id'])) {
            return;
          }

          variant.calories.forEach(calorie => {
            let dietCost = dietCostsData.find(
              dietCost =>
                dietCost.calorific === calorie['@id'] &&
                dietCost.diet === diet['@id']
            );

            if (dietCost === undefined) {
              dietCost = null;
            }

            return dietCosts.push({
              hasChanged: false,
              '@id': dietCost?.['@id'] ?? null,
              dietName: diet.name,
              diet: diet['@id'],
              variantName: variant.name,
              calorific: calorie['@id'],
              calorificName: calorie.name,
              hasUpdateActiveSubscriptions: false,
              price: dietCost?.price ?? 0,
              priceForTest: dietCost?.priceForTest ?? 0,
              priceForSubscription: dietCost?.priceForSubscription ?? 0,
              expectedFoodCost: dietCost?.expectedFoodCost ?? 0,
              vat: dietCost?.vat ?? 0,
              _price: dietCost?.price ?? 0,
              _priceForTest: dietCost?.priceForTest ?? 0,
              _priceForSubscription: dietCost?.priceForSubscription ?? 0,
              _expectedFoodCost: dietCost?.expectedFoodCost ?? 0,
              _vat: dietCost?.vat ?? 0,
            });
          });
        });
      });

      this.setState({
        dietCosts,
      });
    };

    filterDietCosts = selectedDiet => {
      const filteredDietCosts = this.state.dietCosts.filter(
        dietCost => dietCost.diet === selectedDiet
      );
      this.setState({
        selectedDietCosts: filteredDietCosts,
      });
    };

    handleDietChange = (event, selectedDiet) => {
      if (this.state.selectedDietCosts.some(dietCost => dietCost.hasChanged)) {
        return this.confirmModal(selectedDiet['@id']);
      }
      this.filterDietCosts(selectedDiet['@id']);
      this.setState({
        selectedDiet: selectedDiet['@id'],
      });
    };

    handleChange = (event, index) => {
      const selectedDietCosts = this.state.selectedDietCosts;
      let elToChange = selectedDietCosts[index];
      let key = event.target.name;
      if (
        ![
          'price',
          'priceForTest',
          'priceForSubscription',
          'expectedFoodCost',
        ].includes(key)
      ) {
        key = 'vat';
      }

      let newValue = event.target.value.replace(/,/g, '.');
      let isValid = /^[0-9,.]*$/.test(newValue);
      if (!isValid || isNaN(newValue)) {
        return;
      }
      elToChange[key] = newValue;

      elToChange.hasChanged =
        parseFloat(elToChange.price) !== elToChange._price ||
        parseFloat(elToChange.priceForTest) !== elToChange._priceForTest ||
        parseFloat(elToChange.priceForSubscription) !==
          elToChange._priceForSubscription ||
        parseFloat(elToChange.expectedFoodCost) !==
          elToChange._expectedFoodCost ||
        parseFloat(elToChange.vat) !== elToChange._vat;

      this.setState({
        selectedDietCosts,
      });
    };

    confirmModal = selectedDiet => {
      return this.setState({
        alert: (
          <SweetAlert
            warning
            style={{ display: 'block' }}
            title="Wprowadzono zmiany. Czy chcesz je zapisać?"
            onConfirm={async () => {
              await this.saveAll();
              this.setState({ selectedDiet });
              this.filterDietCosts(selectedDiet);
              this.hideAlert();
            }}
            onCancel={() => {
              this.hideAlert();
              const resetDietCosts = this.state.dietCosts.map(dc => {
                if (dc.hasChanged) {
                  return {
                    ...dc,
                    hasChanged: false,
                    hasUpdateActiveSubscriptions: false,
                    price: dc['_price'],
                    priceForTest: dc['_priceForTest'],
                    priceForSubscription: dc['_priceForSubscription'],
                    expectedFoodCost: dc['_pexpectedFoodCost'],
                    vat: dc['_vat'],
                  };
                } else {
                  return dc;
                }
              });
              this.setState({
                selectedDiet: selectedDiet,
                dietCosts: resetDietCosts,
              });
              this.filterDietCosts(selectedDiet);
            }}
            confirmBtnCssClass={
              this.props.classes.button + ' ' + this.props.classes.success
            }
            cancelBtnCssClass={
              this.props.classes.button + ' ' + this.props.classes.danger
            }
            confirmBtnText="Tak"
            cancelBtnText="Nie"
            showCancel
          ></SweetAlert>
        ),
      });
    };

    hideAlert = () => {
      this.setState({
        alert: null,
      });
    };

    validateForm = () => {
      const { t } = this.props;
      const { selectedDietCosts } = this.state;
      let isValid = true;
      if (
        selectedDietCosts.some(
          dietCost =>
            dietCost.price === '' ||
            dietCost.priceForTest === '' ||
            dietCost.priceForSubscription === '' ||
            dietCost.vat === ''
        )
      ) {
        isValid = false;
        this.props.openToast({
          messages: [
            t(
              'errors.priceAndVatRequired',
              'Pola Cena i VAT nie mogą być puste'
            ),
          ],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
      return isValid;
    };

    confirmSubscriptionPriceModal = () => {
      return this.setState(prevState => ({
        ...prevState,
        showSubscriptionAlert: true,
      }));
    };

    saveAll = () => {
      if (
        this.state.selectedDietCosts.some(
          ({ hasChanged, priceForSubscription, _priceForSubscription }) => {
            return hasChanged && priceForSubscription !== _priceForSubscription;
          }
        )
      ) {
        return this.confirmSubscriptionPriceModal();
      }

      return this.saveAllNoSubscriptionModal();
    };

    saveAllNoSubscriptionModal = () => {
      const { t } = this.props;
      if (!this.validateForm()) {
        return;
      }

      const { selectedDietCosts } = this.state;
      selectedDietCosts.forEach(dietCost => {
        if (dietCost.price !== '') {
          dietCost.price = parseFloat(dietCost.price);
        }
        if (dietCost.priceForTest !== '') {
          dietCost.priceForTest = parseFloat(dietCost.priceForTest);
        }
        if (dietCost.priceForSubscription !== '') {
          dietCost.priceForSubscription = parseFloat(
            dietCost.priceForSubscription
          );
        }
        if (
          dietCost.expectedFoodCost !== '' &&
          dietCost.expectedFoodCost !== null
        ) {
          dietCost.expectedFoodCost = parseFloat(dietCost.expectedFoodCost);
        } else {
          dietCost.expectedFoodCost = null;
        }
        if (dietCost.vat !== '') {
          dietCost.vat = parseFloat(dietCost.vat);
        }

        dietCost.hasUpdateActiveSubscriptions = true;
      });

      return Promise.all(
        selectedDietCosts.map(dietCost => {
          if (dietCost.hasChanged) {
            return dietCost['@id']
              ? put(dietCost['@id'], dietCost)
              : post('/diet-costs', dietCost);
          }
          return null;
        })
      ).then(
        () => {
          selectedDietCosts.forEach(dietCost => {
            dietCost.hasChanged = false;
          });
          this.props.openToast({
            messages: [t('success.changesSaved', 'Zmiany zostały zapisane')],
            type: 'success',
            autoHideDuration: 3000,
          });
          this.setState({
            selectedDietCosts: selectedDietCosts.map(dietCost => ({
              ...dietCost,
              _price: dietCost.price,
              _priceForTest: dietCost.priceForTest,
              _priceForSubscription: dietCost.priceForSubscription,
              _expectedFoodCost: dietCost.expectedFoodCost,
              _vat: dietCost.vat,
              hasUpdateActiveSubscriptions: false,
            })),
          });
        },
        error => {
          this.props.openToast({
            messages: [error.response.data['hydra:description']],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
      );
    };

    render() {
      const { t } = this.props;

      return (
        <>
          <WrappedComponent
            {...this.state}
            {...this.props}
            classes={this.props.classes}
            submitForm={this.submitForm}
            handleDietChange={this.handleDietChange}
            getInputValue={this.getInputValue}
            handleChange={this.handleChange}
            saveCalorificPrice={this.saveCalorificPrice}
            saveAll={this.saveAll}
            openToast={this.props.openToast}
          />

          {this.state.showSubscriptionAlert && (
            <SweetAlert
              title={t('priceLists.diets.subscriptionModal.title')}
              onConfirm={() => {
                this.saveAllNoSubscriptionModal();
                this.setState({ showSubscriptionAlert: false });
              }}
              onCancel={() => this.setState({ showSubscriptionAlert: false })}
              confirmBtnCssClass={
                this.props.classes.button + ' ' + this.props.classes.success
              }
              cancelBtnCssClass={
                this.props.classes.button + ' ' + this.props.classes.danger
              }
              confirmBtnText={t('common.shared.save', 'Zapisz')}
              cancelBtnText={t('common.loginForm.buttonBack', 'Wróć')}
              showCancel
              type={'controlled'}
              dependencies={[this.state.counter]}
            >
              <div
                style={{ marginBottom: '20px' }}
                dangerouslySetInnerHTML={{
                  __html: t('priceLists.diets.subscriptionModal.content', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
              {this.state.selectedDietCosts.map(dietCost => {
                if (
                  dietCost.hasChanged &&
                  dietCost.priceForSubscription !==
                    dietCost._priceForSubscription
                ) {
                  return (
                    <div>
                      {`${dietCost.dietName}, ${dietCost.variantName} (${dietCost.calorificName})`}
                    </div>
                  );
                }

                return null;
              })}
            </SweetAlert>
          )}
        </>
      );
    }
  };

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => ({
  diets: state.Diets.diets,
  variants: state.Variants.variants,
});

const mapDispatchToProps = {
  fetchDiets,
  fetchVariants,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(combinedStyles),
  withToast,
  withTranslation(),
  DietPrices
);
