import React from 'react';
import AddonsFormContainer from './AddonsForm/AddonsForm.container';

const AddonsForm = ({ history, location, match }) => {
  return (
    <AddonsFormContainer history={history} location={location} match={match} />
  );
};

export default AddonsForm;
