import { useState } from 'react';
import TagsInput from 'react-tagsinput';
import ReactSelectComponent from 'react-select';
import { useTranslation } from 'react-i18next';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormatListBulleted, Info, ListAlt } from '@material-ui/icons';
import { Tooltip, Divider } from '@material-ui/core';

import Card from 'components/Card/Card';
import LogView from 'components/History/LogView';
import DataGrid from 'components/DataGrid/DataGrid';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem';
import ReactSelect from 'components/ReactSelect/ReactSelect';
import SelectInput from 'components/FormSelect/SelectInput';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridContainer from 'components/Grid/GridContainer';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import FormSelectDropdown from 'components/FormSelect/FormSelectDropdown';
import FormTextInputTranslatable from 'components/FormTextInput/FormTextInputTranslatable';
import { DialogLoader } from 'components/DialogLoader';
import { mapArrayOfTranslationsToPureArray } from 'components/Translatable/TranslatableReader';

import defaultImageForClient from 'assets/img/dishPhotoForClientTemplate.jpg';

import {
  isGranted,
  sortByPosition,
  sortByGroupedBrandPosition,
} from 'helpers/helpers';
import roles from 'helpers/roles';

import TypeBlock from './TypeBlockComponents/TypeBlock';
import CopyMealToMeals from './CopyMealToMeals';
import DishIngredients from './DishIngredients';
import commentsColumnConfig from './commentsColumnConfig';
import menuPlannerColumnConfig from './menuPlannerColumnConfig';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';
import CardWrapper from 'components/Card/CardWrapper';

const BasicMealForm = ({
  dish,
  tags,
  match,
  brands,
  classes,
  history,
  addMeal,
  stowages,
  getImage,
  allergens,
  mealTypes,
  isLoading,
  isEditing,
  openToast,
  clientTags,
  setDishToResize,
  containers,
  handleBrand,
  removeImage,
  getStarRate,
  handleSelect,
  handleChange,
  handleRemove,
  isSubmitting,
  handleSubmit,
  shouldRescale,
  scaleDishSize,
  ecoContainers,
  handleMealType,
  clientImageUrl,
  copyMealTypeTo,
  handleCopySize,
  handleTagChange,
  copyMealTypeFrom,
  setShouldRescale,
  handlePriceChange,
  servingSuggestions,
  productionImageUrl,
  forceDisableInShop,
  getFilteredRecipes,
  showValidationAlert,
  checkedDishToResize,
  selectedIngredients,
  handleCopySizeChange,
  handleSizeNameChange,
  handleQuantityChange,
  handleContainerChange,
  getFilteredIngredients,
  mealTypeIngredientsSum,
  handleEcoContainerChange,
  handleChangeForceDisableInShop,
  handleSizeShopAvailabilityChange,
  handleSizeShopAvailabilityChangeRow,
  copyMode,
}) => {
  const { t } = useTranslation();
  const [mealTypeInputValue, setMealTypeInputValue] = useState('');
  const [isShowOnlyComments, setIsShowOnlyComments] = useState(false);
  const [activeTypes, setActiveTypes] = useState(0);
  const groupedTypes = dish.types.reduce(
    (acc, { brand, ...itemWithoutBrand }) => {
      const { '@id': brandId } = brand;

      return {
        ...acc,
        [brandId]: {
          ...brand,
          types:
            acc[brandId] === undefined
              ? [itemWithoutBrand]
              : [...acc[brandId].types, itemWithoutBrand],
        },
      };
    },
    {}
  );

  const groupedTypesArray = Object.values(groupedTypes);

  const selectedBrandMealTypes = Object.values(groupedTypes).reduce(
    (acc, { types, name }) => {
      const typesWithBrand = types.map(type => ({
        ...type,
        brandName: name,
      }));

      return [...acc, ...typesWithBrand];
    },
    []
  );

  const isMealTypesDisabled = dish.brands.length === 0;

  const mapMealTypesToReactSelectOption = options => {
    return options.map(option => ({
      label:
        option.brand && option.brand.name
          ? `${option.name} - ${option.brand.name}`
          : option.name,
      value: option,
    }));
  };

  const sortByDate = collection => {
    return [...collection].sort(
      (a, b) => new Date(a['createdAt']) - new Date(b['createdAt'])
    );
  };

  return (
    <div>
      <DialogLoader
        loaderState={isSubmitting}
        text={t('form.savingChanges', 'Zapisywanie zmian...')}
      />
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardBody>
              <form>
                <GridContainer justify="center">
                  <GridItem md={12}>
                    <GridContainer>
                      <GridItem md={6}>
                        <h3>{t('form.basicInfo')}</h3>
                        <Divider />
                        <GridContainer>
                          <GridItem md={12}>
                            <FormLabel style={{ fontSize: '14px' }}>
                              {t('form.field.defaultName') + '*'}
                            </FormLabel>
                            <FormTextInputTranslatable
                              data-cy="dish__name"
                              noGrid
                              classes={classes}
                              name="nameForClient"
                              value={dish.nameForClient}
                              handleChange={handleChange}
                              maxLength={160}
                            />
                          </GridItem>
                          <GridItem md={12}>
                            <FormLabel style={{ fontSize: '14px' }}>
                              {t('form.field.workingName') + '*'}
                            </FormLabel>
                            <FormTextInput
                              data-cy="dish__working-name"
                              noGrid
                              classes={classes}
                              name="workingName"
                              value={dish.workingName}
                              handleChange={handleChange}
                              maxLength={160}
                            />
                          </GridItem>
                          <GridItem md={12}>
                            <FormLabel style={{ fontSize: '14px' }}>
                              {t('form.field.description')}
                            </FormLabel>
                            <FormTextInput
                              data-cy="dish__description"
                              noGrid
                              classes={classes}
                              name="description"
                              value={dish.description}
                              handleChange={handleChange}
                              multiline={true}
                              rows={1}
                              rowsMax={10}
                              maxLength={500}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem md={6}>
                            <FormLabel className={classes.labelHorizontal}>
                              {t('form.field.brands') + ' *'}
                            </FormLabel>
                            <SelectInput
                              multiple
                              noGrid
                              classes={classes}
                              mapBy="name"
                              trackBy="@id"
                              options={brands}
                              value={dish.brands}
                              handleChange={handleBrand}
                              name="brands"
                            />
                          </GridItem>
                          <GridItem md={6}>
                            <FormLabel className={classes.labelHorizontal}>
                              {t('form.field.servingProposal')}
                            </FormLabel>
                            <SelectInput
                              noGrid
                              classes={classes}
                              options={servingSuggestions}
                              value={dish.servingSuggestion}
                              name="servingSuggestion"
                              mapBy="value"
                              trackBy="@id"
                              handleChange={handleSelect}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem
                            md={6}
                            onClick={() =>
                              isMealTypesDisabled &&
                              showValidationAlert(t('mealTypes.select.warning'))
                            }
                          >
                            <FormLabel
                              disabled={isMealTypesDisabled}
                              className={classes.labelHorizontal}
                            >
                              {t('form.field.mealTypes') + '*'}
                            </FormLabel>

                            <ReactSelect
                              isMulti={true}
                              closeMenuOnSelect={false}
                              inputValue={mealTypeInputValue}
                              onInputChange={(value, action) => {
                                if (action.action === 'input-change') {
                                  setMealTypeInputValue(value);
                                }
                              }}
                              selectedValues={mapMealTypesToReactSelectOption(
                                dish.types
                              )}
                              options={mapMealTypesToReactSelectOption(
                                sortByGroupedBrandPosition(mealTypes).filter(
                                  item => {
                                    return (
                                      mapMealTypesToReactSelectOption(
                                        dish.types
                                      ).findIndex(
                                        ({ value }) =>
                                          value['@id'] === item['@id']
                                      ) === -1
                                    );
                                  }
                                )
                              )}
                              handleChange={(obj, option) => {
                                if (option.action === 'clear') {
                                  setMealTypeInputValue('');
                                }

                                if (option.action !== 'pop-value') {
                                  handleMealType(obj.map(({ value }) => value));
                                }
                              }}
                              filterOption={(option, searchText) => {
                                return option.label
                                  .toLowerCase()
                                  .includes(searchText.toLowerCase());
                              }}
                              placeholder=" "
                              noOptionsMessage={t(
                                '$*noSearchResults',
                                '$$Brak wyników wyszukiwania'
                              )}
                              Component={ReactSelectComponent}
                              disabled={isMealTypesDisabled}
                            />
                          </GridItem>
                          <GridItem md={6}>
                            <FormLabel className={classes.labelHorizontal}>
                              {t('form.field.stowages')}
                            </FormLabel>
                            <SelectInput
                              noGrid
                              classes={classes}
                              options={[
                                { value: t('common.lack'), '@id': null },
                                ...stowages,
                              ]}
                              value={dish.stowage}
                              name="stowage"
                              mapBy="value"
                              trackBy="@id"
                              handleChange={handleSelect}
                            />
                            <FormTextInput
                              noGrid
                              label={t('form.field.additionalNotesToSticker')}
                              classes={classes}
                              name="additionalNotesToSticker"
                              value={dish.additionalNotesToSticker}
                              handleChange={handleChange}
                              inputSize={12}
                              multiline={true}
                              rows={1}
                              rowsMax={20}
                              maxLength={5000}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem md={6}>
                            <FormLabel className={classes.labelHorizontal}>
                              {t('form.field.tagsForProduction')}
                            </FormLabel>
                            <ReactSelect
                              selectedValues={dish.tags}
                              options={tags}
                              handleChange={handleTagChange}
                            />
                          </GridItem>
                          <GridItem md={6}>
                            <FormLabel className={classes.labelHorizontal}>
                              {t('form.field.tagsForClient')}
                            </FormLabel>
                            <SelectInput
                              multiple
                              noGrid
                              classes={classes}
                              label={t('form.field.tagsForClient')}
                              options={clientTags}
                              value={dish.clientTags}
                              name="clientTags"
                              mapBy="value"
                              trackBy="@id"
                              handleChange={handleChange}
                            />
                          </GridItem>
                        </GridContainer>
                        <GridContainer>
                          <GridItem md={6}>
                            <FormTextInput
                              data-cy="dish__preparation-instruction"
                              noGrid
                              label={t('form.field.preparationInstruction')}
                              classes={classes}
                              name="preparingInstructions"
                              value={dish.preparingInstructions}
                              handleChange={handleChange}
                              inputSize={12}
                              multiline={true}
                              rows={1}
                              rowsMax={20}
                              maxLength={5000}
                            />
                          </GridItem>
                          <GridItem md={6}>
                            <FormTextInput
                              data-cy="dish__notes"
                              noGrid
                              label={t('form.field.notes')}
                              classes={classes}
                              name="note"
                              value={dish.note}
                              handleChange={handleChange}
                              inputSize={12}
                              multiline={true}
                              rows={1}
                              rowsMax={20}
                              maxLength={5000}
                            />
                          </GridItem>
                          <GridItem md={6}>
                            <FormControlLabel
                              label={t(
                                'mealForm.forceDisableInShop',
                                '$$Wyłącz danie ze zmiennej oferty sklepu'
                              )}
                              control={
                                <Checkbox
                                  checked={forceDisableInShop}
                                  onChange={() =>
                                    handleChangeForceDisableInShop(
                                      !forceDisableInShop
                                    )
                                  }
                                  checkedIcon={
                                    <Check className={classes.checkedIcon} />
                                  }
                                  icon={
                                    <Check className={classes.uncheckedIcon} />
                                  }
                                  classes={{
                                    checked: classes.checked,
                                    root: classes.checkRoot,
                                  }}
                                />
                              }
                            />
                            <Tooltip
                              title={
                                <>
                                  <h4>
                                    {t(
                                      'mealForm.forceDisableInShop.tooltip1',
                                      '$$Jeżeli danie jest zaplanowane w menu, to zaznaczając tego checkbox-a, nie będzie się ono pokazywać w sklepie.'
                                    )}
                                  </h4>
                                </>
                              }
                            >
                              <Info
                                style={{
                                  fontSize: '16px',
                                  color: 'grey',
                                  marginLeft: '4px',
                                  marginBottom: '-3px',
                                }}
                              />
                            </Tooltip>
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                      <GridItem md={6}>
                        <h3>{t('form.field.photos')}</h3>
                        <Divider />
                        <GridContainer>
                          <GridItem md={6}>
                            <FormImageUpload
                              label={t('form.field.photoForProduction')}
                              classes={classes}
                              tooltip={t('form.field.photoSizes')}
                              stateName={'productionImage'}
                              getImage={getImage}
                              removeImage={removeImage}
                              previewUrl={productionImageUrl}
                            />
                          </GridItem>
                          <GridItem md={6}>
                            <FormImageUpload
                              label={t('form.field.photoForClient')}
                              classes={classes}
                              tooltip={t('form.field.photoForClientTooltip')}
                              stateName={'clientImage'}
                              getImage={getImage}
                              removeImage={removeImage}
                              defaultImage={defaultImageForClient}
                              previewUrl={clientImageUrl}
                            />
                          </GridItem>
                        </GridContainer>
                        {isEditing && (
                          <div>
                            <h3>{t('form.field.additionalInfo')}</h3>
                            <Divider />
                            <GridContainer>
                              <GridItem md={4}>
                                <FormLabel style={{ fontSize: '14px' }}>
                                  {t('form.field.avgRate')}
                                </FormLabel>
                                <h4>
                                  <b>
                                    {dish.rateAVG
                                      ? dish.rateAVG.toFixed(2)
                                      : '0'}
                                  </b>{' '}
                                  {t('form.field.basedOn')}{' '}
                                  {dish.rateCount || 0} {t('form.field.rates')}
                                </h4>
                                {getStarRate(dish.rateAVG)}
                              </GridItem>
                            </GridContainer>
                          </div>
                        )}
                      </GridItem>
                      {isGranted(roles.ROLE_SHOW_MENU_PLANNING) &&
                        isEditing && (
                          <GridItem md={6}>
                            <h3>
                              {t('form.field.dishAppearedOnMenuPlanning')}
                            </h3>
                            <Divider />
                            {isEditing && (dish?.brands ?? []).length > 0 && (
                              <GridContainer>
                                <GridItem md={12}>
                                  <DataGrid
                                    disableTotal
                                    defaultSorted={[{ id: 'date', desc: true }]}
                                    filterable={false}
                                    sortable={false}
                                    paginationTop={true}
                                    paginationBottom={false}
                                    url="/menu-planners"
                                    columns={menuPlannerColumnConfig}
                                    role="MENU_PLANNER"
                                    minRows={5}
                                    customDefaultPageSize={10}
                                    showPageSizeOptions={false}
                                    manipulateQuery={(requestData, query) => {
                                      // query['draft'] = false;
                                      query['dish'] = match.params.id;
                                      query['selectInBrands'] = dish.brands.map(
                                        brand => brand['@id']
                                      );

                                      query['properties'] = [
                                        'date',
                                        'dish',
                                        'brand',
                                        'variant',
                                        'draft',
                                      ];
                                      query['properties[diet]'] = ['name'];
                                      query['properties[brand]'] = ['name'];
                                      query['properties[variant]'] = ['name'];

                                      return query;
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            )}
                          </GridItem>
                        )}

                      {isGranted(roles.ROLE_SHOW_RATE) &&
                        isEditing &&
                        (dish?.brands ?? []).length > 0 && (
                          <GridItem md={6}>
                            <h3>
                              {t('common.heading.comments', 'Komentarze')}
                            </h3>
                            <Divider />
                            <GridItem md={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    tabIndex={-1}
                                    name="rates"
                                    onClick={() =>
                                      setIsShowOnlyComments(!isShowOnlyComments)
                                    }
                                    checked={isShowOnlyComments}
                                    checkedIcon={
                                      <Check className={classes.checkedIcon} />
                                    }
                                    icon={
                                      <Check
                                        className={classes.uncheckedIcon}
                                      />
                                    }
                                    classes={{
                                      checked: classes.checked,
                                      root: classes.checkRoot,
                                    }}
                                  />
                                }
                                classes={{
                                  label: classes.label,
                                }}
                                label={t(
                                  'common.heading.comments.checkboxDescription',
                                  'Pokaż tylko oceny zawierające komentarze'
                                )}
                              />
                            </GridItem>
                            <GridContainer>
                              <GridItem md={12}>
                                {dish.brands.length && (
                                  <DataGrid
                                    key={isShowOnlyComments}
                                    disableTotal
                                    filterable={false}
                                    sortable={false}
                                    paginationTop={true}
                                    paginationBottom={false}
                                    url={`/client-dish-rates-view?dish.id=${dish.id}`}
                                    columns={commentsColumnConfig}
                                    role="RATE"
                                    minRows={5}
                                    customDefaultPageSize={5}
                                    showPageSizeOptions={true}
                                    manipulateQuery={(requestData, query) => {
                                      query._orX = [];
                                      if (isShowOnlyComments) {
                                        query['length_filter[gt][comment]'] = 1;
                                      }
                                      query['selectInBrands'] = dish.brands.map(
                                        brand => brand['@id']
                                      );

                                      return query;
                                    }}
                                  />
                                )}
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        )}
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              </form>
              <h3>{t('form.field.dishIngredients')}</h3>
              <Divider />
              <GridContainer>
                <GridItem md={3}>
                  <FormSelectDropdown
                    includeId
                    filterName="workName"
                    placeholder={
                      <div style={{ display: 'flex' }}>
                        <FormatListBulleted
                          style={{ marginRight: '5px' }}
                          fontSize="small"
                        />
                        <span>{t('form.field.addIngredient')}</span>
                      </div>
                    }
                    handleClick={addMeal}
                    clearAfterSelect={true}
                    filter={getFilteredIngredients}
                  />
                  <FormSelectDropdown
                    includeId
                    filterName="workName"
                    placeholder={
                      <div style={{ display: 'flex' }}>
                        <ListAlt
                          style={{ marginRight: '5px' }}
                          fontSize="small"
                        />
                        <span>{t('form.field.addRecipe')}</span>
                      </div>
                    }
                    handleClick={addMeal}
                    clearAfterSelect={true}
                    filter={getFilteredRecipes}
                  />
                  <div style={{ marginBottom: '5px' }}>
                    <FormLabel style={{ fontSize: '14px' }}>
                      {t('form.field.allergens')}
                    </FormLabel>
                  </div>
                  <TagsInput
                    tagProps={{
                      className:
                        'react-tagsinput-tag react-tagsinput-tag-small react-tagsinput-tag-disabled danger',
                    }}
                    inputProps={{ placeholder: '' }}
                    disabled
                    value={[
                      ...new Set(mapArrayOfTranslationsToPureArray(allergens)),
                    ]}
                  />
                </GridItem>
                <GridItem md={9}>
                  <p style={{ color: '#ff991f', 'text-align': 'right' }}>
                    {t('form.field.100gramInfo')}
                  </p>
                  <DishIngredients
                    data={selectedIngredients}
                    history={history}
                    handleRemove={handleRemove}
                    openToast={openToast}
                    classes={classes}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>

          <CardWrapper
            title={t(
              'mealTypes.copyMealToMeals.title',
              'Kopiowanie między posiłkami'
            )}
          >
            <CardBody>
              <CopyMealToMeals
                copyMealTypeFrom={copyMealTypeFrom}
                copyMealTypeTo={copyMealTypeTo}
                handleCopySize={handleCopySize}
                handleCopySizeChange={handleCopySizeChange}
                selectedBrandMealTypes={selectedBrandMealTypes}
              />
            </CardBody>
          </CardWrapper>

          <Card>
            <CardBody>
              {Object.values(groupedTypes).map((types, key) => (
                <div
                  style={{
                    position: 'sticky',
                    top: '20px',
                    margin: '-10px 20px 20px -15px',
                    display: 'inline-block',
                    padding: '5px 10px',
                    lineHeight: 'normal',
                    color: '#ffffff',
                    background:
                      key === activeTypes
                        ? 'RGB(0,172,193)'
                        : 'RGB(153,153,153)',
                    borderRadius: '0 5px 5px 0',
                    cursor: 'pointer',
                    zIndex: 2,
                  }}
                >
                  <h1
                    style={{
                      margin: 0,
                    }}
                    onClick={() => {
                      setActiveTypes(key);
                    }}
                  >
                    <b>{types.name.toUpperCase()}</b>
                  </h1>
                </div>
              ))}

              <Card>
                <CardBody>
                  {!groupedTypesArray.length ? (
                    <GridContainer>
                      <GridItem md={12}>
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            borderRadius: '20px',
                          }}
                        >
                          <h1>
                            {t(
                              'mealTypes.chooseMealType',
                              'Wybierz typy posiłków, aby konfigurować dania'
                            )}
                          </h1>
                        </div>
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <GridContainer>
                      <GridItem md={12}>
                        {sortByPosition(
                          groupedTypesArray[activeTypes].types
                        ).map((type, typeKey) => (
                          <TypeBlock
                            classes={classes}
                            key={typeKey}
                            blockId={`${groupedTypesArray[activeTypes]?.[
                              '@id'
                            ].replace('/brands/', '')}_${type.id}`}
                            type={type}
                            dish={dish}
                            dishSizes={dish.sizes}
                            selectedIngredients={selectedIngredients}
                            mealTypeIngredientsSum={mealTypeIngredientsSum}
                            onChange={handleQuantityChange}
                            containers={sortByDate(containers)}
                            ecoContainers={sortByDate(ecoContainers)}
                            handleContainerChange={handleContainerChange}
                            handleEcoContainerChange={handleEcoContainerChange}
                            handlePriceChange={handlePriceChange}
                            handleSizeShopAvailabilityChange={
                              handleSizeShopAvailabilityChange
                            }
                            handleSizeNameChange={handleSizeNameChange}
                            scaleDishSize={scaleDishSize}
                            setShouldRescale={setShouldRescale}
                            shouldRescale={shouldRescale}
                            checkedDishToResize={checkedDishToResize}
                            setDishToResize={setDishToResize}
                            selectedBrandMealTypes={selectedBrandMealTypes}
                            handleCopySizeChange={handleCopySizeChange}
                            handleCopySize={handleCopySize}
                            handleSizeShopAvailabilityChangeRow={
                              handleSizeShopAvailabilityChangeRow
                            }
                            copyMode={copyMode}
                          />
                        ))}
                      </GridItem>
                    </GridContainer>
                  )}
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      {!isLoading && !isEditing && (
        <FormControlStickyButton
          t={t}
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.saveAndClose')}
          cancelPath="/admin/meals"
          secondarySubmitText={t('form.save')}
          handleSubmit={e => handleSubmit(e, true)}
          handleSecondarySubmit={e => handleSubmit(e, false)}
        />
      )}
      {isEditing && (
        <LogView
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.saveAndClose')}
          cancelPath="/admin/meals"
          secondarySubmitText={t('form.save')}
          handleSubmit={e => handleSubmit(e, true)}
          handleSecondarySubmit={e => handleSubmit(e, false)}
          iri={`/dishes/${match.params.id}`}
        />
      )}
    </div>
  );
};

export default BasicMealForm;
