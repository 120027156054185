import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useDebounce from 'hooks/common/useDebounce';

import Checkbox from 'components/CustomCheckbox/Checkbox';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import FormControlLabel from '@material-ui/core/FormControlLabel';

const DietNameSection = ({ classes, diet, updateDiet }) => {
  const { t } = useTranslation();

  const [includeCustomDietName, setIncludeCustomDietName] = useState(false);
  const [dietName, setDietName] = useState('');
  const debouncedDietName = useDebounce(dietName);

  useEffect(() => {
    updateDiet({ ...diet, customDietName: debouncedDietName });
  }, [debouncedDietName]);

  useEffect(() => {
    if (!includeCustomDietName) {
      updateDiet({ ...diet, customDietName: null });
    }
  }, [includeCustomDietName]);
  return (
    <>
      <div data-cy="diet-details_diet-name">
        <FormControlLabel
          control={
            <Checkbox
              checked={includeCustomDietName}
              onClick={() => {
                setIncludeCustomDietName(!includeCustomDietName);
              }}
            />
          }
          label={t('orders.dietName', 'Nadaj "Własną nazwę" diecie"')}
        />
      </div>
      {includeCustomDietName && (
        <FormTextInput
          label={t(
            'orders.labelDietNameShowOnSite',
            'Nazwa widoczna będzie w panelu klienta i etykiecie na torbę'
          )}
          name="friendlyName"
          value={dietName}
          classes={classes}
          inputSize={12}
          maxLength={64}
          handleChange={e => setDietName(e.target.value)}
        />
      )}
    </>
  );
};

export default DietNameSection;
