import types from 'helpers/constants';
import React from 'react';

const initialState = {
  appIsLoading: 0,
  sweetAlerts: [],
};

function App(state = initialState, action) {
  switch (action.type) {
    case types.APP_FETCH_REQUEST:
      return {
        ...state,
        appIsLoading: (state.appIsLoading += 1),
      };
    case types.APP_FETCH_SUCCESS:
      return {
        ...state,
        appIsLoading: (state.appIsLoading -= 1),
      };
    case types.APP_FETCH_FAILURE:
      return {
        ...state,
        appIsLoading: (state.appIsLoading -= 1),
      };
    case types.APP_FETCH_RESET:
      return {
        ...state,
        appIsLoading: 0,
      };
    case types.APP_OPEN_SWEET_TOAST:
      return {
        ...state,
        sweetAlerts: [
          ...state.sweetAlerts.filter(
            el =>
              typeof el.content === 'undefined' ||
              React.isValidElement(el.content)
          ),
          action.payload,
        ],
      };
    case types.APP_CLOSE_ALL_TOASTS:
      return {
        ...state,
        sweetAlerts: [],
      };
    case types.APP_CLOSE_TOAST:
      return {
        ...state,
        sweetAlerts: state.sweetAlerts.filter(
          el => el.key !== action.payload.key
        ),
      };
    default:
      return state;
  }
}

export default App;
