import { get, remove } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchMealSizes = () => {
  return dispatch => {
    return get('/sizes', { pagination: false }).then(response => {
      dispatch({
        type: types.FETCH_MEALSIZES,
        payload: {
          mealSizes: response['hydra:member'],
        },
      });
      return response['hydra:member'];
    });
  };
};

export const fetchMealSize = id => {
  return dispatch => {
    return get(`/sizes/${id}`).then(response => {
      dispatch({
        type: types.FETCH_MEALSIZE,
        payload: {
          name: response.name,
          position: response.position,
        },
      });
      return response;
    });
  };
};

export const removeMealSize = id => {
  return dispatch =>
    remove(`/sizes/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_MEALSIZE,
        payload: {
          mealSizeId: id,
        },
      });
    });
};
