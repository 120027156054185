import React from 'react';
import Select from 'react-select';
import { grayColor } from 'assets/jss/material-dashboard-pro-react.jsx';
import { useSelector } from 'react-redux';

import { getHexOfThemeColor } from 'helpers/themeHelpers';

const SelectInput = ({
  label,
  name,
  value,
  options = [],
  multiple,
  isDisabled = false,
  customStyle = {},
  handleChange,
  isClearable,
} = {}) => {
  const { mainColor } = useSelector(({ Theme: { mainColor } }) => ({
    mainColor,
  }));

  const handleSelectChange = selected => handleChange(selected);

  return (
    <Select
      name={name}
      value={value}
      isMulti={multiple}
      options={options ?? []}
      onChange={handleSelectChange}
      placeholder={label}
      isClearable={isClearable}
      isDisabled={isDisabled}
      styles={{
        container: baseStyle => ({ ...baseStyle, ...customStyle }),
        control: (baseStyles, state) => {
          return {
            ...baseStyles,
            cursor: 'pointer',
            borderColor: state.isFocused
              ? getHexOfThemeColor(mainColor)
              : grayColor[4],
            boxShadow:
              state.isFocused && `0 0 0 1px ${getHexOfThemeColor(mainColor)}`,
            '&:hover': {
              borderColor: getHexOfThemeColor(mainColor),
            },
          };
        },
        menu: (baseStyles, state) => {
          return {
            ...baseStyles,
            zIndex: 9999999999,
          };
        },
        option: (baseStyles, state) => {
          return {
            ...baseStyles,
            cursor: 'pointer',
          };
        },
      }}
    />
  );
};

export default SelectInput;
