import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';

import { combineStyles } from 'helpers/helpers';
import { get } from 'helpers/apiHelpers';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import { withTranslation } from 'react-i18next';
import CardBody from 'components/Card/CardBody';
import CardWrapper from 'components/Card/CardWrapper';

class HoverSettings extends Component {
  componentDidMount = async () => {
    const currentSettings = await get(
      `/brands/${this.props.selectedBrand}/hover`
    );

    const { title, image, active, description } = currentSettings.hover;
    this.props.setState({
      title: title ? title : '',
      image: image || null,
      active: active ? active : false,
      imageUrl: image?.contentUrl || null,
      description: description ? description : '',
    });
  };

  handleInputChange = (event, value) => {
    const newValue = event.target.value;
    this.props.setState({
      ...this.props.state,
      [event.target.name]: value ? value : newValue,
    });
  };

  getImage = (stateName, data) => {
    this.props.setState({
      ...this.props.state,
      [stateName]: data || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    });
  };

  removeImage = stateName => {
    this.props.setState({
      ...this.props.state,
      [stateName]: null,
      [`${stateName}Url`]: null,
    });
  };

  handleToggle = e => {
    this.props.setState({
      ...this.props.state,
      [e.target.name]: !this.props.state[e.target.name],
    });
  };

  validateForm = () => {
    return this.props.state.title;
  };

  render() {
    const { classes } = this.props;
    const { title, active, imageUrl, description } = this.props.state;

    return (
      <CardWrapper
        title={this.props.t(
          'brandCfg.clientPanelDesign.hoverView',
          '$$Konfiguracja - Hover'
        )}
      >
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <>
                <FormTextInput
                  label={this.props.t('form.title') + '*'}
                  classes={classes}
                  name="title"
                  value={title}
                  handleChange={this.handleInputChange}
                  inputSize={6}
                />

                <FormTextInput
                  multiline
                  rows={3}
                  rowsMax={10}
                  label={
                    <div style={{ display: 'flex' }}>
                      <span>{this.props.t('form.field.description')}</span>
                      <div
                        style={{ marginLeft: '10px', display: 'inline-block' }}
                      >
                        <Tooltip
                          title={
                            <div>
                              <h4>
                                {this.props.t('form.field.descriptionTooltip')}
                              </h4>
                              <h4>
                                {this.props.t('common.shared.etc')}{' '}
                                {`<div style="margin: 30px; max-width: 50%">${this.props.t(
                                  'form.menuPlanning.exampleTitleText'
                                )}</div>
`}
                              </h4>
                            </div>
                          }
                          placement="right"
                        >
                          <Info fontSize={'small'} />
                        </Tooltip>
                      </div>
                    </div>
                  }
                  classes={classes}
                  name="description"
                  value={description}
                  handleChange={this.handleInputChange}
                  inputSize={10}
                  maxLength={160}
                />
                <GridContainer>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      {this.props.t('form.field.active.label')}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          name="active"
                          onClick={this.handleToggle}
                          checked={active}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={''}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ marginTop: '20px' }}>
                  <GridItem xs={12} sm={2}>
                    <FormLabel className={classes.labelHorizontal}>
                      {this.props.t('form.field.photo')}
                    </FormLabel>
                  </GridItem>
                  <GridItem xs={12} sm={10} style={{ marginLeft: '-15px' }}>
                    <FormImageUpload
                      classes={classes}
                      stateName="image"
                      getImage={this.getImage}
                      removeImage={this.removeImage}
                      previewUrl={imageUrl}
                    />
                  </GridItem>
                </GridContainer>
              </>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => {
  return {
    roles: state.Auth.user.roles,
    selectedBrand: state.Auth.selectedBrand,
  };
};

export default withTranslation()(
  connect(
    mapStateToProps,
    null
  )(withToast(withStyles(combinedStyles)(HoverSettings)))
);
