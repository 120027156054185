import React, { useEffect } from 'react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { shallowEqual, useSelector } from 'react-redux';
import { useReducer } from 'react';

import ru from 'react-phone-number-input/locale/ru';
import fi from 'react-phone-number-input/locale/fi';
import en from 'react-phone-number-input/locale/en';
import pl from 'react-phone-number-input/locale/pl';
import it from 'react-phone-number-input/locale/it';
import de from 'react-phone-number-input/locale/de';
import ua from 'react-phone-number-input/locale/ua';
import es from 'react-phone-number-input/locale/es';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import {
  isValidPhoneNumber as isValiPhoneNumberMin,
  parsePhoneNumber,
} from 'react-phone-number-input';

import { isValidPhoneNumber as isValiPhoneNumberMobile } from 'react-phone-number-input/mobile';

const _labels = {
  ru,
  fi,
  en,
  pl,
  it,
  de,
  ua,
  es,
};

const getLabels = language => {
  const _lang = language.toLowerCase();
  if (Object.keys(_labels).includes(_lang)) {
    return _labels[_lang];
  } else {
    return _labels['en'];
  }
};

export const ReactPhoneNumberAdapter = ({
  value,
  name,
  // input,
  // meta,
  width,
  margin,
  label,
  required,
  ...rest
}) => {
  // const { error, touched } = meta;
  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { multinational } = useSelector(
    ({
      Brands: {
        brand: { multinational },
      },
    }) => ({
      multinational,
    }),
    shallowEqual
  );

  const validateNumberInRegion = (number, countryCode) => {
    if (
      required &&
      (!number || (typeof number === 'string' && number.length === 0))
    ) {
      return t('formValidation.required', 'Pole jest wymagane');
    }

    if (typeof number !== 'string') {
      return null;
    }

    const parsedValue = parsePhoneNumber(number);
    const countryEquals =
      parsedValue?.country && parsedValue?.country === countryCode;

    const isValid =
      parsedValue?.country === 'PL'
        ? isValiPhoneNumberMobile(number)
        : isValiPhoneNumberMin(number);

    return countryEquals && isValid
      ? undefined
      : t(
          'formValidation.phoneInvalidInRegion',
          'Telefon nieprawidłowy w wybranym regionie'
        );
  };

  const initReducer = arg => {
    const initialComputedValue =
      arg && arg.countryCode
        ? {
            _internalValue: {
              number: arg.number,
              countryCode: arg.countryCode,
            },
            touched: false,
            error: null,
          }
        : {
            _internalValue: {
              number: '',
              countryCode: multinational.defaultRegion,
            },
            touched: false,
            error: null,
          };

    return initialComputedValue;
  };

  const [_val, dispatch] = useReducer(
    (state, action) => {
      switch (action.type) {
        case 'NUMBER':
          return {
            ...state,
            _internalValue: { ...state._internalValue, number: action.payload },
            touched: true,
            error: validateNumberInRegion(
              action.payload,
              state._internalValue.countryCode
            ),
          };
        case 'COUNTRY':
          return {
            ...state,
            _internalValue: {
              ...state._internalValue,
              countryCode: action.payload,
            },
            touched: true,
            error: validateNumberInRegion(
              state._internalValue.number,
              action.payload
            ),
          };
        case 'REINITIALIZE':
          return initReducer(action.payload);

        default:
          return state;
      }
    },
    value,
    initReducer
  );

  useEffect(() => {
    if (!shallowEqual(value, _val._internalValue)) {
      dispatch({ type: 'REINITIALIZE', payload: value });
    }
  }, [value.number, value.countryCode]);

  const onCountryChange = countryCode => {
    if (countryCode !== _val._internalValue.countryCode) {
      dispatch({ type: 'COUNTRY', payload: countryCode });
    }
  };

  const onNumberChange = number => {
    if (number !== _val._internalValue.number) {
      dispatch({ type: 'NUMBER', payload: number });
    }
  };

  const { onChange } = rest;
  useEffect(() => {
    onChange({ target: { name, value: _val._internalValue } });
  }, [onChange, _val._internalValue]);

  return (
    multinational && (
      <PhoneInput
        data-cy="my-phone-input"
        international
        withCountryCallingCode
        initialValueFormat
        error={_val.error && _val.touched}
        labels={getLabels(language)}
        label={label}
        value={_val._internalValue.number}
        onChange={onNumberChange}
        country={_val._internalValue.countryCode}
        defaultCountry={multinational.defaultRegion}
        onCountryChange={onCountryChange}
        countryCallingCodeEditable={false}
        inputComponent={MuiTextFieldAdapter}
        helperText={_val.error}
        style={{ paddingRight: '10px' }}
      ></PhoneInput>
    )
  );
};

const MuiTextFieldAdapter = React.forwardRef((props, ref) => {
  const _change = (e, a) => {
    props.onChange(e);
  };

  return <TextField {...props} onChange={_change} inputRef={ref} />;
});
