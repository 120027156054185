import React from 'react';
import roles from 'helpers/roles';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import axios from 'helpers/gastro';
//styles
import { combineStyles, getNumberFromString, isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import CircularProgress from '@material-ui/core/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { Dialog, DialogContent } from '@material-ui/core';
import AccessDenied from '../../components/Security/AccessDenied';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import CardWrapper from 'components/Card/CardWrapper';

class ReportView extends React.Component {
  state = {
    selectedBrands: [],
    dateFrom: '',
    dateTo: '',
    isGenerating: false,
  };

  url = 'reports/waste';

  getFileName = getReportFileName(this.props.t('common.waste.generateReport'));

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  validateDate = () => {
    return this.state.dateFrom && this.state.dateTo;
  };

  validateBrands = () => {
    return this.state.selectedBrands.length !== 0;
  };

  handleGenerate = (fileType, title) => {
    const { t } = this.props;
    this.setState({ isGenerating: true });
    if (!this.validateDate()) {
      this.setState({ isGenerating: false });
      return this.props.openToast({
        messages: [
          t(
            'reports.selectDates',
            'Wybierz daty, dla których chcesz wygenerować raport'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
    // if (!this.validateBrands()) {
    //   this.setState({isGenerating: false})
    //   return this.props.openToast({
    //     messages: ['Wybierz przynajmniej jedną markę'],
    //     type: 'error',
    //     autoHideDuration: 3000
    //   })
    // }

    const params = {
      from: this.state.dateFrom,
      to: this.state.dateTo,
      brandIds: this.state.selectedBrands
        .filter(brand => brand.value !== '*')
        .map(brand => {
          return +getNumberFromString(brand.value);
        }),
    };

    axios
      .get(fileType === 'xlsx' ? this.url : this.url + '-pdf', {
        responseType: 'blob',
        params: params,
        headers: {
          accept:
            fileType === 'xlsx'
              ? 'application/vnd.ms-excel'
              : 'application/pdf',
        },
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              `${this.getFileName(this.state)}.${
                fileType === 'xlsx' ? 'xlsx' : 'pdf'
              }`
            );
            document.body.appendChild(link);
            link.click();
            this.setState({ isGenerating: false });
          } else {
            // translate
            alert(
              'Nie posiadasz uprawnień do wygenerowania tego raportu. Skontaktuj się z administratorem.'
            );
            this.setState({ isGenerating: false });
          }
        },
        error => {
          this.props.openToast({
            messages: [
              t('reports.cannotGenerate', 'Nie udało się wygenerować raportu'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
          this.setState({ isGenerating: false });
        }
      )
      .finally(() => {
        this.setState({ isGenerating: false });
      });
  };

  render() {
    const { classes } = this.props;
    const title = this.props.t('common.waste.generateReport');

    return isGranted(roles.ROLE_SHOW_REPORT_WASTE) ? (
      <CardWrapper title={title}>
        <Dialog open={this.state.isGenerating}>
          <DialogContent>
            <div style={{ textAlign: 'center' }}>
              <h1>
                {this.props.t('reports.generate', 'Trwa generowanie raportu')}
              </h1>
              <CircularProgress />
            </div>
          </DialogContent>
        </Dialog>
        <CardBody>
          <GridContainer justify="flex-end">
            <GridItem sm={6}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '5px' }}
              >
                {this.props.t('common.waste.selectDateStart') + '*'}
              </FormLabel>
              <FormControl fullWidth>
                <Datetime
                  isValidDate={isDatepickerValidDay}
                  timeFormat={false}
                  closeOnSelect={true}
                  value={this.state.dateFrom}
                  onChange={ev =>
                    this.setState({ dateFrom: ev.format('YYYY-MM-DD') })
                  }
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem sm={6}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '5px' }}
              >
                {this.props.t('common.waste.selectDateEnd') + '*'}
              </FormLabel>
              <FormControl fullWidth>
                <Datetime
                  isValidDate={isDatepickerValidDay}
                  timeFormat={false}
                  closeOnSelect={true}
                  value={this.state.dateTo}
                  onChange={ev =>
                    this.setState({ dateTo: ev.format('YYYY-MM-DD') })
                  }
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem>
              <Button
                onClick={() => this.handleGenerate('pdf', title)}
                disabled={this.state.isGenerating}
                color={'success'}
                round
              >
                {this.props.t('common.shared.generatePDF')}
              </Button>
              <Button
                style={{ marginLeft: '15px' }}
                onClick={() => this.handleGenerate('xlsx', title)}
                disabled={this.state.isGenerating}
                color={'success'}
                round
              >
                {this.props.t('common.shared.generateXSLX')}
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </CardWrapper>
    ) : (
      <AccessDenied />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  brands: state.Auth.user.brands,
});

export default connect(
  mapStateToProps,
  null
)(withToast(withStyles(combinedStyles)(withTranslation()(ReportView))));
