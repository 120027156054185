import React, { Component } from 'react';
import _ from 'lodash';

import { combineStyles, isGranted } from 'helpers/helpers';
import { get, post, put } from 'helpers/apiHelpers';
import { ROLE_EDIT_COST } from 'helpers/roles';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import GridItem from 'components/Grid/GridItem';
import LogView from 'components/History/LogView';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { withToast } from 'material-ui-toast-redux';
import AdditionalPricesRow from './AdditionalPricesRow';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';

class AdditionalPrices extends Component {
  state = {
    additionalPrices: [],
    isLoading: true,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    Promise.all([
      get('/additional-price-types', { pagination: false }),
      get('/additional-prices', { pagination: false }),
    ]).then(([additionalPriceTypesResponse, additionalPricesResponse]) => {
      const additionLPrices = additionalPricesResponse['hydra:member'];
      const additionalPriceTypes = additionalPriceTypesResponse[
        'hydra:member'
      ].map(el => {
        return {
          ...el,
          id: _.get(
            additionLPrices.find(e => e.additionalPriceType === el['@id']),
            '@id',
            null
          ),
          additionalPriceType: el['@id'],
          discount: _.get(
            additionLPrices.find(e => e.additionalPriceType === el['@id']),
            'discount',
            ''
          ),
          vatRate: _.get(
            additionLPrices.find(e => e.additionalPriceType === el['@id']),
            'vatRate',
            null
          ),
          isPercentage: _.get(
            additionLPrices.find(e => e.additionalPriceType === el['@id']),
            'isPercentage',
            false
          ),
        };
      });

      this.setState({
        additionalPrices: additionalPriceTypes,
        isLoading: false,
      });
    });
  };

  findCurrentPriceIndex = id =>
    this.state.additionalPrices.findIndex(point => point['@id'] === id);

  handleChange = (event, point) => {
    const { name, value } = event.target;
    const { additionalPrices } = this.state;

    const index = this.findCurrentPriceIndex(point['@id']);
    additionalPrices[index][name] = value;

    this.setState({ additionalPrices });
  };

  saveRow = point => {
    const { t } = this.props;
    const index = this.findCurrentPriceIndex(point['@id']);
    const currentEl = this.state.additionalPrices[index];

    if (currentEl.discount === '') {
      return this.props.openToast({
        messages: [this.props.t('common.shared.fillFeeField')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    if (isNaN(currentEl.discount)) {
      return this.props.openToast({
        messages: [this.props.t('common.shared.feeFieldMustBeNumber')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const postData = {
      discount: parseFloat(currentEl.discount),
      vatRate: parseInt(currentEl.vatRate),
      isPercentage: currentEl.isPercentage,
      additionalPriceType: currentEl.additionalPriceType,
    };

    const action = currentEl.id ? put : post;
    const url = currentEl.id ? currentEl.id : '/additional-prices';

    action(url, postData).then(() => {
      this.getData();
      this.props.openToast({
        messages: [t('success.chargeSaved', 'Udało się zapisać opłatę')],
        type: 'success',
        autoHideDuration: 3000,
      });
    });
  };

  render() {
    const { classes } = this.props;
    const { additionalPrices } = this.state;
    return (
      <form
        style={{
          opacity: this.state.isLoading ? 0 : 1,
          transition: '0.3s all',
        }}
      >
        {additionalPrices.length === 0 ? null : (
          <Card>
            <CardBody>
              <GridItem md={12}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        {this.props.t('common.shared.nameOfTheFee')}
                      </TableCell>
                      <TableCell>
                        {this.props.t('common.shared.feeType')}
                      </TableCell>
                      <TableCell>
                        {this.props.t('common.shared.feePerDay')}
                      </TableCell>
                      <TableCell>
                        {this.props.t('common.shared.vat')}
                      </TableCell>
                      {isGranted(ROLE_EDIT_COST) && <TableCell />}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {additionalPrices.map((point, index) => (
                      <AdditionalPricesRow
                        key={point['@id']}
                        point={point}
                        classes={classes}
                        handleChange={this.handleChange}
                        saveRow={this.saveRow}
                      />
                    ))}
                  </TableBody>
                </Table>
              </GridItem>
            </CardBody>
          </Card>
        )}
        <LogView iri={`/additional-prices`} />
      </form>
    );
  }
}

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle
);

const enhance = compose(
  withStyles(combinedStyles),
  withToast,
  withTranslation()
);

export default enhance(AdditionalPrices);
