import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import { get } from '../../../helpers/apiHelpers';

import { currencyToSymbol } from 'utils/currencies';

import AttachMoney from '@material-ui/icons/AttachMoney';

import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

const Dashboard = ({ classes, currency, selectedBrand }) => {
  const { t } = useTranslation();
  const [income, setIncome] = useState(0);

  useEffect(() => {
    getIncome();
  }, []);

  const getIncome = () => {
    try {
      get(`statistics/ecommerce/income/day`, {
        brandId: [selectedBrand],
        date: Moment(new Date()).format('YYYY-MM-DD'),
      }).then(({ value }) => setIncome(value.toFixed(2)));
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  return (
    <Card>
      <CardHeader color="warning" quickStats icon>
        <CardIcon color="warning">
          <AttachMoney />
        </CardIcon>
        <div>
          <h3 className={classes.quickStatsTitle}>{t('dashboard.income')}</h3>
          <p className={classes.quickStatsValue}>{`${income} ${currencyToSymbol(
            currency
          )}`}</p>
        </div>
      </CardHeader>
      <CardFooter stats>
        <p className={classes.quickStatsDescription}>
          {t('dashboard.incomeDescription')}
        </p>
      </CardFooter>
    </Card>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    selectedBrand: state.Auth.selectedBrand,
    currency: state.Brands.brand.multinational.currency,
  };
};

export default connect(
  mapStateToProps,
  null
)(withStyles(dashboardStyle)(Dashboard));
