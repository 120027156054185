import React from 'react';
import { connect } from 'react-redux';
import AdminTable from 'layouts/AdminTable';
import Moment from 'moment';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from 'components/CustomButtons/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { fetchMealTypes } from 'actions/MealTypes';
import { fetchVariants } from 'actions/Variants';
import { fetchDiets } from 'actions/Diets';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';

import SummaryContent from './SummaryContent';
import axios from 'helpers/gastro';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { isGranted } from '../../../helpers/helpers';
import { ROLE_SHOW_MENU_SUMMARY } from '../../../helpers/roles';

class Summary extends React.Component {
  state = {
    date: new Moment().startOf('week'),
    variant: '',
    lockedExport: false,
    changeHistory: false,
  };

  async componentDidMount() {
    await Promise.all([
      this.props.fetchMealTypes(),
      this.props.fetchVariants(),
      this.props.fetchDiets(),
    ]);

    if (typeof this.props.match.params.date !== 'undefined') {
      await this.setState(prevState => {
        return {
          ...prevState,
          date: new Moment(this.props.match.params.date).startOf('week'),
        };
      });
    }

    if (typeof this.props.match.params.variant !== 'undefined') {
      const variant = this.props.variants.find(
        variant => variant.id === this.props.match.params.variant
      );
      if (variant) {
        await this.setState(prevState => {
          return {
            ...prevState,
            variant,
          };
        });
      }
    }

    if (this.state.changeHistory) {
      this.props.history.replace(
        `/admin/menu-summary/${this.state.date.format('YYYY-MM-DD')}/${
          this.state?.variant?.id
        }`
      );
      this.setState({
        changeHistory: false,
      });
    }
  }

  getLastDay = () => {
    return new Moment(this.state.date.format('YYYY-MM-DD')).endOf('week');
  };

  getArrayOfDays = () => {
    let array = [];

    array.push(this.state.date.format('YYYY-MM-DD'));
    for (let i = 1; i < 7; i++) {
      const date = new Moment(this.state.date.format('YYYY-MM-DD')).add(
        'days',
        i
      );
      const key = date.locale('en').format('dddd').toLowerCase();

      if (this.props.brand[key] !== 0) {
        array.push(date.format('YYYY-MM-DD'));
      }
    }

    return array;
  };

  async modifyDate(add = true) {
    await this.setState(prevState => {
      return {
        ...prevState,
        date: prevState.date.add(add ? 7 : -7, 'days'),
      };
    });
    this.props.match.params.date = this.state.date.format('YYYY-MM-DD');
    if (this.state.variant !== '') {
      this.props.history.replace(
        `/admin/menu-summary/${this.state.date.format('YYYY-MM-DD')}/${
          this.state.variant.id
        }`
      );
    } else {
      this.props.history.replace(
        `/admin/menu-summary/${this.state.date.format('YYYY-MM-DD')}`
      );
    }
  }

  handleSelectChange = (event, value) => {
    this.setState(() => {
      return {
        variant: value,
        changeHistory: value,
      };
    });
  };

  exportToPDF = date => {
    const { t } = this.props;
    this.setState(prevState => ({ ...prevState, lockedExport: true }));

    const params = {
      'date[after]': this.getArrayOfDays()[0],
      'date[before]': this.getArrayOfDays()[6],
      variant: this.state.variant.id,
    };

    axios
      .get('reports/menu-planner', {
        responseType: 'blob',
        params: params,
        headers: { accept: 'application/pdf' },
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Planowanie-menu-${this.getArrayOfDays()[0]}-${
              this.getArrayOfDays()[6]
            }_(${moment().format('YYYY-MM-DD_HH.mm')}).pdf`
          );
          document.body.appendChild(link);
          link.click();
          this.setState(prevState => ({ ...prevState, lockedExport: false }));
        },
        error => {
          this.props.openToast({
            messages: [
              t('reports.cannotGenerate', 'Nie udało się wygenerować raportu'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
          this.setState(prevState => ({ ...prevState, lockedExport: false }));
        }
      );
  };
  exportToXLSX = date => {
    const { t } = this.props;
    this.setState(prevState => ({ ...prevState, lockedExport: true }));

    const params = {
      'date[after]': this.getArrayOfDays()[0],
      'date[before]': this.getArrayOfDays()[6],
      variant: this.state.variant.id,
      weekView: true,
    };

    axios
      .get('reports/menu-planner', {
        responseType: 'blob',
        params: params,
        headers: { accept: 'application/vnd.ms-excel' },
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            `Planowanie-menu-${this.getArrayOfDays()[0]}-${
              this.getArrayOfDays()[6]
            }_(${moment().format('YYYY-MM-DD_HH.mm')}).xlsx`
          );
          document.body.appendChild(link);
          link.click();
          this.setState(prevState => ({ ...prevState, lockedExport: false }));
        },
        error => {
          this.props.openToast({
            messages: [
              t('reports.cannotGenerate', 'Nie udało się wygenerować raportu'),
              error.response.data['hydra:description'],
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
          this.setState(prevState => ({ ...prevState, lockedExport: false }));
        }
      );
  };

  render() {
    let { variants: givenVariants, diets, classes } = this.props;

    const filteredDiets = this.state.variant
      ? diets.filter(diet => this.state.variant.diets.includes(diet['@id']))
      : [];

    return isGranted(ROLE_SHOW_MENU_SUMMARY) ? (
      <AdminTable title={this.props.t('form.menuPlanning.menuSummary')}>
        <GridContainer>
          <GridItem md={4} style={{ textAlign: 'right' }}>
            <Button
              color="info"
              round
              onClick={() => {
                this.modifyDate(false);
              }}
              disabled={this.state.loading}
            >
              <KeyboardArrowLeft /> {this.props.t('form.menuPlanning.prevWeek')}
            </Button>
          </GridItem>
          <GridItem md={4} style={{ textAlign: 'center' }}>
            {this.state.loading && (
              <GridItem md={12} style={{ textAlign: 'center' }}>
                <CircularProgress />
              </GridItem>
            )}
            {!this.state.loading && (
              <h4>
                {this.state.date.format('DD')}-{this.getLastDay().format('DD')}{' '}
                {this.getLastDay().format('MMMM')}{' '}
                {this.getLastDay().format('YYYY')}
              </h4>
            )}
            <FormSelectSingle
              classes={classes}
              options={givenVariants.map(v => {
                if (!v.active) {
                  v.displayName =
                    v.name + this.props.t('form.menuPlanning.inactive');
                } else {
                  v.displayName = v.name;
                }

                return v;
              })}
              mapBy="displayName"
              trackBy="@id"
              value={this.state.variant}
              handleChange={this.handleSelectChange}
              label={this.props.t('form.menuPlanning.selectVariant')}
            />
          </GridItem>
          <GridItem md={4} style={{ textAlign: 'left' }}>
            <Button
              color="info"
              round
              onClick={() => {
                this.modifyDate(true);
              }}
              disabled={this.state.loading}
            >
              {this.props.t('form.menuPlanning.nextWeek')}
              <KeyboardArrowRight />
            </Button>
          </GridItem>
        </GridContainer>
        {filteredDiets.length > 0 && !this.state.loading && (
          <GridContainer>
            <GridItem md={12} style={{ textAlign: 'center' }}>
              {this.state.lockedExport && (
                <GridItem md={12} style={{ textAlign: 'center' }}>
                  <CircularProgress />
                </GridItem>
              )}
              {!this.state.lockedExport && (
                <Button
                  color="primary"
                  round
                  onClick={() => {
                    this.exportToPDF();
                  }}
                  disabled={this.state.loading}
                >
                  {this.props.t('form.menuPlanning.saveWeekPdf')}
                </Button>
              )}
              {!this.state.lockedExport && (
                <Button
                  color="primary"
                  round
                  onClick={() => {
                    this.exportToXLSX();
                  }}
                  disabled={this.state.loading}
                >
                  {this.props.t('form.menuPlanning.saveWeekExcel')}
                </Button>
              )}
            </GridItem>
          </GridContainer>
        )}
        <div style={{ overflow: 'auto' }}>
          <SummaryContent
            date={this.state.date}
            variant={this.state.variant}
            filteredDiets={filteredDiets}
            dates={this.getArrayOfDays()}
            loading={this.state.loading}
          />
        </div>
      </AdminTable>
    ) : null;
  }
}

const mapStateToProps = state => {
  return {
    variants: state.Variants.variants,
    diets: state.Diets.diets,
    brand: state.Brands.brand,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchMealTypes: () => dispatch(fetchMealTypes()),
  fetchVariants: () => dispatch(fetchVariants()),
  fetchDiets: () => dispatch(fetchDiets()),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(buttonsStyle)(Summary))
);
