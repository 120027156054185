import React, { Component } from 'react';
import AdminTable from 'layouts/AdminTable';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { fetchBrandsList } from 'actions/Brands';
import { fetchRoles } from 'actions/Roles';
import { getMyLinks } from 'actions/Auth';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';

import { post, put, get } from 'helpers/apiHelpers';

import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import defaultState from './defaultState';
import SelectInput from '../../components/FormSelect/SelectInput';
import LogView from '../../components/History/LogView';
import produce from 'immer';
import FormControlStickyButton from 'components/FormControlStickyButton/FormControlStickyButton';

class RolesForm extends Component {
  state = {
    form: defaultState(this.props.custom),
    errors: {},
  };

  isEdit = this.props.match?.params?.id || false;
  isCustom = this.props.custom === true;

  adminPath = '/admin-nav-links';
  customPath = '/my-links';
  path = this.isCustom ? this.customPath : this.adminPath;

  componentDidMount() {
    if (!this.isCustom) {
      this.props.fetchBrandsList();
      this.props.fetchRoles();
    }
    if (this.isEdit) {
      this.fetchEditedRecord();
    }
  }

  fetchEditedRecord = () => {
    let state = defaultState(this.isCustom);
    get(`${this.adminPath}/${this.props.match.params.id}`).then(record => {
      Object.keys(state).forEach(field => {
        if (Array.isArray(record[field])) {
          state[field] = record[field].map(el => el['@id']);
        } else {
          state[field] = record[field];
        }
      });
      this.setState(prevState => ({ ...prevState, form: state }));
    });
  };

  handleInputChange = ({ target: { name, value } }) =>
    this.setState(
      produce(draft => {
        draft.form[name] = value;
      })
    );

  setErrors = violations => {
    let errors = violations.reduce(
      (prev, { propertyPath, message }) => ({
        ...prev,
        [propertyPath]: message,
      }),
      {}
    );

    this.setState(prevState => ({ ...prevState, errors: errors }));
  };

  handleSubmit = () => {
    this.setErrors([]);
    const data = {
      ...this.state.form,
      priority: parseInt(this.state.form.priority),
    };
    const resourceURL = this.isEdit
      ? `${this.adminPath}/${this.props.match.params.id}`
      : this.isCustom
      ? this.customPath
      : this.adminPath;

    const action = this.isEdit
      ? put(resourceURL, data)
      : post(resourceURL, data);

    action
      .then(() => {
        this.props.getMyLinks();
        this.props.history.push(`/admin${this.path}`);
      })
      .catch(err => {
        this.setErrors(err?.response?.data?.violations || []);
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <form>
        <h2>{this.props.t('navLinks.create')}</h2>
        <AdminTable>
          <GridContainer>
            <GridItem sm={2}>
              <FormTextInput
                label={this.props.t('navLinks.fields.symbol') + '*'}
                classes={classes}
                name="symbol"
                value={this.state.form.symbol}
                handleChange={this.handleInputChange}
                inputSize={12}
                errors={this.state.errors}
              />
            </GridItem>
            <GridItem sm={4}>
              <FormTextInput
                label={this.props.t('navLinks.fields.label') + '*'}
                classes={classes}
                name="label"
                value={this.state.form.label}
                handleChange={this.handleInputChange}
                inputSize={12}
                errors={this.state.errors}
              />
            </GridItem>
            <GridItem sm={4}>
              <FormTextInput
                label={this.props.t('navLinks.fields.link') + '*'}
                tooltip={this.props.t('navLinks.fields.linkTooltip')}
                classes={classes}
                name="link"
                value={this.state.form.link}
                handleChange={this.handleInputChange}
                inputSize={12}
                type="url"
                errors={this.state.errors}
              />
            </GridItem>
            <GridItem sm={2}>
              <FormTextInput
                label={this.props.t('navLinks.fields.priority') + '*'}
                tooltip={this.props.t('navLinks.fields.tooltip')}
                classes={classes}
                name="priority"
                value={this.state.form.priority}
                handleChange={this.handleInputChange}
                inputSize={12}
                type="number"
                errors={this.state.errors}
              />
            </GridItem>
            {!this.isCustom && (
              <>
                <GridItem sm={6}>
                  <SelectInput
                    classes={classes}
                    multiple={true}
                    label={this.props.t('navLinks.fields.brands')}
                    tooltip={this.props.t('navLinks.fields.brandsTooltip')}
                    mapBy="name"
                    trackBy="@id"
                    name="brands"
                    value={this.state.form.brands}
                    options={this.props.brands}
                    handleChange={this.handleInputChange}
                    id="brands"
                    size={12}
                  />
                </GridItem>
                <GridItem sm={6}>
                  <SelectInput
                    classes={classes}
                    multiple={true}
                    label={this.props.t('navLinks.fields.roles')}
                    tooltip={this.props.t('navLinks.fields.rolesTooltip')}
                    mapBy="name"
                    trackBy="@id"
                    name="allowedRoles"
                    value={this.state.form.allowedRoles}
                    options={this.props.roles}
                    handleChange={this.handleInputChange}
                    id="brands"
                    size={12}
                  />
                </GridItem>
              </>
            )}
          </GridContainer>
        </AdminTable>

        {!this.isEdit && (
          <FormControlStickyButton
            t={this.props.t}
            classes={classes}
            discardText={this.props.t('form.cancel')}
            submitText={this.props.t('form.save')}
            cancelPath={`/admin${this.path}`}
            handleSubmit={this.handleSubmit}
            history={false}
          />
        )}
        {this.isEdit && !this.isCustom && (
          <LogView
            classes={classes}
            discardText={this.props.t('form.cancel')}
            submitText={this.props.t('form.save')}
            cancelPath={`/admin${this.path}`}
            handleSubmit={this.handleSubmit}
            iri={`/admin-nav-links/${this.props.match.params.id}`}
          />
        )}
      </form>
    );
  }
}

const combinedStyles = combineStyles(buttonsStyle, extendedFormsStyle);

const mapStateToProps = state => ({
  brands: state.Brands.brandsList,
  roles: state.Roles.roles,
});

const mapDispatchToProps = dispatch => ({
  fetchBrandsList: () => dispatch(fetchBrandsList()),
  fetchRoles: () => dispatch(fetchRoles()),
  getMyLinks: () => dispatch(getMyLinks()),
});

export default withTranslation()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withToast(withStyles(combinedStyles)(RolesForm)))
);
