import React from 'react';
import roles from 'helpers/roles';
import moment from 'moment';
import BrandDateSubbrandsReport from './BrandDateSubbrandsReport';
import { withTranslation } from 'react-i18next';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import GridItem from '../../components/Grid/GridItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Datetime from '../../components/Form/DateTme';
import GridContainer from '../../components/Grid/GridContainer';
import getReportFileName from 'helpers/getReportFileName';

class DishLabelsSubBrands extends React.Component {
  state = {
    packedDate: null,
    shouldBeConsumedTo: null,
  };

  render() {
    const { t, classes } = this.props;
    const title = t('reports.reportDishLabels2');
    return (
      <BrandDateSubbrandsReport
        title={title}
        url="reports/overproduction-dish-stickers"
        mimeType="application/vnd.ms-excel"
        fileName={getReportFileName(title)}
        additionalParams={this.state}
        role={roles.ROLE_SHOW_REPORT_DISH_STICKERS}
        reportConfiguration={{
          report: 'OverproductionDishStickers',
          fields: [
            {
              size: 12,
              field: 'dishColumns',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'dishCharsPerColumn',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'ingredientsColumns',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'ingredientsCharsPerColumn',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'allergensColumns',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'allergensCharsPerColumn',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'mode',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: ['COLUMN', 'ROWS'].map(key => ({
                  value: key,
                  label: this.props.t(
                    `configuration.reports.report.OverproductionDishStickers.mode.${key}`
                  ),
                })),
              },
            },
          ],
        }}
        archiveReportNames={['DISH_STICKER']}
      >
        <GridContainer style={{ padding: '12px' }}>
          <GridItem sm={12}>
            <h4 className={classes.cardIconTitle}>
              {this.props.t('reports.defaults')}
            </h4>
          </GridItem>
          <GridItem sm={12} md={6} lg={3}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '5px' }}
            >
              {this.props.t('reports.packedDate')}
            </FormLabel>
            <FormControl fullWidth>
              <Datetime
                className="input-datetime--packed-date"
                timeFormat={false}
                dateFormat={moment.localeData().longDateFormat('L')}
                closeOnSelect={true}
                value={new moment(this.state.packedDate)}
                onChange={ev =>
                  this.setState({ packedDate: ev.format('YYYY-MM-DD') })
                }
                onClear={() => this.setState({ packedDate: null })}
                showClear={this.state.packedDate !== null}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem sm={12} md={6} lg={3}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '5px' }}
            >
              {this.props.t('reports.shouldBeConsumedTo')}
            </FormLabel>
            <FormControl fullWidth>
              <Datetime
                className="input-datetime--consumed-date"
                timeFormat={false}
                dateFormat={moment.localeData().longDateFormat('L')}
                closeOnSelect={true}
                value={new moment(this.state.shouldBeConsumedTo)}
                onChange={ev =>
                  this.setState({ shouldBeConsumedTo: ev.format('YYYY-MM-DD') })
                }
                onClear={() => this.setState({ shouldBeConsumedTo: null })}
                showClear={this.state.shouldBeConsumedTo !== null}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
      </BrandDateSubbrandsReport>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(DishLabelsSubBrands);
