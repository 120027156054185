import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/CustomButtons/Button';

import ROLES from 'helpers/roles';
import { isGranted } from 'helpers/helpers';

import SUB_PAGES from '../consts/SubPages';

const DishPageNavigation = ({
  saveToLs = () => {},
  isEditing,
  selectedBrand,
  currentSubPage,
  switchSubPage = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div>
        <Button
          color={currentSubPage === SUB_PAGES.MEAL_FORM ? 'info' : null}
          onClick={() => switchSubPage(SUB_PAGES.MEAL_FORM)}
        >
          {t('common.regular', '$$Regular')}
        </Button>
        {isGranted(ROLES.ROLE_SHOW_SYSTEM_ADVANCED_DISH) ? (
          <Button
            color={
              currentSubPage === SUB_PAGES.ADVANCED_MEAL_FORM ? 'info' : null
            }
            onClick={() => switchSubPage(SUB_PAGES.ADVANCED_MEAL_FORM)}
          >
            {t(
              '$*mealFormComponent.prepareInstructionsForCustomer',
              '$$Instrukcja przygotowania dla klienta'
            )}
          </Button>
        ) : null}
        {isGranted(ROLES.ROLE_NOTE_DISH) ? (
          <Button
            color={currentSubPage === SUB_PAGES.MEAL_NOTES ? 'info' : null}
            onClick={() => switchSubPage(SUB_PAGES.MEAL_NOTES)}
          >
            {t('common.notes', '$$Notatki')}
          </Button>
        ) : null}
        <Button
          color={currentSubPage === SUB_PAGES.DISH_SUBPAGE ? 'info' : null}
          onClick={() => switchSubPage(SUB_PAGES.DISH_SUBPAGE)}
        >
          {t('$*mealFormComponent.dishSubpage', '$$Podstrona dania')}
        </Button>
        {isEditing && (
          <Button
            color={currentSubPage === SUB_PAGES.FLOW_STATISTICS ? 'info' : null}
            onClick={() => switchSubPage(SUB_PAGES.FLOW_STATISTICS)}
          >
            {t('$*mealFormComponent.flowStats', '$$Statystyki flow')}
          </Button>
        )}
      </div>
      {isEditing && currentSubPage === SUB_PAGES.MEAL_FORM && (
        <Link
          to={`/admin/meals/add/?savedMeal&selectedBrand=${selectedBrand}`}
          target={'_blank'}
        >
          <Button color="primary" variant="contained" onClick={saveToLs}>
            {t('form.meals.copyToNewMeal', 'Skopiuj do nowego dania')}
          </Button>
        </Link>
      )}
    </div>
  );
};

export default DishPageNavigation;
