import { useEffect, useState } from 'react';
import { withToast } from 'material-ui-toast-redux';
import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash/isEmpty';

import { get, put } from 'helpers/apiHelpers';
import { combineStyles, isGranted } from 'helpers/helpers';
import { ROLE_EDIT_DISH_PACKHOUSE } from 'helpers/roles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import EditIcon from '@material-ui/icons/Edit';
import withStyles from '@material-ui/core/styles/withStyles';
import VisibilityIcon from '@material-ui/icons/Visibility';

import CardBody from 'components/Card/CardBody.jsx';
import DataGrid from 'components/DataGrid/DataGrid';
import PdfDialog from 'components/PdfDialog/PdfDialog';

import columnConfig from './columnConfig';
import subTasksColumnConfig from './subTasksColumnConfig';
import SubTasksList from 'views/CateringsFlow/common/SubTasksList/SubTasksList';
import AmountOfDoneModal from 'views/CateringsFlow/DishesPackhouse/components/AmountOfDoneModal/AmountOfDoneModal';

import CATERINGS_FLOW_STATUSES from 'views/CateringsFlow/consts/cateringsFlowStatuses';
import axios from '../../../../../helpers/gastro';
import { SystemUpdateOutlined } from '@material-ui/icons';
import STATUS_COLORS from 'views/CateringsFlow/consts/cateringsFlowStatusesColors';
import CardWrapper from 'components/Card/CardWrapper';

const DishesPackhouseTasksList = ({ classes, tasksListId, openToast }) => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [isPDFPreviewOpened, setIsPDFPreviewOpened] = useState(false);
  const [isAmountOfDoneModalOpened, setIsAmountOfDoneModalOpened] =
    useState(false);
  const [pendingStatusChange, setPendingStatusChange] = useState(null);

  useEffect(() => {
    fetchEmployees();
  }, []);

  const fetchEmployees = async () => {
    try {
      const response = await get('/employees/list', {
        partial: false,
        pagination: false,
        productionWorker: true,
      });
      setEmployees(
        (response?.['hydra:member'] ?? []).map(
          ({ firstName, lastName, '@id': iri, image }) => ({
            '@id': iri,
            value: `${firstName} ${lastName}`,
            contentUrl: image?.contentUrl,
          })
        )
      );
    } catch (error) {}
  };

  const onEmployeeChangeAction = async ({ row, employeeIri }) => {
    try {
      await put(`/tasks/${row?.id}/assign`, {
        assign: employeeIri,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const customActions = row => {
    return isGranted(ROLE_EDIT_DISH_PACKHOUSE)
      ? [
          {
            action: () => {
              setSelectedItem(row);
              setIsAmountOfDoneModalOpened(true);
            },
            icon: <EditIcon />,
            color: 'warning',
            simple: true,
            round: false,
          },
          {
            action: () => {
              setSelectedItem(row);
              setIsPDFPreviewOpened(true);
            },
            icon: <VisibilityIcon />,
            color: 'info',
            simple: true,
            round: false,
          },
          {
            action: ({ row }) => {
              axios
                .get(row['@id'], {
                  responseType: 'blob',
                  headers: { accept: 'application/vnd.ms-excel' },
                })
                .then(response => {
                  const url = window.URL.createObjectURL(
                    new Blob([response.data])
                  );
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `Naklejki - ${row.name}.xlsx`);
                  document.body.appendChild(link);
                  link.click();
                });
            },
            icon: <SystemUpdateOutlined />,
            color: 'info',
            simple: true,
            round: false,
          },
        ]
      : [
          {
            action: () => {
              setSelectedItem(row);
              setIsPDFPreviewOpened(true);
            },
            icon: <VisibilityIcon />,
            color: 'info',
            simple: true,
            round: false,
          },
        ];
  };

  const onStatusChangeAction = async ({ row, newStatus }) => {
    if (
      [
        CATERINGS_FLOW_STATUSES.DONE_WAITING,
        CATERINGS_FLOW_STATUSES.DONE,
      ].includes(row?.status) &&
      row?.status === newStatus
    ) {
      openToast({
        messages: [
          t(
            '$*cateringsFlow.statusHasNotChanged',
            '$$Uwaga! Status nie uległ zmianie. W związku z tym zmiana ilości nie będzie uwzględniona w statystykach flow.'
          ),
        ],
        type: 'warning',
        autoHideDuration: 3000,
      });
    }

    return new Promise(async (res, rej) => {
      const isAmountsModalRequired = [
        CATERINGS_FLOW_STATUSES.DONE_WAITING,
        CATERINGS_FLOW_STATUSES.DONE,
      ].includes(newStatus);

      if (isAmountsModalRequired) {
        setPendingStatusChange({
          url: `/tasks/${row?.id}/status`,
          payload: {
            status: newStatus,
            amountOfDone: parseFloat(row?.amountOfDone ?? 0),
            comment: row?.comment,
          },
          res,
          rej,
        });
        setSelectedItem(row);
        return setIsAmountOfDoneModalOpened(true);
      }

      await put(`/tasks/${row?.id}/status`, {
        status: newStatus,
        amountOfDone: parseFloat(row?.amountOfDone ?? 0),
        comment: row?.comment,
      });
      res();
    });
  };

  const closeAmountOfDoneModal = () => {
    pendingStatusChange?.rej();
    setSelectedItem({});
    setPendingStatusChange(null);
    setIsAmountOfDoneModalOpened(false);
  };

  const handleAmountOfDoneSubmit = async ({
    amountOfDone,
    amountToMake,
    comment,
  }) => {
    if (typeof amountOfDone === 'number' && !isNaN(amountOfDone)) {
      if (pendingStatusChange) {
        await put(pendingStatusChange.url, {
          ...pendingStatusChange.payload,
          amountOfDone,
        });
      }

      await put(`/tasks/${selectedItem?.id}/amounts`, {
        amountToMake,
        amountOfDone,
        comment,
      });

      openToast({
        messages: [
          t(
            '$*cateringsFlow.amountOfDoneHasBeenChangedSuccessfully',
            '$$Ilość została pomyślnie zaktualizowana'
          ),
        ],
        type: 'success',
        autoHideDuration: 3000,
      });
      pendingStatusChange.res();
      setRefresh(!refresh);
      closeAmountOfDoneModal();
    }
  };

  return (
    <>
      <CardWrapper
        title={t(
          '$*cateringsFlow.dishesPackhouse.dishesList.tableTitle',
          '$$Dania do spakowania'
        )}
      >
        <CardBody>
          <DataGrid
            refresh={refresh}
            actions={true}
            customActions={customActions}
            showColumnFilter={true}
            columns={columnConfig({
              t,
              employees,
              onStatusChangeAction,
              onEmployeeChangeAction,
            })}
            edit={false}
            export={false}
            paginationBottom={false}
            paginationTop={true}
            refreshOnFetch
            remove={false}
            getTrProps={(state, rowInfo, column) => {
              if (rowInfo === undefined) {
                return {};
              }

              return {
                'data-qnt': isEmpty(rowInfo?.original?.requires) ? 0 : 1,
              };
            }}
            getTrGroupProps={(_, rowInfo) => ({
              style: {
                background:
                  STATUS_COLORS[rowInfo?.original?.status]?.rowBackground,
                borderBottom: '2px solid darkgrey',
                padding: '8px 0',
              },
            })}
            defaultExpanded
            SubComponent={row => {
              return (
                <SubTasksList
                  row={row}
                  data={row?.original?.requires ?? []}
                  columns={subTasksColumnConfig({ t })}
                />
              );
            }}
            url={`/pack-dish-tasks?taskList.id=${tasksListId}`}
            manipulateQuery={(requestData, query) => {
              const rangeFilters = ['amountToMake', 'amountOfDone'];
              rangeFilters.forEach(el => {
                if (query.hasOwnProperty(el)) {
                  const filters = query[el];
                  delete query[el];
                  if (filters.valueFrom) {
                    query[el + '[gte]'] = filters.valueFrom;
                  }
                  if (filters.valueTo) {
                    query[el + '[lte]'] = filters.valueTo;
                  }
                }
              });

              return query;
            }}
            defaultHiddenColumns={['id', 'diff', 'note']}
            striped={false}
          />
        </CardBody>
      </CardWrapper>
      <AmountOfDoneModal
        isOpened={isAmountOfDoneModalOpened}
        name={
          selectedItem?.dish?.nameForClient
            ? `(${selectedItem?.dish?.id}) ${selectedItem?.dish?.nameForClient}`
            : selectedItem?.name
        }
        initialComment={selectedItem?.comment}
        initialAmountOfDone={selectedItem?.amountOfDone}
        initialAmountToMake={selectedItem?.amountToMake}
        amountToMakeAfterRefreshedAmounts={
          selectedItem?.amountToMakeAfterRefreshedAmounts
        }
        allowChangeAmountToMake={false}
        closeModal={closeAmountOfDoneModal}
        handleSubmit={handleAmountOfDoneSubmit}
      />

      <PdfDialog
        fileUrl={selectedItem?.['@id']}
        isOpened={isPDFPreviewOpened}
        closeModal={() => {
          setSelectedItem({});
          setIsPDFPreviewOpened(false);
        }}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(withToast(DishesPackhouseTasksList));
