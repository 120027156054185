import React, { useState } from 'react';

import { isGranted, translateNamesForExport } from 'helpers/helpers';
import { ROLE_EDIT_COST } from 'helpers/roles';

import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { DialogLoader } from 'components/DialogLoader';
import { shallowEqual, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axios from 'helpers/gastro';
import moment from 'moment';
import CustomDropdown from 'components/CustomDropdown/CustomDropdown';
import GridContainer from 'components/Grid/GridContainer';
import LogView from 'components/History/LogView';

const DietDelivery = ({
  classes,
  deliveryPrices,
  handleChange,
  saveAll,
  isLoading,
  openToast,
}) => {
  const { t } = useTranslation();
  const multinational = useSelector(
    ({
      Brands: {
        brand: { multinational },
      },
    }) => multinational,
    shallowEqual
  );

  const [isExporting, setIsExporting] = useState(false);

  const getExportButtonConfig = () => {
    const namesForExport = translateNamesForExport(t);

    return [
      {
        label: 'XLSX',
        format: 'xlsx',
        endpoint: 'zone-costs',
        fileName: (url, date) =>
          namesForExport[url] ? namesForExport[url] + date : url + date,
      },
      {
        label: 'CSV',
        format: 'csv',
        endpoint: 'zone-costs',
        fileName: (url, date) =>
          namesForExport[url] ? namesForExport[url] + date : url + date,
      },
    ];
  };

  const exportData = ({ format, fileName, endpoint }) => {
    setIsExporting(true);

    const date = moment().format('DD-MM-YYYY_HH-mm');
    const exportedName =
      typeof fileName === 'string' ? fileName : fileName(endpoint, date);

    axios
      .get(`${endpoint}.${format}`, {
        responseType: 'blob',
        params: {},
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${exportedName}.${format}`);
          document.body.appendChild(link);
          link.click();
        },
        () => {
          openToast({
            messages: [
              t(
                'errors.dataExportFailed',
                'Nie udało się wyeksportować danych'
              ),
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
      )
      .finally(() => {
        setIsExporting(false);
      });
  };

  return (
    <form style={{ opacity: isLoading ? 0 : 1, transition: '0.3s all' }}>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />
      <DialogLoader loaderState={isExporting} text={t('dataGrid.exporting')} />
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem>
              <CustomDropdown
                hoverColor="info"
                buttonText={t('dataGrid.export')}
                buttonProps={{
                  fullWidth: true,
                }}
                dropdownHeader={t('dataGrid.selectFormat')}
                dropdownList={getExportButtonConfig().map(conf => ({
                  handleClick: () =>
                    exportData({
                      format: conf.format,
                      fileName: conf.fileName,
                      endpoint: conf.endpoint,
                      manipulateQuery: conf.manipulateQuery,
                    }),
                  optionText: conf.label,
                }))}
              />
            </GridItem>
          </GridContainer>
          <GridItem md={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>{t('common.dietPrices.zone')} </TableCell>
                  <TableCell>
                    {t('common.dietPrices.customerPrice', {
                      defaultValue: 'Cena dla klienta [{{currency}}]',
                      replace: {
                        currency: multinational.currency,
                      },
                    })}
                  </TableCell>
                  <TableCell>{t('common.dietPrices.vat')}</TableCell>
                  <TableCell>{t('common.dietPrices.cateringCost')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {deliveryPrices.map((item, index) => (
                  <TableRow
                    style={{
                      backgroundColor: item.hasChanged
                        ? '#ffffd4'
                        : 'transparent',
                    }}
                    key={index}
                  >
                    <TableCell component="th" scope="row">
                      {item.name}
                      {item.hasChanged ? t('common.shared.modified') : ''}
                    </TableCell>
                    <TableCell>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          disabled: !isGranted(ROLE_EDIT_COST),
                          placeholder: t('common.dietPrices.typePrice'),
                          type: 'number',
                          value: item.price,
                          onChange: event =>
                            handleChange('price', event.target.value, item),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          disabled: !isGranted(ROLE_EDIT_COST),
                          placeholder: t('common.dietPrices.typeVat'),
                          type: 'number',
                          value: item.vat,
                          onChange: event =>
                            handleChange('vat', event.target.value, item),
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          disabled: !isGranted(ROLE_EDIT_COST),
                          placeholder: t('common.dietPrices.deliveryCost'),
                          type: 'number',
                          value: item.cost,
                          onChange: event =>
                            handleChange('cost', event.target.value, item),
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </GridItem>
        </CardBody>
      </Card>

      {isGranted(ROLE_EDIT_COST) && (
        <LogView
          classes={classes}
          submitDisabled={
            !deliveryPrices.some(deliveryPrice => deliveryPrice.hasChanged)
          }
          handleSubmit={saveAll}
          submitText={t('common.shared.save')}
          iri={`/zone-costs`}
        />
      )}

      {!isGranted(ROLE_EDIT_COST) && <LogView iri={`/zone-costs`} />}
    </form>
  );
};

export default DietDelivery;
