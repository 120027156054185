import React from 'react';
import MailNotificationsCard from './components/MailNotificationCardsComponents';
import { mailingOptions } from './constans';
import { CircularProgress } from '@material-ui/core';
import MailNotificationsClientToggleableCard from './components/MailNotificationClientToggleableCardComponents';

const MailNotificationView = ({ props }) => {
  const { t, state, setAllowToChangedElements } = props;

  if (state.adminMailing.length === 0 && state.clientMailing.length === 0) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <MailNotificationsCard
        mailForAdmin={true}
        title={t('mailNotifications.cardTitle.admin')}
        mailing={state.adminMailing}
        defaultConfigFromBrand={state.defaultConfigFromBrand}
        mailingOptions={mailingOptions}
        setAllowToChangedElements={setAllowToChangedElements}
      />
      <MailNotificationsCard
        title={t('mailNotifications.cardTitle.client')}
        mailing={state.clientMailing}
        defaultConfigFromBrand={state.defaultConfigFromBrand}
        mailingOptions={mailingOptions}
        setAllowToChangedElements={setAllowToChangedElements}
      />
      <MailNotificationsClientToggleableCard
        clientMailing={state.clientMailing}
        adminMailing={state.adminMailing}
        mailing={state.clientToggleableMailing}
        mailingOptions={mailingOptions}
        allowToChangedElements={state.allowToChangedElements}
      />
    </>
  );
};

export default MailNotificationView;
