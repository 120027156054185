import { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { useTranslation } from 'react-i18next';

import Card from 'components/Card/Card';
import Button from 'components/CustomButtons/Button';
import Archive from 'components/Archive/Archive';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import ReportConfigurator from 'components/Report/ReportConfigurator';

import axios from 'helpers/gastro';
import { combineStyles } from 'helpers/helpers';

import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AddCircle } from '@material-ui/icons';
import { Dialog, DialogContent } from '@material-ui/core';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import { fetchZoneCategories } from 'actions/ZoneCategories';

import EmployeesReportRow from './EmployeesReportRow';
import CardWrapper from 'components/Card/CardWrapper';

const URL = 'reports/employee-time-entries';
const DEFAULT_ROW = {
  dateTo: '',
  dateFrom: '',
  kitchen: [],
  employee: [],
  department: [],
};
const MIME_TYPES = ['application/pdf', 'application/vnd.ms-excel'];

const EmployeesReport = ({ url, classes, company, openToast }) => {
  const { t } = useTranslation();

  const [rows, setRows] = useState([DEFAULT_ROW]);
  const [isGenerating, setIsGenerating] = useState(false);

  const addRow = () => {
    setRows([...rows, DEFAULT_ROW]);
  };

  const removeRow = idToRemove => {
    setRows([...rows.filter((row, id) => id !== idToRemove)]);
  };

  const updateRow = (updatedRow, index) => {
    let rowsCopy = [...rows];

    rowsCopy[index] = updatedRow;

    setRows(rowsCopy);
  };

  const resolveBtnTxt = mimeType => {
    switch (mimeType) {
      case 'application/pdf':
        return t('reports.gPDF');
      case 'application/vnd.ms-excel':
        return t('reports.gExcel', { format: 'XLSX' });
      case 'text/comma-separated-values':
        return t('reports.gCSV');
      default:
        return 'reports.gReport';
    }
  };

  const resolveFileExtension = mimeType => {
    switch (mimeType) {
      case 'application/pdf':
        return 'pdf';
      case 'application/vnd.ms-excel':
        return 'xlsx';
      case 'text/comma-separated-values':
        return 'csv';
      default:
        return '';
    }
  };

  const validate = row => {
    const { dateTo, dateFrom } = row ?? {};
    return dateTo && dateFrom;
  };

  const handleGenerate = mimeType => {
    if (rows.some(row => !validate(row))) {
      openToast({
        messages: [
          t(
            'clients.fillAllFields',
            'Wypełnij wszystkie obowiązkowe pola oznaczone gwiazdką'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
      return;
    }
    setIsGenerating(true);

    const parameters = rows.map(row => ({
      ...row,
      kitchen: row.kitchen.filter(val => val).map(val => val.value),
      department: row.department.filter(val => val).map(val => val.value),
      employee: row.employee.filter(val => val).map(val => val.value),
    }));

    const params = {
      parameters: parameters,
    };

    axios
      .get(URL, {
        responseType: 'blob',
        params: params,
        headers: { accept: mimeType },
      })
      .then(
        response => {
          if (response.data) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
              'download',
              'RaportPracownikow.' + resolveFileExtension(mimeType)
            );
            document.body.appendChild(link);
            link.click();
            setIsGenerating(false);
          } else {
            alert(t('reports.noAccess', 'Brak dostępu'));
          }
        },
        error => {
          if (typeof error.response !== 'undefined') {
            openToast({
              messages: [
                t('reports.cannotGenerate'),
                error.response.data['hydra:description'],
              ],
              type: 'error',
              autoHideDuration: 3000,
            });
          } else {
            openToast({
              messages: [t('reports.cannotGenerate'), error],
              type: 'error',
              autoHideDuration: 3000,
            });
          }
          setIsGenerating(false);
        }
      );
  };

  const companyId = parseInt(company.split('/').pop());

  return (
    <>
      <CardWrapper
        title={
          <>
            {t('common.mainMenu.employeesReport', 'Raport - pracowników')}
            <ReportConfigurator
              companyId={companyId}
              report={'Employee'}
              fields={[
                {
                  size: 12,
                  field: 'timeCountingType',
                  inputType: 'select',
                  inputProps: {
                    multiple: false,
                    options: [
                      {
                        value: 'SUMMED_TIME_RECORDS',
                        label: t(
                          'reports.employees.timeCountingTypes.sumOfTimeSpent',
                          'Sumę czasów poświęconych na każdy przepis'
                        ),
                      },
                      {
                        value: 'TIME_PERIOD',
                        label: t(
                          'reports.employees.timeCountingTypes.timeSinceStartTillEnd',
                          'Czas od rozpoczęcia pierwszego przepisu do zakończenia ostatniego'
                        ),
                      },
                    ],
                  },
                },
              ]}
            />
          </>
        }
        style={{ marginTop: '20px' }}
      >
        <Dialog open={isGenerating}>
          <DialogContent>
            <div style={{ textAlign: 'center' }}>
              <h1>{t('reports.generate')}</h1>
              <CircularProgress />
            </div>
          </DialogContent>
        </Dialog>
        <CardBody>
          {rows.map((row, index) => (
            <EmployeesReportRow
              row={row}
              onRowRemove={() => removeRow(index)}
              onRowUpdate={updatedRow => updateRow(updatedRow, index)}
              canBeRemoved={index !== 0}
            />
          ))}
          <GridContainer justify={'space-between'}>
            <GridItem>
              <Button
                onClick={addRow}
                disabled={isGenerating}
                color={'default'}
                round
                justIcon
              >
                <AddCircle />
              </Button>
            </GridItem>
            <div style={{ display: 'flex' }}>
              {MIME_TYPES.map(mimeType => {
                return (
                  <GridItem>
                    <Button
                      className={`btn--generate-${resolveFileExtension(
                        mimeType
                      )}`}
                      onClick={() => handleGenerate(mimeType)}
                      disabled={isGenerating}
                      color={'success'}
                      round
                    >
                      {resolveBtnTxt(mimeType)}
                    </Button>
                  </GridItem>
                );
              })}
            </div>
          </GridContainer>
        </CardBody>
      </CardWrapper>
      <Card>
        <CardBody>
          <Archive type={'REPORT'} reportName={'EmployeeTimeEntries'} />
        </CardBody>
      </Card>
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  brands: state.Auth.user.brands,
  company: state.Auth.user.company,
  zoneCategories: state.ZoneCategories.zoneCategories,
});

const mapDispatchToProps = dispatch => ({
  fetchZoneCategories: () => dispatch(fetchZoneCategories()),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(EmployeesReport);
