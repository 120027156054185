import { useState, useEffect } from 'react';
import { withToast } from 'material-ui-toast-redux';
import { useTranslation } from 'react-i18next';

import { combineStyles } from 'helpers/helpers';

import { get, put } from 'helpers/apiHelpers';
import { isGranted } from 'helpers/helpers';
import { ROLE_EDIT_KITCHEN } from 'helpers/roles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import CardBody from 'components/Card/CardBody.jsx';

import EditIcon from '@material-ui/icons/Edit';
import withStyles from '@material-ui/core/styles/withStyles';
import VisibilityIcon from '@material-ui/icons/Visibility';

import DataGrid from 'components/DataGrid/DataGrid';
import PdfDialog from 'components/PdfDialog/PdfDialog';

import columnConfig from './columnConfig';
import AmountOfDoneModal from 'views/CateringsFlow/Kitchen/components/AmountOfDoneModal/AmountOfDoneModal';

import CATERINGS_FLOW_STATUSES from 'views/CateringsFlow/consts/cateringsFlowStatuses';
import CardWrapper from 'components/Card/CardWrapper';

const RecipiesList = ({ classes, tasksListId, openToast }) => {
  const { t } = useTranslation();
  const [refresh, setRefresh] = useState(false);
  const [kitchens, setKitchens] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [pendingStatusChange, setPendingStatusChange] = useState(null);
  const [isPDFPreviewOpened, setIsPDFPreviewOpened] = useState(false);
  const [isAmountOfDoneModalOpened, setIsAmountOfDoneModalOpened] =
    useState(false);
  const [amountOfDoneModalNewStatus, setAmountOfDoneModalNewStatus] =
    useState(null);

  useEffect(() => {
    fetchKitchens();
    fetchEmployees();
    fetchDepartments();
  }, []);

  const customActions = row => {
    return isGranted(ROLE_EDIT_KITCHEN)
      ? [
          {
            action: () => {
              setSelectedItem(row);
              setAmountOfDoneModalNewStatus(row?.status);
              setIsAmountOfDoneModalOpened(true);
            },
            icon: <EditIcon />,
            color: 'warning',
            simple: true,
            round: false,
          },
          {
            action: () => {
              setSelectedItem(row);
              setIsPDFPreviewOpened(true);
            },
            icon: <VisibilityIcon />,
            color: 'info',
            simple: true,
            round: false,
          },
        ]
      : [
          {
            action: () => {
              setSelectedItem(row);
              setIsPDFPreviewOpened(true);
            },
            icon: <VisibilityIcon />,
            color: 'info',
            simple: true,
            round: false,
          },
        ];
  };

  const fetchEmployees = async () => {
    try {
      const response = await get('/employees/list', {
        partial: false,
        pagination: false,
        productionWorker: true,
      });
      setEmployees(
        (response?.['hydra:member'] ?? []).map(
          ({ firstName, lastName, '@id': iri, image }) => ({
            '@id': iri,
            value: `${firstName} ${lastName}`,
            contentUrl: image?.contentUrl,
          })
        )
      );
    } catch (error) {}
  };

  const onStatusChangeAction = async ({
    row,
    newStatus,
    ignoreAmountsModal = false,
  } = {}) => {
    return new Promise(async (res, rej) => {
      const isAmountsModalRequired =
        [
          CATERINGS_FLOW_STATUSES.DONE_WAITING,
          CATERINGS_FLOW_STATUSES.DONE,
        ].includes(newStatus) && !ignoreAmountsModal;

      if (isAmountsModalRequired) {
        setPendingStatusChange({
          url: `/tasks/${row?.id}/status`,
          payload: {
            status: newStatus,
            amountOfDone: parseFloat(row?.amountOfDone ?? 0),
            comment: row?.comment,
          },
          res,
          rej,
        });
        setAmountOfDoneModalNewStatus(newStatus);
        setIsAmountOfDoneModalOpened(true);
        setSelectedItem(row);
      } else {
        await put(`/tasks/${row?.id}/status`, {
          status: newStatus,
          amountOfDone: parseFloat(row?.amountOfDone ?? 0),
          comment: row?.comment,
        });
        res();
      }
    });
  };

  const onEmployeeChangeAction = async ({ row, employeeIri }) => {
    try {
      await put(`/tasks/${row?.id}/assign`, {
        assign: employeeIri,
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleAmountOfDoneSubmit = async ({
    amountOfDone,
    amountToMake,
    amountOfDoneDetails,
    comment,
  }) => {
    if (typeof amountOfDone === 'number' && !isNaN(amountOfDone)) {
      if (pendingStatusChange) {
        await put(pendingStatusChange.url, {
          ...pendingStatusChange.payload,
          amountOfDone,
        });
      }
      await put(`/tasks/${selectedItem?.id}/amounts`, {
        amountToMake,
        amountOfDone,
        amountOfDoneDetails,
        comment,
      });

      openToast({
        messages: [
          t(
            '$*cateringsFlow.amountOfDoneHasBeenChangedSuccessfully',
            '$$Ilość została pomyślnie zaktualizowana'
          ),
        ],
        type: 'success',
        autoHideDuration: 3000,
      });
      pendingStatusChange?.res();
      setPendingStatusChange(null);
      setRefresh(!refresh);
      closeAmountOfDoneModal();
    }
  };

  const closeAmountOfDoneModal = () => {
    pendingStatusChange?.rej();
    setPendingStatusChange(null);
    setAmountOfDoneModalNewStatus(null);
    setIsAmountOfDoneModalOpened(false);
    setSelectedItem({});
  };

  const fetchKitchens = async () => {
    try {
      const response = await get('/kitchens');

      setKitchens(response?.['hydra:member'] ?? []);
    } catch (error) {
      console.log({ error });
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await get('/departments');

      setDepartments(response?.['hydra:member'] ?? []);
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <>
      <CardWrapper
        title={t(
          '$*cateringsFlow.kitchen.recipiesList.tableTitle',
          '$$Przepisy do zrobienia'
        )}
      >
        <CardBody>
          <DataGrid
            refresh={refresh}
            actions={true}
            customActions={customActions}
            columns={columnConfig({
              t,
              kitchens,
              employees,
              departments,
              onStatusChangeAction,
              onEmployeeChangeAction,
            })}
            edit={false}
            showColumnFilter={true}
            paginationBottom={false}
            paginationTop={true}
            remove={false}
            role="KITCHEN"
            url={`/cook-recipe-tasks?taskList.id=${tasksListId}`}
            defaultHiddenColumns={
              isGranted(ROLE_EDIT_KITCHEN)
                ? ['id', 'diff']
                : ['id', 'diff', 'comment', 'kitchen', 'department']
            }
            manipulateQuery={(requestData, query) => {
              if (query['assignedEmployee']) {
                const splicedName = query['assignedEmployee'].split(' ');
                let _andX = [
                  [
                    {
                      'assignedEmployee.firstName': splicedName[0],
                      'assignedEmployee.lastName': splicedName[0],
                    },
                  ],
                ];

                if (splicedName.length > 1) {
                  _andX[0][0]['assignedEmployee.lastName'] = splicedName[1];
                }

                const operator = splicedName.length > 1 ? '_andX' : '_orX';

                delete query['assignedEmployee'];
                query[operator] = _andX;
              }

              return query;
            }}
          />
        </CardBody>
      </CardWrapper>

      <AmountOfDoneModal
        isOpened={isAmountOfDoneModalOpened}
        recipeInfo={selectedItem}
        closeModal={closeAmountOfDoneModal}
        handleSubmit={handleAmountOfDoneSubmit}
        allowChangeAmountToMake={false}
        amountOfDoneModalNewStatus={amountOfDoneModalNewStatus}
      />

      <PdfDialog
        fileUrl={selectedItem?.['@id']}
        pdfAddress={selectedItem?.['@id']}
        isOpened={isPDFPreviewOpened}
        closeModal={() => {
          setSelectedItem({});
          setIsPDFPreviewOpened(false);
        }}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(withToast(RecipiesList));
