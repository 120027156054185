import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import { post } from '../../../helpers/apiHelpers';
import { withToast } from 'material-ui-toast-redux';

const GetResponseModule = ({
  classes,
  handleModuleUpdate,
  module,
  openToast,
}) => {
  const { t } = useTranslation();
  const [isSyncing, setIsSyncing] = useState(false);
  const [isShowApiDomainGetResponse, setIsShowApiDomainGetResponse] = useState(
    module.apiDomain ? true : false
  );

  const handleClientsSync = async () => {
    setIsSyncing(true);
    try {
      await post('/getresponse/client-sync');
      openToast({
        messages: [
          t(
            'brands.newBrandForm.thulium.syncContacts.successMessage',
            'Żądanie zostało wysłane. Synchronizacja klientów to proces który może chwilę potrwać'
          ),
        ],
        type: 'success',
        autoHideDuration: 3000,
      });
      setIsSyncing(false);
    } catch (e) {
      setIsSyncing(false);
    }
  };

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          replace: 'GetResponse',
        })}
      </div>
    );
  }

  return !module ? (
    <div>
      {t('brands.newBrandForm.moduleNotLoaded', {
        defaultValue: '{{moduleName}} not loaded.',
        replace: { moduleName: __filename.split('.')[0] },
      })}
    </div>
  ) : (
    <>
      <GridContainer>
        <GridItem xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={module.enabled}
                onChange={e =>
                  handleModuleUpdate({
                    ...module,
                    enabled: !module.enabled,
                  })
                }
                checkedIcon={<Check className={classes.checkedIcon} />}
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked,
                  root: classes.checkRoot,
                }}
              />
            }
            label={t(
              'brands.newBrandForm.useGetResponseLabel',
              'Używaj GetResponse.com'
            )}
          />

          <Button disabled={isSyncing} onClick={handleClientsSync}>
            {t('brands.newBrandForm.thulium.syncContacts', 'Synchronizuj')}
          </Button>
          <Tooltip
            title={
              <h4>
                {t(
                  'brands.newBrandForm.thulium.syncContacts.tooltip',
                  '$$Synchronizacja klientów może chwilę zająć'
                )}
              </h4>
            }
            placement="top"
          >
            <Info fontSize={'small'} style={{ color: 'gray' }} />
          </Tooltip>
        </GridItem>
        <GridItem xs={12}>
          <FormTextInput
            label={t('brands.newBrandForm.getResponse.apiKey', 'API klucz')}
            classes={classes}
            name="getResponseApiKey"
            value={module.apiKey}
            maxLength={64}
            handleChange={e =>
              handleModuleUpdate({
                ...module,
                apiKey: e.target.value,
              })
            }
            inputSize={12}
          />
        </GridItem>
        <GridItem style={{ display: 'flex' }} xs={12}>
          {!isShowApiDomainGetResponse ? (
            <FormControlLabel
              control={
                <Checkbox
                  checked={isShowApiDomainGetResponse}
                  onChange={() =>
                    setIsShowApiDomainGetResponse(!isShowApiDomainGetResponse)
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t(
                'brands.newBrandForm.getResponse.apiDomain.checkbox.description'
              )}
            />
          ) : (
            <FormTextInput
              label={
                <>
                  <span style={{ marginRight: '5px' }}>
                    {t(
                      'brands.newBrandForm.getResponse.apiDomain',
                      'API domena'
                    )}
                  </span>
                  <Tooltip
                    title={
                      <h4>
                        {t(
                          'brands.newBrandForm.useGetResponse.apiDomain.tooltip',
                          'Przy koncie MAX konieczne jest wprowadzenie domeny. W celu pełnej aktywacji należy skontaktować się z firmą GetResponse aby dokonali konfiguracji na podaną domenę'
                        )}
                      </h4>
                    }
                    placement="top"
                  >
                    <Info fontSize={'small'} style={{ color: 'gray' }} />
                  </Tooltip>
                </>
              }
              classes={classes}
              name="getResponseApiDomain"
              value={module.apiDomain}
              maxLength={64}
              handleChange={e =>
                handleModuleUpdate({
                  ...module,
                  apiDomain: e.target.value,
                })
              }
              inputSize={12}
            />
          )}
        </GridItem>
      </GridContainer>
    </>
  );
};

export default withToast(GetResponseModule);
