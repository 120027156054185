import { DialogLoader } from 'components/DialogLoader';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import Config from '../../sections/Config';
import Excludes from '../../sections/Excludes';
import BasicInfo from '../../sections/BasicInfo';
import BasicInfoCard from 'components/CMS/BasicInfoCard';
import InfoBannerCard from 'components/CMS/InfoBannerCard';
import DescriptionCard from 'components/CMS/DescriptionCard';
import DietAssumptions from '../../sections/DietAssumptions';
import ContainerSelect from '../../sections/ContainerSelect';
import DeliveriesAndOrders from '../../components/DeliveriesAndOrders';
import ClientsPanelInterface from '../../sections/ClientsPanelInterface';

import SUB_PAGES from '../../consts/SubPages';
import DietPageNavigation from '../../components/DietPageNavigation';
import DietFlowStatistics from '../../DietFlowStatistics';

const FormDietsView = ({ props }) => {
  const {
    t,
    classes,
    state,
    setState,
    getImage,
    removeImage,
    handleSubmit,
    setSubpage,
    handleChange,
    setDietSubpageData,
    handleBannerCheckboxChange,
    isEdit,
    dietId,
    allergens,
    ingredientCategories,
  } = props;

  return (
    <>
      <DialogLoader
        loaderState={state.isSubmitting}
        text={t('form.savingChanges')}
      />
      <DialogLoader
        loaderState={state.isLoading}
        text={t('form.loadingDiet')}
      />

      <DietPageNavigation
        isEditing={isEdit}
        subpage={state.subpage}
        setSubpage={setSubpage}
      />

      {state.subpage === SUB_PAGES.CLIENT_PANEL && (
        <>
          <BasicInfo {...props} />
          <ClientsPanelInterface {...props} />
          <Excludes
            allergens={allergens}
            ingredientCategories={ingredientCategories}
            {...props}
          />

          <DietAssumptions {...props} />
          <Config {...props} />
          <ContainerSelect {...props} bagContainer={state.bagContainer} />
        </>
      )}

      {state.subpage === SUB_PAGES.DIET_SUBPAGE && (
        <>
          <BasicInfoCard
            urlSlug={state.urlSlug}
            metaTitle={state.metaTitle}
            handleChange={handleChange}
            metaDescription={state.metaDescription}
            subpage="/diets/"
          />
          <InfoBannerCard
            infoBannerCheckbox={state.bannerEnabled}
            bannerHeader={state.bannerHeader}
            bannerText={state.bannerText}
            handleInputChange={handleChange}
            handleCheckboxChange={handleBannerCheckboxChange}
            getImage={getImage}
            removeImage={removeImage}
            infoBannerMobileUrl={state.infoBannerMobileUrl}
            infoBannerDesktopUrl={state.infoBannerDesktopUrl}
          />
          <DescriptionCard
            value={state.dietDescription}
            setValue={e => setDietSubpageData(e, 'dietDescription')}
            title={t('views.diets.dietDescription')}
          />
        </>
      )}

      {state.subpage === SUB_PAGES.TIME_SLOTS_SUBPAGE && (
        <DeliveriesAndOrders
          state={state}
          setState={newState => setState(prev => ({ ...prev, ...newState }))}
        />
      )}

      {isEdit && state.subpage === SUB_PAGES.DIET_FLOW_STATISTICS && (
        <DietFlowStatistics dietId={dietId} />
      )}

      {[
        SUB_PAGES.CLIENT_PANEL,
        SUB_PAGES.DIET_SUBPAGE,
        SUB_PAGES.TIME_SLOTS_SUBPAGE,
      ].includes(state.subpage) && (
        <FormControlButtons
          classes={classes}
          discardText={t('form.cancel')}
          submitText={t('form.save')}
          cancelPath="/admin/diets"
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default FormDietsView;
