import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FormLabel from '@material-ui/core/FormLabel';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import _ from 'lodash';
import { put } from 'helpers/apiHelpers';

import { Check, Info } from '@material-ui/icons';

import GridItem from 'components/Grid/GridItem';
import SelectAll from 'components/SelectAll';
import GridContainer from 'components/Grid/GridContainer.jsx';

import FormTextInput from 'components/FormTextInput/FormTextInput';

const Goodspeed = ({ classes, handleModuleUpdate, module, zones }) => {
  const { t } = useTranslation();
  const [selectedZones, setSelectedZones] = useState([]);

  useEffect(() => {
    if (zones.length > 0) {
      setSelectedZones(
        zones
          .filter(
            ({ goodSpeedIntegrationEnabled }) => goodSpeedIntegrationEnabled
          )
          .map(zone => ({ label: zone['name'], value: zone['@id'] }))
      );
    }
  }, [zones]);

  const updateZones = updatedZones => {
    if (updatedZones.length > selectedZones.length) {
      const newZone = _.differenceWith(updatedZones, selectedZones);
      if (newZone.length > 0) {
        put(`${newZone[0].value}`, { goodSpeedIntegrationEnabled: true });
      }
    } else if (updatedZones.length < selectedZones.length) {
      const removedZone = _.differenceWith(selectedZones, updatedZones);
      if (removedZone.length > 0) {
        put(`${removedZone[0].value}`, { goodSpeedIntegrationEnabled: false });
      }
    }

    setSelectedZones(updatedZones);
  };

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'Goodspeed',
        })}
      </div>
    );
  }

  return (
    <Fragment>
      <FormTextInput
        label="Url"
        helpText="https://panel.goodspeed.pl"
        classes={classes}
        name="apiUrl"
        value={module.apiUrl}
        disabled={!module.enabled}
        maxLength={64}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            apiUrl: e.target.value,
          })
        }
        inputSize={6}
      />
      <FormTextInput
        label="Token"
        classes={classes}
        name="token"
        helpText={
          <a
            href="https://panel.goodspeed.pl/company-profile"
            target="_blank"
            rel="noreferrer"
          >
            {t('$*common.lookHere')}
          </a>
        }
        value={module.token}
        disabled={!module.enabled}
        maxLength={128}
        handleChange={e =>
          handleModuleUpdate({
            ...module,
            token: e.target.value,
          })
        }
        inputSize={6}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={module.enabled}
            onChange={e =>
              handleModuleUpdate({ ...module, enabled: !module.enabled })
            }
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        label={t('$*goodspeed.enabled', '$$Aktywuj integrację')}
      />
      <Tooltip
        style={{ marginLeft: '-10px', width: '17px' }}
        placement="top"
        title={<h3>{t('$*goodspeed.tooltip')}</h3>}
      >
        <Info fontSize={'small'} color={'action'} />
      </Tooltip>
      <GridContainer alignItems="center">
        <GridItem xs={12} sm={6}>
          <FormLabel
            className={classes.labelHorizontal}
            style={{ marginTop: '15px' }}
          >
            {t(
              '$*goodspeed.selectZones',
              '$$Wybierz z których stref mają się automatycznie przesyłać paczki do goodspeed'
            )}
          </FormLabel>
          <SelectAll
            options={zones}
            trackBy={['@id']}
            mapBy={'name'}
            isClearable={false}
            allowSelectAll={false}
            optionSelected={selectedZones}
            handleChange={selected => updateZones(selected)}
          />
        </GridItem>
      </GridContainer>
    </Fragment>
  );
};

export default Goodspeed;
