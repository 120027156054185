import { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import withStyles from '@material-ui/core/styles/withStyles';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { post, put } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';
import { fetchAllergens } from 'actions/Allergens';
import { fetchDiet, fetchDiets } from 'actions/Diets';
import {
  fetchIngredients,
  fetchIngredientCategories,
} from 'actions/Ingredients';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import TableActions from 'components/DataTable/Actions';
import defaultState from '../../defaultState';
import moment from 'moment';
import {
  dayObject,
  days as daysOfWeek,
} from '../../../BrandConfiguration/consts';
import pick from 'lodash/pick';
import { useState } from 'react';
import FormDietsView from './FormDiets.view';

const toFloat = value => (value === null ? null : parseInt(value));
const timeFields = ['newOrder', 'paymentDeadline'];

const FormDietsContainer = ({
  match,
  location,
  fetchDiets,
  fetchDiet,
  fetchAllergens,
  fetchIngredientCategories,
  openToast,
  fetchIngredients,
  t,
  classes,
  diets,
  history,
  brandConfig,
  allergens,
  diet,
  loading,
  ingredients,
  ingredientCategories,
}) => {
  const [state, setState] = useState({
    ...defaultState,
    isEnabledCustomTimeSlots: false,
    chosenDateAndTime: {
      monday: {
        newOrder: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.sunday'), value: '2021-09-19' },
          time: '00:00',
        },
      },
      tuesday: {
        newOrder: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.monday'), value: '2021-09-20' },
          time: '00:00',
        },
      },
      wednesday: {
        newOrder: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.tuesday'), value: '2021-09-21' },
          time: '00:00',
        },
      },
      thursday: {
        newOrder: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.wednesday'), value: '2021-09-22' },
          time: '00:00',
        },
      },
      friday: {
        newOrder: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.thursday'), value: '2021-09-23' },
          time: '00:00',
        },
      },
      saturday: {
        newOrder: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.friday'), value: '2021-09-24' },
          time: '00:00',
        },
      },
      sunday: {
        newOrder: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
        paymentDeadline: {
          day: { label: t('common.saturday'), value: '2021-09-25' },
          time: '00:00',
        },
      },
    },
  });

  const dietId = match?.params.id;
  const isEdit = location?.pathname.includes('edit');

  useEffect(async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    fetchDiets();
    fetchAllergens();
    fetchIngredientCategories();
    if (isEdit) {
      const diet = await fetchDiet(dietId);
      const {
        assumptionOfProtein,
        assumptionOfFats,
        assumptionOfCarbohydrates,
      } = diet;

      const carbohydratesAssumption =
        assumptionOfCarbohydrates.assumption ?? '';
      const carbohydratesMargin = assumptionOfCarbohydrates.margin ?? '';
      const fatsAssumption = assumptionOfFats.assumption ?? '';
      const fatsMargin = assumptionOfFats.margin ?? '';
      const proteinAssumption = assumptionOfProtein.assumption ?? '';
      const proteinMargin = assumptionOfProtein.margin ?? '';
      const newState = {
        name: diet.name,
        workName: diet.workName,
        position: diet.position ?? '',
        code: diet.code,
        description: diet.description,
        checked: diet.active,
        allowStepWithAdditionalMealTypes: diet.allowStepWithAdditionalMealTypes,
        color: diet.color,
        bagContainer: diet.bagContainer,
        selectedAllergens: diet.excludedAllergens,
        selectedIngredients: diet.excludedIngredients,
        selectedIngredientCategories: diet.excludedIngredientCategories,
        allowSelectMenuFromDiets: diet.allowSelectMenuFromDiets,
        clientImage: diet.clientImage?.['@id'] || null,
        clientImageUrl: diet.clientImage?.contentUrl || null,
        mainImage: diet.mainImage?.['@id'] || null,
        mainImageUrl: diet.mainImage?.contentUrl || null,
        hideDisabledMenuPlannerOptions: diet.hideDisabledMenuPlannerOptions,
        invoiceOverride: diet.invoiceOverride === true,
        invoiceOverrideName: diet.invoiceOverrideName,
        carbohydratesAssumption,
        carbohydratesMargin,
        fatsAssumption,
        fatsMargin,
        proteinAssumption,
        proteinMargin,
        urlSlug: diet.pageSettings.slug ?? '',
        metaTitle: diet.seo.title ?? '',
        metaDescription: diet.seo.description ?? '',
        dietDescription: diet.pageSettings.content ?? '',
        bannerEnabled: diet.banner ? diet.banner.enabled : false,
        bannerHeader: diet.banner ? diet.banner.heading : '',
        bannerText: diet.banner ? diet.banner.content : '',
      };

      if (diet.banner) {
        newState.bannerId = diet.banner['@id'];

        if (diet.banner.desktop) {
          newState.infoBannerDesktop = diet.banner.desktop['@id'];
          newState.infoBannerDesktopUrl = diet.banner.desktop?.contentUrl;
        }

        if (diet.banner.mobile) {
          newState.infoBannerMobile = diet.banner.mobile['@id'];
          newState.infoBannerMobileUrl = diet.banner.mobile?.contentUrl;
        }
      }

      setState(prevState => ({ ...prevState, ...newState }));
      convertMinutesToDate(diet, brandConfig);
    }

    setState(prev => ({ ...prev, isLoading: false }));
  }, []);

  const convertMinutesToDate = (dietConfig, brandConfig) => {
    let obj = { ...state.chosenDateAndTime };

    daysOfWeek.forEach(dayOfWeek => {
      setState(prevState => ({
        ...prevState,
        [dayOfWeek]: dayObject(dietConfig[dayOfWeek], timeFields),
      }));

      timeFields.forEach(field => {
        const minutes =
          dietConfig?.[dayOfWeek]?.[field] ||
          brandConfig?.[dayOfWeek]?.[field] ||
          0;

        const data = moment(state.tableHeaderDates[dayOfWeek]).subtract(
          minutes,
          'minutes'
        );

        const date = data.format('YYYY-MM-DD');
        const time = data.format('HH:mm');
        const day = data.locale('en').format('dddd');
        obj[dayOfWeek][field].day = { label: day, value: date };
        obj[dayOfWeek][field].time = time;
      });
    });

    const weekDaysConfig = pick(dietConfig, daysOfWeek);
    const isEnabledCustomTimeSlots = Object.entries(weekDaysConfig).some(
      ([key, value]) => value !== null
    );

    setState(prevState => ({
      ...prevState,
      isEnabledCustomTimeSlots,
      chosenDateAndTime: obj,
    }));
  };

  const convertDateToMinutes = () => {
    const daysWithConvertedMinutes = {};

    for (const [dayName, value] of Object.entries(state.tableHeaderDates)) {
      const obj = {};

      for (const [dayField, dayFieldData] of Object.entries(
        state.chosenDateAndTime[dayName]
      )) {
        const dayFieldMinutes = moment(value).diff(
          moment(`${dayFieldData.day.value} ${dayFieldData.time}`),
          'minutes'
        );

        obj[dayField] = dayFieldMinutes;
      }

      daysWithConvertedMinutes[dayName] = { ...state[dayName], ...obj };
    }

    return daysWithConvertedMinutes;
  };

  const renderIngredients = stateField => {
    return state[stateField].map(item => {
      return {
        name: (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '10px',
            }}
          >
            <TableActions handleRemove={() => removeMeal(stateField, item)} />
            {`${item.label ?? item.value ?? item.name}`}
          </div>
        ),
      };
    });
  };

  const selectAllergens = ({ target: { value } }) =>
    setState(prev => ({ ...prev, selectedAllergens: value }));

  const selectIngredientCategories = ({ target: { value } }) =>
    setState(prev => ({ ...prev, selectedIngredientCategories: value }));

  const handleInputChange = (event, value) => {
    const newValue = event.target.value;
    setState(prev => ({
      ...prev,
      [event.target.name]: value ? value : newValue,
    }));
  };

  const fillDefaultsMargins = marginName => {
    if (state[marginName] === '') {
      setState(prev => ({ ...prev, [marginName]: 5 }));
    }
  };

  const handleToggle = key => {
    setState(prev => ({ ...prev, [key]: !state[key] }));
  };

  const handleColorPicker = color => {
    setState(prev => ({ ...prev, color: color.hex }));
  };

  const validateSlug = slug => {
    const pattern = new RegExp(/^[A-Za-z0-9]+(?:-[A-Za-z0-9]+)*$/);

    return pattern.test(slug);
  };

  const validateForm = () => {
    const isSlugValid = isEmpty(state.urlSlug) || validateSlug(state.urlSlug);

    if (!state.name || !state.code) {
      openToast({
        messages: [t('form.fillAllRequiredFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    if (!isSlugValid) {
      openToast({
        messages: [
          t(
            'form.dits.slugIsInvalid',
            'Pole "Adres url podstrony" jest nieprawidłowe. Pole to może składać się tylko z liter, cyfr oraz myślników. Nie można używać znaków specjalnych ani spacji.'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    return state.name && state.code && isSlugValid;
  };

  const getImage = (stateName, data) => {
    setState(prev => ({
      ...prev,
      [stateName]: data?.['@id'] || null,
      [`${stateName}Url`]: data?.contentUrl || null,
    }));
  };

  const removeImage = stateName => {
    setState(prev => ({
      ...prev,
      [stateName]: null,
      [`${stateName}Url`]: null,
    }));
  };

  const getFilteredIngredients = async e => {
    return fetchIngredients({
      pageSize: 30,
      page: 0,
      sorted: [],
      logicalFilters: { _orX: [[{ name: e, workName: e }]] },
    });
  };

  const addMeal = itemToAdd => {
    const isAlreadyAdded = state.selectedIngredients.some(
      ingredient => ingredient['@id'] === itemToAdd['@id']
    );

    if (isAlreadyAdded) {
      return openToast({
        messages: [t('form.ingredientAdded')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const selectedIngredients = [...state.selectedIngredients, itemToAdd];

    setState(prevState => ({
      ...prevState,
      selectedIngredients,
    }));
  };

  const removeMeal = (stateField, itemToRemove) => {
    const filteredElements = state[stateField].filter(
      ingredient => ingredient['@id'] !== itemToRemove['@id']
    );
    setState(prev => ({ ...prev, [stateField]: filteredElements }));
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return;
    }

    const arrayOfObjectToArrayOfIRI = element => element['@id'];

    const excludedIngredients = state.selectedIngredients.map(
      arrayOfObjectToArrayOfIRI
    );
    const excludedAllergens = state.selectedAllergens.map(
      arrayOfObjectToArrayOfIRI
    );
    const excludedIngredientCategories = state.selectedIngredientCategories.map(
      arrayOfObjectToArrayOfIRI
    );

    const {
      carbohydratesAssumption,
      carbohydratesMargin,
      proteinAssumption,
      proteinMargin,
      fatsAssumption,
      fatsMargin,
    } = state;

    const assumptionOfFats = {
      assumption: toFloat(fatsAssumption),
      margin: toFloat(fatsMargin),
    };
    const assumptionOfCarbohydrates = {
      assumption: toFloat(carbohydratesAssumption),
      margin: toFloat(carbohydratesMargin),
    };
    const assumptionOfProtein = {
      assumption: toFloat(proteinAssumption),
      margin: toFloat(proteinMargin),
    };
    const days = state.isEnabledCustomTimeSlots
      ? convertDateToMinutes()
      : {
          monday: null,
          tuesday: null,
          wednesday: null,
          thursday: null,
          friday: null,
          saturday: null,
          sunday: null,
        };

    const data = {
      name: state.name,
      workName: state.workName,
      position: parseInt(state.position) || null,
      code: state.code,
      description: state.description,
      active: state.checked,
      allowStepWithAdditionalMealTypes: state.allowStepWithAdditionalMealTypes,
      color: state.color,
      excludedIngredients,
      excludedAllergens,
      excludedIngredientCategories,
      clientImage: state.clientImage,
      mainImage: state.mainImage,
      invoiceOverride: state.invoiceOverride ?? false,
      invoiceOverrideName: state.invoiceOverrideName,
      allowSelectMenuFromDiets: state.allowSelectMenuFromDiets,
      hideDisabledMenuPlannerOptions:
        state.hideDisabledMenuPlannerOptions ?? false,
      assumptionOfCarbohydrates,
      assumptionOfProtein,
      assumptionOfFats,
      bagContainer: state.bagContainer,
      seo: {
        title: state.metaTitle,
        description: state.metaDescription,
      },
      pageSettings: {
        slug: isEmpty(state.urlSlug) ? null : state.urlSlug,
        content: state.dietDescription,
      },
      banner: {
        enabled: state.bannerEnabled,
        heading: state.bannerHeader,
        content: state.bannerText,
        desktop: state.infoBannerDesktop,
        mobile: state.infoBannerMobile,
      },
      ...days,
    };

    if (state.bannerId) {
      data.banner['@id'] = state.bannerId;
    }

    setState(prev => ({ ...prev, isSubmitting: true }));

    const action = isEdit
      ? put(`/diets/${dietId}`, data)
      : post('/diets', data);

    action
      .then(() => {
        history.push('/admin/diets');
        setState(prev => ({ ...prev, isSubmitting: false }));
        if (!isEdit) {
          return openToast({
            messages: [t('notify.advancedDiet')],
            type: 'warning',
            autoHideDuration: 10000,
          });
        }
      })
      .catch(() => {
        setState(prev => ({ ...prev, isSubmitting: false }));
      });
  };

  const handleDiets = (e, selected) =>
    setState(prevState => ({
      ...prevState,
      allowSelectMenuFromDiets: selected.map(el => el['@id']),
    }));

  const getDietOptions = () => {
    if (isEdit) {
      return diets.filter(el => el.id !== dietId);
    }

    return diets;
  };

  const setSubpage = subpage => {
    setState(prev => ({ ...prev, subpage: subpage }));
  };

  const handleChange = event => {
    setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const setBagContainer = obj => {
    if (obj?.id === state.bagContainer?.id) {
      return setState(prev => ({ ...prev, bagContainer: null }));
    }
    setState(prev => ({ ...prev, bagContainer: obj }));
  };

  const setDietSubpageData = (value, name) => {
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleDietSubpageInputChange = (value, name) => {
    setState(prevState => ({ ...prevState, [name]: value }));
  };

  const handleDietSubpageBannerInputChange = e => {
    setState(prevState => ({
      ...prevState,
      banner: { ...prevState.banner, [e.target.name]: e.target.value },
    }));
  };

  const handleBannerCheckboxChange = () => {
    setState(prevState => ({
      ...prevState,
      bannerEnabled: !state.bannerEnabled,
    }));
  };

  const props = {
    t,
    classes,
    state,
    setState,
    getImage,
    removeImage,
    handleSubmit,
    setSubpage,
    handleChange,
    setDietSubpageData,
    handleBannerCheckboxChange,
    isEdit,
    dietId,
    renderIngredients,
    selectAllergens,
    selectIngredientCategories,
    handleInputChange,
    fillDefaultsMargins,
    handleToggle,
    handleColorPicker,
    getFilteredIngredients,
    addMeal,
    handleDiets,
    getDietOptions,
    setBagContainer,
    handleDietSubpageInputChange,
    handleDietSubpageBannerInputChange,
    allergens,
    diet,
    loading,
    ingredients,
    ingredientCategories,
  };

  return <FormDietsView props={props} />;
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  diet: state.Diets.diet,
  diets: state.Diets.diets,
  loading: state.Diets.loading,
  allergens: state.Allergens.allergens,
  ingredients: state.Ingredients.ingredients,
  ingredientCategories: state.Ingredients.ingredientCategories,
  brandConfig: state.Brands.brand,
});

export default withTranslation()(
  connect(mapStateToProps, {
    fetchDiet,
    fetchIngredients,
    fetchIngredientCategories,
    fetchAllergens,
    fetchDiets,
  })(withToast(withStyles(combinedStyles)(FormDietsContainer)))
);
