import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from '@material-ui/icons';
import { Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';

import FormTextInput from 'components/FormTextInput/FormTextInput';
import ModuleNotLoaded from './ModuleNotLoaded';

import { withToast } from 'material-ui-toast-redux';
import Button from '../../../components/CustomButtons/Button';
import { Info } from '@material-ui/icons';
import { post } from 'helpers/apiHelpers';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const BitrixModule = ({ classes, handleModuleUpdate, module, openToast }) => {
  const { t } = useTranslation();
  const [isSyncing, setIsSyncing] = useState(false);

  const handleClientsSync = async () => {
    setIsSyncing(true);
    try {
      await post('/bitrix24/client-sync');
      openToast({
        messages: [
          t(
            'brands.newBrandForm.thulium.syncContacts.successMessage',
            'Żądanie zostało wysłane. Synchronizacja klientów to proces który może chwilę potrwać'
          ),
        ],
        type: 'success',
        autoHideDuration: 3000,
      });
      setIsSyncing(false);
    } catch (e) {
      setIsSyncing(false);
    }
  };

  if (!module) {
    return <ModuleNotLoaded moduleName="Bitrix" />;
  }

  return (
    <Card>
      <CardBody>
        <FormControlLabel
          control={
            <Checkbox
              checked={module?.enabled}
              onChange={() =>
                handleModuleUpdate({
                  ...module,
                  enabled: !module.enabled,
                })
              }
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          label={t('brands.newBrandForm.useBitrix', 'Używaj Bitrix24')}
        />
        <Button disabled={isSyncing} onClick={handleClientsSync}>
          {t('brands.newBrandForm.bitrix.syncContacts', 'Synchronizuj')}
        </Button>
        <Tooltip
          title={
            <h4>
              {t(
                'brands.newBrandForm.thulium.syncContacts.tooltip',
                '$$Synchronizacja klientów może chwilę zająć'
              )}
            </h4>
          }
          placement="top"
        >
          <Info fontSize={'small'} style={{ color: 'gray' }} />
        </Tooltip>
        <GridContainer>
          <GridItem sm={12}>
            <FormTextInput
              label={t(
                'brands.newBrandForm.bitrix.webhookClient',
                'Weebhook client'
              )}
              classes={classes}
              name="webhookClient"
              value={module?.webhookClient}
              handleChange={e =>
                handleModuleUpdate({
                  ...module,
                  webhookClient: e.target.value,
                })
              }
              inputSize={12}
            />

            <FormTextInput
              label={t(
                'brands.newBrandForm.bitrix.webhookEvent',
                'Weebhook event'
              )}
              classes={classes}
              name="webhookEvent"
              value={module?.webhookEvent}
              handleChange={e =>
                handleModuleUpdate({
                  ...module,
                  webhookEvent: e.target.value,
                })
              }
              inputSize={12}
            />
          </GridItem>

          <GridItem sm={12}>
            <FormTextInput
              label={t(
                'brands.newBrandForm.bitrix.fieldMarketingTerms',
                'Identyfikator zgód marketingowych'
              )}
              classes={classes}
              name="fieldMarketingTerms"
              value={module?.fieldMarketingTerms}
              handleChange={e =>
                handleModuleUpdate({
                  ...module,
                  fieldMarketingTerms: e.target.value,
                })
              }
              inputSize={12}
            />

            <FormTextInput
              label={t(
                'brands.newBrandForm.bitrix.fieldCreatedAt',
                'Identyfikator daty utworzenia'
              )}
              classes={classes}
              name="fieldCreatedAt"
              value={module?.fieldCreatedAt}
              handleChange={e =>
                handleModuleUpdate({
                  ...module,
                  fieldCreatedAt: e.target.value,
                })
              }
              inputSize={12}
            />

            <FormTextInput
              label={t(
                'brands.newBrandForm.bitrix.fieldOrderId',
                'Identyfikator numeru zamówienia'
              )}
              classes={classes}
              name="fieldOrderId"
              value={module?.fieldOrderId}
              handleChange={e =>
                handleModuleUpdate({
                  ...module,
                  fieldOrderId: e.target.value,
                })
              }
              inputSize={12}
            />

            <FormTextInput
              label={t(
                'brands.newBrandForm.bitrix.fieldClientId',
                'Identyfikator numeru klienta'
              )}
              classes={classes}
              name="fieldClientId"
              value={module?.fieldClientId}
              handleChange={e =>
                handleModuleUpdate({
                  ...module,
                  fieldClientId: e.target.value,
                })
              }
              inputSize={12}
            />

            <FormTextInput
              label={t(
                'brands.newBrandForm.bitrix.fieldClientEmail',
                'Identyfikator adresu e-mail klienta'
              )}
              classes={classes}
              name="fieldClientEmail"
              value={module?.fieldClientEmail}
              handleChange={e =>
                handleModuleUpdate({
                  ...module,
                  fieldClientEmail: e.target.value,
                })
              }
              inputSize={12}
            />

            <FormTextInput
              label={t(
                'brands.newBrandForm.bitrix.fieldClientPhone',
                'Identyfikator numeru telefonu klienta'
              )}
              classes={classes}
              name="fieldClientPhone"
              value={module?.fieldClientPhone}
              handleChange={e =>
                handleModuleUpdate({
                  ...module,
                  fieldClientPhone: e.target.value,
                })
              }
              inputSize={12}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

export default withToast(BitrixModule);
