export const DYNAMIC_BACKGROUND = {
  defaultCatalog: '/media-catalogs/3ba7ea4c-736c-46b2-bf02-f2401e8a41c7',
};

export const DISH_IMAGE_PLACEHOLDER = {
  PLImageCatalog: '/media-catalogs/860c7911-9873-4705-afa6-939d03c1dd4c',
  PLTransparentImageCatalog:
    '/media-catalogs/bd7b8341-80bf-42ec-8890-197e827d57b1',
  CZImageCatalog: '/media-catalogs/d77dcced-eade-4c78-a01d-80820511078a',
  CZTransparentImageCatalog:
    '/media-catalogs/2a24e245-6f90-4831-bba7-d37b7aca05c6',
};
