import React from 'react';
import { useTranslation } from 'react-i18next';

import SUB_PAGES from '../consts/SubPages';

import Button from 'components/CustomButtons/Button';
import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';

const DietPageNavigation = ({ isEditing, subpage, setSubpage = () => {} }) => {
  const { t } = useTranslation();

  return (
    <GridContainer>
      <GridItem xs={12}>
        <h3 style={{ display: 'block' }}>
          {t('views.diets.dietEdit')}
          <div>
            <Button
              onClick={() => setSubpage(SUB_PAGES.CLIENT_PANEL)}
              color={subpage === SUB_PAGES.CLIENT_PANEL ? 'info' : ''}
            >
              {t('views.diets.clientPanel')}
            </Button>
            <Button
              onClick={() => setSubpage(SUB_PAGES.DIET_SUBPAGE)}
              color={subpage === SUB_PAGES.DIET_SUBPAGE ? 'info' : ''}
            >
              {t('views.diets.dietSubpage')}
            </Button>
            {isEditing && (
              <Button
                onClick={() => setSubpage(SUB_PAGES.DIET_FLOW_STATISTICS)}
                color={subpage === SUB_PAGES.DIET_FLOW_STATISTICS ? 'info' : ''}
              >
                {t('views.diets.dietFlowStatistics', '$$Statystyki flow')}
              </Button>
            )}

            <Button
              onClick={() => setSubpage(SUB_PAGES.TIME_SLOTS_SUBPAGE)}
              color={subpage === SUB_PAGES.TIME_SLOTS_SUBPAGE ? 'info' : ''}
            >
              {t('views.diets.timeSettings', '$$Sloty czasowe')}
            </Button>
          </div>
        </h3>
      </GridItem>
    </GridContainer>
  );
};

export default DietPageNavigation;
