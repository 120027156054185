import cx from 'classnames';
import { useState, useMemo } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from '@material-ui/core/Icon';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';

import SidebarSubRoute from 'components/Sidebar/components/SidebarSubRoute';

import ChevronIcon from 'assets/img/ChevronIcon';

const SidebarRoute = props => {
  const {
    key,
    icon,
    mini,
    path,
    state,
    name,
    color,
    views,
    rtlName,
    rtlMini,
    classes,
    rtlActive,
    isMenuExpanded,
    nameFallback,
  } = props;

  const { t } = useTranslation();
  const location = useLocation();

  const [isCollapsed, setIsCollapsed] = useState(false);

  const paths = views.map(view => view?.path);
  const currentPath = (location?.pathname ?? '').slice(6);
  const includesActiveRoute = (paths ?? []).includes(currentPath);

  const filteredViews = useMemo(
    () => views.filter(({ invisible }) => !invisible),
    [views]
  );
  const navLinkClasses =
    classes.itemLink +
    ' ' +
    cx({
      [' ' + classes.collapseActive]: includesActiveRoute,
    });
  const itemText =
    classes.itemText +
    ' ' +
    cx({
      [classes.itemTextMini]: !isMenuExpanded,
      [classes.itemTextMiniRTL]: rtlActive && !isMenuExpanded,
      [classes.itemTextRTL]: rtlActive,
    });
  const collapseItemText =
    classes.collapseItemText +
    ' ' +
    cx({
      [classes.collapseItemTextMini]: !isMenuExpanded,
      [classes.collapseItemTextMiniRTL]: rtlActive && !isMenuExpanded,
      [classes.collapseItemTextRTL]: rtlActive,
    });
  const itemIcon =
    classes.itemIcon +
    ' ' +
    cx({
      [classes.itemIconRTL]: rtlActive,
    });
  const collapseItemMini =
    classes.collapseItemMini +
    ' ' +
    cx({
      [classes.collapseItemMiniRTL]: rtlActive,
    });

  return (
    <ListItem
      key={key}
      className={cx(
        { [classes.item]: icon !== undefined },
        { [classes.collapseItem]: icon === undefined }
      )}
      data-cy={path}
    >
      <NavLink
        to={'#'}
        className={navLinkClasses}
        onClick={e => {
          e.preventDefault();
          setIsCollapsed(!isCollapsed);
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {icon !== undefined ? (
            props?.icon2 ? (
              <props.icon2 className={itemIcon} />
            ) : (
              <Icon className={itemIcon}>{icon}</Icon>
            )
          ) : (
            <span className={collapseItemMini}>
              {rtlActive ? rtlMini : t(mini)}
            </span>
          )}

          {isMenuExpanded && (
            <>
              <ListItemText
                data-cy={`sidebar_${state}`}
                primary={rtlActive ? rtlName : t(name, nameFallback ?? name)}
                disableTypography={true}
                className={cx(
                  { [itemText]: icon !== undefined },
                  { [collapseItemText]: icon === undefined }
                )}
                style={{ fontWeight: '400', paddingLeft: '0' }}
              />
              <ChevronIcon
                width={'12px'}
                style={{
                  transition: 'all 150ms ease-in',
                  transform: isCollapsed ? 'rotate(180deg)' : 'rotate(0)',
                }}
              />
            </>
          )}
        </div>
      </NavLink>

      <Collapse in={isCollapsed && isMenuExpanded} unmountOnExit>
        <List className={classes.list + ' ' + classes.collapseList}>
          {(filteredViews ?? []).map((routeProps, key) => {
            return (
              <SidebarSubRoute
                key={key}
                color={color}
                classes={classes}
                rtlActive={rtlActive}
                isTreeItem={true}
                isMenuExpanded={isMenuExpanded}
                isOneItemTree={filteredViews.length === 1}
                isLastTreeItem={key === filteredViews.length - 1}
                {...routeProps}
              />
            );
          })}
        </List>
      </Collapse>
    </ListItem>
  );
};

export default SidebarRoute;
