import { useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';

import produce from 'immer';

import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import withStyles from '@material-ui/core/styles/withStyles';
import SearchIcon from '@material-ui/icons/Search';
import makeStyles from '@material-ui/styles/makeStyles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import useDebounce from 'hooks/common/useDebounce';

import { get, put } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

import TableInputContainer from './TableInputContainer';
import { DialogLoader } from 'components/DialogLoader';
import Card from 'components/Card/Card';
import GridItem from 'components/Grid/GridItem';
import CardBody from 'components/Card/CardBody';
import CustomInput from 'components/CustomInput/CustomInput';
import GridContainer from 'components/Grid/GridContainer';
import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import ObservableSection from 'components/ObservableSection/ObservableSection';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

const useStyles = makeStyles({
  string_cell: {
    maxWidth: 300,
  },
  translations_cell: {
    minWidth: 200,
  },
});

let newTranslations = {};

const TranslationsTable = ({
  t,
  isApi,
  classes,
  catalog,
  openToast,
  subCatalog,
}) => {
  const [filterValue, setFilterValue] = useState('');
  const [batches, setBatches] = useState([]);
  const [languages, setLanguages] = useState(null);
  const [languageOrder, setLanguageOrder] = useState(null);
  const [translations, setTranslations] = useState(null);
  const [urlSubCatalog, setUrlSubCatalog] = useState(subCatalog);
  const [isLoading, setIsLoading] = useState(false);

  const debouncedFilterValue = useDebounce(filterValue);

  const customStyles = useStyles();

  useEffect(() => {
    geteFilteredTranslationKeysBatches(debouncedFilterValue);
  }, [debouncedFilterValue]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const languagesResponse = await get(`/languages`);
      const langOrder = Object.values(languagesResponse['hydra:member']).map(
        val => val.isoCode
      );
      const translationsResponse = await get(
        `/translations/${catalog}/${urlSubCatalog}`
      );

      const newBatches = getTranslationKeysBatches({
        batchSize: 10,
        translations: Object.entries(translationsResponse ?? {}),
      });

      setBatches(newBatches);
      setLanguages(languagesResponse['hydra:member']);
      setLanguageOrder(langOrder);
      setTranslations(translationsResponse);
      newTranslations = {};

      setIsLoading(false);
    })();
  }, [catalog, urlSubCatalog]);

  useEffect(() => {
    setUrlSubCatalog(subCatalog);
  }, [subCatalog]);

  const changeTranslationValue = (string, language, value) => {
    newTranslations = produce(newTranslations, draftState => {
      if (value === translations[string][language]) {
        delete draftState[string][language];
        if (Object.keys(draftState[string]).length === 0) {
          delete draftState[string];
        }
        return;
      }
      if (draftState[string]) {
        draftState[string][language] = value;
      } else {
        draftState[string] = { [language]: value };
      }
    });
  };

  const handleSubmit = async () => {
    // setIsSubmitting(true);
    const translationObject = {
      [catalog]: {
        [urlSubCatalog]: newTranslations,
      },
    };

    try {
      await put('/translations/update', translationObject);
      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (e) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: 3000,
      });
    } finally {
      // setIsSubmitting(false);
    }
  };

  const getTranslationKeysBatches = ({ batchSize, translations }) => {
    let newBatches = [];
    const numberOfBatches = Math.ceil(translations.length / batchSize);

    for (let index = 0; index < numberOfBatches; index++) {
      newBatches.push(
        translations.slice(index * batchSize, (index + 1) * batchSize)
      );
    }

    return newBatches;
  };

  const geteFilteredTranslationKeysBatches = filterValue => {
    if (isEmpty(translations)) return;

    if (isEmpty(filterValue)) {
      const newBatches = getTranslationKeysBatches({
        batchSize: 10,
        translations: Object.entries(translations ?? {}),
      });

      return setBatches(newBatches);
    }

    setIsLoading(true);
    const translationsEntries = Object.entries(translations ?? {});

    const filteredTranslationsEntries = translationsEntries.filter(
      ([key, translationValues]) => {
        const values = Object.values(translationValues);
        return (
          key.includes(filterValue) ||
          values.some(value => value.includes(filterValue))
        );
      }
    );

    const newBatches = getTranslationKeysBatches({
      batchSize: 10,
      translations: filteredTranslationsEntries,
    });

    setBatches(newBatches);
    return setIsLoading(false);
  };

  return (
    <>
      <DialogLoader loaderState={isLoading} text={t('common.loading')} />

      {languages && translations && !isLoading && (
        <GridContainer style={{ marginTop: '15px' }}>
          <GridItem xs={12}>
            <Card>
              <CardBody>
                <GridContainer>
                  {isApi && (
                    <GridItem md={6}>
                      <FormSelectSingle
                        classes={classes}
                        options={[
                          { value: 'messages', name: 'Messages' },
                          { value: 'validators', name: 'Validators' },
                        ]}
                        mapBy="name"
                        trackBy="value"
                        value={urlSubCatalog}
                        handleChange={e => {
                          setUrlSubCatalog(e.target.value);
                        }}
                      />
                    </GridItem>
                  )}
                  <GridItem xs={12} sm={5} md={4} lg={3}>
                    <CustomInput
                      id="filterValue"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: t(
                          '$*translationsTable.searchBart.placeholder',
                          'Wyszukaj klucz lub tekst...'
                        ),
                        value: filterValue,
                        onChange: e => setFilterValue(e.target.value),
                        endAdornment: <SearchIcon />,
                      }}
                    />
                  </GridItem>
                  <GridItem md={12}>
                    <div style={{ overflow: 'scroll', maxHeight: '520px' }}>
                      <Table className={classes.table}>
                        <TableHead>
                          <TableRow>
                            <TableCell className={customStyles.string_cell}>
                              String
                            </TableCell>
                            {languages.map(lang => (
                              <TableCell
                                key={lang.name}
                                className={customStyles.translations_cell}
                              >
                                {lang.name}
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {batches.map((batch, index) => {
                            return (
                              <ObservableSection
                                id={`batch-${index}`}
                                key={`batch-${index}`}
                                preload={index === 0}
                                enabled
                              >
                                {batch.map(([string, translationValues]) => (
                                  <TableRow
                                    key={`${catalog}-${urlSubCatalog}-${string}`}
                                  >
                                    <TableCell
                                      className={customStyles.string_cell}
                                    >
                                      {string}
                                    </TableCell>
                                    {languageOrder.map(lang => (
                                      <TableCell
                                        key={`${catalog}-${urlSubCatalog}-${string}-${lang}`}
                                        className={
                                          customStyles.translations_cell
                                        }
                                      >
                                        <TableInputContainer
                                          string={string}
                                          translation={translationValues[lang]}
                                          language={lang}
                                          changeTranslationValue={
                                            changeTranslationValue
                                          }
                                        />
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                ))}
                              </ObservableSection>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </div>
                  </GridItem>
                </GridContainer>

                <FormControlButtons
                  classes={classes}
                  submitText={t('common.shared.save', 'Zapisz')}
                  handleSubmit={handleSubmit}
                />
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(({ Auth: { selectedBrand } }) => ({
    selectedBrand,
  })),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(TranslationsTable);
