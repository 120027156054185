import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/CustomButtons/Button.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';

const FormControlButtons = ({
  classes,
  cancelPath,
  submitText,
  discardText,
  submitColor,
  handleSubmit,
  submitDisabled,
  secondarySubmitText,
  secondarySubmitDisabled,
  handleSecondarySubmit,
  customButtonsLeft = [],
  customButtonsRight = [],
  dataCy,
}) => {
  return (
    <GridContainer justify="flex-end">
      <GridItem data-cy={dataCy ? dataCy : '__buttons_add_and_cancel'}>
        {customButtonsLeft.map(({ label, ...props }) => (
          <Button {...props}>{label}</Button>
        ))}
        {cancelPath && (
          <Link to={cancelPath}>
            <Button style={{ margin: '5px' }} className={classes.marginRight}>
              {discardText}
            </Button>
          </Link>
        )}
        {secondarySubmitText && (
          <Button
            style={{ margin: '5px' }}
            color={submitColor ? submitColor : 'success'}
            className={classes.marginLeft}
            onClick={handleSecondarySubmit}
            disabled={secondarySubmitDisabled ? secondarySubmitDisabled : false}
          >
            {secondarySubmitText}
          </Button>
        )}
        {submitText && (
          <Button
            color={submitColor ? submitColor : 'success'}
            className={classes.marginLeft}
            onClick={handleSubmit}
            disabled={submitDisabled ? submitDisabled : false}
          >
            {submitText}
          </Button>
        )}
        {customButtonsRight.map(({ label, ...props }) => (
          <Button {...props}>{label}</Button>
        ))}
      </GridItem>
    </GridContainer>
  );
};

export default FormControlButtons;
