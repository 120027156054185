import { useTranslation } from 'react-i18next';

import {
  Dialog,
  FormGroup,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import Button from 'components/CustomButtons/Button';

import {
  dangerColor,
  successColor,
  warningColor,
} from 'assets/jss/material-dashboard-pro-react.jsx';

import { Label, StyledSpinner } from './styled';

const ResultDialog = ({
  isOpened,
  isLoading = true,
  handleClose,
  results = [],
}) => {
  const { t } = useTranslation();

  const getRestults = () => {
    return results.map(result => {
      switch (result?.type) {
        case 'warning':
          return (
            <Label
              height="auto"
              width="100%"
              margin="0 0 15px 0"
              justifyContent="flex-start"
              backgroundColor={warningColor[0]}
            >
              {result?.message}
            </Label>
          );

        case 'info':
          return (
            <Label
              height="auto"
              width="100%"
              margin="0 0 15px 0"
              justifyContent="flex-start"
              backgroundColor={successColor[0]}
            >
              {result?.message}
            </Label>
          );

        case 'error':
          return (
            <Label
              height="auto"
              width="100%"
              margin="0 0 15px 0"
              justifyContent="flex-start"
              backgroundColor={dangerColor[0]}
            >
              {result?.message}
            </Label>
          );
        default:
          return null;
      }
    });
  };

  return (
    <Dialog open={isOpened} maxWidth="sm" fullWidth>
      {isLoading ? (
        <>
          <DialogContent>
            <FormGroup>
              <div
                style={{
                  display: 'flex',
                  padding: '50px 0',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <h3>
                  {t(
                    '$*form.menuPlanning.attentionMenuIsBeingCopied',
                    '$$Uwaga, trwa kopiowaniu menu, proszę czekać...'
                  )}
                </h3>
                <StyledSpinner viewBox="0 0 50 50" size="50px">
                  <circle
                    className="circle"
                    cx="25"
                    cy="25"
                    r="20"
                    fill="none"
                    strokeWidth="2"
                  />
                </StyledSpinner>
              </div>
            </FormGroup>
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle>
            {t(
              ('$*form.menuPlanning.copyingFinished', '$$Kopiowanie zakończone')
            )}
          </DialogTitle>
          <DialogContent>
            <FormGroup>{getRestults()}</FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t('form.close')}</Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default ResultDialog;
