import GridItem from 'components/Grid/GridItem';
import { Fragment, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { translateActions } from './translateActions';
import { translateModules } from './translateModules';
import GridContainer from 'components/Grid/GridContainer';
import Checkbox from '@material-ui/core/Checkbox';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/styles/withStyles';
import makeStyles from '@material-ui/styles/makeStyles';
import ColumnManager from 'components/DataGrid/ColumnManager';
import { withToast } from 'material-ui-toast-redux';

const useStyles = makeStyles({
  root: {},
  checkboxParent: {
    margin: '0px',
  },
});

const RoleSections = ({
  section,
  permissionGroup,
  assignedPermissions,
  t,
  handleCheckboxChange,
  openToast,
}) => {
  const titleTranslateActions = translateActions(t);
  const roleTranslateModules = translateModules(t);
  const customStyles = useStyles();
  const [state, setState] = useState({
    showAllPermissionsColumn: false,
    initial: null,
    options: [
      { name: 'SHOW', title: titleTranslateActions.SHOW },
      { name: 'CREATE', title: titleTranslateActions.CREATE },
      { name: 'EDIT', title: titleTranslateActions.EDIT },
      { name: 'REMOVE', title: titleTranslateActions.REMOVE },
      { name: 'EXPORT', title: titleTranslateActions.EXPORT },
      { name: 'IMPORT', title: titleTranslateActions.IMPORT },
      { name: 'NOTE', title: titleTranslateActions.NOTE },
    ],
    hidden: ['SHOW', 'CREATE', 'EDIT', 'REMOVE'],
    numberVisibleColumn: null,
  });

  useEffect(() => {
    if (state.initial === null) {
      setInitalConfigVisibilityColumn();
    }
    window.addEventListener('resize', () => {
      setInitalConfigVisibilityColumn();
    });
  }, []);

  const setInitalConfigVisibilityColumn = () => {
    if (window.innerWidth > 0) {
      setState(prevState => ({
        ...prevState,
        initial: true,
        showAllPermissionsColumn: false,
        numberVisibleColumn: 1,
        hidden: ['CREATE', 'EDIT', 'REMOVE', 'EXPORT', 'IMPORT', 'NOTE'],
      }));
    }
    if (window.innerWidth > 750) {
      setState(prevState => ({
        ...prevState,
        initial: true,
        showAllPermissionsColumn: false,
        numberVisibleColumn: 2,
        hidden: ['EDIT', 'REMOVE', 'EXPORT', 'IMPORT', 'NOTE'],
      }));
    }

    if (window.innerWidth > 1000) {
      setState(prevState => ({
        ...prevState,
        initial: true,
        showAllPermissionsColumn: false,
        numberVisibleColumn: 3,
        hidden: ['REMOVE', 'EXPORT', 'IMPORT', 'NOTE'],
      }));
    }

    if (window.innerWidth > 1200) {
      setState(prevState => ({
        ...prevState,
        initial: true,
        showAllPermissionsColumn: false,
        numberVisibleColumn: 5,
        hidden: ['IMPORT', 'NOTE'],
      }));
    }

    if (window.innerWidth > 1900) {
      setState(prevState => ({
        ...prevState,
        initial: true,
        showAllPermissionsColumn: true,
        numberVisibleColumn: 7,
        hidden: [],
      }));
    }
  };

  return (
    <Fragment>
      {/* title and action name */}
      {!state.showAllPermissionsColumn ? (
        <GridContainer>
          <GridItem xs={8} sm={10} lg={10}></GridItem>
          <GridItem xs={4} sm={2} lg={2}>
            <ColumnManager
              columns={state.options}
              hidden={state.hidden}
              setHidden={hidden => {
                if (
                  state.options.length - hidden.length >
                  state.numberVisibleColumn
                ) {
                  return openToast({
                    messages: [
                      t(
                        'rolesForm.showColumn.info',
                        'Osiągnąłeś maksymalną liczbę widocznych kolumn, ukryj jedną z kolumn a następnie ponownie kliknij switch'
                      ),
                    ],
                    type: 'info',
                    autoHideDuration: 6000,
                  });
                }
                setState(prevState => ({
                  ...prevState,
                  hidden: hidden,
                }));
              }}
            />
          </GridItem>
        </GridContainer>
      ) : null}
      <GridContainer>
        <GridItem xs={6} sm={4} md={3} lg={2} xl={5}>
          {t(`${section.name}`).toUpperCase()}
        </GridItem>

        {state.options.map(option => {
          if (!state.hidden.includes(option.name)) {
            return (
              <GridItem xs={6} sm={4} md={3} lg={2} xl={1}>
                {titleTranslateActions[option.name]}
              </GridItem>
            );
          }
          return null;
        })}
        <GridItem
          xs={12}
          style={{
            borderBottom: '2px solid #d2d2d2',
            marginTop: '10px',
            marginBottom: '10px',
          }}
        ></GridItem>
      </GridContainer>

      {/* role title */}
      {(permissionGroup ?? []).map(permission => {
        return (
          permission?.name && (
            <GridContainer
              key={permission.name}
              style={{
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid #E0E0E0',
                padding: '2px 0px 2px 10px',
              }}
            >
              <GridItem xs={6} sm={4} md={3} lg={2} xl={5}>
                {roleTranslateModules[permission?.name]}
              </GridItem>

              {state.options.map(option => {
                if (!state.hidden.includes(option.name)) {
                  return (
                    <GridItem
                      className={customStyles.checkboxParent}
                      xs={6}
                      sm={4}
                      md={3}
                      lg={2}
                      xl={1}
                    >
                      {permission.permissions.map(permissionType => {
                        return permissionType.type === option.name ? (
                          <Checkbox
                            key={permissionType.role}
                            tabIndex={-1}
                            name={permissionType.role}
                            onClick={e => {
                              handleCheckboxChange(
                                e,
                                assignedPermissions.includes(
                                  permissionType.role
                                ),
                                permissionType.role
                              );
                            }}
                            checked={assignedPermissions.includes(
                              permissionType.role
                            )}
                          />
                        ) : null;
                      })}
                    </GridItem>
                  );
                }
                return null;
              })}
            </GridContainer>
          )
        );
      })}
    </Fragment>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle);

export default withTranslation()(
  withToast(withStyles(combinedStyles)(RoleSections))
);
