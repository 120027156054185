import { get, post, remove, patch } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchMenuTemplates = (force = false) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      if (!force && getState().MenuTemplates.menuTemplates.length > 0) {
        resolve({ menuTemplates: getState().MenuTemplates.menuTemplates });

        return getState().MenuTemplates.menuTemplates;
      }

      if (getState().MenuTemplates.loading) {
        return;
      }

      dispatch({
        type: types.LOADING_MENU_TEMPLATES,
        payload: {
          loading: true,
        },
      });
      return get('/menu-templates', { pagination: false }).then(response => {
        dispatch({
          type: types.FETCH_MENU_TEMPLATES,
          payload: {
            menuTemplates: response['hydra:member'],
          },
        });
        dispatch({
          type: types.LOADING_MENU_TEMPLATES,
          payload: {
            loading: false,
          },
        });
        resolve({ menuTemplates: response['hydra:member'] });

        return response['hydra:member'];
      });
    });
  };
};

export const fetchMenuTemplate = date => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      get(`/menu-templates`, { date })
        .then(menuTemplates => {
          let menuTemplate;
          if (menuTemplates['hydra:member'].length > 0) {
            menuTemplate = menuTemplates['hydra:member'][0];
            resolve({ menuTemplate });
          } else {
            menuTemplate = { name: '' };
          }

          dispatch({
            type: types.FETCH_MENU_TEMPLATE,
            payload: { menuTemplate },
          });
        })
        .catch(err => {
          dispatch(resetMenuTemplate());
          reject(err);
        });
    });
  };
};

export const createMenuTemplate = data => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      post('/menu-templates', data)
        .then(menuTemplate => {
          dispatch({
            type: types.CREATE_MENU_TEMPLATE,
            payload: menuTemplate,
          });
          resolve(menuTemplate);
        })
        .catch(reject);
    });
  };
};

export const removeMenuTemplate = id => {
  return dispatch =>
    remove(`/menu-templates/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_MENU_TEMPLATE,
        payload: {
          menuTemplateId: id,
        },
      });
    });
};

export const applyMenuTemplate = (id, date) => {
  return dispatch => {
    return new Promise((resolve, reject) => {
      patch(`/menu-templates/${id}/apply/${date}`, {})
        .then(data => {
          resolve(data);
        })
        .catch(reject);
    });
  };
};

export const resetMenuTemplates = () => {
  return dispatch => ({
    type: types.RESET_MENU_TEMPLATES_STATE,
    payload: {
      menuTemplates: [],
    },
  });
};

export const resetMenuTemplate = () => {
  return dispatch => ({
    type: types.RESET_MENU_TEMPLATE_STATE,
    payload: {
      menuTemplate: { name: '' },
    },
  });
};
