import React from 'react';
import { post } from 'helpers/apiHelpers';

import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation, withTranslation } from 'react-i18next';
import { DialogTitle, Divider, FormLabel } from '@material-ui/core';
import { useState } from 'react';
import Datetime from 'react-datetime';
import DateTime from 'components/Form/DateTme';
import SimpleWysiwyg from 'components/FormTextInput/SimpleWysiwyg';
import { useEffect } from 'react';
import Moment from 'moment';
import DialogActions from '@material-ui/core/DialogActions';
import Button from 'components/CustomButtons/Button';
const NewEmailModal = ({
  classes,
  openToast,
  setEmailModalStatus,
  EmailModalStatus,
  client,
}) => {
  const { t } = useTranslation();
  const [mail, setMail] = useState({
    plannedSendAt: '',
    subject: '',
    content: '',
  });
  const initDate = new Moment().format('DD.MM.YYYY HH:mm');

  useEffect(() => {
    if (mail.plannedSendAt === '') {
      setMail(prev => ({ ...prev, plannedSendAt: initDate }));
    }
  }, []);

  const validateForm = () => {
    return !!mail.plannedSendAt && !!mail.subject.trim() && !!mail.content;
  };

  const sendMail = () => {
    const validate = validateForm();
    if (!validate) {
      return openToast({
        messages: [
          t(
            'common.diets.email.validateForm.error',
            'Wypełnij pola oznaczone gwiazdką'
          ),
        ],
        type: 'error',
        autoHideDuration: 5000,
      });
    }

    post('/emails', {
      plannedSendAt: mail.plannedSendAt,
      subject: mail.subject,
      content: mail.content,
      client: client['@id'],
      data: client.email,
    }).then(newMessage => {
      setMail({ plannedSendAt: '', subject: '', content: '' });
      setEmailModalStatus(false);
      openToast({
        messages: [t('success.emailSent', 'Email został wysłany')],
        type: 'success',
        autoHideDuration: 5000,
      });
    });
  };

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={EmailModalStatus}
      onClose={() => setEmailModalStatus(false)}
    >
      <DialogTitle>
        {t('common.diets.email.title', 'Tworzenie maila')}
      </DialogTitle>
      <Divider style={{ margin: '0px 20px 20px 20px' }} />

      <DialogContent style={{ height: '35vh' }}>
        <GridItem sm={6}>
          <FormLabel className={classes.labelHorizontal}>
            {t('common.diets.email.deliveryDate', 'Data wysyłki')} *
          </FormLabel>
          <DateTime
            timeFormat={true}
            closeOnSelect={true}
            value={mail?.plannedSendAt}
            onChange={ev => {
              setMail(prev => ({
                ...prev,
                plannedSendAt: ev.format('DD.MM.YYYY HH:mm'),
              }));
            }}
            isValidDate={day =>
              day.isAfter(Datetime.moment().subtract(1, 'day'))
            }
            inputProps={{
              placeholder: t('common.diets.email.setDate', 'Podaj datę'),
              readOnly: true,
            }}
            locale="pl"
          />
        </GridItem>
        <GridItem sm={6} style={{ marginTop: '20px' }}>
          <FormLabel className={classes.labelHorizontal}>
            {t('common.diets.email.subject', 'Temat')} *
          </FormLabel>
          <FormTextInput
            classes={classes}
            name="subject"
            value={mail.subject}
            handleChange={e => {
              setMail(prev => ({
                ...prev,
                subject: e.target.value,
              }));
            }}
            inputSize={12}
            type="text"
          />
        </GridItem>
        <GridItem sm={12} style={{ marginTop: '20px' }}>
          <FormLabel className={classes.labelHorizontal}>
            {t('common.diets.email.content', 'Treść komunikatu')} *
          </FormLabel>
        </GridItem>
        <GridItem>
          <SimpleWysiwyg
            handleChange={e => {
              if (mail.content.length > 0) {
                setMail(prev => ({
                  ...prev,
                  content: e.target.value,
                }));
              } else {
                setMail(prev => ({
                  ...prev,
                  content: e.target.value.replace('&nbsp;', ''),
                }));
              }
            }}
            value={mail.content}
          />
        </GridItem>
        {/* </GridItem> */}
      </DialogContent>

      <DialogActions
        className={classes.modalFooter}
        style={{ marginRight: '35px' }}
      >
        <Button onClick={() => setEmailModalStatus(false)}>
          {t('common.diets.email.cancel', 'Anuluj')}
        </Button>
        <Button
          onClick={() => {
            sendMail();
          }}
          color="success"
        >
          {t(t('common.diets.email.sendEmail', 'Wyślij mail'))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(NewEmailModal);
