import React from 'react';
import Notes from 'components/Notes/Notes';
import CardBody from 'components/Card/CardBody.jsx';

import roles from 'helpers/roles';
import CardWrapper from 'components/Card/CardWrapper';

const NotesRecipeForm = ({ recipeId }) => {
  return (
    <CardWrapper>
      <CardBody>
        <Notes
          urlParam={`/recipes/${recipeId}`}
          notePermission={roles.ROLE_NOTE_RECIPE}
        />
      </CardBody>
    </CardWrapper>
  );
};

export default NotesRecipeForm;
