import React from 'react';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Tooltip } from '@material-ui/core';
import GridContainer from '../../components/Grid/GridContainer';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import Datetime from '../../components/Form/DateTme';
import GridItem from '../../components/Grid/GridItem';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import {
  choiceTrueFalseField,
  ReportLabelDivider,
} from '../../components/Report/ReportConfigurator';
import getReportFileName from 'helpers/getReportFileName';

class DishLabels extends React.Component {
  state = {
    packedDate: null,
    shouldBeConsumedTo: null,
  };

  render() {
    const { t, classes } = this.props;
    const title = t('reports.reportDishLabels');
    return (
      <BaseBrandReport
        title={title}
        excelUrl="reports/dish-stickers"
        pdfUrl="reports/dish-stickers-pdf"
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_DISH_STICKERS}
        pdfAvaliable={true}
        additionalParams={this.state}
        excelAvaliable={true}
        useMealCategories={true}
        useZoneCategories={true}
        reportConfiguration={{
          report: 'DishStickers',
          fields: [
            {
              size: 12,
              field: 'dishColumns',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'dishCharsPerColumn',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'ingredientsColumns',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'ingredientsCharsPerColumn',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'allergensColumns',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'allergensCharsPerColumn',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'mode',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: ['COLUMN', 'ROWS'].map(key => ({
                  value: key,
                  label: this.props.t(
                    `configuration.reports.report.DishStickers.mode.${key}`
                  ),
                })),
              },
            },
            {
              size: 12,
              field: 'codeV1Version',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: ['V1_1', 'V1_2', 'V1_3'].map(option => ({
                  value: option,
                  label: this.props.t(
                    `configuration.reports.report.DishStickers.codeV1Version.${option}`
                  ),
                })),
              },
            },
            {
              size: 12,
              field: 'dietColumnMode',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: ['DIET_RAW', 'DIET_OVERRIDDEN_BY_CHOICE'].map(
                  option => ({
                    value: option,
                    label: this.props.t(
                      `configuration.reports.report.DishStickers.dietColumnMode.${option}`
                    ),
                  })
                ),
              },
            },
            {
              size: 12,
              inputProps: {
                content: (
                  <Tooltip
                    style={{ cursor: 'help' }}
                    title={
                      <div>
                        <h4>
                          {this.props.t(
                            'configuration.reports.report.DishStickers.sortBy.help'
                          )}
                        </h4>
                      </div>
                    }
                    placement="bottom"
                  >
                    <span>
                      {this.props.t(
                        'configuration.reports.report.DishStickers.sortBy.title'
                      )}
                    </span>
                  </Tooltip>
                ),
              },
              inputType: 'divider',
            },
            {
              size: 4,
              field: 'sortByDietPosition',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByMealTypePosition',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortBySizeName',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByCodeV1',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByCodeV2',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByClientChanged',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByClientChangedBag',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByContainerType',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByDietWorkName',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByCalorificWorkName',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'sortByVariantShortName',
              inputType: 'number',
            },
            {
              size: 12,
              inputProps: {
                content: <ReportLabelDivider label={'PDF'} />,
              },
              inputType: 'divider',
            },
            choiceTrueFalseField({
              size: 4,
              t: this.props.t,
              field: 'pdfShowIngredients',
            }),
            choiceTrueFalseField({
              size: 4,
              t: this.props.t,
              field: 'pdfShowDiet',
            }),
            choiceTrueFalseField({
              size: 4,
              t: this.props.t,
              field: 'pdfShowMealType',
            }),
            choiceTrueFalseField({
              size: 4,
              t: this.props.t,
              field: 'pdfShowServingSuggestion',
            }),
            choiceTrueFalseField({
              size: 4,
              t: this.props.t,
              field: 'pdfShowDayOfWeek',
            }),
            choiceTrueFalseField({
              size: 4,
              t: this.props.t,
              field: 'pdfShowContainerType',
            }),
            choiceTrueFalseField({
              size: 4,
              t: this.props.t,
              field: 'pdfShowAllergen',
            }),
            choiceTrueFalseField({
              size: 4,
              t: this.props.t,
              field: 'pdfShowMacroIngredients',
            }),
            choiceTrueFalseField({
              size: 4,
              t: this.props.t,
              field: 'pdfShowCodeV2',
            }),
          ],
        }}
        archiveReportNames={['DISH_STICKER']}
        dateType="single"
      >
        <GridContainer style={{ padding: '12px' }}>
          <GridItem sm={12}>
            <h4 className={classes.cardIconTitle}>
              {this.props.t('reports.defaults')}
            </h4>
          </GridItem>
          <GridItem sm={12} md={6} lg={3}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '5px' }}
            >
              {this.props.t('reports.packedDate')}
            </FormLabel>
            <FormControl fullWidth>
              <Datetime
                className="input-datetime--packed-date"
                timeFormat={false}
                dateFormat={moment.localeData().longDateFormat('L')}
                closeOnSelect={true}
                value={new moment(this.state.packedDate)}
                onChange={ev =>
                  this.setState({ packedDate: ev.format('YYYY-MM-DD') })
                }
                onClear={() => this.setState({ packedDate: null })}
                showClear={this.state.packedDate !== null}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </GridItem>
          <GridItem sm={12} md={6} lg={3}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '5px' }}
            >
              {this.props.t('reports.shouldBeConsumedTo')}
            </FormLabel>
            <FormControl fullWidth>
              <Datetime
                className="input-datetime--consumed-date"
                timeFormat={false}
                dateFormat={moment.localeData().longDateFormat('L')}
                closeOnSelect={true}
                value={new moment(this.state.shouldBeConsumedTo)}
                onChange={ev =>
                  this.setState({ shouldBeConsumedTo: ev.format('YYYY-MM-DD') })
                }
                onClear={() => this.setState({ shouldBeConsumedTo: null })}
                showClear={this.state.shouldBeConsumedTo !== null}
                inputProps={{
                  readOnly: true,
                }}
              />
            </FormControl>
          </GridItem>
        </GridContainer>
      </BaseBrandReport>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(DishLabels);
