import { get } from 'helpers/apiHelpers';
import { store } from 'store';

import types from 'helpers/constants';

export const fetchOrderStatuses = () => {
  return dispatch => {
    if (
      !store.getState().OrderStatuses.loading &&
      store.getState().OrderStatuses.orderStatuses.length === 0
    ) {
      dispatch({
        type: types.START_LOADING_ORDER_STATUSES,
      });
      return get('/order-statuses', { pagination: false }).then(response => {
        dispatch({
          type: types.FETCH_ORDERSTATUSES,
          payload: {
            orderStatuses: response['hydra:member'],
          },
        });
        dispatch({
          type: types.FINISH_LOADING_ORDER_STATUSES,
        });
      });
    }
  };
};
