import React, { useState } from 'react';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { FakeUserIcon } from 'components';
import { FormLabel } from '@material-ui/core';
import { orderStyles } from '../../styles';
import SelectInput from 'components/FormSelect/SelectInput';
import { NavLink } from 'react-router-dom';
import { withToast } from 'material-ui-toast-redux';
import { useTranslation } from 'react-i18next';
import { useTranslatedOrderStatuses } from 'views/Orders/Orders/statusTranslations';

const DietDetailsHeader = ({
  classes,
  dietInfo,
  client,
  history,
  allDiets,
  openToast,
  orderInfo,
  fetchOnDietChange,
}) => {
  const { t } = useTranslation();
  const orderStatuses = useTranslatedOrderStatuses();

  const getStatusInfoColor = status => {
    switch (status.systemValue) {
      case 'WAITING_FOR_PAYMENT':
        return 'orange';
      case 'CANCELED':
        return 'red';
      case 'PAID':
      default:
        return 'green';
    }
  };

  const activeDiet = {
    label: dietInfo.active ? (
      <b>
        ID #{dietInfo.id} {t('diets.active', 'Aktywna')}
      </b>
    ) : (
      <span>
        ID #{dietInfo.id} {t('diets.inactive', 'Nieaktywna')}
      </span>
    ),
    id: dietInfo.id,
    value: dietInfo['@id'],
  };

  const [onlyActive, setOnlyActive] = useState(false);

  const dietsToShow = onlyActive
    ? allDiets.filter(diet => diet.active)
    : allDiets;

  const allDietsAreInactive = !dietsToShow.some(diet => diet.active);

  const domain = dietInfo?.order?.comingFromDomain ?? client.homeDomain;
  return (
    <div
      style={{
        marginLeft: '5px',
        marginTop: '15px',
      }}
    >
      <GridContainer>
        <GridItem md={2}>
          <FormLabel>{t('common.client', 'Klient')}</FormLabel>
          <h1>
            <b>
              <NavLink to={`/admin/clients/edit/${client.id}`}>
                {client.firstName} {client.lastName}
              </NavLink>
              <FakeUserIcon email={client.email} domain={domain} />
            </b>
            <div>
              <NavLink to={`/admin/clients/edit/${client.id}`}>
                ID #{client.id}
              </NavLink>
            </div>
          </h1>
        </GridItem>
        <GridItem md={2}>
          <FormLabel>{t('diets.diet', 'Dieta')}</FormLabel>
          <span>
            <h1>
              <b>{dietInfo?.diet?.name}</b>
              <span style={{ display: 'block' }}>ID #{dietInfo.id}</span>
            </h1>
          </span>
        </GridItem>
        <GridItem md={2}>
          <FormLabel>{t('orders.order', 'Zamówienie')}</FormLabel>
          <span>
            <h1>
              <b>
                {dietInfo.isTest && t('common.trial', 'Próbne')}{' '}
                <span
                  style={{
                    color: getStatusInfoColor(orderInfo.status),
                  }}
                >
                  {orderStatuses[orderInfo.status.systemValue]}
                </span>
              </b>
              <span style={{ display: 'block' }}>ID #{dietInfo.order.id}</span>
            </h1>
          </span>
        </GridItem>
        <GridItem md={4}>
          <div style={{ marginTop: '25px' }}>
            {dietInfo.variant && (
              <div style={orderStyles.infoPill}>{dietInfo.variant?.name}</div>
            )}
            {dietInfo.calorific && (
              <div style={orderStyles.infoPill}>{dietInfo.calorific?.name}</div>
            )}
            {dietInfo.dietLength ? (
              <div style={orderStyles.infoPill}>
                {dietInfo.dietLength} {t('brandCfg.days', 'dni')}
              </div>
            ) : null}
          </div>
        </GridItem>
        <GridItem md={2}>
          <FormLabel>
            {t('dietDetails.orderedDiet', 'Zamówiona dieta')}
          </FormLabel>
          <SelectInput
            classes={classes}
            mapBy="label"
            trackBy="value"
            placeholder={t('common.dietPrices.selectDiet', 'Wybierz dietę')}
            label={
              <div>
                <input
                  id={'onlyActiveCheck'}
                  style={{ marginRight: '10px' }}
                  checked={onlyActive}
                  onChange={() =>
                    allDietsAreInactive
                      ? openToast({
                          messages: [
                            t(
                              'dietDetails.noDietsForThisUser',
                              'Brak aktywnych diet dla tego użytkownika'
                            ),
                          ],
                          type: 'warning',
                          autoHideDuration: 3000,
                        })
                      : setOnlyActive(!onlyActive)
                  }
                  type="checkbox"
                />
                <label style={{ userSelect: 'none' }} htmlFor="onlyActiveCheck">
                  {t('dietDetails.showOnlyActive', 'Pokaż tylko aktywne')}
                </label>
              </div>
            }
            options={dietsToShow}
            value={activeDiet}
            handleChange={(e, obj) => {
              history.push(`/admin/ecommerce-diets/preview/${obj.id}`);
              fetchOnDietChange(obj.id);
            }}
            size={12}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default withToast(DietDetailsHeader);
