import React from 'react';
import { compose } from 'recompose';

// API Connection
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// core components

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { DialogLoader } from '../../components/DialogLoader';
import AdvancedMealFormComponent from './AdvancedMealFormComponent';
import MealNotes from './MealNotes';
import DishSubpage from './DishSubpage';
import BasicMealForm from './BasicMealForm';
import DishFlowStatistics from './DishFlowStatistics';
import DishPageNavigation from './components/DishPageNavigation';
import { combineStyles } from '../../helpers/helpers';
import { withTranslation } from 'react-i18next';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import SUB_PAGES from './consts/SubPages';

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

class MealForm extends React.Component {
  state = {
    currentSubPage: SUB_PAGES.MEAL_FORM,
    showNotes: false,
    showDishSubpage: false,
    mealTypeInputValue: '',
  };

  switchSubPage = newCurrentSubPage => {
    this.setState({ currentSubPage: newCurrentSubPage });
  };

  toggleNotesPage = bool => {
    this.setState({ showNotes: bool });
  };

  toggleDishSubpage = bool => {
    this.setState({ showDishSubpage: bool });
  };

  sortByDate = collection => {
    return [...collection].sort(
      (a, b) => new Date(a['createdAt']) - new Date(b['createdAt'])
    );
  };

  mapMealTypesToReactSelectOption = options => {
    return options.map(option => ({
      label:
        option.brand && option.brand.name
          ? `${option.name} - ${option.brand.name}`
          : option.name,
      value: option,
    }));
  };

  render() {
    const {
      t,
      urlSlug,
      classes,
      metaTitle,
      friendlyName,
      handleSubmit,
      metaDescription,
      dishDescription,
      handleCmsInputChange,
    } = this.props;

    if (this.props.isLoading || !this.props.initialized) {
      return (
        <DialogLoader
          loaderState={this.props.isLoading}
          text={t('common.shared.isLoading', 'Wczytywanie dania...')}
        />
      );
    }

    return (
      <>
        <DialogLoader
          loaderState={this.props.isSubmitting}
          text={t('form.savingChanges', 'Zapisywanie zmian...')}
        />
        <h3 style={this.props.isEditing && { display: 'block' }}>
          {this.props.isEditing ? t('form.editMeal') : t('form.createMeal')}
        </h3>
        <DishPageNavigation
          saveToLs={this.props.saveToLs}
          isEditing={this.props.isEditing}
          switchSubPage={this.switchSubPage}
          selectedBrand={this.props.selectedBrand}
          currentSubPage={this.state.currentSubPage}
        />
        {this.state.currentSubPage === SUB_PAGES.MEAL_FORM && (
          <BasicMealForm {...this.props} dishId={this.props.dish.id} />
        )}
        {this.state.currentSubPage === SUB_PAGES.ADVANCED_MEAL_FORM && (
          <AdvancedMealFormComponent
            {...this.props}
            toggleNotesPage={this.toggleNotesPage}
            toggleDishSubpage={this.toggleDishSubpage}
          />
        )}
        {this.state.currentSubPage === SUB_PAGES.MEAL_NOTES && (
          <MealNotes dishId={this.props.dish.id} />
        )}
        {this.state.currentSubPage === SUB_PAGES.DISH_SUBPAGE && (
          <DishSubpage
            classes={classes}
            urlSlug={urlSlug}
            metaTitle={metaTitle}
            metaDescription={metaDescription}
            friendlyName={friendlyName}
            dishDescription={dishDescription}
            handleSubmit={handleSubmit}
            handleCmsInputChange={handleCmsInputChange}
          />
        )}
        {this.props.isEditing &&
          this.state.currentSubPage === SUB_PAGES.FLOW_STATISTICS && (
            <DishFlowStatistics
              dishId={this.props.dish.id}
              urlSlug={urlSlug}
              metaTitle={metaTitle}
              metaDescription={metaDescription}
              friendlyName={friendlyName}
              dishDescription={dishDescription}
              handleCmsInputChange={handleCmsInputChange}
            />
          )}
      </>
    );
  }
}

export default compose(withTranslation(), withStyles(combinedStyles))(MealForm);
