import { useState } from 'react';
import { withToast } from 'material-ui-toast-redux';
import { useTranslation } from 'react-i18next';

import isEmpty from 'lodash/isEmpty';

import Input from '@material-ui/core/Input';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import S from './TreeNode.styles';

const TreeNode = ({
  label,
  indent,
  children,
  openToast,
  onChange = () => {},
  onDeleteNode = () => {},
}) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [initialValue, setInitialValue] = useState(label);

  const [editableValue, setEditableValue] = useState(label);

  const handleChangeSubmit = () => {
    if (isEmpty(editableValue)) {
      return openToast({
        messages: [
          t('$*treeNode.field.error.isEmpty', 'To pole nie może być puste'),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    onChange(editableValue);
    setInitialValue(editableValue);
    setIsEditing(false);
  };

  const handleClickClear = () => {
    onChange(initialValue);
    setIsEditing(false);
  };

  return (
    <S.TreeNodeWrapper indent={indent}>
      <S.TreeNode>
        <div
          style={{ display: 'flex', 'align-items': 'center' }}
          onClick={() => {
            !isEditing && setIsExpanded(prevState => !prevState);
          }}
        >
          {children && children[0] !== false && !isEditing ? (
            <span>
              <KeyboardArrowDownIcon
                style={{
                  transform: isExpanded ? 'rotate(0)' : 'rotate(-90deg)',
                  transition: 'all 0.1s linear',
                }}
              />
            </span>
          ) : (
            <span>
              <KeyboardArrowDownIcon
                style={{
                  visibility: 'hidden',
                }}
              />
            </span>
          )}
          {isEditing ? (
            <Input
              value={editableValue}
              onChange={e => setEditableValue(e.target.value)}
            />
          ) : (
            <p>{label}</p>
          )}
        </div>
        <span>
          {isEditing ? (
            <CheckIcon
              style={{ color: 'rgb(76, 175, 80)' }}
              onClick={handleChangeSubmit}
            />
          ) : (
            <EditIcon
              style={{ color: '#ff9800', 'font-size': '20px' }}
              onClick={() => setIsEditing(true)}
            />
          )}
        </span>
        {isEditing && (
          <span onClick={handleClickClear}>
            <ClearIcon style={{ color: 'rgb(244, 67, 54)' }} />
          </span>
        )}
        {!isEditing && (
          <span onClick={onDeleteNode}>
            <DeleteForeverIcon style={{ color: 'rgb(244, 67, 54)' }} />
          </span>
        )}
      </S.TreeNode>
      {isExpanded && children}
    </S.TreeNodeWrapper>
  );
};

export default withToast(TreeNode);
