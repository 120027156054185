import React from 'react';
import { useTranslation } from 'react-i18next';

import { Creatable } from 'react-select';
import { colourOptions } from './data';

const defaultStyles = {
  control: styles => ({
    ...styles,
    border: 'none',
    borderRadius: '0',
    borderBottom: '1px solid #d2d2d2',
    backgroundColor: 'white',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'none',
    },
  }),
  dropdownIndicator: styles => ({
    ...styles,
    display: 'none',
  }),
  indicatorSeparator: styles => ({
    ...styles,
    display: 'none',
  }),
  option: (styles, { isFocused }) => ({
    ...styles,
    color: isFocused ? 'white' : 'black',
    fontWeight: isFocused ? '700' : '400',
    backgroundColor: isFocused ? '#4dd0e1' : 'white',
    ':hover': {
      color: 'white',
      fontWeight: '700',
    },
    ':active': {
      backgroundColor: '#00b8d4',
    },
  }),
  multiValue: styles => {
    return {
      ...styles,
      backgroundColor: '#00bcd4',
      borderRadius: '9px',
    };
  },
  multiValueLabel: styles => ({
    ...styles,
    color: 'white',
    fontWeight: '700',
  }),
  multiValueRemove: styles => ({
    ...styles,
    color: 'white',
    ':hover': {
      borderRadius: '0 7px 7px 0',
      backgroundColor: '#26c6da',
      color: 'white',
      cursor: 'pointer',
    },
  }),
  menu: styles => ({
    ...styles,
    zIndex: '4 !important',
  }),
};

const logChange = sel => {
  console.log(sel);
};

const Select = ({
  Component = Creatable,
  selectedValues,
  handleChange,
  options,
  customStyles,
  placeholder,
  noOptionsMessage,
  isMulti = true,
  ...restProps
}) => {
  const { t } = useTranslation();

  return (
    <Component
      defaultValue={[]}
      value={selectedValues}
      isMulti={isMulti}
      styles={customStyles || defaultStyles}
      name="colors"
      options={options || colourOptions}
      className="basic-multi-select"
      classNamePrefix="select"
      getOptionLabel={({ label, value }) => label || value}
      getOptionValue={({ value }) => value}
      onChange={handleChange || logChange}
      noOptionsMessage={() =>
        noOptionsMessage ||
        t('components.reactSelect.noOptionsMessage', 'Dodaj nowy tag')
      }
      placeholder={
        placeholder || t('components.reactSelect.placeholder', 'Dodaj tagi')
      }
      {...restProps}
    />
  );
};
export default Select;
