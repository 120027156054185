import React from 'react';
import roles from 'helpers/roles';
import { useTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';
import BaseBrandReport from './BaseBrandReport';
const SelectionsV3Codes = () => {
  const { t } = useTranslation();

  return (
    <>
      <BaseBrandReport
        title={t('reports.reportsSelections')}
        excelUrl="/reports/selections"
        mimeType="application/vnd.ms-excel"
        fileName={getReportFileName(t('reports.reportsSelections'))}
        role={roles.ROLE_SHOW_REPORT_SELECTIONS}
        excelAvaliable={true}
        archiveReportNames={['V3_SELECTIONS']}
        dateType="single"
      />
    </>
  );
};

export default SelectionsV3Codes;
