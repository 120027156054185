import React, { useEffect, useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { rootDefaultState, mappingDefaultState } from '../defaultState';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { post } from 'helpers/apiHelpers';
import { fetchDiets } from 'actions/Diets';
import { fetchVariants } from 'actions/Variants';
import { combineStyles } from 'helpers/helpers';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import { withTranslation } from 'react-i18next';
import SweetAlert from 'react-bootstrap-sweetalert';
import FormMassActionsView from './FormMassActions.view';

const FormMassActionsContainer = ({
  t,
  classes,
  diets,
  fetchDiets,
  fetchVariants,
  openToast,
  history,
  variants,
}) => {
  const [state, setState] = useState({
    ...rootDefaultState,
    isSubmitting: false,
  });

  useEffect(async () => {
    setState(prev => ({ ...prev, isLoading: true }));
    fetchDiets();
    fetchVariants();
  }, []);

  const selectAllergens = ({ target: { value } }) =>
    setState(prev => ({ ...prev, selectedAllergens: value }));

  const selectIngredientCategories = ({ target: { value } }) =>
    setState(prev => ({ ...prev, selectedIngredientCategories: value }));

  const handleInputChange = (event, value) => {
    const newValue = event.target.value;
    setState(prev => ({
      ...prev,
      [event.target.name]: value ? value : newValue,
    }));
  };

  const fillDefaultsMargins = marginName => {
    if (state[marginName] === '') {
      setState(prev => ({ ...prev, [marginName]: 5 }));
    }
  };

  const handleToggle = name => {
    setState(prev => ({ ...prev, [name]: !state[name] }));
  };

  const handleChangeDate = async (event, field) => {
    await setState(prevState => ({
      ...prevState,
      [field]: event.format('YYYY-MM-DD'),
    }));
  };

  const confirmModal = () => {
    setState(prev => ({
      ...prev,
      alert: (
        <SweetAlert
          warning
          title={t('common.shared.areYouSure')}
          onConfirm={() => {
            onSave();
            hideAlert();
          }}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={classes.button + ' ' + classes.success}
          cancelBtnCssClass={classes.button + ' ' + classes.danger}
          confirmBtnText={t('common.shared.yes')}
          cancelBtnText={t('common.shared.no')}
          showCancel
        />
      ),
    }));
  };

  const handleSubmit = () => {
    if (!state.applyToAllActiveDiets) {
      if (!state.dateFrom || !state.dateTo) {
        return openToast({
          messages: [t('massAction.dietElements.validation.dates')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    }

    confirmModal();
  };

  const onSave = () => {
    const data = {
      dateFrom: state.applyToAllActiveDiets ? null : state.dateFrom,
      dateTo: state.applyToAllActiveDiets ? null : state.dateTo,
      applyToAllActiveDiets: state.applyToAllActiveDiets,
      updateDietDefaults: state.updateDietDefaults,
      updateBags: state.updateBags,
      updateSubscriptions: state.updateSubscriptions,
      updateSubscriptionIntents:
        state.updateSubscriptionIntents && !state.resetSubscriptionIntents,
      resetSubscriptionIntents: state.resetSubscriptionIntents,
      mapping: state.mapping.map(el => ({
        from: {
          diet: el.from.diet?.['@id'] ?? null,
          variant: el.from.variant?.['@id'] ?? null,
          calorific: el.from.calorific?.['@id'] ?? null,
        },
        to: {
          diet: el.to.diet?.['@id'] ?? null,
          variant: el.to.variant?.['@id'] ?? null,
          calorific: el.to.calorific?.['@id'] ?? null,
        },
      })),
    };
    setState(prev => ({ ...prev, isSubmitting: true }));

    const action = post('/diet-elements-configurations', data);

    action
      .then(() => {
        history.push('/admin/mass-actions/diet-elements');
        setState(prev => ({ ...prev, isSubmitting: false }));
      })
      .catch(() => setState(prev => ({ ...prev, isSubmitting: false })));
  };

  const hideAlert = () => {
    setState(prev => ({ ...prev, alert: null }));
  };

  const addMappingRow = () => {
    setState(prevState => ({
      ...prevState,
      mapping: [...prevState.mapping, { ...mappingDefaultState() }],
    }));
  };

  const handleDiets = (type, index, e, selected) => {
    const mappings = [...state.mapping];

    mappings[index][type].diet = selected;
    setState(prevState => ({
      ...prevState,
      mapping: [...mappings],
    }));
  };

  const handleVariants = (type, index, e, selected) => {
    const mappings = [...state.mapping];

    mappings[index][type].variant = selected;
    setState(prevState => ({
      ...prevState,
      mapping: [...mappings],
    }));
  };
  const handleCalorific = (type, index, e, selected) => {
    const mappings = [...state.mapping];

    mappings[index][type].calorific = selected;
    setState(prevState => ({
      ...prevState,
      mapping: [...mappings],
    }));
  };

  const getDietVariants = diet => {
    return variants.filter(el => el.diets.includes(diet['@id']));
  };

  const getCountRowsWithDiet = diet => {
    return state.mapping.filter(el => el.from.diet?.['@id'] === diet['@id'])
      .length;
  };

  const getCountRowsWithVariant = variant => {
    return state.mapping.filter(
      el => el.from.variant?.['@id'] === variant['@id']
    ).length;
  };

  const getCountRowsWithVariantAndDiet = (diet, variant) => {
    return state.mapping.filter(
      el =>
        el.from.diet?.['@id'] === diet['@id'] &&
        el.from.variant?.['@id'] === variant['@id']
    ).length;
  };

  const fillVariants = (index, diet) => {
    const variantsToFill = getDietVariants(diet);

    let newMapping = [...state.mapping];
    const currentRow = newMapping.splice(index, 1)[0];

    variantsToFill.forEach((el, vIndex) => {
      newMapping.splice(index + vIndex, 0, {
        from: {
          diet: diet,
          variant: el,
          calorific: null,
        },
        to: {
          diet: currentRow.to.diet,
          variant: currentRow.to.variant,
          calorific: currentRow.to.calorific,
        },
      });
    });

    setState(prevState => ({
      ...prevState,
      mapping: newMapping,
    }));
  };

  const fillVariantCalories = (index, diet, variant) => {
    let newMapping = [...state.mapping];
    const currentRow = newMapping.splice(index, 1)[0];

    variant.calories.forEach((el, vIndex) => {
      newMapping.splice(index + vIndex, 0, {
        from: {
          diet: diet,
          variant: variant,
          calorific: el,
        },
        to: {
          diet: currentRow.to.diet,
          variant: currentRow.to.variant,
          calorific: currentRow.to.calorific,
        },
      });
    });

    setState(prevState => ({
      ...prevState,
      mapping: newMapping,
    }));
  };

  const removeRow = index => {
    let newMapping = [...state.mapping];
    newMapping.splice(index, 1);

    setState(prevState => ({
      ...prevState,
      mapping: newMapping,
    }));
  };

  const props = {
    t,
    classes,
    diets,
    fetchDiets,
    fetchVariants,
    openToast,
    history,
    variants,
    state,
    setState,
    selectAllergens,
    selectIngredientCategories,
    handleInputChange,
    fillDefaultsMargins,
    handleToggle,
    handleChangeDate,
    confirmModal,
    handleSubmit,
    onSave,
    hideAlert,
    addMappingRow,
    handleDiets,
    handleVariants,
    handleCalorific,
    getDietVariants,
    getCountRowsWithDiet,
    getCountRowsWithVariant,
    getCountRowsWithVariantAndDiet,
    fillVariants,
    fillVariantCalories,
    removeRow,
  };
  return <FormMassActionsView props={props} />;
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  diets: state.Diets.diets,
  variants: state.Variants.variants,
});

export default withTranslation()(
  connect(mapStateToProps, {
    fetchDiets,
    fetchVariants,
  })(withToast(withStyles(combinedStyles)(FormMassActionsContainer)))
);
