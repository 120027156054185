import React from 'react';
import roles from '../../helpers/roles';
import { withTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';
import CombinedReport from './CombinedReport/CombinedReport';
import { Tooltip } from '@material-ui/core';
import getReportFileNameWithMultiplier from 'helpers/getReportFileNameWithMultiplier';

const SORT_RAW_OPTIONS = [
  0,
  100,
  1000,
  10000,
  100000,
  1000000,
  10000000,
  100000000,
  1000000000,
];
const SORT_OPTIONS = SORT_RAW_OPTIONS.map((option, index) => ({
  value: option,
  label: index === 0 ? 0 : SORT_RAW_OPTIONS.length - index,
}));
class DishCards extends React.Component {
  state = {
    includeSubscriptions: false,
  };

  render() {
    const { t } = this.props;

    return (
      <CombinedReport
        name="DISHES_CARD"
        title={t('common.mainMenu.reportsDishCards')}
        url="reports/dishes-card"
        mimeTypes={['application/pdf', 'application/vnd.ms-excel']}
        role={roles.ROLE_SHOW_REPORT_DISHES_CARD}
        fileName={params =>
          getReportFileNameWithMultiplier(
            params,
            this.props.t('common.mainMenu.reportsDishCards')
          )
        }
        useDateRange={true}
        showMultiplier={true}
        validateBrands={false}
        validateDatePicker={true}
        useRecipeTags={true}
        singleSelectRecipeTags={true}
        labelSelectTags={t('reports.selectTags')}
        useZoneCategories={true}
        reportConfiguration={{
          report: 'DishCardCompanyConfiguration',
          fields: [
            {
              size: 12,
              field: 'groupBy',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: 'ID',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.groupBy.id`
                    ),
                  },
                  {
                    value: 'CODE_V2',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.groupBy.codeV2`
                    ),
                  },
                ],
              },
            },
            {
              size: 12,
              name: 'showImage',
              field: 'showImage',
              inputProps: {
                content: (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    {this.props.t(
                      'configuration.reports.report.DishCardCompanyConfiguration.showImage'
                    )}
                  </div>
                ),
              },
              inputType: 'checkbox',
            },
            {
              size: 12,
              inputProps: {
                content: (
                  <div style={{ width: '100%', textAlign: 'center' }}>
                    {this.props.t(
                      'configuration.reports.report.DishCardCompanyConfiguration.showCode'
                    )}
                  </div>
                ),
              },
              inputType: 'divider',
            },
            ...['V1', 'V2', 'V3'].map(version => ({
              size: 4,
              field: `showCode${version}`,
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: true,
                    label: this.props.t(`common.yes`),
                  },
                  {
                    value: false,
                    label: this.props.t(`common.no`),
                  },
                ],
              },
            })),
            {
              size: 12,
              field: `showUnitColumn`,
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: true,
                    label: this.props.t(`common.yes`),
                  },
                  {
                    value: false,
                    label: this.props.t(`common.no`),
                  },
                ],
              },
            },
            {
              size: 12,
              field: 'orientation',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: 'Landscape',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.orientation.Landscape`
                    ),
                  },
                  {
                    value: 'Portrait',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.orientation.Portrait`
                    ),
                  },
                ],
              },
            },
            {
              size: 12,
              field: 'codeV1Version',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: ['V1_1', 'V1_2', 'V1_3'].map(option => ({
                  value: option,
                  label: this.props.t(
                    `configuration.reports.report.DishCardCompanyConfiguration.codeV1Version.${option}`
                  ),
                })),
              },
            },
            {
              size: 12,
              field: 'wpoColumnMode',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: 'WPO',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.wpoColumnMode.WPO`
                    ),
                  },
                  {
                    value: 'WPOM',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.wpoColumnMode.WPOM`
                    ),
                  },
                  {
                    value: 'WPO_WPOM',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.wpoColumnMode.WPO_WPOM`
                    ),
                  },
                ],
              },
            },
            {
              size: 12,
              inputProps: {
                content: (
                  <Tooltip
                    style={{ cursor: 'help' }}
                    title={
                      <div>
                        <h4>
                          {this.props.t(
                            'configuration.reports.report.DishCardCompanyConfiguration.sortBy.help'
                          )}
                        </h4>
                      </div>
                    }
                    placement="bottom"
                  >
                    <span>
                      {this.props.t(
                        'configuration.reports.report.DishCardCompanyConfiguration.sortBy.title'
                      )}
                    </span>
                  </Tooltip>
                ),
              },
              inputType: 'divider',
            },
            {
              size: 4,
              field: 'sortByDietPosition',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: SORT_OPTIONS,
              },
            },
            {
              size: 4,
              field: 'sortByMealTypePosition',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: SORT_OPTIONS,
              },
            },
            {
              size: 4,
              field: 'sortByCodeV1',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: SORT_OPTIONS,
              },
            },
            {
              size: 4,
              field: 'sortBySizePosition',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: SORT_OPTIONS,
              },
            },
            {
              size: 4,
              field: 'sortByCodeV2',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: SORT_OPTIONS,
              },
            },
            {
              size: 4,
              field: 'sortByClientChanged',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: SORT_OPTIONS,
              },
            },
            {
              size: 12,
              inputProps: {
                content: (
                  <Tooltip
                    style={{ cursor: 'help' }}
                    title={
                      <div>
                        <h4>
                          {this.props.t(
                            'configuration.reports.report.DishCardCompanyConfiguration.keyBy.help'
                          )}
                        </h4>
                      </div>
                    }
                    placement="bottom"
                  >
                    <span>
                      {this.props.t(
                        'configuration.reports.report.DishCardCompanyConfiguration.keyBy.title'
                      )}
                    </span>
                  </Tooltip>
                ),
              },
              inputType: 'divider',
            },
            {
              size: 4,
              field: 'keyByDish',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'keyByDiet',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'keyByMealType',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'keyByClientChanged',
              inputType: 'number',
            },
            {
              size: 4,
              field: 'keyByCodeV1',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'roundDecimalPoints',
              inputType: 'number',
            },
            {
              size: 12,
              field: `forceKg`,
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: true,
                    label: this.props.t(`common.yes`),
                  },
                  {
                    value: false,
                    label: this.props.t(`common.no`),
                  },
                ],
              },
            },
          ],
        }}
        archiveReportNames={['DISHES_CARD', 'DISH_CARDS_XLSX']}
      />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(DishCards);
