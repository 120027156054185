import React from 'react';

import { Info } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core/index';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  styles: {
    maxWidth: props => props.maxWidth,
    color: props => props.color,
  },
}));

const CustomTooltip = ({
  title,
  style = { maxWidth: '300px', color: 'white' },
}) => {
  const customStyles = useStyles(style);
  return (
    <Tooltip
      classes={{ tooltip: customStyles.styles }}
      title={<h4>{title}</h4>}
      placement="right"
    >
      <Info style={{ marginLeft: '5px', color: 'grey', fontSize: '16px' }} />
    </Tooltip>
  );
};

export default CustomTooltip;
