import React from 'react';
import { withToast } from 'material-ui-toast-redux';

import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';

import withStyles from '@material-ui/core/styles/withStyles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { useState } from 'react';
import MailNotificationClientToggleableCardView from './MailNotificationClientToggleableCard.view';

const MailNotificationClientToggleableCardContainer = ({
  classes,
  t,
  mailingOptions,
  clientMailing,
  openToast,
  mailing,
  adminMailing,
  allowToChangedElements,
}) => {
  const [state, setState] = useState({ mailing });

  const props = {
    state,
    setState,
    classes,
    t,
    mailingOptions,
    clientMailing,
    adminMailing,
    openToast,
  };
  return <MailNotificationClientToggleableCardView props={props} />;
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(MailNotificationClientToggleableCardContainer);
