import React, { useState } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import gastro from 'helpers/gastro';
import axios from 'helpers/gastro';
import { get, post } from 'helpers/apiHelpers';
import { compose } from 'redux';
import { withToast } from 'material-ui-toast-redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import importCSVExample from '../exampleFiles/import-csv-example.csv';
import importCSVExamplePrices from '../exampleFiles/import-csv-example-prices.csv';
import importCSVExampleTranslations from '../exampleFiles/import-csv-example-translations.csv';
import importCSVExamplePricesFi from '../exampleFiles/import-csv-example-prices-fi.csv';
import importCSVExampleFi from '../exampleFiles/import-csv-example-fi.csv';
import CustomRemoveBody from '../auixilaryFunctions/CustomRemoveBody';
import { remove } from 'helpers/apiHelpers';
import moment from 'moment';
import ListIngredientsView from './ListIngredients.view';

const ListIngredientsContainer = ({
  history,
  classes,
  i18n,
  multinational,
  t,
  brand,
  openToast,
}) => {
  const [state, setState] = useState({
    openedModal: false,
    modalMode: '',
    file: null,
    isImporting: false,
    importButtonClicked: false,
    checkedRowIds: [],
    isConfirmModalOpened: false,
    refreshList: false,
    recipesWithIngredientArray: [],
    exporting: false,
  });

  const { language } = i18n;
  const { currency } = multinational;

  const newSize = () => history.push(`${window.location.pathname}/add`);

  const renderCustomRemoveBody = props => {
    return <CustomRemoveBody {...props} classes={classes} />;
  };

  const toggleModal = mode => {
    setState(prevState => ({
      ...prevState,
      openedModal: !prevState.openedModal,
      modalMode: mode,
    }));
  };

  const importIngredients = () => {
    setState(prev => ({ ...prev, importButtonClicked: true }));
    uploadFile(state.file).then(file => {
      postImporterTask(file, state.modalMode);
    });
  };

  const postImporterTask = (file, mode) => {
    setState(prev => ({ ...prev, isImporting: true }));

    const data = {
      type:
        mode === 'ingredients'
          ? 'INGREDIENTS'
          : mode === 'translations'
          ? 'INGREDIENT_TRANSLATIONS'
          : 'INGREDIENT_PRICES',
      file: file['@id'],
      config: {
        skipHeader: true,
        delimiter: ';',
      },
    };

    post('/importer-tasks', data).then(
      res => {
        setState(prev => ({
          ...prev,
          openedModal: false,
          file: null,
          importButtonClicked: false,
        }));
        setState(prev => ({ ...prev, isImporting: false }));
        return openToast({
          messages: [t('form.importerInfo26'), t('form.importerInfo23')],
          type: 'success',
          autoHideDuration: 3000,
        });
      },
      error => {
        setState(prev => ({ ...prev, isImporting: false }));
        return openToast({
          messages: [t('form.importerInfo25')],
          type: 'error',
          autoHideDuration: 3000,
        });
      }
    );
  };

  const uploadFile = async file => {
    let data = new FormData();
    data.append('file', file);

    let response = await gastro.post('/media', data);

    return new Promise((resolve, reject) => {
      resolve(response.data);
    });
  };

  const loadExample = language => {
    switch (language) {
      case 'fi':
        return importCSVExampleFi;
      default:
        return importCSVExample;
    }
  };

  const loadPricesExample = language => {
    switch (language) {
      case 'fi':
        return importCSVExamplePricesFi;
      default:
        return importCSVExamplePrices;
    }
  };

  const loadTranslationsExample = language => {
    switch (language) {
      case 'fi':
        return importCSVExampleTranslations;
      default:
        return importCSVExampleTranslations;
    }
  };

  const refreshList = () => {
    setState(prevState => ({
      ...prevState,
      refreshList: !prevState.refreshList,
    }));
  };

  const massDeleteHandleMultipleCheckboxes = array => {
    setState(prevState => ({ ...prevState, checkedRowIds: array }));
  };

  const massDeleteHandleSingleCheckbox = id => {
    const checkedIdsUpdated = [...state.checkedRowIds];
    const checkedIdIndex = checkedIdsUpdated.indexOf(id);
    if (checkedIdIndex >= 0) {
      checkedIdsUpdated.splice(checkedIdIndex, 1);
    } else {
      checkedIdsUpdated.push(id);
    }
    setState(prevState => ({
      ...prevState,
      checkedRowIds: checkedIdsUpdated,
    }));
  };

  const checkForOccurrenceIngredientWithDishes = () => {
    let recipesWithIngredientArray = [];
    let url = `/recipes?`;
    state.checkedRowIds.forEach((id, index) => {
      if (index === 0) {
        url = url.concat(`ingredients.ingredient.id[]=${id}`);
      } else {
        url = url.concat(`&ingredients.ingredient.id[]=${id}`);
      }
    });

    get(url, {
      pagtination: false,
      itemsPerPage: 200,
    }).then(recipe => {
      recipe['hydra:member'].map(recipe => {
        recipesWithIngredientArray.push(recipe.workName + ' ');
        return null;
      });
      setState(prevState => ({
        ...prevState,
        recipesWithIngredientArray: recipesWithIngredientArray,
        isConfirmModalOpened: true,
      }));
    });
  };

  const massDeleteSubmit = () => {
    setState(prevState => ({
      ...prevState,
      isConfirmModalOpened: false,
      isDeleting: false,
    }));

    let url = `/ingredients?`;
    state.checkedRowIds.forEach((id, index) => {
      if (index === 0) {
        url = url.concat(`id[]=${id}`);
      } else {
        url = url.concat(`&id[]=${id}`);
      }
    });
    url = url.concat(`&removeAllAction=true`);
    remove(url)
      .then(() => {
        openToast({
          messages: [
            t(
              'dictionary.massdelete.success',
              'Wybrane elementy zostały usunięte'
            ),
          ],
          type: 'success',
          autoHideDuration: 5000,
        });
        refreshList();
      })
      .catch(err => {
        openToast({
          messages: [
            t(
              'dictionary.massdelete.error',
              'Usunięcie wybranych elementów nie powiodło się'
            ),
          ],
          type: 'error',
          autoHideDuration: 5000,
        });
      });
  };

  const exportPrices = () => {
    const fileName = 'prices_export_file';
    const endpoint = '/ingredient-prices-export';
    const format = 'xlsx';
    const date = moment().format('DD-MM-YYYY_HH-mm');
    const exportedName = `${fileName}_${date}`;

    const params = {
      'order[id]': 'DESC',
      properties: ['id', 'name', 'workName', 'shoppingName', 'price'],
    };

    axios
      .get(`${endpoint}.${format}`, {
        responseType: 'blob',
        params: params,
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${exportedName}.${format}`);
          document.body.appendChild(link);
          link.click();
          setState(prev => ({ ...prev, exporting: false }));
        },
        error => {
          setState(prev => ({ ...prev, exporting: false }));
          return openToast({
            messages: [
              t(
                'errors.dataExportFailed',
                'Nie udało się wyeksportować danych'
              ),
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
      );
  };

  const exportTranslations = () => {
    const fileName = 'translations_export_file';
    const endpoint = '/ingredient-translations-export';
    const format = 'xlsx';
    const date = moment().format('DD-MM-YYYY_HH-mm');
    const exportedName = `${fileName}_${date}`;

    const params = {
      'order[id]': 'DESC',
    };

    axios
      .get(`${endpoint}.${format}`, {
        responseType: 'blob',
        params: params,
      })
      .then(
        response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${exportedName}.${format}`);
          document.body.appendChild(link);
          link.click();
          setState(prev => ({ ...prev, exporting: false }));
        },
        error => {
          setState(prev => ({ ...prev, exporting: false }));
          return openToast({
            messages: [
              t(
                'errors.dataExportFailed',
                'Nie udało się wyeksportować danych'
              ),
            ],
            type: 'error',
            autoHideDuration: 3000,
          });
        }
      );
  };

  const props = {
    classes,
    state,
    setState,
    t,
    language,
    currency,
    newSize,
    renderCustomRemoveBody,
    toggleModal,
    importIngredients,
    loadExample,
    loadPricesExample,
    loadTranslationsExample,
    massDeleteHandleMultipleCheckboxes,
    checkForOccurrenceIngredientWithDishes,
    massDeleteSubmit,
    exportPrices,
    exportTranslations,
    massDeleteHandleSingleCheckbox,
  };
  return <ListIngredientsView props={props} />;
};

const enhance = compose(
  connect(({ Brands: { brand: { multinational } } }) => ({ multinational })),
  withToast,
  withStyles(buttonsStyle),
  withTranslation()
);

export default enhance(ListIngredientsContainer);
