import React from 'react';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

class FoodCostBags extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('reports.reportFoodCostBags');

    return (
      <BaseBrandReport
        title={title}
        excelUrl="reports/food-cost-bags"
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_FOOD_COST_BAGS}
        pdfAvaliable={false}
        excelAvaliable={true}
        dateType="range"
        archiveReportNames={['FOOD_COST_BAGS']}
      />
    );
  }
}

export default withTranslation()(FoodCostBags);
