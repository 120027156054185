import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import Menu from '@material-ui/icons/Menu';

// core components
import AdminNavbarLinks from './AdminNavbarLinks';
import Button from 'components/CustomButtons/Button.jsx';

import adminNavbarStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarStyle.jsx';

import dashRoutes from '../../routes';
import ChevronIcon from 'assets/img/ChevronIcon';
import { get } from '../../helpers/apiHelpers';
import { OpenInNew } from '@material-ui/icons';

function AdminNavbar({ ...props }) {
  const { classes, color, rtlActive, brandText, location } = props;
  const appBarClasses = cx({
    [' ' + classes[color]]: color,
  });

  const { t } = useTranslation();
  const [breadcrumbsName, setBreadcrumbsName] = useState(null);
  const [breadcrumbsIcon, setBreadcrumbsIcon] = useState(null);
  const [breadcrumbsPath, setBreadcrumbsPath] = useState(null);
  const [editFieldName, setEditFieldName] = useState(null);
  const [hoverElement, setHoverElement] = useState(false);

  useEffect(() => {
    setEditFieldName(null);
    setBreadcrumbs();
    if (
      location.pathname.includes('/edit/') ||
      location.pathname.includes('/preview/')
    ) {
      subpageNameSettings();
    }
  }, [location.pathname]);

  const setBreadcrumbs = () => {
    const elementWitchoutViews = dashRoutes.filter(el => el.path !== undefined);

    if (location.pathname.includes('/admin/meals/edit/')) {
      setBreadcrumbsPath('/admin/meals');
    }

    if (location.pathname.includes('/admin/filter-line-diets/edit/')) {
      setBreadcrumbsPath('/admin/filter-line-diets/');
    }

    elementWitchoutViews.map(el => {
      if (location.pathname.includes('/admin/my-links')) {
        setBreadcrumbsName('Skróty');
        setBreadcrumbsIcon(<OpenInNew />);
      }
      if (location.pathname.includes('/admin' + el.path)) {
        setBreadcrumbsIcon(el.icon);
        setBreadcrumbsPath('/admin' + el.path);
        setBreadcrumbsName(null);
      }
      return null;
    });
    const elWithViews = dashRoutes.filter(el => el.path === undefined);

    elWithViews.map(el => {
      for (let i = 0; i < el.views.length; i++) {
        if (location.pathname === '/admin' + el.views[i].path) {
          setBreadcrumbsIcon(el.icon);
          setBreadcrumbsName(t(el.name));
          setBreadcrumbsPath('/admin' + el.views[i].path);
        }
      }
      return null;
    });
  };

  const subpageNameSettings = () => {
    const locationPathname = location.pathname;
    const path = locationPathname.split('/')[2]; // get only part of path after /admin/    const id = locationPathname.match(/\d+/g)[0]; // get first number of regex array
    let id = locationPathname.match(/\d+/g)[0]; // get first number of regex array

    if (path === 'bag-containers' || path === 'product-feed') {
      id = locationPathname.split('/')[4];
    }

    if (path === 'kitchen') {
      setEditFieldName('Przepisy do zrobienia');
      return;
    }

    if (path === 'dishes-packhouse') {
      setEditFieldName('Dania do spakowania');
      return;
    }

    if (path === 'bags-packhouse') {
      setEditFieldName('Diety do spakowania');
      return;
    }

    const endpoint = {
      meals: 'dishes',
      types: 'meal-types',
      messages: 'notifications',
      'product-feed': 'feeds',
      'pages-creator': 'pages',
    }[path];

    if (endpoint) {
      get(`/${endpoint}/${id}`).then(el => {
        if (el.name) {
          setEditFieldName(el.name);
        } else if (el.firstName) {
          setEditFieldName(`${el.firstName} ${el.lastName}`);
        } else if (el.workName) {
          setEditFieldName(el.workName);
        } else if (el.workingName) {
          setEditFieldName(el.workingName);
        } else if (el.code) {
          setEditFieldName(el.code);
        } else if (el.value) {
          setEditFieldName(el.value);
        } else if (el.title) {
          setEditFieldName(el.title);
        } else if (el.content) {
          setEditFieldName('Edycja komunikatu');
        } else if (el.diet) {
          setEditFieldName(el.diet.name);
        } else if (el.label) {
          setEditFieldName(el.label);
        } else if (el.domain) {
          setEditFieldName('Edycja Product Feed');
        }
      });
    }
  };
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <div
            style={{
              paddingLeft: '15px',
              display: 'flex',
              marginLeft: '10px',
              alignItems: 'center',
              fontSize: '1.3em',
              color: '#888888',
            }}
            className={classes.title}
            color="transparent"
          >
            <span style={{ display: 'flex' }}>{breadcrumbsIcon}</span>
            <div
              style={{
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <h3>{breadcrumbsName} </h3>
              {breadcrumbsName && (
                <ChevronIcon
                  width={'12px'}
                  style={{
                    margin: '0 10px 0 10px',
                    transform: 'rotate(-90deg)',
                  }}
                />
              )}
              <a
                style={{
                  cursor: 'pointer',
                  color: '#888888',
                  transition: '.5s',
                  borderBottom: hoverElement ? '1px solid #888888' : '',
                }}
                href={`${breadcrumbsPath}`}
                onMouseOver={() => setHoverElement(!hoverElement)}
                onMouseOut={() => setHoverElement(!hoverElement)}
              >
                <h3>{brandText}</h3>
              </a>
              {editFieldName && (
                <ChevronIcon
                  width={'12px'}
                  style={{
                    margin: '0 10px 0 10px',
                    transform: 'rotate(-90deg)',
                  }}
                />
              )}
              <h3 style={{ color: 'black' }}>{editFieldName}</h3>
            </div>
          </div>
        </div>
        <Hidden xsDown implementation="css">
          <AdminNavbarLinks rtlActive={rtlActive} />
        </Hidden>
        <Hidden mdUp implementation="css">
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

AdminNavbar.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
};

export default withStyles(adminNavbarStyle)(AdminNavbar);
