//[type][reportName]: [translationKey]
const Report = {
  CUSTOM: {},
  REPORT: {
    ACTIVE_DIETS: 'common.mainMenu.reportActiveDiets',
    BAG_STICKERS: 'common.mainMenu.reportsBoxLabels',
    CHECK_LIST_DISHES: 'common.mainMenu.reportsDishesChecklist',
    CHECK_LIST_RECIPES: 'common.mainMenu.reportsRecipesChecklist',
    CONTAINERS: 'common.mainMenu.reportsContainers',
    DELIVERY: 'common.mainMenu.reportsForDriver',
    DISHES_CARD: 'common.mainMenu.reportsDishCards',
    DISH_LIST: 'common.mainMenu.reportsDishesToProduction',
    DISH_STICKER: 'common.mainMenu.reportsDishLabels',
    FOOD_COST: 'common.mainMenu.reportsFoodCost',
    FOOD_COST_BAGS: 'common.mainMenu.reportsFoodCostBags',
    INCOMING_SUBSCRIPTION: 'common.mainMenu.reportsIncomingSubscriptions',
    INCOME_SUMMARY: 'common.mainMenu.reportsIncomeSummary',
    MENU_PLANNER: 'common.mainMenu.menuPlanner',
    MONEY_BOX_HISTORY: 'common.mainMenu.reportsMoneyboxTopUp',
    NO_COLUMNS: 'NO_COLUMNS',
    OVERPRODUCTION: 'common.mainMenu.reportsOverProduction',
    PACKERS: 'common.mainMenu.reportPackers',
    PERSONAL_PICKUPS: 'common.mainMenu.reportPersonalPickups',
    PURCHASED_ADDONS: 'common.mainMenu.reportsAddonsList',
    PURCHASED_ADDONS_BY_CLIENT: 'common.mainMenu.reportsAddonsByClientList',
    RECIPE_STICKERS: 'common.mainMenu.reportRecipeStickers',
    RECIPES_CARD: 'common.mainMenu.reportsRecipeCards',
    SHOPPING_LIST: 'common.mainMenu.reportsShoppingList',
    USED_DISCOUNT_CODES: 'common.mainMenu.reportsDiscountCodes',
  },
  EXPORT: {
    allergen: 'common.mainMenu.allergens',
    container: 'common.mainMenu.containers',
    ecocontainer: 'common.mainMenu.ecoContainers',
    ingredientcategory: 'common.mainMenu.ingredientCategories',
    tag: 'common.mainMenu.tagsForProduction',
    homerequirement: 'common.mainMenu.homeRequirements',
    sex: 'common.mainMenu.sex',
    howknow: 'common.mainMenu.howKnow',
    wholesaler: 'common.mainMenu.wholesalers',
    clienttag: 'common.mainMenu.tagsForClients',
    unit: 'common.mainMenu.units',
    servingsuggestion: 'common.mainMenu.servingSuggestions',
    pickuppoint: 'common.mainMenu.pickupPoints',
    recipe: 'common.mainMenu.recipes',
    masssms: 'common.mainMenu.sms',
    diet: 'common.mainMenu.diets',
    employee: 'common.mainMenu.myCompanyEmployees',
    client: 'common.mainMenu.clients',
    size: 'common.mainMenu.sizes',
    address: 'common.mainMenu.addresses',
    order: 'common.mainMenu.orders',
    rate: 'common.mainMenu.clientComments',
    ingredient: 'common.mainMenu.ingredients',
    subscription: 'common.mainMenu.subscriptions',
    discountcode: 'common.mainMenu.discountCodes',
    package: 'common.mainMenu.dietFilters',
    variant: 'common.mainMenu.variants',
    role: 'common.mainMenu.myCompanyRoles',
    endofdietreminder: 'common.mainMenu.myCompanyMailReminders',
    driver: 'common.mainMenu.drivers',
    addoncategory: 'common.mainMenu.addonsCategories',
    addon: 'common.mainMenu.addons',
    zone: 'common.mainMenu.zones',
    zonecost: 'common.mainMenu.priceLists',
    discountsameaddress: 'common.mainMenu.discounts',
    discountpickuppoint: 'common.mainMenu.discounts',
    discountdietlength: 'common.mainMenu.discounts',
    mealtype: 'common.mainMenu.typesOfMeals',
    contractor: 'common.mainMenu.contractors',
    page: 'common.mainMenu.myCompanyPagesCreator',
    waste: 'common.mainMenu.waste',
    dietcost: 'common.mainMenu.priceLists',
    subbrand: 'common.mainMenu.subBrands',
    adminnavlink: 'common.mainMenu.myCompanyManageNavLinks',
    overproduction: 'common.mainMenu.additionalOrders',
    brand: 'common.mainMenu.myCompanyBrands',
    dish: 'common.mainMenu.meals',
  },
};

export default Report;
