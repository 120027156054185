import { useState, useEffect } from 'react';
import { combineStyles } from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  fetchPaymentTypes,
} from '../../../actions/Brands';
import { FormTextInput } from 'components';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import CardBody from '../../../components/Card/CardBody';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Card from '../../../components/Card/Card';
import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import { Tooltip } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { get, put } from 'helpers/apiHelpers';
import { DialogLoader } from '../../../components/DialogLoader';
import FormControlButtons from '../../../components/FormControlButtons/FormControlButtons';

const AccessKeyEditModule = ({ t, match, history, openToast, classes }) => {
  const [appMode, setAppMode] = useState(null);
  const [accessKeyName, setAccessKeyName] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await get(`/brand-access-keys/${match?.params?.id}`);
      setAppMode(response?.modulesConfiguration?.AppMode ?? {});
      setAccessKeyName(response?.name ?? '');
      setIsLoading(false);
    } catch (error) {
      console.log('Error: ', error);
    }
  };

  const handleCheckboxClick = ({ target: { name } }) => {
    if (appMode[name] === 'DISABLED') {
      setAppMode(prevState => ({
        ...prevState,
        [name]: name === 'catering' ? 'FULL' : 'DISHES',
      }));
    } else if (name === 'override') {
      setAppMode(prevState => ({
        ...prevState,
        [name]: !appMode[name],
      }));
    } else {
      setAppMode(prevState => ({
        ...prevState,
        [name]: 'DISABLED',
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await put(`/brand-access-keys/${match?.params?.id}`, {
        name: accessKeyName,
        overriddenConfiguration: {
          AppMode: {
            ...appMode,
          },
        },
      });
      openToast({
        messages: [t('common.shared.savedChanges')],
        type: 'success',
        autoHideDuration: 3000,
      });
      history.push('/admin/access-keys');
    } catch (error) {
      setIsLoading(false);
      console.log('Error: ', error);
    }
  };

  return (
    <>
      <DialogLoader
        loaderState={isLoading}
        text={t('common.shared.isLoading')}
      />

      {appMode && !isLoading && (
        <>
          <Card>
            <CardBody>
              <GridContainer>
                <GridItem md={12}>
                  <FormTextInput
                    multiline
                    label={t('columns.name', 'Nazwa')}
                    classes={classes}
                    name="name"
                    value={accessKeyName}
                    handleChange={ev => {
                      setAccessKeyName(ev.target.value);
                    }}
                    inputSize={6}
                    maxLength={400}
                  />
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <h3>
                    {t(
                      'brandCfg.operatingMode.formOfBusinessOrSale',
                      'Formy działalności / Formy sprzedaży'
                    )}
                  </h3>
                </GridItem>
                <GridItem xs={12} lg={6}>
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="override"
                          onClick={handleCheckboxClick}
                          checked={appMode?.override}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={t(
                        'brandCfg.accessKeys.appMode.override',
                        'Czy nadpisywać?'
                      )}
                    />
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="catering"
                        onClick={handleCheckboxClick}
                        checked={appMode.catering === 'FULL'}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                        disabled={!appMode.override}
                      />
                    }
                    label={t(
                      'brandCfg.operatingMode.dietCatering',
                      'Catering dietetyczny'
                    )}
                  />

                  <div
                    style={{
                      display: 'flex',
                      'flex-direction': 'column',
                      'padding-left': '30px',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={appMode.catering === 'FULL'}
                          onChange={() => {
                            setAppMode(prevState => ({
                              ...prevState,
                              catering: 'FULL',
                            }));
                          }}
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                          disabled={!appMode.override}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={
                        <>
                          {t(
                            'brandCfg.operatingMode.fullVersion',
                            'wersja full'
                          )}{' '}
                          <div style={{ marginLeft: '10px' }}>
                            <Tooltip
                              title={
                                <div>
                                  <h5
                                    dangerouslySetInnerHTML={{
                                      __html: t(
                                        'brandCfg.operatingMode.fullVersionTooltip',
                                        {
                                          interpolation: {
                                            escapeValue: false,
                                          },
                                        }
                                      ),
                                    }}
                                  />
                                </div>
                              }
                              placement="right"
                            >
                              <Info fontSize={'small'} />
                            </Tooltip>
                          </div>
                        </>
                      }
                    />
                  </div>
                </GridItem>

                <GridItem xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="shop"
                        onClick={handleCheckboxClick}
                        checked={appMode.shop === 'DISHES'}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                        disabled={!appMode.override}
                      />
                    }
                    label={t(
                      'brandCfg.operatingMode.individualMealSale',
                      'Sprzedaż pojedynczych posiłków'
                    )}
                  />

                  <div
                    style={{
                      display: 'flex',
                      'flex-direction': 'column',
                      'padding-left': '30px',
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={appMode.shop === 'DISHES'}
                          onChange={() => {
                            setAppMode(prevState => ({
                              ...prevState,
                              shop: 'DISHES',
                            }));
                          }}
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                          disabled={!appMode.override}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={
                        <>
                          {t(
                            'brandCfg.operatingMode.shopMeals',
                            'sklep z posiłkami'
                          )}{' '}
                          <div style={{ marginLeft: '10px' }}>
                            <Tooltip
                              title={
                                <div>
                                  <h5
                                    dangerouslySetInnerHTML={{
                                      __html: t(
                                        'brandCfg.operatingMode.shopMealsTooltip',
                                        {
                                          interpolation: {
                                            escapeValue: false,
                                          },
                                        }
                                      ),
                                    }}
                                  />
                                </div>
                              }
                              placement="right"
                            >
                              <Info fontSize={'small'} />
                            </Tooltip>
                          </div>
                        </>
                      }
                    />
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <FormControlButtons
            classes={classes}
            submitText={t('common.shared.save', 'Zapisz')}
            handleSubmit={handleSubmit}
          />
        </>
      )}
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand }, Brands: { paymentTypes } }) => ({
      selectedBrand,
      paymentTypes,
    }),
    { fetchBrand, fetchBrandConfigModulesPack, fetchPaymentTypes }
  ),
  withToast,
  withStyles(combinedStyles)
);

export default enhance(AccessKeyEditModule);
