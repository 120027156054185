import React from 'react';
import { TableCell } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withTranslation } from 'react-i18next';
import FormTextInputTranslatable from 'components/FormTextInput/FormTextInputTranslatable';
import {
  compareTranslatableValues,
  isTranslatableValuesObjectEmpty,
} from 'hooks/redux/Translatable/useTranslatableLanguages';

class SizeNameCell extends React.Component {
  state = {
    isDefault: false,
  };

  componentDidMount() {
    const { dishSizes, size, dish } = this.props;
    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    const isDefault =
      isTranslatableValuesObjectEmpty(dishSizeBySize.name) ||
      compareTranslatableValues(dish.nameForClient, dishSizeBySize.name);

    this.setState({
      isDefault: isDefault,
    });
  }

  render() {
    const {
      dish,
      size,
      dishSizes,
      handleSizeNameChange,
      classes,
      t,
    } = this.props;
    const { isDefault } = this.state;

    const dishSizeBySize = dishSizes.find(
      dishSize => dishSize.size === size['@id']
    );

    return (
      <TableCell>
        <FormControlLabel
          control={
            <Checkbox
              checked={isDefault}
              onChange={e => {
                if (!this.props.copyMode) {
                  if (isDefault) {
                    handleSizeNameChange(dish.nameForClient, dishSizeBySize);
                  } else {
                    handleSizeNameChange('', dishSizeBySize);
                  }
                  if (!isDefault) {
                    handleSizeNameChange(
                      dish.nameForClientInitial,
                      dishSizeBySize
                    );
                  }
                }
                this.setState({ isDefault: !isDefault });
              }}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked,
                root: classes.checkRoot,
              }}
            />
          }
          label={t('mealTypes.default.fem', 'Domyślna')}
        />
        {!isDefault && (
          <FormTextInputTranslatable
            classes={classes}
            formControlProps={{ fullWidth: true }}
            maxLength={160}
            value={dishSizeBySize.name}
            handleChange={e =>
              handleSizeNameChange(e.target.value, dishSizeBySize)
            }
          />
        )}
      </TableCell>
    );
  }
}

export default withTranslation()(SizeNameCell);
