import React, { Fragment } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import RegularButton from 'components/CustomButtons/Button.jsx';
import { withToast } from 'material-ui-toast-redux';
import { connect } from 'react-redux';

import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import CustomInput from '../../components/CustomInput/CustomInput';
import { put } from '../../helpers/apiHelpers';
import { combineStyles, isGranted } from '../../helpers/helpers';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx';

import withStyles from '@material-ui/core/styles/withStyles';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import roles from '../../helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import GridItem from '../../components/Grid/GridItem';

const SmsNotificationsCard = ({
  sms,
  clientToggleableSms,
  setState,
  title,
  classes,
  t,
  openToast,
}) => {
  return (
    <Card>
      <CardBody>
        <GridItem md={12}>
          <h3>{title}</h3>
        </GridItem>
        <div style={{ overflow: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t('notify.whatSms')}</TableCell>
                <TableCell>{t('notify.active')}</TableCell>
                <TableCell>{t('notify.content')}</TableCell>
                {isGranted(roles.ROLE_EDIT_SMS_CONFIGURATION) && (
                  <TableCell>{t('notify.actions')}</TableCell>
                )}
              </TableRow>
            </TableHead>

            <TableBody>
              {sms.map(option => {
                return (
                  <TableRow key={option.id}>
                    <TableCell>
                      <div>
                        {option.smsKey
                          ? t(`notifications.sms.${option.smsKey}`)
                          : t(
                              'mailNotifications.systemNotification',
                              'Powiadomienie systemowe'
                            )}
                      </div>
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={option.enabledForClient}
                        onChange={e => {
                          const items = [...sms];
                          const allowForClientSms = [...clientToggleableSms];

                          const newState = items.map(item => {
                            if (item['@id'] === option['@id']) {
                              if (!item.enabledForClient === false) {
                                item.allowClientToToggle = false;
                              }

                              item.enabledForClient = !item.enabledForClient;
                              item.disabled = true;
                            }
                            return item;
                          });

                          const clientToggleableSmsNewState =
                            allowForClientSms.map(item => {
                              if (item['@id'] === option['@id']) {
                                if (!item.enabledForClient === false) {
                                  item.allowClientToToggle = false;
                                }
                                item.enabledForClient = !item.enabledForClient;
                              }
                              return item;
                            });

                          setState(prevState => ({
                            ...prevState,
                            clientSms: newState,
                            clientToggleableSms: clientToggleableSmsNewState,
                          }));
                        }}
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Fragment>
                        <CustomInput
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            value: option.templateForClient,

                            onChange: e => {
                              setState(prevState => ({
                                ...prevState,
                                clientSms: sms.map(obj => {
                                  if (obj.id === option.id) {
                                    return {
                                      ...obj,
                                      templateForClient: e.target.value,
                                      disabled: true,
                                    };
                                  } else {
                                    return obj;
                                  }
                                }),
                              }));
                            },
                          }}
                          onChange={e => {
                            setState(prevState => ({
                              ...prevState,
                              clientSms: sms.map(obj => {
                                if (obj.id === option.id) {
                                  return {
                                    ...obj,
                                    templateForClient: e.target.value,
                                    disabled: true,
                                  };
                                } else {
                                  return obj;
                                }
                              }),
                            }));
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            margin: '10px 0 5px 0',
                          }}
                        >
                          <div>
                            <Button
                              onClick={() => {
                                const win = window.open(
                                  '',
                                  'Podgląd Administracja',
                                  'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes'
                                );
                                win.document.write(option.templateForClient);
                              }}
                            >
                              {t(
                                'menuPlanner.action.template.use.preview',
                                'Podgląd'
                              )}
                            </Button>
                            <Button
                              onClick={() => {
                                setState(prevState => ({
                                  ...prevState,
                                  clientSms: sms.map(obj => {
                                    if (obj.id === option.id) {
                                      return {
                                        ...obj,
                                        templateForClient:
                                          option.defaultTemplateForClient,
                                        disabled: true,
                                      };
                                    } else {
                                      return obj;
                                    }
                                  }),
                                }));
                              }}
                            >
                              {t(
                                'common.restoreDefaultFem',
                                'Przywróć domyślną'
                              )}
                            </Button>
                          </div>
                        </div>
                        {!option.isHidden && (
                          <div style={{ maxHeight: '70vh', overflowX: 'auto' }}>
                            <Editor
                              value={option.templateForOffice}
                              onValueChange={code => {
                                const newMailing = [...this.state.mailing];
                                const optionToChange = newMailing.find(
                                  newMailingOption =>
                                    newMailingOption.id === option.id
                                );
                                optionToChange.templateForOffice = code;
                                optionToChange.hasChanged = true;

                                this.setState({
                                  mailing: newMailing,
                                });
                              }}
                              highlight={code => highlight(code, languages.jsx)}
                              padding={10}
                              style={{
                                fontFamily:
                                  '"Fira code", "Fira Mono", monospace',
                                fontSize: 12,
                              }}
                            />
                          </div>
                        )}
                      </Fragment>
                    </TableCell>
                    {isGranted(roles.ROLE_EDIT_SMS_CONFIGURATION) && (
                      <TableCell>
                        <RegularButton
                          disabled={!option.disabled}
                          color={'success'}
                          onClick={() => {
                            put(option['@id'], option).then(
                              res => {
                                setState(prevState => {
                                  return {
                                    ...prevState,
                                    clientSms: sms?.map(sms => {
                                      if (sms.id === option.id) {
                                        return {
                                          ...sms,
                                          disabled: false,
                                        };
                                      } else {
                                        return sms;
                                      }
                                    }),
                                  };
                                });

                                openToast({
                                  messages: [
                                    t(
                                      'notify.changesSaved',
                                      'Zmiany zostały zapisane'
                                    ),
                                  ],
                                  type: 'success',
                                  autoHideDuration: 3000,
                                });
                              },
                              error =>
                                openToast({
                                  messages: [
                                    t(
                                      'notify.cannotSave',
                                      'Nie udało się zapisać zmian'
                                    ),
                                  ],
                                  type: 'error',
                                  autoHideDuration: 3000,
                                })
                            );
                          }}
                        >
                          {t('common.shared.save', 'Zapisz')}
                        </RegularButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(
  extendedFormsStyle,
  buttonsStyle,
  sweetAlertStyle
);

const mapStateToProps = state => {
  return {
    brand: state.Auth.selectedBrand,
  };
};

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation(),
  connect(mapStateToProps, null)
);

export default enhance(SmsNotificationsCard);
