import { get, remove, post, put } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchVariants = (requestParams = {}) => {
  return dispatch => {
    return get('/variants', { pagination: false, ...requestParams }).then(
      response => {
        dispatch({
          type: types.FETCH_VARIANTS,
          payload: {
            variants: response['hydra:member'],
          },
        });
      }
    );
  };
};

export const fetchVariant = id => {
  return dispatch => {
    return get(`/variants/${id}`).then(variant => {
      dispatch({
        type: types.FETCH_VARIANT,
        payload: {
          variant,
        },
      });
      return variant;
    });
  };
};

export const removeVariant = id => {
  return dispatch =>
    remove(`/variants/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_VARIANT,
        payload: {
          variantId: id,
        },
      });
    });
};

export const addVariant = data => {
  return dispatch => post('/variants', data);
};

export const editVariant = (id, data) => {
  return dispatch => put(`/variants/${id}`, data);
};
