import {
  primaryColor,
  grayColor,
} from 'assets/jss/material-dashboard-pro-react.jsx';

const accordionStyle = theme => ({
  root: {
    backgroundColor: 'transparent',
    flexGrow: 1,
  },
  expansionPanel: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:before': {
      display: 'none !important',
    },
  },
  expansionPanelExpanded: {
    margin: '0',
  },
  expansionPanelSummary: {
    minHeight: 'auto !important',
    backgroundColor: 'transparent',
    borderBottom: '1px solid ' + grayColor[5],
    padding: '0',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    color: 'rgba(0, 0, 0, 0.54)',
    '&:hover': {
      color: primaryColor[0],
    },
  },
  expansionPanelSummaryExpaned: {
    '& $expansionPanelSummaryExpandIcon': {
      [theme.breakpoints.up('md')]: {
        top: 'auto !important',
      },
      transform: 'rotate(180deg)',
      [theme.breakpoints.down('sm')]: {
        top: '10px !important',
      },
    },
  },
  expansionPanelSummaryContent: {
    margin: '0 !important',
  },
  expansionPanelSummaryExpandIcon: {
    marginRight: '-20px',
    [theme.breakpoints.up('md')]: {
      top: 'auto !important',
    },
    transform: 'rotate(0deg)',
    color: 'inherit',
    [theme.breakpoints.down('sm')]: {
      top: '10px !important',
    },
  },
  expansionPanelSummaryExpandIconExpanded: {},
  title: {
    fontSize: '15px',
    fontWeight: 'bolder',
    marginTop: '0',
    marginBottom: '0',
    color: 'inherit',
  },
  expansionPanelDetails: {
    padding: '5px 0px 5px',
  },
});

export default accordionStyle;
