import React from 'react';
import BaseBrandReport from './BaseBrandReport';
import roles from 'helpers/roles';
import { withTranslation } from 'react-i18next';
import getReportFileName from 'helpers/getReportFileName';

class FoodCost extends React.Component {
  render() {
    const { t } = this.props;
    const title = t('reports.reportFoodCost');

    return (
      <BaseBrandReport
        title={title}
        excelUrl="reports/food-cost"
        fileName={getReportFileName(title)}
        role={roles.ROLE_SHOW_REPORT_FOOD_COST}
        pdfAvaliable={false}
        excelAvaliable={true}
        archiveReportNames={['FOOD_COST']}
        dateType="single"
      />
    );
  }
}

export default withTranslation()(FoodCost);
