import React from 'react';
import { Router, Route, Switch, withRouter } from 'react-router';

import AddonsList from './AddonsList';
import AddonsForm from './AddonsForm';
import AddonsSettings from './AddonsSettings';
import withRole from 'components/Security/withRole';
import {
  ROLE_CREATE_ADDON,
  ROLE_EDIT_ADDON,
  ROLE_SHOW_ADDON,
  ROLE_SHOW_MODULE_ADDONS,
} from 'helpers/roles';

const Addons = props => {
  const { history, match } = props;
  const ProtectedAddonsList = withRole(ROLE_SHOW_ADDON, AddonsList);
  const ProtectedCreateForm = withRole(ROLE_CREATE_ADDON, AddonsForm);
  const ProtectedEditForm = withRole(ROLE_EDIT_ADDON, AddonsForm);
  const ProtectedSettings = withRole(ROLE_SHOW_MODULE_ADDONS, AddonsSettings);

  return (
    <Router history={history}>
      <Switch>
        <Route
          exact
          path={`${match.url}/`}
          render={parentProps => (
            <ProtectedAddonsList {...props} {...parentProps} />
          )}
        />
        <Route
          path={`${match.url}/add`}
          render={() => <ProtectedCreateForm {...props} />}
        />
        <Route
          path={`${match.url}/edit/:id`}
          render={parentProps => (
            <ProtectedEditForm {...props} {...parentProps} />
          )}
        />
        <Route
          path={`${match.url}/settings`}
          render={parentProps => (
            <ProtectedSettings {...props} {...parentProps} />
          )}
        />
      </Switch>
    </Router>
  );
};

export default withRouter(Addons);
