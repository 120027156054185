/*eslint-disable*/
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';

// core components
import FixedPlugin from 'components/FixedPlugin/FixedPlugin.jsx';

// other
import image from 'assets/img/sidebar-2.png';

import { setMainColor } from 'actions/Theme';

import { put, get } from 'helpers/apiHelpers';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { combineStyles } from '../../helpers/helpers';
import { withTranslation } from 'react-i18next';

class ThemeSettings extends React.Component {
  state = {
    miniActive: false,
    image: image,
    color: 'caterings',
    bgColor: 'black',
    hasImage: true,
    fixedClasses: 'dropdown',
    updated: false,
  };

  handleImageClick = image => {
    this.setState({ image: image, updated: true });
  };

  handleColorClick = color => {
    this.props.setMainColor(color);
    this.setState({ color: color, updated: true });
  };

  handleBgColorClick = bgColor => {
    this.setState({ bgColor: bgColor, updated: true });
  };

  handleFixedClick = () => {
    if (this.state.fixedClasses === 'dropdown') {
      this.setState({ fixedClasses: 'dropdown show' });
    } else {
      this.setState({ fixedClasses: 'dropdown' });
    }
  };

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive, updated: true });
  }

  handleHasImage() {
    this.setState({ hasImage: !this.state.hasImage, updated: true });
  }

  componentDidMount() {
    get(`${this.props.user.id}/settings`).then(response => {
      this.setState({
        miniActive: response.settings.miniActive,
        image: response.settings.image,
        color: response.settings.color,
        bgColor: response.settings.bgColor,
        hasImage: response.settings.hasImage,
      });
    });
  }

  componentDidUpdate() {
    this.props.setUpdateParentState(this.state);

    if (!this.state.updated) {
      return;
    }

    let settings = {
      miniActive: this.state.miniActive ?? false,
      image: this.state.image,
      color: this.state.color,
      bgColor: this.state.bgColor,
      hasImage: this.state.hasImage ?? false,
    };

    put(this.props.user.id, { settings: settings }).then(() => {
      this.props.openToast({
        messages: [this.props.t('success.dataUpdated', 'Zaktualizowano dane')],
        type: 'success',
        autoHideDuration: 3000,
      });
    });
  }

  render() {
    return (
      <FixedPlugin
        handleImageClick={this.handleImageClick}
        handleColorClick={this.handleColorClick}
        handleBgColorClick={this.handleBgColorClick}
        handleHasImage={this.handleHasImage}
        color={this.state['color']}
        bgColor={this.state['bgColor']}
        bgImage={this.state['image']}
        handleFixedClick={this.handleFixedClick}
        fixedClasses={this.state.fixedClasses}
        sidebarMinimize={this.sidebarMinimize.bind(this)}
        miniActive={this.state.miniActive}
        rtlActive={false}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.Auth.user,
  };
};

const mapDispatchToProps = {
  setMainColor,
};

const enhance = compose(
  withToast,
  withTranslation(),
  withStyles(combineStyles),
  connect(mapStateToProps, mapDispatchToProps)
);

export default enhance(ThemeSettings);
