import { useState, useEffect } from 'react';
import NumberFormat from 'react-number-format';
import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import { withToast } from 'material-ui-toast-redux';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import AdminTable from 'layouts/AdminTable';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import gastro from 'helpers/gastro';
import { post, put } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';

import defaultState from './defaultState';
import FormTextInputTranslatable from 'components/FormTextInput/FormTextInputTranslatable';

const Form = ({
  match,
  classes,
  history,
  valueUrl,
  valueKey,
  location,
  openToast,
  translatable = false,
}) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ ...defaultState });

  const isEdit = location.pathname.includes('edit');
  const elementId = match.params.id;

  useEffect(() => {
    if (isEdit) {
      fetchValue();
    }
  }, []);

  const fetchValue = () => {
    gastro
      .get(`${valueUrl}/${elementId}?translations=${translatable}`)
      .then(async response => {
        setState({
          ...state,
          name: response.data.name,
          volume: response.data.volume,
          brandVat: response.data.brandVat,
          brandCost: response.data.brandCost,
          clientVat: response.data.clientVat,
          clientCost: response.data.clientCost,
        });
      });
  };

  const handleInputChange = event => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const validateForm = () => {
    return state.name;
  };

  const handleSubmit = () => {
    if (!validateForm()) {
      return openToast({
        messages: [t('form.nameFieldCannotBeEmpty')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    const data = {
      name: state.name,
      volume: state.volume ? parseFloat(state.volume) : 0,
      brandVat: state.brandVat ? parseFloat(state.brandVat) : 0,
      brandCost: state.brandCost ? parseFloat(state.brandCost) : 0,
      clientVat: state.clientVat ? parseFloat(state.clientVat) : 0,
      clientCost: state.clientCost ? parseFloat(state.clientCost) : 0,
    };

    const action = isEdit
      ? put(`${valueUrl}/${elementId}`, data)
      : post(valueUrl, data);

    action.then(() => history.push(`/admin/${valueUrl}`));
  };
  const NameComponent =
    true !== translatable ? FormTextInput : FormTextInputTranslatable;

  return (
    <AdminTable title={isEdit ? t('form.editValue') : t('form.addNewValue')}>
      <NameComponent
        label={t('form.field.name') + '*'}
        classes={classes}
        name={'name'}
        value={state.name}
        handleChange={handleInputChange}
        inputSize={4}
      />
      <NumberFormat
        label={t('bagContainer.volume')}
        classes={classes}
        name={'volume'}
        customInput={FormTextInput}
        value={state.volume}
        onChange={handleInputChange}
        allowNegative={false}
        allowedDecimalSeparators={[',', '.']}
        allowLeadingZeros={false}
        inputSize={4}
      />
      <NumberFormat
        label={t('bagContainer.clientVat')}
        classes={classes}
        name={'clientVat'}
        customInput={FormTextInput}
        value={state.clientVat}
        onChange={handleInputChange}
        allowNegative={false}
        allowedDecimalSeparators={[',', '.']}
        allowLeadingZeros={false}
        inputSize={4}
      />
      <NumberFormat
        label={t('bagContainer.clientCost')}
        classes={classes}
        name={'clientCost'}
        customInput={FormTextInput}
        value={state.clientCost}
        onChange={handleInputChange}
        allowNegative={false}
        allowedDecimalSeparators={[',', '.']}
        allowLeadingZeros={false}
        inputSize={4}
      />
      <NumberFormat
        label={t('bagContainer.brandVat')}
        classes={classes}
        name={'brandVat'}
        customInput={FormTextInput}
        value={state.brandVat}
        onChange={handleInputChange}
        allowNegative={false}
        allowedDecimalSeparators={[',', '.']}
        allowLeadingZeros={false}
        inputSize={4}
      />
      <NumberFormat
        label={t('bagContainer.brandCost')}
        classes={classes}
        name={'brandCost'}
        customInput={FormTextInput}
        value={state.brandCost}
        onChange={handleInputChange}
        allowNegative={false}
        allowedDecimalSeparators={[',', '.']}
        allowLeadingZeros={false}
        inputSize={4}
      />

      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath={`/admin/${valueUrl}`}
        handleSubmit={handleSubmit}
      />
    </AdminTable>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withToast(withStyles(combinedStyles)(Form));
