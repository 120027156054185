import React from 'react';
import roles from 'helpers/roles';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CombinedReport from './CombinedReport/CombinedReport';
import getReportFileNameWithMultiplier from 'helpers/getReportFileNameWithMultiplier';

class RecipeIngredients extends React.Component {
  state = {
    eachRecipeOnNewPage: true,
    includeSubscriptions: false,
  };

  render() {
    const { t } = this.props;
    const title = t('reports.reportRecipeIngredients');

    return (
      <CombinedReport
        name="RECIPE_INGREDIENTS"
        title={title}
        url="reports/recipe-ingredients"
        mimeTypes={['application/vnd.ms-excel']}
        fileName={params =>
          getReportFileNameWithMultiplier(
            params,
            this.props.t('common.mainMenu.reportsRecipeIngredients')
          )
        }
        role={roles.ROLE_SHOW_REPORT_RECIPES_INGREDIENTS}
        useDateRange={true}
        showMultiplier={true}
        validateBrands={false}
        validateDatePicker={true}
        archiveReportNames={['RECIPE_INGREDIENTS']}
        useZoneCategories={true}
        useRecipeTags={true}
        singleSelectRecipeTags={true}
      />
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(RecipeIngredients);
