import React from 'react';

import Assignment from '@material-ui/icons/Assignment';
// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { cardTitle } from 'assets/jss/material-dashboard-pro-react';
import { makeStyles } from '@material-ui/styles';
import CardIcon from 'components/Card/CardIcon';

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: '15px',
    marginBottom: '0px',
  },
};

const useStyles = makeStyles(() => ({
  cardHeaderDiv: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '16px',
  },
  listHeaderDivMargin: {
    marginLeft: '4px',
  },
  containerStyle: {
    marginTop: '15px',
  },
  settingComponentStyle: { marginTop: '15px' },
}));

const AdminTable = ({
  children,
  classes: propClasses,
  title,
  settingsComponent,
  iconComponent,
  icon,
  isMarginLeft = false,
  iconColor = 'primary',
}) => {
  const classes = useStyles();

  const isEdit =
    isMarginLeft ||
    window.location.pathname.includes('edit') ||
    window.location.pathname.includes('add');
  return (
    <GridContainer className={classes.containerStyle}>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="primary" icon>
            {icon && (
              <CardIcon color={iconColor}>
                {iconComponent ? iconComponent : <Assignment />}
              </CardIcon>
            )}
            <div
              className={classNames(
                classes.cardHeaderDiv,
                !isEdit && classes.listHeaderDivMargin
              )}
            >
              <h3 className={propClasses.cardIconTitle}>{title}</h3>
              <div className={classes.settingComponentStyle}>
                {settingsComponent}
              </div>
            </div>
          </CardHeader>
          <CardBody>{children}</CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withStyles(styles)(AdminTable);
