import CardBody from '../../../components/Card/CardBody';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import FormTextInput from '../../../components/FormTextInput/FormTextInput';
import { Divider } from '@material-ui/core';
import SelectInput from 'components/FormSelect/SelectInput';
import CustomCheckbox from 'components/Checkbox/CustomCheckbox';
import { usePanelConfigurationContext } from './PanelConfiguration.context';
import { compose } from 'redux';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import withStyles from '@material-ui/core/styles/withStyles';
import { withTranslation } from 'react-i18next';
import CardWrapper from 'components/Card/CardWrapper';

const premiumTypeOptions = t => [
  {
    value: 'DISABLED',
    label: t('brands.newBrandForm.premiumTypeOptions.disabled', 'Wyłączona'),
  },
  {
    value: 'STANDARD',
    label: t(
      'brands.newBrandForm.premiumTypeOptions.standard',
      'Możliwa zmiana według cen diet'
    ),
  },
  {
    value: 'PREMIUM',
    label: t(
      'brands.newBrandForm.premiumTypeOptions.premium',
      'Możliwa zmiana po wykupieniu premium'
    ),
  },
];

const CustomMenuOptions = ({ classes, t }) => {
  const {
    handleCheckboxChange,
    brandConfig,
    configClientPanelModule,
    setConfigClientPanelModule,
    setBrandConfig,
  } = usePanelConfigurationContext();

  return (
    <CardWrapper
      title={t(
        'brandCfg.menuSelectionAssistant.menuAndAdditionalDishes',
        'Wybór menu i dodatkowych dań'
      )}
    >
      <CardBody>
        <GridContainer>
          <GridItem md={3}>
            <SelectInput
              classes={classes}
              label={t(
                'brands.newBrandForm.menuChangesAllowed',
                'Możliwość zmiany menu'
              )}
              mapBy="label"
              trackBy="value"
              value={brandConfig.premiumType}
              options={premiumTypeOptions(t)}
              handleChange={e =>
                setBrandConfig(prevState => ({
                  ...prevState,
                  premiumType: e.target.value,
                }))
              }
            />

            {brandConfig.premiumType === 'PREMIUM' && (
              <>
                <CustomCheckbox
                  checked={brandConfig.premiumAdditionalStep}
                  onChange={() => handleCheckboxChange('premiumAdditionalStep')}
                  label={t(
                    'brandCfg.menuSelectionAssistant.showSeparateStepForOrderPath',
                    'Wyświetl oddzielny krok ścieżki zamówienia - Wybór menu'
                  )}
                />

                <h4>
                  {t(
                    'module.configClientPanel.chooseMenuDescription.label',
                    'Tekst na kroku "Wybór menu"'
                  )}
                </h4>
                <Divider style={{ marginBottom: '20px' }} />

                <FormTextInput
                  multiline
                  classes={classes}
                  name="chooseMenuDescription"
                  value={configClientPanelModule?.chooseMenuDescription}
                  maxLength={5000}
                  rows={10}
                  handleChange={e =>
                    setConfigClientPanelModule(prevState => ({
                      ...prevState,
                      chooseMenuDescription: e.target.value,
                    }))
                  }
                  inputSize={12}
                />
              </>
            )}
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

const enhance = compose(withTranslation(), withStyles(extendedFormsStyle));

export default enhance(CustomMenuOptions);
