import types from 'helpers/constants';

const initialState = {
  isOpen: true,
  isWrap: true,
};

function SideBar(state = initialState, action) {
  switch (action.type) {
    case types.CHANGE_SIDEBAR:
      return {
        ...state,
        isOpen: action.payload.isOpen,
        isWrap: action.payload.isWrap,
      };
    default:
      return state;
  }
}

export default SideBar;
