import React from 'react';
import roles from 'helpers/roles';
import GridItem from 'components/Grid/GridItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import CombinedReport from './CombinedReport/CombinedReport';
import GridContainer from '../../components/Grid/GridContainer';
import getReportFileNameWithMultiplier from 'helpers/getReportFileNameWithMultiplier';
import { ReportLabelDivider } from '../../components/Report/ReportConfigurator';

class RecipeCards extends React.Component {
  state = {
    eachRecipeOnNewPage: true,
    includeSubscriptions: false,
  };

  render() {
    const { classes, t } = this.props;
    const title = t('reports.reportRecipeCards');

    return (
      <CombinedReport
        name="RECIPES_CARD"
        title={title}
        additionalParams={{
          eachRecipeOnNewPage: this.state.eachRecipeOnNewPage,
        }}
        url="reports/recipes-card"
        mimeTypes={['application/pdf', 'application/vnd.ms-excel']}
        fileName={params =>
          getReportFileNameWithMultiplier(
            params,
            this.props.t('common.mainMenu.reportsRecipeCards')
          )
        }
        role={roles.ROLE_SHOW_REPORT_RECIPES_CARD}
        useDateRange={true}
        showMultiplier={true}
        validateBrands={false}
        validateDatePicker={true}
        archiveReportNames={['RECIPES_CARD']}
        useZoneCategories={true}
        useRecipeTags={true}
        singleSelectRecipeTags={true}
        reportConfiguration={{
          report: 'RecipeCardCompanyConfiguration',
          fields: [
            {
              size: 12,
              field: 'sortMode',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: 'SORT_BY_DIET',
                    label: this.props
                      .t(
                        'configuration.reports.report.RecipeCardCompanyConfiguration.sortByDiet'
                      )
                      .toUpperCase(),
                  },
                  {
                    value: 'SORT_BY_MEAL_TYPE',
                    label: this.props
                      .t(
                        'configuration.reports.report.RecipeCardCompanyConfiguration.sortByMealType'
                      )
                      .toUpperCase(),
                  },
                  {
                    value: 'SORT_BY_AMOUNT_TO_MAKE',
                    label: this.props
                      .t(
                        'configuration.reports.report.RecipeCardCompanyConfiguration.sortByAmountToMake'
                      )
                      .toUpperCase(),
                  },
                ],
              },
            },
            {
              size: 12,
              field: 'roundDecimalPoints',
              inputType: 'number',
            },
            {
              size: 12,
              field: 'splitWeightIntoDetails',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: true,
                    label: this.props.t(`common.yes`),
                  },
                  {
                    value: false,
                    label: this.props.t(`common.no`),
                  },
                ],
              },
            },
            {
              size: 12,
              field: 'showUnits',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: true,
                    label: this.props.t(`common.yes`),
                  },
                  {
                    value: false,
                    label: this.props.t(`common.no`),
                  },
                ],
              },
            },
            {
              size: 12,
              field: 'showPortionDetails',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: true,
                    label: this.props.t(`common.yes`),
                  },
                  {
                    value: false,
                    label: this.props.t(`common.no`),
                  },
                ],
              },
            },
            {
              size: 12,
              field: `forceKg`,
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: true,
                    label: this.props.t(`common.yes`),
                  },
                  {
                    value: false,
                    label: this.props.t(`common.no`),
                  },
                ],
              },
            },
            {
              size: 12,
              inputProps: {
                content: <ReportLabelDivider label={'PDF'} />,
              },
              inputType: 'divider',
            },
            {
              size: 12,
              field: 'orientation',
              inputType: 'select',
              inputProps: {
                multiple: false,
                options: [
                  {
                    value: 'Landscape',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.orientation.Landscape`
                    ),
                  },
                  {
                    value: 'Portrait',
                    label: this.props.t(
                      `configuration.reports.report.DishCardCompanyConfiguration.orientation.Portrait`
                    ),
                  },
                ],
              },
            },
            {
              size: 12,
              field: 'css',
              inputType: 'text',
            },
          ],
        }}
      >
        <GridContainer justify="flex-end">
          <GridItem sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  className="input-checkbox--recipe-new-page"
                  checked={this.state.eachRecipeOnNewPage}
                  onChange={e => {
                    this.setState({
                      eachRecipeOnNewPage: !this.state.eachRecipeOnNewPage,
                    });
                  }}
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t('reports.recipePerNewPage')}
            />
          </GridItem>
        </GridContainer>
      </CombinedReport>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withStyles(combinedStyles), withTranslation());

export default enhance(RecipeCards);
