import cx from 'classnames';
import { useLocation, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Icon from '@material-ui/core/Icon';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const SidebarRoute = props => {
  const { t } = useTranslation();
  const location = useLocation();
  const {
    key,
    icon,
    path,
    mini,
    name,
    color,
    layout,
    classes,
    rtlMini,
    rtlName,
    redirect,
    invisible,
    rtlActive,
    sidebarPath,
    nameFallback,
    externalLink,
    isOneItemTree,
    isMenuExpanded,
    isTreeItem = false,
    isLastTreeItem = false,
  } = props;

  let pathToDataCy = '';
  if (path) {
    pathToDataCy = path.replace('/', '');
  }

  const isActiveRoute =
    location.pathname.split('/admin')[1] === path ? 'active' : '';

  const innerNavLinkClasses =
    classes.collapseItemLink +
    ' ' +
    cx({
      [' ' + classes[color]]: isActiveRoute,
    });
  const navLinkClasses =
    classes.itemLink +
    ' ' +
    cx({
      [' ' + classes[color]]: isActiveRoute,
    });
  const itemText =
    classes.itemText +
    ' ' +
    cx({
      [classes.itemTextMini]: !isMenuExpanded,
      [classes.itemTextMiniRTL]: rtlActive && !isMenuExpanded,
      [classes.itemTextRTL]: rtlActive,
    });
  const itemIcon =
    classes.itemIcon +
    ' ' +
    cx({
      [classes.itemIconRTL]: rtlActive,
    });
  const collapseItemMini =
    classes.collapseItemMini +
    ' ' +
    cx({
      [classes.collapseItemMiniRTL]: rtlActive,
    });

  let linkProps = {};
  if (externalLink) {
    linkProps.onClick = event => {
      event.preventDefault();
      window.open(path);
    };
  }

  if (redirect || invisible) {
    return null;
  }

  return (
    <div key={key} style={{ position: 'relative', margin: '0 15px' }}>
      {isTreeItem && isLastTreeItem && (
        <div
          style={{
            top: isOneItemTree ? '-1px' : '-5px',
            left: '24px',
            width: '1px',
            borderLeft: '1px solid currentColor',
            marginLeft: '-1px',
            zIndex: '3',
            height: '20px',
            position: 'absolute',
          }}
        />
      )}

      <ListItem
        className={cx(
          { [classes.item]: icon !== undefined },
          { [classes.collapseItem]: icon === undefined },
          { [classes.subRouteTreeItem]: isTreeItem }
        )}
        data-cy={path}
      >
        <div
          style={{
            heiht: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: '5px 0',
            borderLeft:
              isTreeItem && !isLastTreeItem ? '1px solid currentColor' : null,
          }}
        >
          {isTreeItem && (
            <div
              className={cx({
                [classes.subRouteTreeItemLabelPointer]: isTreeItem,
              })}
              style={{ marginLeft: isLastTreeItem ? 0 : '-1px' }}
            />
          )}
          <NavLink
            style={{
              cursor: 'pointer',
              width: '100%',
              padding: '3px 10px',
              userSelect: 'none',
              margin: isTreeItem ? '0 0 0 5px' : '0',
            }}
            to={layout + (sidebarPath || path)}
            className={cx(
              { [navLinkClasses]: icon !== undefined },
              { [innerNavLinkClasses]: icon === undefined }
            )}
            {...linkProps}
          >
            {!isTreeItem && (
              <>
                {icon !== undefined ? (
                  props?.icon2 ? (
                    <props.icon2 className={itemIcon} />
                  ) : (
                    <Icon className={itemIcon}>{icon}</Icon>
                  )
                ) : (
                  <span className={collapseItemMini}>
                    {rtlActive ? rtlMini : t(mini)}
                  </span>
                )}
              </>
            )}
            <ListItemText
              data-cy={`sidebar_subSection_${pathToDataCy}`}
              primary={rtlActive ? rtlName : t(name, nameFallback ?? name)}
              disableTypography={true}
              className={cx({ [itemText]: true })}
              style={!isTreeItem ? { fontWeight: '400' } : {}}
            />
          </NavLink>
        </div>
      </ListItem>
    </div>
  );
};

export default SidebarRoute;
