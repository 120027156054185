import React, { Component } from 'react';
import { orderStyles } from '../../styles';

import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_NOTE, ROLE_NOTE_CLIENT } from 'helpers/roles';
import { put, get } from 'helpers/apiHelpers';

import ModalButton from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import FormTextInput from 'components/FormTextInput/FormTextInput';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Close from '@material-ui/icons/Close';

class NotesEditModal extends Component {
  state = {
    data: {},
    hasChanged: false,
  };

  componentDidMount() {
    this.setState({ data: this.props.editModalData });
  }

  addNote = e => {
    const { t } = this.props;
    if (this.state.data.content === '') {
      return this.props.openToast({
        messages: [t('errors.contentRequired', 'Treść nie może być pusta')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    e.preventDefault();
    put(this.state.data['@id'], {
      '@id': this.state.data['@id'],
      content: this.state.data.content,
    }).then(newMessage => {
      this.setState({ data: {} });
      this.closeModal();
      this.props.openToast({
        messages: [t('success.noteUpdate', 'Notatka została zaktualizowana')],
        type: 'success',
        autoHideDuration: 3000,
      });
    });
  };
  //
  closeModal = async () => {
    await get(`/notes`, { key: this.props.client['@id'] }).then(notes => {
      this.props.setNotes(notes['hydra:member']);
      this.props.setNotesModalStatus(true);
      this.props.setEditModalStatus(false);
    });
  };

  render() {
    const { classes, t } = this.props;

    return (
      <Dialog
        maxWidth="lg"
        fullWidth={false}
        open={this.props.editModalStatus}
        onClose={() => this.closeModal()}
      >
        <DialogContent>
          <ModalButton
            style={orderStyles.closeButton}
            color={'transparent'}
            justIcon
            round
            onClick={() => this.closeModal()}
          >
            <Close />
          </ModalButton>
          <GridContainer>
            {(isGranted(ROLE_CREATE_NOTE) || isGranted(ROLE_NOTE_CLIENT)) && (
              <GridItem md={12}>
                <div style={{ marginLeft: '5px', ...orderStyles.box }}>
                  <b>{t('notes.editNote', 'Edycja notatki')}</b>
                  <FormTextInput
                    multiline
                    label={t('notes.noteContent', 'Treść notatki')}
                    classes={classes}
                    name="phone"
                    value={this.state.data.content}
                    handleChange={ev => {
                      const newValue = ev.target.value;
                      this.setState(prevState => ({
                        ...prevState,
                        hasChanged: true,
                        data: {
                          ...prevState.data,
                          content: newValue,
                        },
                      }));
                    }}
                    inputSize={12}
                    maxLength={400}
                  />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <ModalButton
                      color={'primary'}
                      disabled={!this.state.hasChanged}
                      onClick={ev => this.addNote(ev)}
                    >
                      {!this.state.hasChanged
                        ? t('cmmon.noChangesApplied', 'Nie dokonano zmian')
                        : t('common.saveChanges', 'Zapisz zmiany')}
                    </ModalButton>
                  </div>
                </div>
              </GridItem>
            )}
          </GridContainer>
        </DialogContent>
      </Dialog>
    );
  }
}

export default NotesEditModal;
