import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import withStyles from '@material-ui/core/styles/withStyles';
import { get } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';

import CardBody from 'components/Card/CardBody';
import SelectInput from 'components/FormSelect/SelectInput';
import CardWrapper from 'components/Card/CardWrapper';

const ContainerSelect = ({ classes, bagContainer, setBagContainer }) => {
  const { t } = useTranslation();
  const [containers, setContainers] = useState([]);

  useEffect(async () => {
    const newContainers = await get('/bag-containers');
    setContainers(newContainers?.['hydra:member']);
  }, []);

  return (
    <CardWrapper title={t('diets.configContainerType', '$$Ustawienia torby')}>
      <CardBody style={{ marginBottom: '1rem' }}>
        <SelectInput
          classes={classes}
          label={t(
            ('diets.confidContainerType.selectLabel',
            'Wybierz do jakiej torby ma być pakowana dieta')
          )}
          mapBy="name"
          trackBy="@id"
          value={bagContainer}
          options={containers}
          handleChange={(ev, obj) => setBagContainer(obj)}
        />
      </CardBody>
    </CardWrapper>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(ContainerSelect);
