import { get, remove } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchUserAddresses = (id, pagination = true) => {
  return dispatch => {
    return get(`/clients/${id}/addresses`, { pagination }).then(response => {
      dispatch({
        type: types.FETCH_USERADDRESSES,
        payload: {
          addresses: response['hydra:member'],
          pagination: false,
        },
      });
      return response['hydra:member'];
    });
  };
};

export const removeAddress = id => {
  return dispatch =>
    remove(`/addresses/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_ADDRESS,
        payload: {
          address: id,
        },
      });
    });
};
