import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Moment from 'moment';

import { combineStyles } from 'helpers/helpers';

import CardBody from 'components/Card/CardBody.jsx';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import { infoColor } from 'assets/jss/material-dashboard-pro-react.jsx';

import withStyles from '@material-ui/core/styles/withStyles';
import VisibilityIcon from '@material-ui/icons/Visibility';

import TaskStatus from 'views/CateringsFlow/common/TaskStatus/TaskStatus';
import TasksListDetailsModal from 'views/CateringsFlow/common/TasksListDetalsModal/TasksListDetailsModal';
import CardWrapper from 'components/Card/CardWrapper';

const TasksListDetails = ({
  note,
  params,
  status,
  classes,
  generatedBy,
  generatedAt,
  isDetailsModalAvaliable = true,
}) => {
  const { t } = useTranslation();
  const [isDetailsModalOpened, setIsDetailsModalOpened] = useState(false);

  const FieldsContainer = styled.div`
    display: flex;
    justifycontent: space-between;
  `;
  const DetailsField = styled.div`
    margin: 0 25px;
  `;
  const FieldTextValue = styled.p`
    margin-top: 15px;
  `;

  return (
    <>
      <CardWrapper
        title={t(
          '$*cateringsFlow.tasksListDetails.title',
          '$$Szczegóły listy zadań'
        )}
      >
        <CardBody>
          <FieldsContainer>
            <div style={{ display: 'flex' }}>
              {/* GENERATED BY */}
              <DetailsField>
                <h4>
                  {t(
                    '$*cateringsFlow.tasksListDetails.headers.createdBy',
                    'Wygenerował/a'
                  )}
                </h4>
                <FieldTextValue>{`${generatedBy?.firstName} ${generatedBy?.lastName}`}</FieldTextValue>
              </DetailsField>
              {/* GENERATED AT */}
              <DetailsField>
                <h4>
                  {t(
                    '$*cateringsFlow.tasksListDetails.headers.createdAt',
                    'Data wygenerowania'
                  )}
                </h4>
                <FieldTextValue>
                  {Moment(generatedAt).format('YYYY-MM-DD')}
                </FieldTextValue>
              </DetailsField>
              {/* Comments */}
              <DetailsField>
                <h4>
                  {t(
                    '$*cateringsFlow.tasksListDetails.headers.comments',
                    'Komentarz'
                  )}
                </h4>
                <FieldTextValue>{note}</FieldTextValue>
              </DetailsField>
            </div>
            <div style={{ display: 'flex' }}>
              {/* STATUS */}
              <DetailsField>
                <h4>
                  {t(
                    '$*cateringsFlow.tasksListDetails.headers.status',
                    'Status'
                  )}
                </h4>
                <TaskStatus status={status} />
              </DetailsField>
              {/* ACTIONS */}
              {isDetailsModalAvaliable && (
                <DetailsField>
                  <h4>
                    {t(
                      '$*cateringsFlow.tasksListDetails.headers.actions',
                      'Akcje'
                    )}
                  </h4>
                  <div>
                    <VisibilityIcon
                      onClick={() => setIsDetailsModalOpened(true)}
                      style={{ color: infoColor[0], cursor: 'pointer' }}
                    />
                  </div>
                </DetailsField>
              )}
            </div>
          </FieldsContainer>
        </CardBody>
      </CardWrapper>
      <TasksListDetailsModal
        params={params ?? []}
        comments={note ?? ''}
        isOpened={isDetailsModalOpened}
        closeModal={() => setIsDetailsModalOpened(false)}
      />
    </>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

export default withStyles(combinedStyles)(TasksListDetails);
