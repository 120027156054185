import PropTypes from 'prop-types';
import React from 'react';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
//styles
import { combineStyles, isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import SelectAll from 'components/SelectAll';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { get } from 'helpers/apiHelpers';
import { ROLE_SHOW_SUB_BRAND, ROLE_SHOW_ZONE_CATEGORY } from 'helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Check, DeleteForever } from '@material-ui/icons';
import MultiplierCalculator from 'components/MultiplierCalculator/MultiplierCalculator';

class CombinedReportRow extends React.Component {
  state = {
    _key: '',
    subBrandsOptions: [],
    brands: [],
    subBrands: [],
    zoneCategories: [],
    recipeTags: [],
    dateFrom: '',
    dateTo: '',
    multiplier: 1,
    includeSubscriptions: false,
    multiplierCalculatorOpen: false,
  };

  updateParent = () =>
    this.props?.onRowUpdated({
      brands: this.state.brands,
      subBrands: this.state.subBrands,
      zoneCategories: this.state.zoneCategories,
      recipeTags: this.state.recipeTags,
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      multiplier: this.state.multiplier,
      includeSubscriptions: this.state.includeSubscriptions,
      _key: this.state._key,
    });

  handleChange = async event => {
    await this.setState(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

    this.updateParent();
  };

  handleChangeDate = async (event, field) => {
    await this.setState(prevState => ({
      ...prevState,
      [field]: event.format('YYYY-MM-DD'),
    }));

    this.updateParent();
  };

  handleToggle = async event => {
    await this.setState(prevState => ({
      ...prevState,
      [event.target.name]: !prevState[event.target.name],
    }));

    this.updateParent();
  };

  handleBrandChange = async selected => {
    await this.setState({
      brands: selected,
      subBrands: [],
      options: [],
    });
    isGranted(ROLE_SHOW_SUB_BRAND) && this.fetchSubBrands(selected);

    this.updateParent();
  };

  handleSubBrandChange = async selected => {
    await this.setState({
      subBrands: selected.filter(el => typeof el != 'undefined'),
    });

    this.updateParent();
  };

  handleZoneCategories = async selected => {
    await this.setState({
      zoneCategories: selected.filter(el => typeof el != 'undefined'),
    });

    this.updateParent();
  };

  handleRecipeTags = async selected => {
    await this.setState({
      recipeTags: selected.filter(el => typeof el != 'undefined'),
    });

    this.updateParent();
  };

  rowRemoved = row => this.props?.onRowRemoved(row);

  fetchSubBrands = async selected => {
    const selectInBrands = selected.length
      ? selected.map(brand => brand.value).filter(val => val !== '*')
      : [];

    let params = {};
    if (selectInBrands.length > 0) {
      params.selectInBrands = selectInBrands;
    }
    const response = await get('/sub-brands', params);

    const allSubBrandsElement = this.state.subBrands.find(
      subBrand => subBrand?.value === '*'
    );

    const subBrands = response['hydra:member'];
    const subBrandsIds = subBrands.map(el => el['@id']);

    const selectedSubBrands = !!allSubBrandsElement
      ? subBrands.map(({ name: label, '@id': value }) => ({ label, value }))
      : [];

    const prevSelectedSubBrands = this.state.subBrands.filter(
      subBrand => subBrand && subBrandsIds.includes(subBrand.value)
    );

    return this.setState({
      subBrandsOptions: subBrands,
      subBrands: [
        allSubBrandsElement,
        ...selectedSubBrands,
        ...prevSelectedSubBrands,
      ],
    });
  };

  componentDidMount() {
    this.setState(prevState => ({
      ...prevState,

      brands: this.props.brands,
      subBrands: this.props.subBrands,
      dateFrom: this.props.dateFrom,
      dateTo: this.props.dateTo,
      multiplier: this.props.multiplier,
      recipeTags: this.props.recipeTags,
      zoneCategories: this.props.zoneCategories,
      includeSubscriptions: false,
      _key: this.props._key,
    }));
    if (isGranted(ROLE_SHOW_SUB_BRAND)) {
      this.fetchSubBrands(this.props.brands);
    }
  }

  render() {
    const { classes } = this.props;
    const useSubBrands = this.props.useSubBrands !== false;

    return (
      <Card style={{ marginTop: '20px' }}>
        <CardBody>
          <GridContainer justify="flex-start" style={{ position: 'relative' }}>
            {this.props.canBeRemoved && (
              <DeleteForever
                onClick={() => this.rowRemoved(this.state)}
                style={{
                  position: 'absolute',
                  left: '-10px',
                  top: '-10px',
                  zIndex: 22,
                  background: '#989898',
                  color: '#fff',
                  borderRadius: '20px',
                  padding: 2,
                  cursor: 'pointer',
                }}
              />
            )}
            <GridItem sm={this.props.showMultiplier === true ? 2 : 4}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '2px' }}
              >
                {this.props.t('reports.selectBrands')}
              </FormLabel>
              <SelectAll
                className="input-select--brand"
                options={this.props.brandsOptions}
                trackBy={'@id'}
                mapBy={'name'}
                optionSelected={this.state.brands}
                handleChange={this.handleBrandChange}
              />
            </GridItem>
            {this.props.showMultiplier === true && (
              <GridItem sm={2}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '5px' }}
                >
                  {this.props.t('common.multiplier')}
                </FormLabel>
                <FormControl fullWidth>
                  <FormTextInput
                    className="input-text--multiplier"
                    classes={classes}
                    name="multiplier"
                    value={this.state.multiplier}
                    type={'number'}
                    handleChange={this.handleChange}
                    inputSize={12}
                    onFocus={() =>
                      this.setState({ multiplierCalculatorOpen: true })
                    }
                    inputRef={node => {
                      this.anchorElement = node;
                    }}
                  />
                  <MultiplierCalculator
                    classes={classes}
                    url={'/statistics/ecommerce/bags/day/'}
                    open={this.state.multiplierCalculatorOpen}
                    anchorElement={this.anchorElement}
                    brandIds={this.state.brands}
                    handleChange={(name, value) =>
                      this.handleChange({
                        target: {
                          name: name,
                          value: value,
                        },
                      })
                    }
                  />
                </FormControl>
              </GridItem>
            )}
            {isGranted(ROLE_SHOW_SUB_BRAND) && useSubBrands && (
              <GridItem sm={2}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '2px' }}
                >
                  {this.props.t('reports.selectSubbrands')}
                </FormLabel>
                <SelectAll
                  className="input-select--subbrand"
                  options={this.state.subBrandsOptions}
                  trackBy={'@id'}
                  mapBy={'name'}
                  optionSelected={this.state.subBrands}
                  customLabel={subBrand => {
                    const fullBrand = this.props.brandsOptions.find(
                      brand => brand['@id'] === subBrand.brand
                    );
                    const brandName = fullBrand && fullBrand.name;
                    const subBrandName = subBrand.name;
                    const label = subBrandName;
                    const labelWithBrandName = `${label} (${brandName})`;

                    return brandName ? labelWithBrandName : label;
                  }}
                  handleChange={this.handleSubBrandChange}
                />
              </GridItem>
            )}
            {isGranted(ROLE_SHOW_ZONE_CATEGORY) &&
              this.props.useZoneCategories && (
                <GridItem sm={2}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '2px' }}
                  >
                    {this.props.t('reports.selectZoneCategories')}
                  </FormLabel>
                  <SelectAll
                    className="input-select--zone-category"
                    options={this.props.zoneCategoriesOptions}
                    trackBy={'@id'}
                    mapBy={'name'}
                    optionSelected={this.state.zoneCategories}
                    handleChange={this.handleZoneCategories}
                  />
                </GridItem>
              )}
            {this.props.useDateRange && (
              <>
                <GridItem sm={2}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '5px' }}
                  >
                    {this.props.t('reports.dateFrom')}
                  </FormLabel>
                  <FormControl fullWidth>
                    <Datetime
                      className="input-datetime--date-from"
                      isValidDate={isDatepickerValidDay}
                      timeFormat={false}
                      dateFormat={moment.localeData().longDateFormat('L')}
                      closeOnSelect={true}
                      value={new moment(this.state.dateFrom)}
                      onChange={ev => this.handleChangeDate(ev, 'dateFrom')}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </GridItem>
                <GridItem sm={2}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '5px' }}
                  >
                    {this.props.t('reports.dateTo')}
                  </FormLabel>
                  <FormControl fullWidth>
                    <Datetime
                      className="input-datetime--date-to"
                      isValidDate={isDatepickerValidDay}
                      timeFormat={false}
                      dateFormat={moment.localeData().longDateFormat('L')}
                      closeOnSelect={true}
                      value={new moment(this.state.dateTo)}
                      onChange={ev => this.handleChangeDate(ev, 'dateTo')}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </>
            )}
            {!this.props.useDateRange && (
              <>
                <GridItem sm={2}>
                  <FormLabel
                    className={classes.labelHorizontal}
                    style={{ marginBottom: '5px' }}
                  >
                    {this.props.t('reports.selectDate')}
                  </FormLabel>
                  <FormControl fullWidth>
                    <Datetime
                      className="input-datetime--date-range"
                      isValidDate={isDatepickerValidDay}
                      timeFormat={false}
                      dateFormat={moment.localeData().longDateFormat('L')}
                      closeOnSelect={true}
                      value={new moment(this.state.dateFrom)}
                      onChange={ev => this.handleChangeDate(ev, 'dateFrom')}
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                  </FormControl>
                </GridItem>
              </>
            )}
            {this.props.useRecipeTags && (
              <GridItem sm={2}>
                <FormLabel
                  className={classes.labelHorizontal}
                  style={{ marginBottom: '2px' }}
                >
                  {this.props.t('reports.selectRecipeTags')}
                </FormLabel>
                <SelectAll
                  className="input-select--production-tags"
                  options={this.props.recipeTagsOptions}
                  trackBy={'@id'}
                  mapBy={'value'}
                  optionSelected={this.state.recipeTags}
                  handleChange={this.handleRecipeTags}
                />
              </GridItem>
            )}
            <GridItem sm={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    className="input-checkbox--include-subscriptions"
                    name={'includeSubscriptions'}
                    checked={this.state.includeSubscriptions}
                    onChange={this.handleToggle}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={this.props.t('reports.includeSubscriptions')}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  brandsOptions: state.Auth.user.brands,
  zoneCategoriesOptions: state.ZoneCategories.zoneCategories,
  recipeTagsOptions: state.Dictionary.productionTags,
  company: state.Auth.user.company,
});

CombinedReportRow.propTypes = {
  classes: PropTypes.any,
  brands: PropTypes.array,
  subBrands: PropTypes.array,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  multiplier: PropTypes.number,
  includeSubscriptions: PropTypes.bool,
  brandsOptions: PropTypes.array,
  zoneCategoriesOptions: PropTypes.array,
  showMultiplier: PropTypes.bool,
  showIncludeSubscriptions: PropTypes.bool,
  validateBrands: PropTypes.bool,
  useDateRange: PropTypes.bool,
  useSubBrands: PropTypes.bool,
  useZoneCategories: PropTypes.bool,
  useRecipeTags: PropTypes.bool,
  canBeRemoved: PropTypes.bool,
};

const enhance = compose(
  connect(mapStateToProps, null),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(CombinedReportRow);
