import React from 'react';
import Moment from 'moment';

import YesNoFilter from 'components/Grid/Filter/YesNo';
import DateRangeFilter from 'components/Grid/Filter/DateRange';
import BooleanView from '../../components/DataGrid/ValueViews/BooleanView';
class MoneyBoxFilter extends React.Component {
  state = {
    moneyBox: this.props.value || null,
  };

  render() {
    return (
      <div style={{ display: 'flex' }}>
        <input
          type="number"
          value={this.state.moneyBox}
          onChange={async ev => {
            await this.setState({
              moneyBox: ev.target.value,
            });
            this.props.onFilterChange(this.state.moneyBox);
          }}
        />
      </div>
    );
  }
}

const translateColumnConfig = (t, sexes, clientTags) => [
  {
    title: 'ID',
    accessor: 'id',
    name: 'id',
    width: 80,
    filterable: true,
    sortable: true,
  },
  {
    title: 'clients.smsClientTags',
    accessor: row => {
      return (
        <ul>
          {row.smsClientTags.map(el => (
            <li style={{ marginLeft: '-24px' }}>{el.value}</li>
          ))}
        </ul>
      );
    },
    name: 'smsClientTags',
    Filter: ({ onChange, filter }) => {
      return (
        <select
          style={{ height: '30px', width: '100%' }}
          onChange={e => onChange(e.target.value)}
          value={filter?.value || ''}
        >
          <option value={''}>{t('clients.all')}</option>
          <option value={'noTags'}>{t('clients.noTags', 'Brak tagów')}</option>
          {clientTags.map(el => {
            return <option value={el.value}>{el.value}</option>;
          })}
        </select>
      );
    },
    filtered: true,
    width: 160,
  },
  {
    title: 'clients.creationDate',
    accessor: row => new Moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss'),
    name: 'createdAt',
    Filter: ({ onChange, filter }) => (
      <DateRangeFilter onChange={onChange} value={filter?.value} />
    ),
    width: 160,
  },
  {
    title: 'clients.activeDiets',
    accessor: row => <BooleanView value={row.hasActiveDiets} />,
    name: 'hasActiveDiets',
    width: 75,
    sortable: false,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
  },
  {
    title: 'clients.fullname',
    accessor: row => `${row.firstName} ${row.lastName}`,
    name: 'firstName',
  },
  {
    title: 'clients.refCode',
    accessor: client => client?.referenceCode?.code ?? '-',
    name: 'referenceCode.code',
  },
  {
    title: 'clients.gender',
    accessor: client => client?.gender?.value ?? '-',
    width: 120,
    name: 'gender',
    Filter: ({ onChange, filter }) => (
      <select
        style={{ height: '30px', width: '100%' }}
        onChange={e => onChange(e.target.value)}
        value={filter?.value || ''}
      >
        <option value="">{t('clients.all')}</option>
        {sexes.map(({ '@id': iriId, id, value }) => {
          return (
            <option key={id} value={iriId}>
              {value}
            </option>
          );
        })}
      </select>
    ),
  },
  {
    title: 'clients.phone',
    accessor: prop => (
      <a href={`tel:${prop.phone.number}`}>{prop.phone.number}</a>
    ),
    name: 'phone.number',
  },
  {
    title: 'clients.email',
    accessor: 'email',
    name: 'email',
  },
  {
    title: 'clients.messagesTerms',
    accessor: row => <BooleanView value={row.messagesTerms} />,
    name: 'messagesTerms',
    width: 75,
    sortable: false,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
  },
  {
    title: 'clients.marketing',
    accessor: row => <BooleanView value={row.marketingTerms} />,
    name: 'marketingTerms',
    width: 75,
    sortable: false,
    Filter: ({ onChange, filter }) => (
      <YesNoFilter onChange={onChange} value={`${filter?.value}`} />
    ),
  },
  {
    title: 'clients.fromYouKnow',
    accessor: 'knowAboutUs',
    name: 'knowAboutUs',
  },
  {
    title: 'clients.moneybox',
    accessor: 'moneyBox',
    name: 'moneyBox',
    width: 100,
    Filter: ({ onChange, filter }) => (
      <MoneyBoxFilter onFilterChange={onChange} value={filter?.value} />
    ),
  },
  {
    title: 'clients.paidOrders',
    accessor: 'amountPaidOrders',
    name: 'amountPaidOrders',
    width: 100,
  },
  {
    title: 'clients.note',
    accessor: prop => {
      return prop.clientLastNote?.content;
    },
    name: 'clientLastNote.content',
    filterable: true,
    sortable: true,
  },
];

export default translateColumnConfig;
