import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

import Danger from 'components/Typography/Danger.jsx';
import FormLabel from '@material-ui/core/FormLabel';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import { useTranslation } from 'react-i18next';

import { CustomTooltip } from 'components';
import LanguagePicker from 'components/Translatable/LanguagePicker';
import useTranslatableSelectedLanguage, {
  TranslatableObjectKey,
} from 'hooks/redux/Translatable/useTranslatableLanguages';
import { useSelector } from 'react-redux';
const FormTextInputTranslatable = ({
  label,
  classes,
  success,
  error,
  helpText,
  handleChange,
  multiline,
  inputSize,
  rows,
  rowsMax,
  name,
  value,
  type,
  disabled,
  noMargin,
  noGrid,
  tooltip,
  maxLength,
  noAutoComplete,
  errors = {},
  setDefaultLanguage = () => {},
  ...props
}) => {
  const { t } = useTranslation();
  const [controlledVal, setControlledVal] = useState(
    typeof value === 'string'
      ? { [TranslatableObjectKey]: { pl: value } }
      : value
  );
  const selectedLanguage = useTranslatableSelectedLanguage();
  const defaultLanguage = useSelector(root => root.Company?.language ?? 'pl');
  const errorMessage = errors[name] || false;

  useEffect(() => {
    const tmpControlledValue =
      typeof value === 'string'
        ? { [TranslatableObjectKey]: { pl: value } }
        : value;
    if (JSON.stringify(controlledVal) !== JSON.stringify(tmpControlledValue)) {
      setControlledVal(tmpControlledValue);
    }
  }, [value]);

  useEffect(() => {
    handleChange(
      { target: { name: name, value: controlledVal } },
      controlledVal
    );
    setDefaultLanguage(defaultLanguage);
  }, [controlledVal, defaultLanguage]);

  const currentValue = useMemo(
    () => controlledVal?.[TranslatableObjectKey]?.[selectedLanguage] ?? '',
    [controlledVal, selectedLanguage]
  );

  const defaultValue = useMemo(
    () => controlledVal?.[TranslatableObjectKey]?.[defaultLanguage] ?? '',
    [controlledVal, defaultLanguage]
  );

  const handleTranslatableChange = useCallback(
    ({ target: { value } }) => {
      setControlledVal(prevVal => ({
        [TranslatableObjectKey]: {
          ...(prevVal[TranslatableObjectKey] ?? {}),
          [selectedLanguage]: value,
        },
      }));
    },
    [selectedLanguage]
  );

  return (
    <GridContainer alignItems="center">
      <GridItem xs={12} sm={inputSize}>
        <GridContainer alignItems="center" spacing={0}>
          <GridItem xs={12}>
            <FormLabel className={classes.labelHorizontal}>
              {label} {tooltip && <CustomTooltip title={tooltip} />}
            </FormLabel>
            <CustomInput
              success={success}
              error={error}
              required={true}
              helpText={helpText}
              maxLength={maxLength}
              formControlProps={{
                fullWidth: true,
              }}
              name={name}
              inputProps={{
                max: 12,
                multiline: multiline,
                onChange: handleTranslatableChange,
                value: currentValue,
                type: type ? type : 'text',
                rows: rows,
                rowsMax: rowsMax,
                name: name,
                disabled: disabled ? disabled : false,
                autoComplete: noAutoComplete ? 'new-password' : 'on',
                ...props,
                startAdornment: <LanguagePicker />,
              }}
            />
            {(!defaultValue || defaultValue === '') && (
              <Danger>
                {t('translatable.defaultLanguage.empty', {
                  replace: { defaultLanguage: defaultLanguage },
                  defaultValue:
                    'Brak uzupełnionej wartości dla domyślnego języka "{{defaultLanguage}}"',
                })}
              </Danger>
            )}
            {errorMessage && <Danger>{errorMessage}</Danger>}
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
};

export default memo(FormTextInputTranslatable);
