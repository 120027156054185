import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Divider } from '@material-ui/core';
import Datetime from 'react-datetime';
import Check from '@material-ui/icons/Check';
import React from 'react';
import { modulesNames } from '../../../consts';
import Checkbox from '@material-ui/core/Checkbox';

const DisabledDaysSectionSettings = ({
  state,
  classes,
  t,
  setState,
  handleModuleUpdate,
  modules,
}) => {
  return (
    <>
      <h4 style={{ marginTop: '50px' }}>{t('brandCfg.daysOut')}</h4>
      <Divider style={{ marginBottom: '20px' }} />
      <Datetime
        className={'customRdtPickerStyle'}
        timeFormat={false}
        input={false}
        open={true}
        value={'0'}
        disabled={true}
        renderDay={(props, currentDate, selectedDate) => {
          let disabledDays = [...state.disabledDays];
          const isSelected = disabledDays.includes(
            currentDate.format('YYYY-MM-DD')
          );
          const key = currentDate.locale('en').format('dddd').toLowerCase();

          props.className += ' customTdStyle';
          let customDayStyle = 'customDayStyle';

          if (state[key].shouldBeDeliveredIn === 0) {
            customDayStyle = 'customDisabledClick';
          }
          if (state[key].shouldBeDeliveredInBlock === 0) {
            customDayStyle = 'customDisabledClick';
          }
          if (isSelected) {
            props.className += ' dayExcluded';
            customDayStyle += ' isSelected';
          }
          return (
            <td {...props}>
              <div className={customDayStyle}>{currentDate.date()}</div>
            </td>
          );
        }}
        onChange={ev => {
          const key = ev.locale('en').format('dddd').toLowerCase();
          if (state[key] === 0) {
            return;
          }
          let disabledDays = [...state.disabledDays];
          if (disabledDays.includes(ev.format('YYYY-MM-DD'))) {
            setState(prevState => ({
              ...prevState,
              disabledDays: state.disabledDays.filter(
                date => date !== ev.format('YYYY-MM-DD')
              ),
            }));
          } else {
            disabledDays.push(ev.format('YYYY-MM-DD'));

            setState(prevState => ({
              ...prevState,
              disabledDays: disabledDays,
            }));
            return false;
          }
        }}
      />
      <GridContainer>
        <GridItem md={12}>
          <Checkbox
            style={{ marginLeft: '-14px' }}
            onClick={() =>
              handleModuleUpdate({
                ...modules?.[modulesNames.ConfigClientPanel],
                hideDisabledDaysOnClientCalendar: !modules?.[
                  modulesNames.ConfigClientPanel
                ].hideDisabledDaysOnClientCalendar,
              })
            }
            checked={
              modules?.[modulesNames.ConfigClientPanel]
                .hideDisabledDaysOnClientCalendar
            }
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
          <span>{t('brandCfg.hideDisabledDays')}</span>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default DisabledDaysSectionSettings;
