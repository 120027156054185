import { useState, useEffect } from 'react';
import React from 'react';
import { combineStyles } from 'helpers/helpers';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import {
  fetchBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
  fetchPaymentTypes,
  selectDefaultPaymentType,
  setEnabledPaymentTypes,
} from 'actions/Brands';
import { withToast } from 'material-ui-toast-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { modulesNames } from '../consts';
import PaymentMethodView from './PaymentMethod.view';

const paymentTypesTranslations = t => ({
  PAYU_CARD: {
    title: 'PayU',
    paymentTypeStandard: 'PAYU_CARD',
    paymentTypeSubscription: 'PAYU_CARD',
    option: 'cardPaymentsEnabled',
    method: t('paymentType.payUCard', 'Zapisanie karty - PayU'),
    tooltip: t(
      'paymentType.payUCard.tooltip',
      'Do działania metody niezbędne jest posiadanie aktywnych płatności cyklicznych w PayU'
    ),
    designation: 'RGB(0,172,193)',
  },
  PAYU_PAY_BY_LINKS: {
    title: 'PayU',
    paymentTypeStandard: 'PAYU_PAY_BY_LINKS',
    option: 'payByLink',
    method: t('paymentType.payUPBL', 'Szybkie przelewy - PayU'),
    designation: 'RGB(0,172,193)',
  },
  PAYU_PAYPO: {
    title: 'PayU',
    paymentTypeStandard: 'PAYU_PAYPO',
    option: 'payPo',
    method: t('paymentType.payUPayPo', 'Płatności odroczone PayPo - PayU'),
    designation: 'RGB(0,172,193)',
  },
  PAYU_GOOGLE_PAY: {
    title: 'PayU',
    paymentTypeStandard: 'PAYU_GOOGLE_PAY',
    option: 'googlePayEnabled',
    method: t('paymentType.payUGooglePay', 'Google Pay - PayU'),
    designation: 'RGB(0,172,193)',
  },
  PAYU: {
    title: 'PayU',
    paymentTypeStandard: 'PAYU',
    method: t('paymentType.payU', 'PayU'),
    designation: 'RGB(0,172,193)',
  },
  PAYU_BLIK: {
    title: 'PayU',
    paymentTypeStandard: 'PAYU_BLIK',
    option: 'blik',
    method: t('paymentType.payUBlik', 'Szybki Blik - PayU'),
    designation: 'RGB(0,172,193)',
  },
  PAYU_SANDBOX: {
    title: 'PayU',
    paymentTypeStandard: 'PAYU_SANDBOX',
    option: 'sandbox',
    method: t('paymentType.payUSandbox', 'Sandbox'),
    designation: 'RGB(0,172,193)',
  },
  STRIPE_CARD: {
    title: 'Stripe',
    paymentTypeStandard: 'STRIPE_CARD',
    paymentTypeSubscription: 'STRIPE_CARD',
    option: 'cardPayments',
    method: t('paymentType.stripeCard', 'Zapisanie karty - Stripe'),
    designation: 'RGB(76,175,80)',
  },
  STRIPE_LINK: {
    title: 'Stripe',
    paymentTypeStandard: 'STRIPE_LINK',
    method: t('paymentType.stripeLink', 'Stripe'),
    designation: 'RGB(76,175,80)',
  },
  PAYPO: {
    title: 'PayPo',
    paymentTypeStandard: 'PAYPO',
    method: t('paymentType.payPo', 'PayPo'),
    designation: 'RGB(204,0,0)',
  },
  PAYNOW: {
    title: 'PayNow',
    paymentTypeStandard: 'PAYNOW',
    method: t('paymentType.payNow', 'PayNow'),
    designation: 'RGB(255,152,0)',
  },
  PAYNOW_SANDBOX: {
    title: 'PayNow',
    paymentTypeStandard: 'PAYNOW_SANDBOX',
    option: 'sandbox',
    method: t('paymentType.payNowSandbox', 'Sandbox'),
    designation: 'RGB(255,152,0)',
  },
});

const PaymentMethodContainer = ({
  classes,
  t,
  fetchBrand,
  initialPaymentTypes,
  selectedBrand,
  fetchBrandConfigModulesPack,
  updateBrandConfigModulesPack,
  openToast,
  fetchPaymentTypes,
}) => {
  const [paymentTypes, setPaymentTypes] = useState(initialPaymentTypes);
  const [modules, setModules] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (selectedBrand) {
      fetchPaymentTypes(selectedBrand);
    }
  }, [selectedBrand]);

  useEffect(() => {
    setPaymentTypes(initialPaymentTypes);
  }, [initialPaymentTypes]);

  useEffect(() => {
    (async () => {
      const modulesConfig = await fetchBrandConfigModulesPack(selectedBrand, [
        modulesNames.PayU,
        modulesNames.Stripe,
        modulesNames.BlueMedia,
        modulesNames.PayNow,
        modulesNames.AdditionalPayment,
        modulesNames.ItemPaymentTypes,
        modulesNames.ConfigClientPanel,
        modulesNames.PayPo,
      ]);

      setModules(modulesConfig.configuration);
      setIsLoading(false);
    })();
  }, []);

  const handleModuleUpdate = data => {
    const type = data['@type'];
    let module = { ...modules[type], ...data };
    setModules({ ...modules, [type]: module });
  };

  const handleSubmit = async () => {
    try {
      await updateBrandConfigModulesPack(selectedBrand, {
        ...modules,
        ItemPaymentTypes: paymentTypes,
      });

      openToast({
        messages: [t('success.changesSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    } catch (e) {
      openToast({
        messages: [t('notify.cannotSave')],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const defaultPaymentOptions = [
    {
      label: t('module.configClientPanel.oneTimePayment'),
      value: 2,
    },
    {
      label: t('module.configClientPanel.subscription'),
      value: 1,
    },
  ];

  const props = {
    classes,
    t,
    modules,
    setModules,
    isLoading,
    handleModuleUpdate,
    handleSubmit,
    openToast,
    paymentTypesTranslations,
    defaultPaymentOptions,
    paymentTypes,
    setPaymentTypes,
  };
  return <PaymentMethodView props={props} />;
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(
  withTranslation(),
  connect(
    ({ Auth: { selectedBrand }, Brands: { paymentTypes } }) => ({
      initialPaymentTypes: paymentTypes,
      selectedBrand,
    }),
    {
      fetchBrand,
      fetchBrandConfigModulesPack,
      updateBrandConfigModulesPack,
      fetchPaymentTypes,
      selectDefaultPaymentType,
      setEnabledPaymentTypes,
    }
  ),
  withToast,
  withStyles(combinedStyles)
);
export default enhance(PaymentMethodContainer);
