import { get } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchSexes = () => async dispatch => {
  dispatch({
    type: types.FETCH_SEXES,
  });

  try {
    const { 'hydra:member': sexes } = await get('/sexes', {
      pagination: false,
    });

    dispatch({
      type: types.FETCH_SEXES_SUCCESS,
      payload: {
        sexes,
      },
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_SEXES_FAILED,
    });
  }
};

export const fetchHowKnow = () => async dispatch => {
  dispatch({
    type: types.FETCH_HOW_KNOW,
  });

  try {
    const { 'hydra:member': howKnow } = await get('/how-knows', {
      pagination: false,
    });

    dispatch({
      type: types.FETCH_HOW_KNOW_SUCCESS,
      payload: {
        howKnow,
      },
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_HOW_KNOW_FAILED,
    });
  }
};

export const fetchProductionTags = () => async dispatch => {
  dispatch({
    type: types.FETCH_PRODUCTION_TAGS,
  });

  try {
    const { 'hydra:member': productionTags } = await get('/tags', {
      pagination: false,
    });

    dispatch({
      type: types.FETCH_PRODUCTION_TAGS_SUCCESS,
      payload: {
        productionTags,
      },
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_PRODUCTION_TAGS_FAILED,
    });
  }
};

export const fetchSmsTagsForClients = () => async dispatch => {
  dispatch({
    type: types.FETCH_SMS_TAGS,
  });

  try {
    const { 'hydra:member': smsClientTags } = await get('/sms-client-tags', {
      pagination: false,
    });

    dispatch({
      type: types.FETCH_SMS_TAGS_SUCCESS,
      payload: {
        smsClientTags,
      },
    });
  } catch (error) {
    dispatch({
      type: types.FETCH_SMS_TAGS_FAILED,
    });
  }
};
