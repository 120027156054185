import types from 'helpers/constants';

const initialState = {
  mainColor: 'caterings',
};

function App(state = initialState, action) {
  switch (action.type) {
    case types.THEME_SET_MAIN_COLOR:
      return {
        ...state,
        mainColor: action.payload.mainColor,
      };
    default:
      return state;
  }
}

export default App;
