import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import produce from 'immer';
import * as yup from 'yup';

import GroupIcon from '@material-ui/icons/Group';
import withStyles from '@material-ui/core/styles/withStyles';

import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';

import AdminTable from 'layouts/AdminTable';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

import { get, post, put } from 'helpers/apiHelpers';
import { combineStyles } from 'helpers/helpers';
import { withTranslation } from 'react-i18next';

const initialFormData = {
  name: '',
  nip: '',
  street: '',
  city: '',
  postCode: '',
  www: '',
  email: '',
  phoneNumber: '',
  note: '',
};

const generateSchema = (validators, t, language) => {
  const invalidNipLength = t(
    'validationError.nipLengthNotEquals10',
    'NIP musi zawierać 10 cyfr'
  );
  return yup.object().shape({
    name: yup.string().required(t('formValidation.required')),
    nip: yup.string().test('isNIP', invalidNipLength, value => {
      if (!!value && language === 'pl') {
        const schema = yup.string().min(10).max(10);

        return schema.isValidSync(value);
      }

      return true;
    }),
    postCode: yup
      .string()
      .test(
        'isPostCode',
        t('zones.incorrectPostCode', 'Kod pocztowy niepoprawny'),
        value => {
          if (!!value) {
            const schema = yup
              .string()
              .matches(
                new RegExp(validators?.postCode),
                t('zones.incorrectPostCode', 'Kod pocztowy niepoprawny')
              );

            return schema.isValidSync(value);
          }

          return true;
        }
      ),
    www: yup
      .string()
      .test(
        'isWebsite',
        t('validationError.invalidWWW', 'Nieprawidłowy adres www'),
        value => {
          if (!!value) {
            const schema = yup
              .string()
              .matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                t('validationError.invalidWWW', 'Nieprawidłowy adres www')
              );

            return schema.isValidSync(value);
          }

          return true;
        }
      ),
    email: yup
      .string()
      .nullable()
      .notRequired()
      .email(
        t(
          'brands.newBrandForm.helpText.incorrect',
          t(
            'brands.newBrandForm.helpText.incorrect',
            'Nieprawidłowy adres email'
          )
        )
      ),
  });
};

const ContractorsForm = ({
  classes,
  history,
  location,
  match,
  openToast,
  selectedRegionForValidations,
  t,
  i18n: { language },
}) => {
  const isEdit = location.pathname.includes('edit');
  const contractorId = match.params.id;

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  const postCodeValidator = useCallback(
    (regexpString => {
      if (null === regexpString) {
        return true;
      }
      const regexp = new RegExp(`${regexpString}$`);
      return input => regexp.test(input);
    })(selectedRegionForValidations?.postCodeRegexp),
    [selectedRegionForValidations]
  );

  const schema = useMemo(
    () =>
      generateSchema(
        {
          postCode: selectedRegionForValidations?.postCodeRegexp,
        },
        t,
        language
      ),
    [selectedRegionForValidations, t, language]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (isEdit) {
        try {
          const response = await get(`/contractors/${contractorId}`);
          const {
            '@id': iriId,
            '@type': iriType,
            createdAt,
            updatedAt,
            ...onlyFormData
          } = response;

          setFormData({
            ...onlyFormData,
          });
        } catch (error) {}
      }
    };

    fetchData();
  }, []);

  const handleChange = ({ target: { name, value } }) => {
    setFormData(
      produce(draft => {
        draft[name] = value;
      })
    );
  };

  const handleSubmit = async () => {
    const isValid = schema.isValidSync(formData);
    schema.validate(formData).catch(({ path, message, ...rest }) => {
      setErrors({
        [path]: message,
      });
    });

    if (!isValid) {
      return openToast({
        messages: [
          t(
            'errors.fillAllFieldsWithValidValues',
            'Wypełnij wszystkie pola prawidłowymi wartościami!'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }

    try {
      isEdit
        ? await put(`/contractors/${contractorId}`, formData)
        : await post('/contractors', formData);

      isEdit
        ? history.push('/admin/contractors')
        : setFormData(initialFormData);
    } catch ({ response: { data = {}, status } }) {
      if (status === 500) {
        return openToast({
          messages: [
            t('errors.serverNotResponding', 'Serwer nie odpowiada.'),
            t(
              'errors.tryAgainLater',
              'Spóbuj ponownie później, przepraszamy za problemy.'
            ),
          ],
          type: 'error',
          autoHideDuration: 3000,
        });
      }

      openToast({
        messages: [
          t('errors.sthWentWrong', 'Coś poszło nie tak'),
          t(
            'errors.checkForm',
            'Upewnij się, czy formularz został wypełniony prawidłowo'
          ),
        ],
        type: 'error',
        autoHideDuration: 3000,
      });
    }
  };

  const isPostCodeValid = postCodeValidator(formData.postCode);

  //translate
  return (
    <AdminTable
      title={
        isEdit
          ? t('contractors.edit', 'Edytuj kontrahenta')
          : t('contractors.edit', 'Dodaj nowego kontrahenta')
      }
      iconComponent={<GroupIcon />}
    >
      <GridContainer>
        <GridItem sm={8}>
          <GridContainer>
            <GridItem sm={12}>
              <FormTextInput
                label={t('columns.name', 'Nimi') + ' *'}
                classes={classes}
                name="name"
                value={formData.name}
                handleChange={handleChange}
                errors={errors}
              />
            </GridItem>
            <GridItem sm={12}>
              <FormTextInput
                label={t('myCompany.taxId', 'NIP')}
                classes={classes}
                name="nip"
                type="number"
                value={formData.nip}
                handleChange={handleChange}
                errors={errors}
              />
            </GridItem>
            <GridItem sm={12}>
              <FormTextInput
                label={t('myCompany.street', 'Ulica')}
                classes={classes}
                name="street"
                value={formData.street}
                handleChange={handleChange}
                errors={errors}
              />
            </GridItem>
            <GridItem sm={12}>
              <FormTextInput
                label={t('myCompany.city', 'Miasto')}
                classes={classes}
                name="city"
                value={formData.city}
                handleChange={handleChange}
                errors={errors}
              />
            </GridItem>
            <GridItem sm={12}>
              <FormTextInput
                label={t('myCompany.postcode', 'Kod pocztowy')}
                classes={classes}
                name="postCode"
                value={formData.postCode}
                handleChange={handleChange}
                errors={errors}
                error={!isPostCodeValid}
                success={isPostCodeValid}
                placeholder="00-000"
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem sm={4}>
          <GridContainer>
            <GridItem sm={12}>
              <FormTextInput
                label={t('clients.email2', 'E-mail')}
                classes={classes}
                name="email"
                value={formData.email}
                handleChange={handleChange}
                errors={errors}
              />
            </GridItem>
            <GridItem sm={12}>
              <FormTextInput
                label={t('myCompany.phone', 'Telefon')}
                classes={classes}
                name="phoneNumber"
                value={formData.phoneNumber}
                handleChange={handleChange}
                errors={errors}
                placeholder="+48 000-000-000"
              />
            </GridItem>
            <GridItem sm={12}>
              <FormTextInput
                label={t('common.website', 'Strona www')}
                classes={classes}
                name="www"
                value={formData.www}
                handleChange={handleChange}
                errors={errors}
              />
            </GridItem>
            <GridItem sm={12}>
              <FormTextInput
                label={t('form.field.note', 'Notatka')}
                classes={classes}
                name="note"
                value={formData.note}
                handleChange={handleChange}
                errors={errors}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
      <FormControlButtons
        classes={classes}
        discardText={t('common.shared.cancel', 'Anuluj')}
        submitText={t('common.shared.save', 'Zapisz')}
        cancelPath="/admin/contractors"
        handleSubmit={handleSubmit}
      />
    </AdminTable>
  );
};

const mapStateToProps = state => ({
  selectedRegionForValidations: state.Brands.selectedRegionForValidations,
});

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);
const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  withTranslation(),
  connect(mapStateToProps, null)
);

export default enhance(ContractorsForm);
