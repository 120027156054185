const InputMaskStyle = {
  defaultMask: {
    marginTop: '10px',
    border: 'none',
    borderBottom: '1px solid RGB(210,210,210)',
    padding: '5px',
  },
};

export default InputMaskStyle;
