import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import { Tooltip } from '@material-ui/core';
import FormLabel from '@material-ui/core/FormLabel';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import Card from 'components/Card/Card';
import React from 'react';
import { FormTextInput } from 'components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Check, Info } from '@material-ui/icons';
import Checkbox from '@material-ui/core/Checkbox';

const LogoSettings = ({
  classes,
  t,
  getImage,
  removeImage,
  state,
  handleInputChange,
}) => {
  return (
    <>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem sm={6} md={3}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginTop: '15px', marginBottom: '24px' }}
              >
                {t('brandCfg.logoMobile')}
                <Tooltip
                  title={
                    <div>
                      <h4>{t('brandCfg.logoMobileDescriptionTooltip')}</h4>
                      <h4>
                        {t('common.height')}
                        {' 60px; '}
                        {t('common.width')}
                        {' 160px'}
                      </h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info fontSize={'small'} />
                </Tooltip>
              </FormLabel>
              <div style={{ marginLeft: '-15px' }}>
                <FormImageUpload
                  classes={classes}
                  stateName="logoMobile"
                  buttonText={t('brandCfg.selectLogo')}
                  getImage={getImage}
                  removeImage={removeImage}
                  previewUrl={state.logoMobileUrl}
                />
              </div>
            </GridItem>
            <GridItem sm={6} md={3}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginTop: '15px', marginBottom: '24px' }}
              >
                {t('brandCfg.logoDesktop')}
                <Tooltip
                  title={
                    <div>
                      <h4>{t('brandCfg.logoDesktopDescriptionTooltip')}</h4>
                      <h4>
                        {t('common.height')}
                        {' 70px; '}
                        {t('common.width')}
                        {' 160px'}
                      </h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info fontSize={'small'} />
                </Tooltip>
              </FormLabel>
              <div style={{ marginLeft: '-15px' }}>
                <FormImageUpload
                  classes={classes}
                  stateName="logoDesktop"
                  buttonText={t('brandCfg.selectLogo')}
                  getImage={getImage}
                  removeImage={removeImage}
                  previewUrl={state.logoDesktopUrl}
                />
              </div>
            </GridItem>
            <GridItem sm={6} md={3}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginTop: '15px', marginBottom: '24px' }}
              >
                {t('brandCfg.logoMail')}
                <Tooltip
                  title={
                    <div>
                      <h4>
                        {t('common.height')} {t('common.any')}
                        {'; '}
                        {t('common.width')}
                        {' 200px'}
                      </h4>
                    </div>
                  }
                  placement="right"
                >
                  <Info fontSize={'small'} />
                </Tooltip>
              </FormLabel>
              <div style={{ marginLeft: '-15px' }}>
                <FormImageUpload
                  classes={classes}
                  stateName="logoMail"
                  buttonText={t('brandCfg.selectLogo')}
                  getImage={getImage}
                  removeImage={removeImage}
                  previewUrl={state.logoMailUrl}
                />
              </div>
            </GridItem>
            <GridItem sm={6} md={3}>
              {' '}
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginTop: '15px', marginBottom: '24px' }}
              >
                {t('brandCfg.iconTabBrowser')}
              </FormLabel>
              <FormImageUpload
                classes={classes}
                stateName="favicon"
                buttonText={t('brandCfg.selectIcon')}
                getImage={getImage}
                removeImage={removeImage}
                previewUrl={state.faviconUrl}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={4}>
              <FormTextInput
                label={t('brandCfg.addrDestLogo')}
                classes={classes}
                name="logoLink"
                value={state.logoLink}
                maxLength={255}
                handleChange={handleInputChange}
                inputSize={12}
              />
              {state.logoLink.includes('https://') && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="logoLinkOpenNewCard"
                      onClick={() => {
                        handleInputChange({
                          target: {
                            name: 'logoLinkOpenNewCard',
                            value: !state.logoLinkOpenNewCard,
                          },
                        });
                      }}
                      checked={state.logoLinkOpenNewCard}
                      checkedIcon={<Check className={classes.checkedIcon} />}
                      icon={<Check className={classes.uncheckedIcon} />}
                      classes={{
                        checked: classes.checked,
                        root: classes.checkRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label={t('brandCfg.address.logo.openNewLink')}
                />
              )}
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};

export default LogoSettings;
