import { useState, useEffect } from 'react';
import { withToast } from 'material-ui-toast-redux';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

//styles
import { combineStyles, isGranted } from 'helpers/helpers';
import { isDatepickerValidDay } from 'helpers/dateHelpers';
//components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import SelectAll from 'components/SelectAll';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx';
import { get } from 'helpers/apiHelpers';
import { ROLE_SHOW_SUB_BRAND } from 'helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import FormTextInput from 'components/FormTextInput/FormTextInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Check, DeleteForever } from '@material-ui/icons';

const Task = ({
  classes,
  dateTo,
  dateFrom,
  multiplier,
  removeTask = () => {},
  canBeRemoved = false,
  brandsOptions = [],
  handleChange = () => {},
  selectedBrands,
  selectedSubBrands,
  includeSubscriptions,
}) => {
  const { t } = useTranslation();

  const [subBrandsOptions, setSubBrandsOptions] = useState([]);

  useEffect(() => {
    if (isGranted(ROLE_SHOW_SUB_BRAND)) {
      fetchSubBrands(selectedBrands ?? []);
    }
  }, [selectedBrands]);

  useEffect(() => {
    validateSelectedSubBrands();
  }, [subBrandsOptions]);

  const fetchSubBrands = async selected => {
    const selectInBrands = selected.length
      ? selected.map(brand => brand.value).filter(val => val !== '*')
      : [];

    let params = {};
    if (selectInBrands.length > 0) {
      params.selectInBrands = selectInBrands;
    }

    const response = await get('/sub-brands', params);

    return setSubBrandsOptions(response?.['hydra:member'] ?? []);
  };

  const validateSelectedSubBrands = () => {
    const validSubBrands = selectedSubBrands.filter(({ value }) =>
      subBrandsOptions.some(({ '@id': iri }) => iri === value)
    );

    if (validSubBrands.Length !== selectedSubBrands.Length) {
      handleChange(validSubBrands, 'selectedSubBrands');
    }
  };

  return (
    <Card style={{ marginTop: '20px', zIndex: '4' }}>
      <CardBody>
        <GridContainer justify="flex-start" style={{ position: 'relative' }}>
          {canBeRemoved && (
            <DeleteForever
              onClick={removeTask}
              style={{
                position: 'absolute',
                left: '-10px',
                top: '-10px',
                zIndex: 22,
                background: '#989898',
                color: '#fff',
                borderRadius: '20px',
                padding: 2,
                cursor: 'pointer',
              }}
            />
          )}
          <GridItem sm={2}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '2px' }}
            >
              {t('reports.selectBrands')}
            </FormLabel>
            <SelectAll
              className="input-select--brand"
              options={brandsOptions}
              trackBy={'@id'}
              mapBy={'name'}
              optionSelected={selectedBrands}
              handleChange={brand => handleChange(brand, 'selectedBrands')}
            />
          </GridItem>
          <GridItem sm={2}>
            <FormLabel
              className={classes.labelHorizontal}
              style={{ marginBottom: '5px' }}
            >
              {t('common.multiplier')}
            </FormLabel>
            <FormControl fullWidth>
              <FormTextInput
                className="input-text--multiplier"
                classes={classes}
                name="multiplier"
                value={multiplier}
                type={'number'}
                handleChange={e => {
                  const value = e.target.value;
                  if (value === '') {
                    handleChange('', 'multiplier');
                    return;
                  }

                  if (value.includes('.') || value.includes(',')) {
                    const spliced = value.includes('.')
                      ? value.split('.')
                      : value.split(',');
                    handleChange(
                      Math.abs(parseFloat(value)).toFixed(
                        Math.min(spliced.pop().length, 2)
                      ),
                      'multiplier'
                    );
                  } else {
                    handleChange(Math.abs(parseInt(value)), 'multiplier');
                  }
                }}
                inputSize={12}
              />
            </FormControl>
          </GridItem>
          {isGranted(ROLE_SHOW_SUB_BRAND) && (
            <GridItem sm={2}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '2px' }}
              >
                {t('reports.selectSubbrands')}
              </FormLabel>
              <SelectAll
                className="input-select--subbrand"
                options={subBrandsOptions}
                trackBy={'@id'}
                mapBy={'name'}
                optionSelected={selectedSubBrands}
                customLabel={subBrand => {
                  const fullBrand = brandsOptions.find(
                    brand => brand['@id'] === subBrand.brand
                  );
                  const brandName = fullBrand && fullBrand.name;
                  const subBrandName = subBrand.name;
                  const label = subBrandName;
                  const labelWithBrandName = `${label} (${brandName})`;

                  return brandName ? labelWithBrandName : label;
                }}
                handleChange={subbrand =>
                  handleChange(subbrand, 'selectedSubBrands')
                }
              />
            </GridItem>
          )}
          <>
            <GridItem sm={2}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '5px' }}
              >
                {t('reports.dateFrom')}
              </FormLabel>
              <FormControl fullWidth>
                <Datetime
                  className="input-datetime--date-from"
                  isValidDate={isDatepickerValidDay}
                  timeFormat={false}
                  dateFormat={moment.localeData().longDateFormat('L')}
                  closeOnSelect={true}
                  value={new moment(dateFrom)}
                  onChange={e => handleChange(e, 'dateFrom')}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </GridItem>
            <GridItem sm={2}>
              <FormLabel
                className={classes.labelHorizontal}
                style={{ marginBottom: '5px' }}
              >
                {t('reports.dateTo')}
              </FormLabel>
              <FormControl fullWidth>
                <Datetime
                  className="input-datetime--date-to"
                  isValidDate={isDatepickerValidDay}
                  timeFormat={false}
                  dateFormat={moment.localeData().longDateFormat('L')}
                  closeOnSelect={true}
                  value={new moment(dateTo)}
                  onChange={e => handleChange(e, 'dateTo')}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </FormControl>
            </GridItem>
          </>
          <GridItem sm={2}>
            <FormControlLabel
              control={
                <Checkbox
                  className="input-checkbox--include-subscriptions"
                  name={'includeSubscriptions'}
                  checked={includeSubscriptions}
                  onChange={() =>
                    handleChange(!includeSubscriptions, 'includeSubscriptions')
                  }
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              label={t('reports.includeSubscriptions')}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const mapStateToProps = state => ({
  company: state.Auth.user.company,
  brandsOptions: state.Auth.user.brands,
});

const enhance = compose(
  connect(mapStateToProps, null),
  withToast,
  withStyles(combinedStyles),
  withTranslation()
);

export default enhance(Task);
