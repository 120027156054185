import types from 'helpers/constants';

export const ChangeSidebarStatus = status => {
  return dispatch => {
    return dispatch({
      type: types.CHANGE_SIDEBAR,
      payload: {
        isOpen: !status.miniModeActive,
        isWrap: status.isMenuExpanded,
      },
    });
  };
};
