export const createZohoSalesIQ = widgetCode => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'zohoSalesIQ';

  let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="https://salesiq.zoho.eu/widget";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML="<div id='zsiqwidget'></div>";`;

  script.appendChild(document.createTextNode(code));
  document.body.appendChild(script);
};
