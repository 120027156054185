import React, { Fragment } from 'react';

import Check from '@material-ui/icons/Check';
import Checkbox from '@material-ui/core/Checkbox';
// import ExtensionIcon from '@material-ui/icons/Extension';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import addonPhotoTemplate from 'assets/img/addonPhotoTemplate.jpg';

import AdminTable from 'layouts/AdminTable';
import CustomInput from 'components/CustomInput/CustomInput';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';
import FormImageUpload from 'components/FormImageUpload/FormImageUpload';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';
import Tooltip from '@material-ui/core/Tooltip';

import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button';
// import roles from 'helpers/roles';
import BasicInfoCard from 'components/CMS/BasicInfoCard';
import DescriptionCard from 'components/CMS/DescriptionCard';
import { Info } from '@material-ui/icons';
import CardWrapper from 'components/Card/CardWrapper';
const defaultUsageOptions = [
  { name: 'form.field.defaultUsage.option.forBag', value: 'FOR_BAG' },
  { name: 'form.field.defaultUsage.option.forDiet', value: 'FOR_DIET' },
];

const excludedPlacesOptions = [
  { name: 'form.field.excludedPlaces.option.newOrder', value: 'NEW_ORDER' },
  { name: 'form.field.excludedPlaces.option.extendBag', value: 'EXTEND_BAG' },
];

const ADDON_SUBPAGE = 'ADDON_SUBPAGE';

const AddonsFormView = ({ props }) => {
  const {
    t,
    classes,
    formData,
    categories,
    contractors,
    diets,
    subpage,
    setSubpage,
    addonSubpageData,
    addonDescription,
    setAddonDescription,
    handleChange,
    handleAddonSubpageInputChange,
    handleCheckboxClick,
    getImage,
    removeImage,
    handleSubmit,
    CLIENT_PANEL,
    isEdit,
    tooltipStyles,
  } = props;

  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12}>
          <h2 style={{ display: 'block' }}>
            {isEdit
              ? t('views.addons.addons.editAddon')
              : t('views.addons.addons.newAddon')}
            <div>
              <Button
                onClick={() => setSubpage(CLIENT_PANEL)}
                color={subpage === CLIENT_PANEL ? 'info' : ''}
              >
                {t('views.addons.clientPanel')}
              </Button>
              <Button
                onClick={() => setSubpage(ADDON_SUBPAGE)}
                color={subpage === ADDON_SUBPAGE ? 'info' : ''}
              >
                {t('views.addons.addonSubpage')}
              </Button>
            </div>
          </h2>
        </GridItem>
      </GridContainer>

      {subpage === CLIENT_PANEL && (
        <>
          <AdminTable
            title={t('views.addons.addons.section.clientPanel.title') + ':'}
          >
            <GridContainer>
              <GridItem sm={8}>
                <GridContainer>
                  <GridItem sm={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.name.label')} *
                      <Tooltip
                        className={tooltipStyles.root}
                        title={
                          <h4>
                            {t(
                              'views.addons.addons.form.field.name.tooltip',
                              'Maksymalnie 70 znaków'
                            )}
                          </h4>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      maxLength={70}
                      inputProps={{
                        name: 'name',
                        value: formData.name,
                        onChange: handleChange,
                      }}
                    />
                  </GridItem>
                  <GridItem sm={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.shortDescription.label')}
                      <Tooltip
                        className={tooltipStyles.root}
                        title={
                          <h4>
                            {t(
                              'views.addons.addons.form.field.shortDescription.tooltip',
                              "Maksymalnie 500 znaków, wspierane są tagi HTML np. <a href='link'>Czytaj więcej</a>"
                            )}
                          </h4>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      maxLength={600}
                      inputProps={{
                        name: 'shortDescription',
                        value: formData.shortDescription,
                        onChange: handleChange,
                      }}
                    />
                  </GridItem>

                  <GridItem sm={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          tabIndex={-1}
                          name="active"
                          onClick={handleCheckboxClick}
                          checked={formData.active}
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label={
                        <>
                          {t('form.field.active.label')}
                          <Tooltip
                            className={tooltipStyles.root}
                            title={
                              <h4>
                                {t(
                                  'views.addons.addons.form.field.active.tooltip',
                                  'Nieaktywny dodatek jest niedostępny do zakupu niezależnie od reszty ustawień'
                                )}
                              </h4>
                            }
                            placement="right"
                          >
                            <Info fontSize={'small'} />
                          </Tooltip>
                        </>
                      }
                    />
                  </GridItem>
                  <GridItem sm={5}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.excludedPlaces.label')}
                      <Tooltip
                        title={t(
                          'views.addons.addons.form.field.excludedPlaces.tooltip'
                        )}
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </FormLabel>
                    <SelectInput
                      noGrid
                      multiple
                      classes={classes}
                      name="excludedPlaces"
                      mapBy="name"
                      trackBy="value"
                      options={excludedPlacesOptions.map(({ name, value }) => ({
                        name: t(name),
                        value,
                      }))}
                      value={formData.excludedPlaces}
                      handleChange={handleChange}
                    />
                  </GridItem>
                  <GridItem sm={4}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.defaultUsage.label')}
                    </FormLabel>
                    <SelectInput
                      noGrid
                      classes={classes}
                      name="defaultUsage"
                      mapBy="name"
                      trackBy="value"
                      options={defaultUsageOptions.map(({ name, value }) => ({
                        name: t(name),
                        value,
                      }))}
                      value={formData.defaultUsage}
                      handleChange={handleChange}
                    />
                  </GridItem>

                  <GridItem sm={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.category.label')}
                      <Tooltip
                        className={tooltipStyles.root}
                        title={
                          <h4>
                            {t(
                              'views.addons.addons.form.field.category.tooltip',
                              'Brak wyboru poskutuje przypisaniem domyślnej kategorii'
                            )}
                          </h4>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </FormLabel>
                    <SelectInput
                      noGrid
                      classes={classes}
                      name="category"
                      mapBy="name"
                      options={categories}
                      value={formData.category}
                      handleChange={handleChange}
                    />
                  </GridItem>
                  <GridItem sm={2}>
                    <FormLabel
                      className={classes.labelHorizontal}
                      style={{ marginBottom: '9px' }}
                    >
                      {t('form.field.priority.label')}
                      <Tooltip
                        className={tooltipStyles.root}
                        title={
                          <h4>
                            {t(
                              'views.addons.addons.form.field.priority.tooltip',
                              'Im większa liczba tym wcześniej wyświetlany'
                            )}
                          </h4>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'position',
                        value: formData.position,
                        onChange: handleChange,
                        type: 'number',
                      }}
                    />
                  </GridItem>
                  <GridItem sm={3}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.clientCost.label')} *
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'clientCost',
                        value: formData.clientCost,
                        onChange: handleChange,
                        type: 'number',
                        style: { marginTop: '9px' },
                      }}
                    />
                  </GridItem>
                  <GridItem sm={4}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.clientVat.label')} *
                      <Tooltip
                        className={tooltipStyles.root}
                        title={
                          <h4>
                            {t(
                              'views.addons.addons.form.field.clientVat.tooltip',
                              'Podawany w fakturach'
                            )}
                          </h4>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </FormLabel>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: 'clientVat',
                        value: formData.clientVat,
                        onChange: handleChange,
                        type: 'number',
                        style: { marginTop: '9px' },
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem sm={4}>
                <GridContainer>
                  <GridItem sm={12}>
                    <FormImageUpload
                      classes={classes}
                      stateName="image"
                      getImage={getImage}
                      removeImage={removeImage}
                      previewUrl={formData?.imageUrl}
                      defaultImage={addonPhotoTemplate}
                    />
                  </GridItem>
                  <GridItem sm={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('form.field.restrictedToDiets.label')}
                      <Tooltip
                        className={tooltipStyles.root}
                        title={
                          <h4>
                            {t(
                              'views.addons.addons.form.field.restrictedToDiets.tooltip',
                              'Brak wyboru oznacza globalną dostępność'
                            )}
                          </h4>
                        }
                        placement="right"
                      >
                        <Info fontSize={'small'} />
                      </Tooltip>
                    </FormLabel>
                    <SelectInput
                      noGrid
                      multiple
                      classes={classes}
                      name="restrictedToDiets"
                      mapBy="name"
                      trackBy="value"
                      options={diets.map(diet => ({
                        name: diet.name,
                        value: diet['@id'],
                      }))}
                      value={formData.restrictedToDiets}
                      handleChange={handleChange}
                    />
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </AdminTable>

          <CardWrapper
            title={t('views.addons.addons.section.otherSettings.title')}
          >
            <CardBody>
              <GridContainer>
                <GridItem sm={8}>
                  <GridContainer>
                    <GridItem sm={4}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.cost.label')} *
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'cost',
                          value: formData.cost,
                          onChange: handleChange,
                          type: 'number',
                          style: { marginTop: '9px' },
                        }}
                      />
                    </GridItem>

                    <GridItem sm={4}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.contractor.label')}
                      </FormLabel>
                      <SelectInput
                        noGrid
                        classes={classes}
                        name="contractor"
                        mapBy="name"
                        options={contractors}
                        value={formData.contractor}
                        handleChange={handleChange}
                      />
                    </GridItem>

                    <GridItem sm={4}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.stock.label')}
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'stock',
                          value: formData.stock,
                          onChange: handleChange,
                          type: 'number',
                          style: { marginTop: '9px' },
                        }}
                      />
                    </GridItem>

                    <GridItem sm={4}>
                      <FormLabel
                        className={classes.labelHorizontal}
                        style={{ marginBottom: '7px' }}
                      >
                        {t('form.field.code.label')} *
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'code',
                          value: formData.code,
                          onChange: handleChange,
                        }}
                      />
                    </GridItem>

                    <GridItem sm={8}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.notes.label')}
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'notes',
                          value: formData.notes,
                          onChange: handleChange,
                          style: { marginTop: '7px' },
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>

          <CardWrapper
            title={t('views.addons.addons.section.logisticSettings.title')}
          >
            <CardBody>
              <GridContainer>
                <GridItem sm={8}>
                  <GridContainer>
                    <GridItem sm={4}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.volume.label')}
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'volume',
                          value: formData.volume,
                          onChange: handleChange,
                          type: 'number',
                          style: { marginTop: '9px' },
                        }}
                      />
                    </GridItem>
                    <GridItem sm={4}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t('form.field.weight.label')}
                      </FormLabel>
                      <CustomInput
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          name: 'weight',
                          value: formData.weight,
                          onChange: handleChange,
                          type: 'number',
                          style: { marginTop: '9px' },
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </CardBody>
          </CardWrapper>
        </>
      )}

      {subpage === ADDON_SUBPAGE && (
        <>
          <BasicInfoCard
            urlSlug={addonSubpageData.urlSlug}
            metaTitle={addonSubpageData.metaTitle}
            metaDescription={addonSubpageData.metaDescription}
            handleChange={handleAddonSubpageInputChange}
            subpage="/addons/"
          />

          <DescriptionCard
            value={addonDescription}
            setValue={setAddonDescription}
            title={t('views.addons.addonSubpage.addonDescription')}
          />
        </>
      )}

      <FormControlButtons
        classes={classes}
        discardText={t('form.cancel')}
        submitText={t('form.save')}
        cancelPath="/admin/addons"
        handleSubmit={handleSubmit}
      />
    </Fragment>
  );
};

export default AddonsFormView;
