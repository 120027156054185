import React from 'react';
import { compose } from 'redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import withStyles from '@material-ui/core/styles/withStyles';

import ExtensionIcon from '@material-ui/icons/Extension';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import AdminTable from 'layouts/AdminTable';
import DataGrid from 'components/DataGrid/DataGrid';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { isGranted } from 'helpers/helpers';
import { ROLE_CREATE_ADDON } from 'helpers/roles';

import columnConfig from './columnConfig';

const AddonsCategoriesList = ({ classes }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const newCategory = () => history.push(`${window.location.pathname}/add`);

  return (
    <AdminTable
      title={t('views.addons.addonsCategories.list')}
      iconComponent={<ExtensionIcon />}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        {isGranted(ROLE_CREATE_ADDON) && (
          <FormControlButtons
            classes={classes}
            handleSubmit={newCategory}
            submitText={`${t('views.addons.addonsCategories.addCategory')} +`}
          />
        )}
      </div>
      <DataGrid
        actions={true}
        editPath={`${window.location.pathname}/edit`}
        remove={true}
        export={true}
        paginationTop={true}
        paginationBottom={false}
        url="/addon-categories"
        reportName={'addoncategory'}
        columns={columnConfig.map(({ title, ...rest }) => ({
          ...rest,
          title: t(`columns.${title}`),
        }))}
        role="ADDON"
      />
    </AdminTable>
  );
};

const enhance = compose(withStyles(buttonsStyle));

export default enhance(AddonsCategoriesList);
