import { useState, useEffect } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withToast } from 'material-ui-toast-redux';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Settings from '@material-ui/icons/Settings';
import withStyles from '@material-ui/core/styles/withStyles';
import PowerSettings from '@material-ui/icons/PowerSettingsNew';

import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx';

import { put, get } from 'helpers/apiHelpers';

import {
  handleBrandChange,
  refreshLogin,
  setSelectedLanguage,
} from 'actions/Auth';
import { fetchLanguages } from 'actions/Auth';

import Button from 'components/CustomButtons/Button';
import SelectInput from 'components/FormSelect/SelectInput';

import { ROLE_EDIT_BRAND } from 'helpers/roles';
import { isGranted, combineStyles } from 'helpers/helpers';

import 'moment/locale/en-gb';
import 'moment/locale/uk';
import 'moment/locale/fi';
import 'moment/locale/pl';
import 'moment/locale/ru';

import translationEN from 'locales/en.json';
import translationPL from 'locales/pl.json';

const uploadedContentBaseURL = `${process.env.REACT_APP_API_ENDPOINT}uploaded-media/`;

const SidebarMobileLinks = ({
  user,
  brand,
  brands,
  classes,
  bgColor,
  history,
  rtlActive,
  languages = [],
  openToast,
  fetchLanguages,
  selectedLanguage,
  handleBrandChange,
  setSelectedLanguage,
  adminAllowedToChangeLanguage,
}) => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (!i18n.language) {
      initSidebarMobileLinks();
    }
  }, []);

  useEffect(() => {
    if (language !== i18n.language) {
      setLanguage(i18n.language);
      fetchLanguageBeforeChange(i18n.language);
      moment.locale(i18n.language);
    }
  }, [language]);

  const initSidebarMobileLinks = async () => {
    try {
      await fetchLanguages();

      i18n.changeLanguage(user.language).catch(error => console.error(error));
      moment.locale(user.language);
    } catch (e) {
      console.log({ e });
    }
  };

  const fetchLanguageBeforeChange = lang => {
    const DEFAULT_BUNDLES = { en: translationEN, pl: translationPL };
    return new Promise(async res => {
      const resource = i18n.getResource(lang, 'translation');

      if (typeof resource === 'undefined') {
        let startedLanguage = {};

        try {
          startedLanguage = await get(
            '/translations/loader/admin/default',
            {},
            { forcedLanguage: lang }
          );
        } catch (e) {}
        await i18n.addResourceBundle(lang, 'translation', {
          ...(DEFAULT_BUNDLES[lang ?? i18n.language ?? 'pl'] ?? {}),
          ...startedLanguage,
        });
      }
      res(true);
    });
  };

  const saveUserLanguage = (value, languageObject) => {
    put(user.id, { language: value })
      .then(() => {
        setSelectedLanguage(languageObject);
      })
      .then(() => {
        openToast({
          messages: [t('success.dataUpdated', 'Zaktualizowano dane')],
          type: 'success',
          autoHideDuration: 3000,
        });
      });
  };

  const handleLanguageChange = async (
    { target: { value } },
    languageObject
  ) => {
    await fetchLanguageBeforeChange(value);
    i18n.changeLanguage(value).catch(error => console.error(error));
    moment.updateLocale(value, {
      week: {
        dow: 1,
      },
    });

    saveUserLanguage(value, languageObject);
  };

  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });

  return (
    <div className={classes.sidebarMobileLinks}>
      <div style={{ display: 'flex', margin: '0 15px' }}>
        <div
          className={wrapper}
          style={
            languages && adminAllowedToChangeLanguage
              ? { width: '170px' }
              : { width: '100%', minWidth: '150px', marginLeft: '10px' }
          }
        >
          <SelectInput
            classes={classes}
            options={brands}
            mapBy="name"
            trackBy="id"
            value={brand}
            handleChange={(ev, brand) => {
              handleBrandChange(ev, { brand, refresh: true });
            }}
          />
        </div>

        {languages && adminAllowedToChangeLanguage && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '80px',
            }}
          >
            <div
              style={{
                marginRight: '10px',
              }}
            >
              {selectedLanguage?.image && (
                <img
                  src={`${uploadedContentBaseURL}${selectedLanguage.image.contentUrl}`}
                  alt=""
                />
              )}
            </div>
            <SelectInput
              mapBy="isoCode"
              value={selectedLanguage}
              classes={classes}
              options={languages}
              trackBy="isoCode"
              handleChange={handleLanguageChange}
            />
          </div>
        )}
      </div>

      <div style={{ padding: '5px 15px' }}>
        {isGranted(ROLE_EDIT_BRAND) && (
          <Button
            color={'transparent'}
            style={{ margin: '5px 0', width: '100%' }}
            onClick={() => history.push(`/admin/brands/edit/${brand}`)}
          >
            <div style={{ width: '100%' }}>
              <Settings /> Ustawienia panelu
            </div>
          </Button>
        )}

        <Button
          color={'transparent'}
          onClick={() => history.push('/auth/login')}
          style={{ margin: '5px 0', width: '100%' }}
        >
          <PowerSettings /> {t('brandCfg.logout')}
        </Button>
      </div>
    </div>
  );
};

const combinedStyles = combineStyles(
  sidebarStyle,
  extendedFormsStyle,
  adminNavbarLinksStyle
);

const mapStateToProps = state => {
  return {
    brands: state.Auth.user.brands,
    brand: state.Auth.selectedBrand,
    user: state.Auth.user,
    adminAllowedToChangeLanguage:
      state.Brands.brand.multinational.adminAllowedToChangeLanguage,
    languages: state.Auth.languages,
    selectedLanguage: state.Auth.selectedLanguage,
  };
};

const mapDispatchToProps = {
  handleBrandChange,
  refreshLogin,
  fetchLanguages,
  setSelectedLanguage,
};

const enhance = compose(
  withToast,
  withStyles(combinedStyles),
  connect(mapStateToProps, mapDispatchToProps),
  withRouter
);

export default enhance(SidebarMobileLinks);
