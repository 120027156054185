import { TranslatableObjectKey } from 'hooks/redux/Translatable/useTranslatableLanguages';

const defaultState = {
  mealTypeIngredientsSum: null,
  forceDisableInShop: false,
  isSubmitting: false,
  isLoading: false,
  isAdvancedCreation: false,
  historyHidden: true,
  tags: [],
  clientTags: [],
  productionImageUrl: null,
  clientImageUrl: null,
  customerPortal: null,
  customerPortalUrl: null,
  pdfImage: null,
  pdfImageUrl: null,
  servingSuggestions: [],
  stowages: [],
  emptyMealTypeIngredients: [],
  diets: [],
  mealTypes: [],
  selectedIngredients: [],
  mealSizes: [],
  containers: [],
  ecoContainers: [],
  homeRequirements: [],
  disabledTypes: [],
  editDishMenuPlanner: [],
  dishUniqueDiets: [],
  dishUniqueDatesDietsAndBrands: [],
  dish: {
    id: null,
    nameForClient: { [TranslatableObjectKey]: {} },
    nameForClientInitial: { [TranslatableObjectKey]: {} },
    clientTags: [],
    homeRequirements: [],
    tags: [],
    productionImage: null,
    clientImage: null,
    servingSuggestion: null,
    note: '',
    sizes: [],
    preparingInstructions: '',
    rateAVG: 0,
    rate: 0,
    tips: '',
    preparingEstimatedMinutes: 60,
    types: [],
    brands: [],
    shouldRescale: false,
  },
};

export default defaultState;
