import { goldColor } from 'assets/jss/material-dashboard-pro-react';

const RefreshIcon = ({ color = goldColor[0], ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={27}
    height={27}
    fill="none"
    {...props}
  >
    <path
      fill={color}
      d="M4.957 9.867A9.282 9.282 0 0 1 13.5 4.219a7.9 7.9 0 0 1 5.585 2.315l2.752 2.747h-2.853c-.701 0-1.265.565-1.265 1.266 0 .701.564 1.265 1.265 1.265h5.907c.701 0 1.265-.564 1.265-1.265V4.64c0-.702-.564-1.266-1.265-1.266-.702 0-1.266.564-1.266 1.266v2.853L20.878 4.74A10.429 10.429 0 0 0 13.5 1.688c-4.883 0-9.076 2.963-10.874 7.187a1.267 1.267 0 0 0 2.331.992Zm19.406 8.279a1.264 1.264 0 1 0-2.325-.997 9.28 9.28 0 0 1-8.538 5.632 7.9 7.9 0 0 1-5.585-2.315L5.163 17.72h2.853c.701 0 1.265-.565 1.265-1.266 0-.701-.564-1.265-1.265-1.265H2.109c-.701 0-1.265.564-1.265 1.265v5.906c0 .702.564 1.266 1.265 1.266.702 0 1.266-.564 1.266-1.266v-2.853l2.747 2.748a10.412 10.412 0 0 0 7.378 3.058c4.878 0 9.06-2.953 10.863-7.166Z"
    />
  </svg>
);

export default RefreshIcon;
