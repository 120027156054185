import React from 'react';
import DataGrid from './DataGrid';
import roles from 'helpers/roles';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class PdfGenerator extends React.Component {
  render() {
    const title = this.props.t('reports.pdfReport');

    return (
      <DataGrid
        title={title}
        excelUrl="reports/bag-stickers"
        pdfUrl="reports/bag-stickers-pdf"
        mimeType="application/vnd.ms-excel"
        role={roles.ROLE_SHOW_REPORT_BAG_STICKERS}
        pdfAvaliable
      />
    );
  }
}

const enhance = compose(withTranslation());

export default enhance(PdfGenerator);
