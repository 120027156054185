import { get, remove } from 'helpers/apiHelpers';

import types from 'helpers/constants';

export const fetchMealTypes = () => {
  return dispatch => {
    return get('/meal-types', { pagination: false }).then(response => {
      dispatch({
        type: types.FETCH_MEALTYPES,
        payload: {
          mealTypes: response['hydra:member'],
        },
      });
      return response['hydra:member'];
    });
  };
};

export const fetchMealType = id => {
  return dispatch => {
    return get(`/meal-types/${id}`).then(response => {
      dispatch({
        type: types.FETCH_MEALTYPE,
        payload: {
          name: response.name,
          position: response.position,
          sizes: response.sizes,
        },
      });
      return response;
    });
  };
};

export const removeMealType = id => {
  return dispatch =>
    remove(`/meal-types/${id}`).then(() => {
      dispatch({
        type: types.REMOVE_MEALTYPE,
        payload: {
          mealTypeId: id,
        },
      });
    });
};
