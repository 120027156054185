import React from 'react';
import CardBody from '../../../components/Card/CardBody';
import ReactTable from 'react-table';
import { withTranslation } from 'react-i18next';
import ExcludedIngredientsTooltip from '../ExcludedIngredientsTooltip';
import FormSelectMultiple from '../../../components/FormSelect/FormSelectMultiple';
import FormSelectDropdown from '../../../components/FormSelect/FormSelectDropdown';
import { compose } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import { combineStyles } from '../../../helpers/helpers';
import extendedFormsStyle from '../../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from '../../../assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CardWrapper from 'components/Card/CardWrapper';

const Excludes = ({
  t,
  renderIngredients,
  selectIngredientCategories,
  getFilteredIngredients,
  selectAllergens,
  addMeal,
  state,
  props,
  classes,
  allergens,
  ingredientCategories,
}) => {
  const selectedAllergens = renderIngredients('selectedAllergens');
  const selectedIngredients = renderIngredients('selectedIngredients');
  const selectedIngredientCategories = renderIngredients(
    'selectedIngredientCategories'
  );

  return (
    <CardWrapper
      title={
        <h3 style={{ marginBottom: '20px' }}>
          {t('form.field.excluded')}
          <div
            className={classes.labelHorizontal}
            style={{ marginLeft: '10px', verticalAlign: 'sub' }}
          >
            <ExcludedIngredientsTooltip />
          </div>
        </h3>
      }
    >
      <CardBody style={{ marginBottom: '2rem' }}>
        <GridContainer>
          <GridItem xs={12} lg={6}>
            <FormSelectMultiple
              classes={classes}
              label={t('form.field.allergens')}
              options={allergens}
              value={state.selectedAllergens}
              mapBy={'value'}
              handleChange={selectAllergens}
              name={'allergens'}
              id={'allergens'}
              labelSm={3}
              selectSm={4}
              dontShowInline
            />
            <ReactTable
              data={selectedAllergens}
              resizable={false}
              sortable={false}
              columns={[
                {
                  Header: t('form.field.excludedAllergens'),
                  accessor: 'name',
                  width: '100%',
                },
              ]}
              minRows={1}
              defaultPageSize={state.reactTablePageSize}
              showPaginationTop={false}
              showPaginationBottom={
                selectedAllergens.length > state.reactTablePageSize
              }
              className="-striped -highlight"
              noDataText=""
              previousText={t('dataGrid.prevPage')}
              nextText={t('dataGrid.nextPage')}
              loadingText={t('dataGrid.loading')}
              pageText={t('dataGrid.page')}
              ofText={t('dataGrid.pageOf')}
              rowsText={t('dataGrid.results')}
            />
            <FormSelectDropdown
              filterName="name"
              placeholder={t('form.field.typeIngredient')}
              handleClick={addMeal}
              clearAfterSelect={true}
              filter={getFilteredIngredients}
              includeWorkName
            />
            <ReactTable
              data={selectedIngredients}
              resizable={false}
              sortable={false}
              columns={[
                {
                  Header: t('form.field.excludedIngredients'),
                  accessor: 'name',
                  width: '100%',
                },
              ]}
              minRows={1}
              defaultPageSize={state.reactTablePageSize}
              showPaginationBottom={
                selectedIngredients.length > state.reactTablePageSize
              }
              className="-striped -highlight"
              noDataText=""
              previousText={t('dataGrid.prevPage')}
              nextText={t('dataGrid.nextPage')}
              loadingText={t('dataGrid.loading')}
              pageText={t('dataGrid.page')}
              ofText={t('dataGrid.pageOf')}
              rowsText={t('dataGrid.results')}
            />
            <FormSelectMultiple
              classes={classes}
              label={t('form.field.ingredientCategories')}
              options={ingredientCategories}
              value={state.selectedIngredientCategories}
              mapBy={'value'}
              handleChange={selectIngredientCategories}
              name={'ingredientCategories'}
              id={'ingredientCategories'}
              labelSm={3}
              selectSm={4}
              dontShowInline
            />
            <ReactTable
              data={selectedIngredientCategories}
              resizable={false}
              sortable={false}
              columns={[
                {
                  Header: t('form.field.excludedIngredientCategories'),
                  accessor: 'name',
                  width: '100%',
                },
              ]}
              minRows={1}
              defaultPageSize={state.reactTablePageSize}
              showPaginationTop={false}
              showPaginationBottom={
                selectedIngredientCategories.length > state.reactTablePageSize
              }
              className="-striped -highlight"
              noDataText=""
              previousText={t('dataGrid.prevPage')}
              nextText={t('dataGrid.nextPage')}
              loadingText={t('dataGrid.loading')}
              pageText={t('dataGrid.page')}
              ofText={t('dataGrid.pageOf')}
              rowsText={t('dataGrid.results')}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </CardWrapper>
  );
};

const combinedStyles = combineStyles(extendedFormsStyle, buttonsStyle);

const enhance = compose(withTranslation(), withStyles(combinedStyles));

export default enhance(Excludes);
