import React, { Component } from 'react';

import { combineStyles } from 'helpers/helpers';
import { get, post, put, remove } from 'helpers/apiHelpers';

import { isGranted } from 'helpers/helpers';
import { ROLE_EDIT_COST } from 'helpers/roles';

import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle';
import buttonsStyle from 'assets/jss/material-dashboard-pro-react/views/buttonsStyle';
import validationFormsStyle from 'assets/jss/material-dashboard-pro-react/views/validationFormsStyle';

import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import FormSelectSingle from 'components/FormSelect/FormSelectSingle';
import FormControlButtons from 'components/FormControlButtons/FormControlButtons';

import { withToast } from 'material-ui-toast-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';

class DietDiscounts extends Component {
  state = {
    rows: [],
  };

  componentDidMount() {
    get('/discount-diet-lengths').then(discountResponse =>
      this.setState({
        rows: discountResponse['hydra:member'].map(el => ({
          hasChanged: false,
          _minLength: el.minLength,
          _maxLength: el.maxLength,
          _isPercentage: el.isPercentage,
          _discount: el.discount,
          ...el,
        })),
      })
    );
  }

  addRow = () => {
    const newRow = {
      minLength: '',
      maxLength: '',
      isPercentage: '',
      discount: '',
      isLoading: false,
      hasChanged: false,
    };

    this.setState({
      rows: this.state.rows.concat(newRow),
    });
  };

  deleteRow = async index => {
    const rowsAfterDelete = this.state.rows.filter((el, ind) => ind !== index);

    const id = this.state.rows[index].id;

    if (id) {
      remove(`/discount-diet-lengths/${id}`);
    }

    this.setState({
      rows: rowsAfterDelete,
    });
  };

  handleChange = (event, index) => {
    const { name, value } = event.target;
    const { rows } = this.state;

    if (!isNaN(value)) {
      let initialVal = `_${name}`;
      rows[index].hasChanged =
        this.state.rows[index][initialVal] !== parseInt(value);

      rows[index][name] = value;

      this.setState({ rows });
    }
  };

  saveRow = index => {
    const { rows } = this.state;
    this.setState({
      isLoading: true,
    });
    const currentRow = rows[index];

    if (
      currentRow.discount === '' ||
      currentRow.minLength === '' ||
      currentRow.maxLength === ''
    ) {
      this.props.openToast({
        messages: [this.props.t('common.shared.fillAllFields')],
        type: 'error',
        autoHideDuration: 3000,
      });
      this.setState({
        isLoading: false,
      });
    }

    if (
      isNaN(currentRow.discount) ||
      isNaN(currentRow.minLength) ||
      isNaN(currentRow.maxLength)
    ) {
      this.props.openToast({
        messages: [this.props.t('common.shared.fieldsMustBeNumber')],
        type: 'error',
        autoHideDuration: 3000,
      });
      return this.setState({
        isLoading: false,
      });
    }

    const postData = {
      minLength: parseInt(currentRow.minLength),
      maxLength: parseInt(currentRow.maxLength),
      isPercentage: currentRow.isPercentage,
      discount: parseFloat(currentRow.discount),
    };

    const action = currentRow.id ? put : post;
    const url = currentRow.id
      ? `/discount-diet-lengths/${currentRow.id}`
      : '/discount-diet-lengths';

    action(url, postData).then(() => {
      get('/discount-diet-lengths').then(discountResponse =>
        this.setState({ rows: discountResponse['hydra:member'] })
      );
      this.props.openToast({
        messages: [this.props.t('common.shared.discountSaved')],
        type: 'success',
        autoHideDuration: 3000,
      });
    });
    this.setState({
      isLoading: false,
    });
  };

  render() {
    const { classes } = this.props;
    const { rows } = this.state;

    const DiscountElementTypes = [
      {
        name: this.props.t('common.shared.percentage', 'Procentowy'),
        value: true,
      },
      {
        name: this.props.t('common.shared.amount', 'Kwotowy'),
        value: false,
      },
    ];

    return (
      <GridItem md={12}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>
                {this.props.t('common.shared.dayCountFrom')}
              </TableCell>
              <TableCell>{this.props.t('common.shared.dayCountTo')}</TableCell>
              <TableCell>
                {this.props.t('common.shared.discountType')}
              </TableCell>
              <TableCell>
                {this.props.t('common.shared.discountForDay')}
              </TableCell>
              {isGranted(ROLE_EDIT_COST) && <TableCell />}
              {isGranted(ROLE_EDIT_COST) && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => {
              return (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: row.hasChanged ? '#ffffd4' : 'transparent',
                  }}
                >
                  <TableCell component="th" scope="row">
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        disabled: !isGranted(ROLE_EDIT_COST),
                        type: 'text',
                        placeholder: this.props.t('common.shared.typeValue'),
                        name: 'minLength',
                        value: row.minLength,
                        onChange: event => this.handleChange(event, index),
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        disabled: !isGranted(ROLE_EDIT_COST),
                        type: 'text',
                        placeholder: this.props.t('common.shared.typeValue'),
                        name: 'maxLength',
                        value: row.maxLength,
                        onChange: event => this.handleChange(event, index),
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FormSelectSingle
                      classes={classes}
                      mapBy="name"
                      name="isPercentage"
                      trackBy="value"
                      value={row.isPercentage}
                      options={DiscountElementTypes}
                      disabled={!isGranted(ROLE_EDIT_COST)}
                      handleChange={event => this.handleChange(event, index)}
                      labelSm={0}
                      selectSm={8}
                    />
                  </TableCell>
                  <TableCell>
                    <CustomInput
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        disabled: !isGranted(ROLE_EDIT_COST),
                        type: 'text',
                        placeholder: this.props.t('common.shared.typeValue'),
                        name: 'discount',
                        value: row.discount,
                        onChange: event => this.handleChange(event, index),
                      }}
                    />
                  </TableCell>
                  {isGranted(ROLE_EDIT_COST) && (
                    <TableCell>
                      <FormControlButtons
                        classes={classes}
                        handleSubmit={() => this.saveRow(index)}
                        submitText={this.props.t('common.shared.save')}
                        submitDisabled={this.state.isLoading || !row.hasChanged}
                      />
                    </TableCell>
                  )}
                  {isGranted(ROLE_EDIT_COST) && (
                    <TableCell>
                      <FormControlButtons
                        classes={classes}
                        submitColor={'danger'}
                        handleSubmit={() => this.deleteRow(index)}
                        submitText={this.props.t('common.shared.delete')}
                      />
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
            {isGranted(ROLE_EDIT_COST) && (
              <TableRow>
                <TableCell
                  className={'variant-addRow'}
                  colSpan={6}
                  onClick={this.addRow}
                >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ display: 'block' }}>
                      + {this.props.t('common.shared.addDiscount')}
                    </span>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </GridItem>
    );
  }
}

const combinedStyles = combineStyles(
  extendedFormsStyle,
  validationFormsStyle,
  buttonsStyle
);

const enhance = compose(
  withStyles(combinedStyles),
  withToast,
  withTranslation()
);

export default enhance(DietDiscounts);
