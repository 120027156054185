import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormLabel,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@material-ui/core';
import { Check, Info } from '@material-ui/icons';

import FormTextInputNoGrid from 'components/FormTextInput/FormTextInputNoGrid';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import SelectInput from 'components/FormSelect/SelectInput';

import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';

const FakturowniaModule = ({ classes, handleModuleUpdate, module }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const integrationOptions = useMemo(
    () => [
      {
        label: t(
          'module.Fakturownia.integrationOption.invoice',
          'Generuj faktury'
        ),
        value: 'INVOICE',
      },
      {
        label: t(
          'module.Fakturownia.integrationOption.advance',
          'Generuj zamówienia i faktury zaliczkowe'
        ),
        value: 'ADVANCE',
      },
      {
        label: t(
          'module.Fakturownia.integrationOption.receipt',
          'Generuj paragony'
        ),
        value: 'RECEIPT',
      },
    ],
    [language]
  );

  const invoiceDatesOptions = useMemo(
    () => [
      {
        label: t(
          'module.Fakturownia.invoiceDatesOptions.orderDate',
          'Data złożenia zamówienia'
        ),
        value: 'ORDER_DATE',
      },
      {
        label: t(
          'module.Fakturownia.invoiceDatesOptions.lastBagDate',
          'Data ostatniej torby'
        ),
        value: 'LAST_BAG_DATE',
      },
    ],
    [language]
  );

  const dietIntegrationModes = useMemo(
    () =>
      ['DIET', 'BAGS'].map(el => ({
        label: t(`module.Fakturownia.dietIntegrationMode.${el}`),
        value: el,
      })),
    [language]
  );

  const handleCheckboxClick = () => {
    if (module.integrationType === 'DISABLED') {
      handleModuleUpdate({
        ...module,
        integrationType: 'INVOICE',
      });
    } else {
      handleModuleUpdate({
        ...module,
        integrationType: 'DISABLED',
      });
    }
  };

  if (!module) {
    return (
      <div>
        {t('brands.newBrandForm.moduleNotLoaded', {
          defaultValue: '{{moduleName}} not loaded.',
          moduleName: 'FakturowniaModule',
        })}
      </div>
    );
  }
  return (
    <>
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                style={{ marginRight: '3px' }}
                control={
                  <Checkbox
                    checked={module.allowInvoicesForClient}
                    onChange={() =>
                      handleModuleUpdate({
                        ...module,
                        allowInvoicesForClient: !module.allowInvoicesForClient,
                      })
                    }
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={t(
                  'brands.newBrandForm.showOption',
                  'Pokaż opcję wystawiania dokumentów księgowych na firmę'
                )}
              />
              <Tooltip
                title={
                  <h4>
                    {t(
                      'module.Fakturownia.addonsCustomName.showOptionInvoiceTooltip',
                      '$$Paragonów z NIP i/lub faktur.'
                    )}
                  </h4>
                }
                placement="top"
              >
                <Info style={{ marginLeft: '0px', fontSize: '16px' }} />
              </Tooltip>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <GridContainer>
            <GridItem md={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      module.integrationType === 'INVOICE' ||
                      module.integrationType === 'RECEIPT' ||
                      module.integrationType === 'ADVANCE'
                    }
                    onChange={handleCheckboxClick}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot,
                    }}
                  />
                }
                label={'Fakturownia.pl'}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <GridContainer>
                <div
                  style={{
                    margin: '10px',
                    border: '1px solid #cfc9c9',
                    width: '100%',
                  }}
                >
                  <GridItem md={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('brands.newBrandForm.host', 'Host ')}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={classes}
                      name="host"
                      value={module.host}
                      maxLength={64}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          host: e.target.value,
                        })
                      }
                    />
                  </GridItem>

                  <GridItem md={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('brands.newBrandForm.token', 'Token ')}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={classes}
                      name="token"
                      value={module.token}
                      maxLength={64}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          token: e.target.value,
                        })
                      }
                    />
                  </GridItem>

                  <GridItem md={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t('brands.newBrandForm.department', 'Departament ')}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={classes}
                      name="department"
                      value={module.department}
                      maxLength={64}
                      handleChange={e => {
                        if (!isNaN(e.target.value)) {
                          handleModuleUpdate({
                            ...module,
                            department: e.target.value,
                          });
                        }
                      }}
                    />
                  </GridItem>
                </div>

                <div
                  style={{
                    margin: '10px',
                    border: '1px solid #cfc9c9',
                    width: '100%',
                  }}
                >
                  <GridItem md={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'module.Fakturownia.integrationType.label',
                        'Tryb integracji'
                      )}
                    </FormLabel>
                    <SelectInput
                      noGrid
                      classes={classes}
                      mapBy="label"
                      trackBy="value"
                      name="integrationType"
                      value={module.integrationType}
                      options={integrationOptions}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          integrationType: e.target.value,
                        })
                      }
                      size={12}
                      disabled={module.integrationType === 'DISABLED'}
                    />
                  </GridItem>

                  {module.integrationType === 'RECEIPT' && (
                    <>
                      <GridItem md={12} style={{ height: '65px' }}>
                        <FormLabel className={classes.labelHorizontal}>
                          {t(
                            'brands.newBrandForm.minAmountInvoice',
                            'Kwota od której mają generować się faktury dla firm, zamiast paragonów z NIP-em'
                          )}
                        </FormLabel>
                        <FormTextInputNoGrid
                          classes={classes}
                          name="minAmountInvoice"
                          value={module.minAmountInvoice}
                          maxLength={64}
                          handleChange={e => {
                            if (!isNaN(e.target.value)) {
                              handleModuleUpdate({
                                ...module,
                                minAmountInvoice: e.target.value,
                              });
                            }
                          }}
                        />
                      </GridItem>
                      <GridItem md={12} style={{ height: '65px' }}>
                        <FormControlLabel
                          style={{ marginRight: '3px' }}
                          control={
                            <Checkbox
                              checked={module.autoPrint}
                              onChange={() =>
                                handleModuleUpdate({
                                  ...module,
                                  autoPrint: !module.autoPrint,
                                })
                              }
                              checkedIcon={
                                <Check className={classes.checkedIcon} />
                              }
                              icon={<Check className={classes.uncheckedIcon} />}
                              classes={{
                                checked: classes.checked,
                                root: classes.checkRoot,
                              }}
                            />
                          }
                          label={t(
                            'module.Fakturownia.integrationOption.autoPrint',
                            '$$Drukuj paragony automatycznie'
                          )}
                        />
                        <Tooltip
                          title={
                            <h5>
                              {t(
                                'module.Fakturownia.addonsCustomName.printReceiptAutomaticallyTooltip',
                                '$$W przypadku odpowiedniego połączenia fakturownia.pl z drukarką fiskalną, paragony będą drukować się automatycznie i na bieżąco.'
                              )}
                            </h5>
                          }
                          placement="top"
                        >
                          <Info
                            style={{ marginLeft: '0px', fontSize: '16px' }}
                          />
                        </Tooltip>
                      </GridItem>
                    </>
                  )}
                </div>

                {module.integrationType === 'INVOICE' && (
                  <div
                    style={{
                      margin: '10px',
                      border: '1px solid #cfc9c9',
                      width: '100%',
                    }}
                  >
                    <GridItem md={12} style={{ height: '65px' }}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t(
                          'module.Fakturownia.integrationType.invoiceIssueDateType',
                          'Data Wystawienia faktury'
                        )}
                      </FormLabel>
                      <SelectInput
                        noGrid
                        classes={classes}
                        mapBy="label"
                        trackBy="value"
                        name="invoiceIssueDateType"
                        value={module.invoiceIssueDateType}
                        options={invoiceDatesOptions}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            invoiceIssueDateType: e.target.value,
                          })
                        }
                        size={12}
                        disabled={module.integrationType === 'DISABLED'}
                      />
                    </GridItem>

                    <GridItem md={12} style={{ height: '65px' }}>
                      <FormLabel className={classes.labelHorizontal}>
                        {t(
                          'module.Fakturownia.integrationType.invoiceSellDateType',
                          'Data sprzedaży na fakturze'
                        )}
                      </FormLabel>
                      <SelectInput
                        noGrid
                        classes={classes}
                        mapBy="label"
                        trackBy="value"
                        name="invoiceSellDateType"
                        value={module.invoiceSellDateType}
                        options={invoiceDatesOptions}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            invoiceSellDateType: e.target.value,
                          })
                        }
                        size={12}
                        disabled={module.integrationType === 'DISABLED'}
                      />
                    </GridItem>
                  </div>
                )}

                <div
                  style={{
                    margin: '10px',
                    border: '1px solid #cfc9c9',
                    width: '100%',
                  }}
                >
                  <GridItem md={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'module.Fakturownia.dietIntegrationMode.label',
                        'Tryb pozycji diety'
                      )}
                    </FormLabel>
                    <SelectInput
                      noGrid
                      classes={classes}
                      mapBy="label"
                      trackBy="value"
                      name="dietIntegrationMode"
                      value={module.dietIntegrationMode}
                      options={dietIntegrationModes}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          dietIntegrationMode: e.target.value,
                        })
                      }
                      size={12}
                      disabled={module.integrationType === 'DISABLED'}
                    />
                  </GridItem>
                  <GridItem md={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'brands.newBrandForm.productName',
                        'Nazwa pozycji produktu (diety)'
                      )}

                      <Tooltip
                        title={
                          <h4>
                            {t(
                              'brands.newBrandForm.typeOfDiet',
                              'Wartość % VAT jest pobierana z cennika rodzaju diety.'
                            )}
                          </h4>
                        }
                        placement="top"
                      >
                        <Info style={{ marginLeft: '4px', fontSize: '16px' }} />
                      </Tooltip>
                    </FormLabel>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={module.dietItemNameEnabled}
                        onChange={() =>
                          handleModuleUpdate({
                            ...module,
                            dietItemNameEnabled: !module.dietItemNameEnabled,
                          })
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                        style={{ padding: '11px 14px 9px 0' }}
                      />

                      <FormTextInputNoGrid
                        classes={classes}
                        name="dietItemName"
                        value={module.dietItemName}
                        maxLength={255}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            dietItemName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </GridItem>

                  <GridItem md={12}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'module.Fakturownia.addonsCustomName.label',
                        'Nazwa pozycji dodatki'
                      )}
                      <Tooltip
                        title={
                          <h4>
                            {t(
                              'module.Fakturownia.addonsCustomName.toltip',
                              'Wartość % VAT jest pobierana z dodatków.'
                            )}
                          </h4>
                        }
                        placement="top"
                      >
                        <Info style={{ marginLeft: '4px', fontSize: '16px' }} />
                      </Tooltip>
                    </FormLabel>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={module.addonsCustomNameEnabled}
                        onChange={() =>
                          handleModuleUpdate({
                            ...module,
                            addonsCustomNameEnabled: !module.addonsCustomNameEnabled,
                          })
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                        style={{ padding: '11px 14px 9px 0' }}
                      />

                      <FormTextInputNoGrid
                        classes={classes}
                        name="addonsCustomName"
                        value={module.addonsCustomName}
                        maxLength={255}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            addonsCustomName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </GridItem>

                  <GridItem xs={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'brands.newBrandForm.dishNameOnInvoice',
                        'Nazwy dań na fakturze'
                      )}
                    </FormLabel>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={module.dishesCustomNameEnabled}
                        onChange={() =>
                          handleModuleUpdate({
                            ...module,
                            dishesCustomNameEnabled: !module.dishesCustomNameEnabled,
                          })
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                        style={{ padding: '11px 14px 9px 0' }}
                      />

                      <FormTextInputNoGrid
                        classes={classes}
                        name="dishesCustomName"
                        value={module.dishesCustomName}
                        maxLength={255}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            dishesCustomName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </GridItem>

                  <GridItem xs={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'brands.newBrandForm.mealTypeNameOnInvoice',
                        'Nazwy dodatkowych posiłków na fakturze'
                      )}
                    </FormLabel>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Checkbox
                        checked={module.mealTypesCustomNameEnabled}
                        onChange={() =>
                          handleModuleUpdate({
                            ...module,
                            mealTypesCustomNameEnabled: !module.mealTypesCustomNameEnabled,
                          })
                        }
                        checkedIcon={<Check className={classes.checkedIcon} />}
                        icon={<Check className={classes.uncheckedIcon} />}
                        classes={{
                          checked: classes.checked,
                          root: classes.checkRoot,
                        }}
                        style={{ padding: '11px 14px 9px 0' }}
                      />

                      <FormTextInputNoGrid
                        classes={classes}
                        name="mealTypesCustomName"
                        value={module.mealTypesCustomName}
                        maxLength={255}
                        handleChange={e =>
                          handleModuleUpdate({
                            ...module,
                            mealTypesCustomName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </GridItem>
                </div>
              </GridContainer>
            </GridItem>

            <GridItem md={6}>
              <GridContainer>
                <div
                  style={{
                    margin: '10px',
                    border: '1px solid #cfc9c9',
                    width: '100%',
                  }}
                >
                  <GridItem md={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'brands.newBrandForm.deliveryItem',
                        'Nazwa pozycji dostawy'
                      )}
                      <Tooltip
                        title={
                          <h4>
                            {t(
                              'brands.newBrandForm.zoneDelivery',
                              'Wartość % VAT jest pobierana z cennika dostawy '
                            )}
                          </h4>
                        }
                        placement="top"
                      >
                        <Info style={{ marginLeft: '4px', fontSize: '16px' }} />
                      </Tooltip>
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={classes}
                      name="deliveryItemName"
                      value={module.deliveryItemName}
                      maxLength={255}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          deliveryItemName: e.target.value,
                        })
                      }
                    />
                  </GridItem>
                  <GridItem md={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'brands.newBrandForm.dietDayMod',
                        'Nazwa pozycji "modyfikacji dnia diety"'
                      )}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={classes}
                      name="bagModificationName"
                      value={module.bagModificationName}
                      maxLength={255}
                      handleChange={e =>
                        handleModuleUpdate({
                          ...module,
                          bagModificationName: e.target.value,
                        })
                      }
                    />
                  </GridItem>
                </div>
                <div
                  style={{
                    margin: '10px',
                    border: '1px solid #cfc9c9',
                    width: '100%',
                  }}
                >
                  <GridItem md={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'module.Fakturownia.bagModificationVat.label',
                        'Stawka VAT pozycji "modyfikacji dnia diety"'
                      )}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={classes}
                      name="bagModificationVat"
                      value={module.bagModificationVat}
                      maxLength={255}
                      handleChange={e => {
                        if (!isNaN(e.target.value)) {
                          handleModuleUpdate({
                            ...module,
                            bagModificationVat: e.target.value,
                          });
                        }
                      }}
                    />
                  </GridItem>
                  <GridItem md={12} style={{ height: '65px' }}>
                    <FormLabel className={classes.labelHorizontal}>
                      {t(
                        'module.Fakturownia.shopDishVat.label',
                        'Stawka VAT dla dań sklepowych'
                      )}
                    </FormLabel>
                    <FormTextInputNoGrid
                      classes={classes}
                      name="dishesVat"
                      value={module.dishesVat}
                      maxLength={255}
                      handleChange={e => {
                        if (!isNaN(e.target.value)) {
                          handleModuleUpdate({
                            ...module,
                            dishesVat: e.target.value,
                          });
                        }
                      }}
                    />
                  </GridItem>
                </div>
                <div
                  style={{
                    margin: '10px',
                    border: '1px solid #cfc9c9',
                    width: '100%',
                  }}
                >
                  <GridItem md={12} style={{ height: '65px' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={module.showMoneyboxOnInvoice}
                          onChange={() =>
                            handleModuleUpdate({
                              ...module,
                              showMoneyboxOnInvoice: !module.showMoneyboxOnInvoice,
                            })
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={t(
                        'brands.newBrandForm.fakturownia.addCommentWithAmountOfUsedMoneyboxPoints',
                        '$$W komentarzu do faktury podaj liczbę zużytych punktów do skarbonki'
                      )}
                    />
                  </GridItem>
                  <GridItem md={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={module.mergeSamePositions}
                          onChange={() =>
                            handleModuleUpdate({
                              ...module,
                              mergeSamePositions: !module.mergeSamePositions,
                            })
                          }
                          checkedIcon={
                            <Check className={classes.checkedIcon} />
                          }
                          icon={<Check className={classes.uncheckedIcon} />}
                          classes={{
                            checked: classes.checked,
                            root: classes.checkRoot,
                          }}
                        />
                      }
                      label={t(
                        'brands.newBrandForm.mergeSamePositions',
                        'Scalaj tak samo nazwane pozycje w jedną'
                      )}
                    />
                  </GridItem>
                </div>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </>
  );
};

export default FakturowniaModule;
