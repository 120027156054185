import React from 'react';
import styled from 'styled-components';
import { Async } from 'react-select';
import { useTranslation } from 'react-i18next';

import FormControlLabel from '@material-ui/core/FormControlLabel';

import Select from 'components/CustomSelect/Select';
import Checkbox from 'components/CustomCheckbox/Checkbox';

import { asyncSelectStyles } from 'views/Orders/styles';

import BoxSection from '../BoxSection';
import OrderSummarySection from '../OrderSummarySection/OrderSummarySection';
import CustomerInvoiceSection from '../CustomerInvoiceSection/CustomerInvoiceSection';
import { withToast } from 'material-ui-toast-redux';
const SummarySectionWrapper = styled.div`
  width: 100%;
  display: flex;
  margin: 0 -5px;
  flex-flow: column;
  align-itmes: center;
  justify-content: space-between;

  @media (min-width: 769px) {
    flex-flow: row;
  }
`;

const SummarySection = ({
  diets,
  classes,
  orderCost,
  promoCode,
  paymentType,
  useMoneyBox,
  usePromoCode,
  paymentMethods,
  setUseMoneyBox,
  setUsePromoCode,
  selectPromoCode,
  sendConfirmation,
  promisePromoCodes,
  selectPaymentType,
  isInvoiceIncluded,
  setSendConfirmation,
  customerInvoiceData,
  setIsInvoiceIncluded,
  setCustomerInvoiceData,
  openToast,
}) => {
  const { t } = useTranslation();

  return (
    <SummarySectionWrapper>
      <BoxSection
        title={t('orders.additionalOptions', '$$Opcje dodatkowe')}
        style={{ margin: '5px' }}
      >
        <div
          style={{ width: '100%' }}
          data-cy="new-order_additional-option_send-confirm"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={sendConfirmation}
                onClick={e => {
                  setSendConfirmation(prev => !prev);
                }}
              />
            }
            label={t(
              'orders.sendClientNotify',
              '$$Wyślij potwierdzenie do klienta'
            )}
          />
        </div>
        <div
          style={{ width: '100%' }}
          data-cy="new-order_additional-option_discount-code"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={usePromoCode}
                onClick={() => {
                  setUsePromoCode(!usePromoCode);
                }}
              />
            }
            label={t(
              'orders.newOrder.SummarySection.discountCode',
              'Użyj kodu rabatowego'
            )}
          />
          {usePromoCode && (
            <>
              <Async
                styles={asyncSelectStyles}
                isClearable={true}
                loadOptions={promisePromoCodes}
                onChange={code => {
                  if (code?.value?.diets.length > 0) {
                    let array = [];
                    code?.value?.diets.map(el => {
                      return array.push(el === diets?.[0].selectedDiet.value);
                    });
                    if (array.includes(true)) {
                    } else {
                      return openToast({
                        messages: [
                          t(
                            'orders.discountCodeExclude',
                            'Kod rabatowy został wykluczony z tej diety.'
                          ),
                        ],
                        type: 'error',
                        autoHideDuration: 3000,
                      });
                    }
                  }

                  return selectPromoCode(code);
                }}
                value={promoCode}
                placeholder={t('orders.discountCode', '$$Kod rabatowy')}
                clearValue={() => selectPromoCode(null)}
                noOptionsMessage={() => t('orders.discountCodeContent')}
              />
            </>
          )}
        </div>
        <div
          style={{ width: '100%' }}
          data-cy="new-order_additional-option_piggy-bank-points"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={useMoneyBox}
                onClick={e => {
                  setUseMoneyBox(!useMoneyBox);
                }}
              />
            }
            label={t(
              'orders.useMoneyboxPoints',
              '$$Wykorzystaj punkty ze skarbonki'
            )}
          />
        </div>
        <div
          style={{ width: '100%' }}
          data-cy="new-order_additional-option_issue-invoice"
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isInvoiceIncluded}
                onClick={() => {
                  setIsInvoiceIncluded(!isInvoiceIncluded);
                }}
              />
            }
            label={t('orders.makeInvoice', '$$Wystaw fakturę')}
          />
          {isInvoiceIncluded && (
            <CustomerInvoiceSection
              classes={classes}
              customerInvoiceData={customerInvoiceData}
              setCustomerInvoiceData={setCustomerInvoiceData}
            />
          )}
        </div>
      </BoxSection>
      <BoxSection
        title={t('orders.paymentSection', '$$Płatność')}
        style={{ margin: '5px' }}
      >
        <div style={{ marginTop: '15px' }} data-cy="new-order_payment-types">
          <Select
            classes={classes}
            mapBy="label"
            trackBy="value"
            label={t('orders.select', '$$Wybierz')}
            value={paymentType}
            options={paymentMethods}
            handleChange={option => {
              selectPaymentType(option);
            }}
          />
        </div>
      </BoxSection>
      <OrderSummarySection
        diets={diets}
        orderCost={orderCost}
        useMoneyBox={useMoneyBox}
      />
    </SummarySectionWrapper>
  );
};

export default withToast(SummarySection);
